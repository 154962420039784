import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/shared/interfaces/user.model';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-drive-license',
  templateUrl: './drive-license.component.html',
  styleUrls: ['./drive-license.component.scss']
})
export class DriveLicenseComponent implements OnInit {
  @Input() user: User;
  @Input() isUser: boolean;


  constructor(private userService: UserService) { }

  ngOnInit(): void {


    console.log(this.user, "user")

  }

  replaceDriveLicense() {
    const data = this.user.cv.data.map(t => t.skills[0].subCategories[0].driveLicenses.data);
    let newArr = Array.prototype.concat(...data);

    newArr =  newArr.filter((el, i, a) => i === a.findIndex((findTest) => findTest.name === el.name));



    this.user.searchFilterSkills.searchDriveLicenses.data = [];
    this.user.searchFilterSkills.searchDriveLicenses.data = newArr;
   this.userService.updateUser(this.user._id, this.user);
  }

  removeDriveLicense(index) {
    this.user.searchFilterSkills.searchDriveLicenses.data.splice(index, 1);
    this.userService.updateUser(this.user._id, this.user);
  }

}
