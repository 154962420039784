import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivityField, Branches, EmploymentType, StatusTitle } from 'src/app/shared/enums/enums-model';
import { ActivityFieldArray, AdsBranch, AdsCategory, AdsEmployment, AdsManager, EmploymentTypeArray, ImageCards, Page } from 'src/app/shared/interfaces/model';
import { AdsManagerService } from 'src/app/shared/services/ads-manager.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BranchesService } from 'src/app/shared/services/branches.service';
import { Categories, CategoryService } from 'src/app/shared/services/category.service';
import { ChangeDetectionService } from 'src/app/shared/services/change-detection.service';
import { CountryDataService } from 'src/app/shared/services/country-data.service';
import { PagesService } from 'src/app/shared/services/pages.service';
import { SenderService } from 'src/app/shared/services/sender.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { CarouselCardDialogComponent } from 'src/app/components/modals/carousel-card-dialog/carousel-card-dialog.component';
@Component({
  selector: 'app-ads-manager',
  templateUrl: './ads-manager.component.html',
  styleUrls: ['./ads-manager.component.scss']
})
export class AdsManagerComponent implements OnInit, AfterViewInit {
  countryJson;
  selectedIndex: boolean = false;
  isEditingNote: boolean = false;
  selectedIndexPage: boolean = false;
  selectedWorkplace: boolean = false;
  minDate: Date;
  minDateFrom: Date;
  adsFormat: boolean = true;
  todayDate = new Date();
  dateAfterOneMonth: Date;
  binding = '';
  buttonName = '';
  audienceAll: boolean = true;
  carousel: boolean = false;
  singleImg: boolean = true;
  apiData: any;
  selectedGender;
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    rewind: true,
    dots: false,
    navSpeed: 300,
    nav: true,
    navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
    center: true,
    autoplay: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
  }
  toggleOptions: Array<String> = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ];
  public adsManager = new AdsManager();
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  @Input() page: Page;
  public genders = [
    { id: StatusTitle.All, description: "data.all" },
    { id: StatusTitle.Mr, description: "data.mr" },
    { id: StatusTitle.Mrs, description: "data.mrs" },
  ];

  public budgetArray = [
    { id: 0, description: "Tagesbudget" },
    { id: 1, description: "Laufzeitbudget" },

  ]

  skillOffer: '';
  isCountrySelected: boolean = true;
  isEndDateSelected: boolean = true;
  isPreviewMode = false
  imageCards: boolean = false;
  budgetPlanStartDateDum = new Date();
  budgetPlanEndDate = new Date();



  public employmentType = [
    { id: EmploymentType.All, description: "data.all", checked: false },
    { id: EmploymentType.Search, description: "search", checked: false },
    { id: EmploymentType.EmployedFullTime, description: "employmentType.EmployedFullTime", checked: false },
    { id: EmploymentType.EmployedPartTime, description: "employmentType.EmployedPartTime", checked: false },
    { id: EmploymentType.Internship, description: "employmentType.Internship", checked: false },
    { id: EmploymentType.Owner, description: "employmentType.Owner", checked: false },
    { id: EmploymentType.BordMember, description: "employmentType.Boardmember", checked: false },
    { id: EmploymentType.Volunteer, description: "employmentType.Volunteer", checked: false },
    { id: EmploymentType.SelfEmployed, description: "employmentType.SelfEmployed", checked: false },
    { id: EmploymentType.Shareholder, description: "employmentType.Shareholder", checked: false },
    { id: EmploymentType.Official, description: "employmentType.Official", checked: false },
    { id: EmploymentType.Recruiter, description: "employmentType.Recruiter", checked: false },
    { id: EmploymentType.Freelancer, description: "employmentType.Freelancer", checked: false },
    { id: EmploymentType.Partner, description: "employmentType.Partner", checked: false },
  ];

  public activityField = [
    { id: ActivityField.All, checked: false, description: "data.all" },
    { id: ActivityField.Finanzen, checked: false, description: "activityField.finance" },
    { id: ActivityField.Forschung, checked: false, description: "activityField.research" },
    { id: ActivityField.IT, checked: false, description: "activityField.it" },
    { id: ActivityField.Kundenbetreuung, checked: false, description: "activityField.customerCare" },
    { id: ActivityField.Management, checked: false, description: "activityField.management" },
    { id: ActivityField.Marketing, checked: false, description: "activityField.marketing" },
    { id: ActivityField.Personalwessen, checked: false, description: "activityField.humanResource" },
    { id: ActivityField.Produktion, checked: false, description: "activityField.production" },
    { id: ActivityField.Vertrieb, checked: false, description: "activityField.distribution" },
    { id: ActivityField.Project, checked: false, description: "activityField.project" },
    { id: ActivityField.Sonstige, checked: false, description: "activityField.other" }
  ];


  public callAction = [
    { id: 0, checked: false, description: "No button" },
    { id: 1, checked: false, description: "Call Now" },
    { id: 2, checked: false, description: "Listen now" },
    { id: 3, checked: false, description: "Contact us" },
    { id: 5, checked: false, description: "Request time" },
    { id: 6, checked: false, description: "Get quote" },
    { id: 7, checked: false, description: "Save" },
    { id: 8, checked: false, description: "See menu" },
    { id: 9, checked: false, description: "Order now" },
    { id: 10, checked: false, description: "Watch more" },
    { id: 11, checked: false, description: "Shop now" },
    { id: 12, checked: false, description: "Sign up" },
    { id: 13, checked: false, description: "Subscribe" },
    { id: 14, checked: false, description: "Send message" },
    { id: 15, checked: false, description: "Learn more" },
    { id: 16, checked: false, description: "Apply now" },
    { id: 17, checked: false, description: "Download" },
    { id: 18, checked: false, description: "Book now" }
  ]

  countryJsonObject;
  constructor(private _formBuilder: UntypedFormBuilder,
    public countryDataService: CountryDataService,
    public branchesService: BranchesService,
    public categoryService: CategoryService,
    private cd: ChangeDetectorRef,
    private pageService: PagesService,
    private adsService: AdsManagerService,
    private authService: AuthService,
    private receiveService: SenderService,
    public router: Router,
    public changeDetector: ChangeDetectionService,
    private readonly http: HttpClient,
    private dialog: MatDialog
  ) {


    this.firstFormGroup = this._formBuilder.group({
      adsTitle: ['', Validators.required],
      country: ['', Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      pcKnowledge: ['', Validators.required],
      gender: [2, Validators.required],
      employmentType: [1, Validators.required]

    });
  }
  ngAfterViewInit(): void {
    this.employmentType[0].checked = true;
    if (this.router.url.endsWith('edit')) {
      (document.getElementById('profilBased') as HTMLInputElement).checked = !this.adsManager.audienceAll;
      (document.getElementById('audienceAll') as HTMLInputElement).checked = this.adsManager.audienceAll;
      this.employmentType[0].checked = this.adsManager.employmentType.all;
      (document.getElementById('dateCategoryAll') as HTMLInputElement).checked = this.adsManager.userAge.all;
      (document.getElementById('countryAll') as HTMLInputElement).checked = this.adsManager.region.all;
      (document.getElementById('skillCategoryAll') as HTMLInputElement).checked = this.adsManager.skills.all;
      (document.getElementById('categoryAll') as HTMLInputElement).checked = this.adsManager.category.all;
      (document.getElementById('adsFormatCarousel') as HTMLInputElement).checked = !this.adsManager.adsDetails.selectedCheckboxSingleImg;
      (document.getElementById('adsFormatSingle') as HTMLInputElement).checked = this.adsManager.adsDetails.selectedCheckboxSingleImg;
      (document.getElementById('adsBrand') as HTMLInputElement).checked = this.adsManager.objective.brand;
      (document.getElementById('adsVisitWeb') as HTMLInputElement).checked = this.adsManager.objective.websiteVisit;
      this.budgetPlanStartDateDum = this.adsManager.budgetPlan.startDate;
      this.budgetPlanEndDate = this.adsManager.budgetPlan.endDate;
      if (this.adsManager.adsDetails.selectedCheckboxSingleImg) {
        this.singleImg = true;
        this.adsFormat = true;
      } else {
        this.carousel = true;
        this.adsFormat = false;
      }

    } else {
      this.branchesService.branches[0].checked = true;
      this.activityField[0].checked = true;
      (document.getElementById('profilBased') as HTMLInputElement).checked = false;
      (document.getElementById('audienceAll') as HTMLInputElement).checked = true;
      (document.getElementById('categoryAll') as HTMLInputElement).checked = true;
      (document.getElementById('skillCategoryAll') as HTMLInputElement).checked = true;
      (document.getElementById('dateCategoryAll') as HTMLInputElement).checked = true;
      (document.getElementById('adsBrand') as HTMLInputElement).checked = true;
     this.adsManager.budgetPlan = {};
     this.adsManager.adsDetails = {};
     this.adsManager.budgetPlan.form = 1;
     this.adsManager.budgetPlan.budget = 0.00;
     this.adsManager.budgetPlan.startDate = new Date();
     this.adsManager.budgetPlan.endDate = new Date();
     this.adsManager.budgetPlan.value = 1000;
      this.adsManager.objective.brand = true;
      this.adsManager.adsDetails.selectedCheckboxSingleImg = true;

      const todayDateMonth = new Date().getMonth() + 1;
      const currentYear = new Date().getFullYear();
      const date = new Date().getDate()
      this.budgetPlanStartDateDum = this.adsManager.budgetPlan.startDate;
      this.budgetPlanEndDate = new Date(currentYear, todayDateMonth, date);
      this.adsManager.budgetPlan.endDate = new Date(currentYear, todayDateMonth, date);
      this.adsManager.objective.websiteVisit = false;

    }


  }

  ngOnInit(): void {
    this.countryDataService.countryData$.pipe().subscribe(res => this.countryJson = res);
    this.branchesService.getBranches();
    this.categoryService.getCategories();
    this.receiveService.adsData.subscribe(res => {


      if (res && this.router.url.endsWith('edit')) {
        this.adsManager = res;


          if (this.adsManager.activityField.all) {
            this.activityField[0].checked = true;
          } else {
            this.activityField = this.activityField.map(elem => {
              elem.checked = this.adsManager.activityField.data.find(t => t.name === elem.id) ? true : false;
              console.log(elem, "elem")
              return elem;
            });
          }

          this.branchesService.branches.unshift({ checked: false, id: Branches.All, description: "data.all" });

          if (this.adsManager.branch.all) {
            this.branchesService.branches[0].checked = true;
          } else {
            this.branchesService.branches = this.branchesService.branches.map(
              elem => {
                elem.checked = this.adsManager.branch.data.find(t => t.name === elem.id) ? true : false;
                return elem;
              });
          }


        /*  this.categoryService.categories = this.categoryService.categories.map(t => t.subCategory.map(elem => {
            elem.checked = this.adsManager.category.data.find(t => t.name === elem.id) ? true : false;
            return elem
          })); */



        this.employmentType = this.employmentType.map(elem => {
          elem.checked = this.adsManager.employmentType.data.find(t => t.name === elem.id) ? true : false;
          return elem;
        })








        this.getCountryID(this.adsManager.country);
        this.audienceAll = this.adsManager.audienceAll;

      } else {
        const currentYear = new Date().getFullYear();
        const yearMinus18 = new Date().getFullYear() - 18;
        const yearMinus19 = new Date().getFullYear() - 19;
        if (this.adsManager.gender == null) {
          this.adsManager.gender = {};
          this.adsManager.gender.data = 2;
          this.adsManager.gender.all = true;
        }
        if (this.adsManager.budgetPlan == null) {
          this.adsManager.budgetPlan = {};
          this.adsManager.budgetPlan.active = false;
        }



        const todayDateMonth = new Date().getMonth() + 1;
        const month = new Date().getMonth();
        const date = new Date().getDate()
        this.minDate = new Date(yearMinus18, month, date);
        this.minDateFrom = new Date(yearMinus19, month, date);
        this.dateAfterOneMonth = new Date(currentYear, todayDateMonth, date);
        const findPrimaryLocation = this.page?.locations?.find(t => t.headquarters === true);
        this.adsManager.country = findPrimaryLocation?.country;
        this.branchesService.branches.unshift({ checked: true, id: Branches.All, description: "data.all" });
        if (this.adsManager.objective == null) {
          this.adsManager.objective = {};
          this.adsManager.objective.brand = true;
          this.adsManager.objective.websiteVisit = false;
        }



        if (this.adsManager.country == null) {
          this.getCountryID('DE');
        } else {
          this.getCountryID(this.adsManager.country);

        }
        this.adsManager.budgetPlan.endDate = this.dateAfterOneMonth;
        this.adsManager.budgetPlan.startDate = this.todayDate;
      }
    })

  }

  changeCountry(event) {
    this.adsManager.country = event;
    this.getCountryID(this.adsManager.country);
  }

  get selectedCountry() {
    return this.countryJson.find(r => r.ISO_2 === this.firstFormGroup.get("country").value);
  }

  getCountryID(id) {
    this.countryJsonObject = this.countryJson.find(t => t.ISO_2 === id);
  }
  addOfferObj() {
    if (this.adsManager.skills == null) {
      this.adsManager.skills = {};
      this.adsManager.skills.data = [];
      this.adsManager.skills.data.unshift({ name: this.skillOffer });

    } else {
      this.adsManager.skills.data.unshift({ name: this.skillOffer });

    }
    this.skillOffer = '';
    (document.getElementById('skillCategoryAll') as HTMLInputElement).checked = false;
    this.adsManager.skills.all = false;
  }




  removeSkillObj(index: number) {
    this.adsManager.skills.data.splice(index, 1);
    if (this.adsManager.skills.data.length < 1) {
      (document.getElementById('skillCategoryAll') as HTMLInputElement).checked = true;

    }
  }

  changeCountrySelected() {
    this.isCountrySelected = true;
  }

  changeCitySelected() {
    this.isCountrySelected = false;

  }

  setNoteEditMode(mode) {
    this.isEditingNote = mode;
  }
  cancelNodeEdit() {
    this.selectedIndex = undefined;
    this.selectedWorkplace = false;
    this.selectedIndexPage = false;
  }

  adsText(event) {
    if (this.adsManager?.adsDetails == null) {
      this.adsManager.adsDetails = {};
      this.adsManager.adsDetails.text = event;
    }
    return this.adsManager.adsDetails.text = event;
  }

  adsDescription(event) {
    if (this.adsManager?.adsDetails == null) {
      this.adsManager.adsDetails = {};
      this.adsManager.adsDetails.description = event;
    }
    return this.adsManager.adsDetails.description = event;

  }
  adsUrl(event) {
    if (this.adsManager?.adsDetails == null) {
      this.adsManager.adsDetails = {};
      this.adsManager.adsDetails.url = event;
    }
    return this.adsManager.adsDetails.url = event;
  }


  dateCategoryAll() {
    (document.getElementById('dateCategoryAll') as HTMLInputElement).checked = true;
    this.adsManager.userAge.all = true;
    this.adsManager.userAge.startDate = null;
    this.adsManager.userAge.endDate = null;
  }

  userAgeStartDate(event) {
    if (this.adsManager?.userAge == null) {
      this.adsManager.userAge = {};
      this.adsManager.userAge.startDate = event;
      this.adsManager.userAge.all = true;
      (document.getElementById('dateCategoryAll') as HTMLInputElement).checked = false;
    }
    this.adsManager.userAge.all = false;

    (document.getElementById('dateCategoryAll') as HTMLInputElement).checked = false;
    console.log(this.adsManager.userAge.startDate, "ekekke")
    return this.adsManager.userAge.startDate = event;

  }
  userAgeEndDate(event) {
    if (this.adsManager?.userAge == null) {
      this.adsManager.userAge = {};
      this.adsManager.userAge.endDate = event;
      this.adsManager.userAge.all = true;
      (document.getElementById('dateCategoryAll') as HTMLInputElement).checked = false;
    }

    console.log(this.adsManager?.userAge?.endDate)
    if (this.adsManager?.userAge?.startDate == null && this.adsManager?.userAge.endDate == null) {
      console.log("everything is null")
    }
    (document.getElementById('dateCategoryAll') as HTMLInputElement).checked = false;

    this.adsManager.userAge.all = false;

    return this.adsManager.userAge.endDate = event;

  }

  adsSharedDesc(event) {
    if (this.adsManager?.adsDetails == null) {
      this.adsManager.adsDetails = {};
      this.adsManager.adsDetails.sharedDescription = event;
    }
    return this.adsManager.adsDetails.sharedDescription = event;

  }

  selectCountryAll() {
    (document.getElementById('countryAll') as HTMLInputElement).checked = true;
    this.adsManager.region = {};
  }

  selectionChanged(item, index) {

    (document.getElementById('countryAll') as HTMLInputElement).checked = false

    if (this.adsManager.region == null) {
      this.adsManager.region = {};
      this.adsManager.region.data = [];
      this.adsManager.region.data.push(item)

    } else {
      if (this.adsManager.region.data.find(t => t === item)) {
        const dataIndex = this.adsManager?.region?.data?.findIndex(t => t === item);
        this.adsManager.region.data.splice(dataIndex, 1);
      } else {
        this.adsManager.region.data.push(item);
        this.adsManager?.region?.data?.forEach(
          (i) => (this.adsManager.postalCode = i)
        );
      }
      if (this.adsManager?.region?.data?.length > 1) {
        this.adsManager.postalCode = null;
        this.adsManager.postalCode = "";
      } else if (this.adsManager?.region?.data?.length === 0) {
        this.adsManager.postalCode = null;
        this.adsManager.postalCode = "";
        this.adsManager.region.all = true;
        (document.getElementById('countryAll') as HTMLInputElement).checked = true


      } else {
        this.adsManager?.region?.data?.forEach(
          (i) => (this.adsManager.postalCode = i)
        );
      }

      this.adsManager.region.all = false;

    }
  }


  saveNote(item, index) {
    if (item) {
      this.adsManager.postalCode = item.postalCode;
    }
    this.pageService.updatePage(this.page._id, this.page);
    this.selectedIndex = undefined;
    this.selectedWorkplace = false;
  }



  selectIndexForButtons(index) {
    this.selectedIndex = index;
    this.selectedWorkplace = false;
  }

  employmentTypeFnc(id, index, event) {
    if (id === this.employmentType[0].id) {
      this.employmentType = this.employmentType.map(m => ({ ...m, checked: false }))
      this.employmentType[index].checked = true;
    } else if (id !== this.employmentType[0].id) {
      this.employmentType[0].checked = false;
    }


    if (event.target.checked && index === 0) {
      this.adsManager.employmentType = {};
      this.adsManager.employmentType.all = true;
      this.adsManager.employmentType.data = [] as AdsEmployment[];
    } else {
      if (this.adsManager.employmentType == null) {
        this.adsManager.employmentType = {};
        this.adsManager.employmentType.data = [] as EmploymentTypeArray[];
        if (event.target.checked) {
          this.adsManager.employmentType.data.push({ name: id })
        }
      } else {

        this.adsManager.employmentType.all = false;

        if (event.target.checked && this.adsManager?.employmentType?.data?.find(t => t.name === id)) {
          return;
        } else if (event.target.checked && this.adsManager.employmentType.data && this.adsManager?.employmentType.data.length === 0) {
          this.adsManager.employmentType.data.unshift({ name: id })
        } else if (event.target.checked && this.adsManager.employmentType.data && this.adsManager?.employmentType?.data?.find(t => t.name !== id)) {
          this.adsManager.employmentType.data.unshift({ name: id })
        } else if (!event.target.checked && this.adsManager?.employmentType?.data?.find(t => t.name === id)) {
          const dataIndex = this.adsManager?.employmentType?.data?.findIndex(t => t.name === id);
          this.adsManager.employmentType.data.splice(dataIndex, 1);
        }
      }
      if (this.adsManager.employmentType.data.length === 0) {
        this.adsManager.employmentType.all = true;
        this.employmentType[0].checked = true;
      }
    }


  }




  activityFieldFn(id, index, event) {
    if (id === this.activityField[0].id) {
      this.activityField = this.activityField.map(m => ({ ...m, checked: false }));
      this.activityField[index].checked = true;
    } else if (id !== this.activityField[0].id) {
      this.activityField[0].checked = false;
    }

    if (event.target.checked && index === 0) {
      this.adsManager.activityField = {};
      this.adsManager.activityField.all = true;
      this.adsManager.activityField.data = [] as ActivityFieldArray[];
    } else {
      if (this.adsManager.activityField == null) {
        this.adsManager.activityField = {};
        this.adsManager.activityField.data = [] as ActivityFieldArray[];
        if (event.target.checked) {
          this.adsManager.activityField.data.push({ name: id })
        }
      } else {
        this.adsManager.activityField.all = false;

        if (event.target.checked && this.adsManager?.activityField?.data?.find(t => t.name === id)) {
          return;
        } else if (event.target.checked && this.adsManager.activityField.data.length === 0) {
          this.adsManager.activityField.data.push({ name: id })
        } else if (event.target.checked && this.adsManager.activityField?.data && this.adsManager?.activityField?.data?.find(t => t.name !== id)) {
          this.adsManager.activityField?.data.unshift({ name: id })
        } else if (!event.target.checked && this.adsManager?.activityField?.data?.find(t => t.name === id)) {
          const dataIndex = this.adsManager?.activityField?.data?.findIndex(t => t.name === id);
          this.adsManager.activityField?.data.splice(dataIndex, 1)
        }
      }
      if (this.adsManager.activityField.data.length === 0) {
        this.adsManager.activityField.all = true;
        this.activityField[0].checked = true;
      }
    }




  }

  categoryFnc(id: string, index: number, event) {
    (document.getElementById('categoryAll') as HTMLInputElement).checked = false;



    if (this.adsManager.category == null) {
      this.adsManager.category = {};
      this.adsManager.category.data = [] as AdsCategory[];
      if (event.target.checked) {
        this.adsManager.category.data.push({ name: id })
      }

    } else {
      this.adsManager.category.all = false;
      if (event.target.checked && this.adsManager?.category?.data?.find(t => t.name === id)) {
        return;
      } else if (event.target.checked && this.adsManager.category?.data?.length === 0) {
        this.adsManager.category.data.push({ name: id })
      } else if (event.target.checked && this.adsManager.category.data && this.adsManager?.category?.data?.find(t => t.name !== id)) {
        this.adsManager.category.data.unshift({ name: id })
      } else if (!event.target.checked && this.adsManager?.category?.data?.find(t => t.name === id)) {
        const dataIndex = this.adsManager?.category?.data?.findIndex(t => t.name === id);
        this.adsManager.category.data.splice(dataIndex, 1)
      }

      if (this.adsManager.category.data.length === 0) {
        (document.getElementById('categoryAll') as HTMLInputElement).checked = true;


      }
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.adsManager.adsDetails.cards, event.previousIndex, event.currentIndex);
  }
  branchFnc(id: string, index: number, event) {
    if (id === this.branchesService.branches[0].id) {
      this.branchesService.branches = this.branchesService.branches.map(m => ({ ...m, checked: false }))
      this.branchesService.branches[index].checked = true;
    } else if (id !== this.branchesService.branches[0].id) {
      this.branchesService.branches[0].checked = false;
    }
    if (event.target.checked && index === 0) {
      this.adsManager.branch = {};
      this.adsManager.branch.all = true;
      this.adsManager.branch.data = [] as AdsBranch[];
    } else {
      if (this.adsManager.branch == null) {
        this.adsManager.branch = {};
        this.adsManager.branch.all = false;
        this.adsManager.branch.data = [] as AdsBranch[];

        if (event.target.checked) {
          this.adsManager.branch.data.push({ name: id })
        }
      } else {
        this.adsManager.branch.all = false;

        if (event.target.checked && this.adsManager.branch?.data?.find(t => t.name === id)) {
          return;
        } else if (event.target.checked && this.adsManager.branch.data.length === 0) {
          this.adsManager.branch.data.push({ name: id })
        } else if (event.target.checked && this.adsManager.branch?.data?.find(t => t.name !== id)) {
          this.adsManager.branch.data.push({ name: id })
        } else if (!event.target.checked && this.adsManager.branch?.data?.find(t => t.name === id)) {
          const dataIndex = this.adsManager?.branch?.data?.findIndex(t => t.name === id);
          this.adsManager.branch.data.splice(dataIndex, 1)
        }
      }
      if (this.adsManager.branch.data.length === 0) {
        this.adsManager.branch.all = true;
        this.branchesService.branches[0].checked = true;
      }
    }
  }

  checkedBranch(data) {

  }

  changeGender(event) {
    let index = event.target.value;
index = index.substring(index.indexOf(':')+1);


    if (this.adsManager.gender == null) {
      this.adsManager.gender = {};
      this.adsManager.gender.all = false;
    }


    if (index === StatusTitle.All.toString()) {
      this.adsManager.gender.data = index;
      this.adsManager.gender.all = true;
    } else if (index !== StatusTitle.All.toString()) {
      this.adsManager.gender.all = false;
      this.adsManager.gender.data = index;
    }

  }

  makeAllFalse() {
    this.categoryService.categories = this.categoryService.categories.map(t => ({ ...t, subCategory: t.subCategory.map(m => ({ ...m, checked: false })) }));
    this.adsManager.category.all = true;
    this.adsManager.category.data = [] as AdsCategory[];

  }

  activeVideoAd() {
    this.adsFormat = false;

  }

  activeImageAd() {
    this.adsFormat = true;
  }

  isEndDateSelectedFn() {
    this.isEndDateSelected = !this.isEndDateSelected;
  }

  changeDropButton(event) {
    const data = this.callAction.find(t => t.id.toString() === event);

    if (this.adsManager?.adsDetails == null) {
      this.adsManager.adsDetails = {};
      if (event === "0") {
        this.adsManager.adsDetails.buttonName = null;
        this.adsManager.adsDetails.selectedButton = null;
        return;
      }
      this.adsManager.adsDetails.buttonName = data.description;
      this.adsManager.adsDetails.selectedButton = data.id;
    }
    if (event === "0") {
      this.adsManager.adsDetails.buttonName = null;
      return;
    } else {
      this.adsManager.adsDetails.selectedButton = data.id;
      return this.adsManager.adsDetails.buttonName = data.description;

    }

  }


  addCards(event) {
    (document.getElementById('adsFormatSingle') as HTMLInputElement).checked = false;
    if (this.adsManager?.adsDetails?.cards == null || this.adsManager?.adsDetails?.cards?.length === 0) {
      this.adsManager.adsDetails = {};
      this.adsManager.adsDetails.cards = [];

      const test: ImageCards = {
        headline: 'Card 1',
        url: 'card1.com',
        description: 'card 1',
        buttonName: 'string',
        selectedButton: 0
      }
      const test2: ImageCards = {
        headline: 'Card 2',
        url: 'card2.com',
        description: 'card 2',
        buttonName: 'string',
        selectedButton: 0
      }
      this.adsManager.adsDetails.cards.push(test);
      this.adsManager.adsDetails.cards.push(test2);
    }

    this.singleImg = false;
    this.carousel = true;
    this.adsManager.adsDetails.selectedCheckboxSingleImg = false;



  }

  addSingleImg(event) {
    this.singleImg = true;
    this.carousel = false;
    this.adsManager.adsDetails.selectedCheckboxSingleImg = true;
    this.adsManager.adsDetails.cards = [];
    (document.getElementById('adsFormatCarousel') as HTMLInputElement).checked = false
  }


  changeText(event) {
    const messageEle = document.getElementById('headline');
    const counterEle = document.getElementById('counter');
    const maxLength = messageEle.getAttribute('maxlength');
    counterEle.innerHTML = '0/' + maxLength;
    const target = event.target;
    const currentLength = target.value.length;
    counterEle.innerHTML = currentLength + '/' + maxLength;
  }

  changeAdsDetailsDescription(event) {
    const messageEle = document.getElementById('adsDetailsDescription');
    const counterEle = document.getElementById('counter1');
    const maxLength = messageEle.getAttribute('maxlength');
    counterEle.innerHTML = '0/' + maxLength;
    const target = event.target;
    const currentLength = target.value.length;
    counterEle.innerHTML = currentLength + '/' + maxLength;
  }

  audienceAllSelected(event) {
    this.audienceAll = true;
    (document.getElementById('profilBased') as HTMLInputElement).checked = false;
    (document.getElementById('profilBased') as HTMLInputElement).checked = false;
    (document.getElementById('branchAds') as HTMLInputElement).checked = true;
    (document.getElementById('dateCategoryAll') as HTMLInputElement).checked = true;
    (document.getElementById('categoryAll') as HTMLInputElement).checked = true;
    (document.getElementById('skillCategoryAll') as HTMLInputElement).checked = true;



    this.makeEmptyObjects();
    this.makeAllFalse();
    this.selectCountryAll();


    this.adsManager.branch.all = true;
    this.adsManager.skills.all = true;
    this.adsManager.userAge.all = true;
    this.adsManager.gender.all = true;
    this.adsManager.activityField.all = true;
    this.adsManager.employmentType.all = true;
    this.adsManager.category.all = true;
    this.adsManager.region.all = true;
    this.adsManager.audienceAll = true;


    this.adsManager.gender.data = 2;
    (document.getElementById('gender') as HTMLInputElement).value = '2';
    this.employmentType = this.employmentType.map(m => ({ ...m, checked: false }))
    this.employmentType[0].checked = true;
    this.branchesService.branches = this.branchesService.branches.map(m => ({ ...m, checked: false }))
    this.branchesService.branches[0].checked = true;
    this.activityField = this.activityField.map(m => ({ ...m, checked: false }));
    this.activityField[0].checked = true;



    this.adsManager.userAge.startDate = null;
    this.adsManager.userAge.endDate = null;
    this.adsManager.gender.data = 2;


    this.adsManager.activityField.data = [];
    this.adsManager.employmentType.data = [];
    this.adsManager.branch.data = [];
    this.adsManager.skills.data = [];
    this.adsManager.category.data = [];

    console.log(this.adsManager, "adsManager");


  }

  profilBased(event) {
    this.audienceAll = false;
    (document.getElementById('audienceAll') as HTMLInputElement).checked = false;
    this.adsManager.audienceAll = false;
  }


  createAds() {

    if (this.router.url.endsWith('edit')) {
      this.adsService.updateAds(this.adsManager._id, this.adsManager)
    } else {



      this.adsManager.pageId = this.page._id;
      this.adsManager.userId = this.authService.userID;



        if ((document.getElementById('audienceAll') as HTMLInputElement).checked === true) {
          this.makeEmptyObjects();

          this.adsManager.category.all = true;
          this.adsManager.branch.all = true;
          this.adsManager.userAge.all = true;
          this.adsManager.employmentType.all = true;
          this.adsManager.skills.all = true;
          this.adsManager.activityField.all = true;
          this.adsManager.region.all = true;
          this.adsManager.audienceAll = true;
          this.adsManager.gender.all = true;
          this.adsManager.gender.data = 2;
        }
        this.adsManager.createdDate = new Date();
           this.adsService.createAds(this.adsManager, false, this.page._id).subscribe(t => t);
         this.cd.markForCheck();
           this.changeDetector.emitChanges(true);

           this.router.navigateByUrl(this.page.pageUrl + '/ads_manager');

    }
  }


  makeEmptyObjects() {

    this.adsManager.userAge = {};

    if (this.adsManager?.category == null) {
      this.adsManager.category = {};
    }
    if (this.adsManager?.branch == null) {
      this.adsManager.branch = {};
    }
    if (this.adsManager?.employmentType == null) {
      this.adsManager.employmentType = {};
    }
    if (this.adsManager?.skills == null) {
      this.adsManager.skills = {};
    }
    if (this.adsManager?.activityField == null) {
      this.adsManager.activityField = {};
    }
    if (this.adsManager?.region == null) {
      this.adsManager.region = {};
    }
    if (this.adsManager?.gender == null) {
      this.adsManager.gender = {};
    }
  }

  bPlanStartDate(event) {
    if (this.adsManager?.budgetPlan == null) {
      this.adsManager.budgetPlan = {};
      this.adsManager.budgetPlan.startDate = event;
    }
    return this.adsManager.budgetPlan.startDate = event;
  }


  changeBudgetPlanV(event) {
    if (this.adsManager?.budgetPlan == null) {
      this.adsManager.budgetPlan = {};
      this.adsManager.budgetPlan.value = event;
    }

    console.log(event, this.adsManager.budgetPlan.value )
    this.adsManager.budgetPlan.value = event;
  }

  budgetPlanForm(event: number) {
    if (this.adsManager?.budgetPlan == null) {
      this.adsManager.budgetPlan = {};
      this.adsManager.budgetPlan.form = event;
      this.adsManager.budgetPlan.value = this.adsManager.budgetPlan.value
    }
    return this.adsManager.budgetPlan.form = event;

  }

  addImageCard() {
    console.log("test")
    this.imageCards = true;
    if (this.adsManager.adsDetails?.cards?.length > 0) {
      const getLength = this.adsManager.adsDetails?.cards?.length + 1;
      const test2: ImageCards = {
        headline: `Card ${getLength}`,
        url: `card${getLength}.com`,
        description: `Card ${getLength}`,
        buttonName: 'string',
        selectedButton: 0
      }
      this.adsManager.adsDetails.cards.push(test2)
    }

  }

  openDialog(data) {
    this.dialog.open(CarouselCardDialogComponent, {
      data: data
    }).afterClosed().subscribe(res => {
      this.adsManager.adsDetails.cards[res.index] = res.imageCard;
      console.log(res.imageCard, "imageCard")
      if (res.imageCard.selectedButton === 0) {
        this.adsManager.adsDetails.cards[res.index].buttonName = null;
        return;
      }
    })
  }

  activeBrand() {

    (document.getElementById('adsBrand') as HTMLInputElement).checked = true;
    (document.getElementById('adsVisitWeb') as HTMLInputElement).checked = false;
    this.adsManager.objective.brand = true;
    this.adsManager.objective.websiteVisit = false;


  }

  activeVisitWeb() {
    (document.getElementById('adsVisitWeb') as HTMLInputElement).checked = true;
    (document.getElementById('adsBrand') as HTMLInputElement).checked = false;
    this.adsManager.objective.brand = false;
    this.adsManager.objective.websiteVisit = true;


  }
  removeImageCard(index: number) {
    this.adsManager.adsDetails.cards.splice(index, 1)
  }

  calculateDays() {

    return this.getDayDiff(
      new Date(this.adsManager?.budgetPlan?.startDate),
      new Date(this.adsManager?.budgetPlan?.endDate)
    )
  }

  getDayDiff(startDate: Date, endDate: Date): number {
    const msInDay = 24 * 60 * 60 * 1000;

    return Math.round(
      Math.abs(Number(startDate) - Number(endDate)) / msInDay + 1
    );
  }
}
