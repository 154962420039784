<!-- <aside class="pt-4 aside" style="left: 0">
  <section class="sc-smart">
    <div class="widget-item p-0 px-3">
          <h2>Create Page</h2>

          <form class="form-grid" name="ngForm" #myForm="ngForm" (ngSubmit)="myForm.form.valid && addNewPage(myForm)">
            <div class="inputbox mt-3">
              <span>{{ "pagesInfo.pageName" | translate }}</span>
              <input type="text" name="pageName" [(ngModel)]="page.name" #pageName="ngModel"
                [placeholder]="'pagesInfo.writePageName' | translate" class="form-control"
                [ngClass]="{ 'is-invalid': myForm.submitted && pageName.invalid }" required />
              <div *ngIf="myForm.submitted && pageName.invalid" class="invalid-feedback">
                <div *ngIf="pageName.errors.required">{{'pagesInfo.pageName' | translate}} {{'error.isRequired' |
                  translate}}</div>
              </div>
            </div>
            <div class="inputbox mt-3">
              <div class="col-md-12 p-0">
                <label>{{ "career.branch" | translate }} </label>
                <select class="col-md-12 form-control" id="selectedBranch" name="selectedBranch" #selectedBranch="ngModel"
                  [(ngModel)]="page.branches" [ngClass]="{ 'is-invalid': myForm.submitted && selectedBranch.invalid }"
                  required>
                  <option *ngFor="let level of branchesService.branches" [ngValue]="level.id" [selected]="level">

                  </option>
                </select>
                <div *ngIf="myForm.submitted && selectedBranch.invalid" class="invalid-feedback">
                  <div *ngIf="selectedBranch.errors.required">{{'error.select' | translate}} {{'pagesInfo.branch' |
                    translate}}</div>
                </div>
              </div>
            </div>

            <div class="inputbox mt-3">
              <div class="col-md-12 p-0">
                <label>{{ "pagesInfo.categories" | translate }} </label>
                <select class="col-md-12 form-control" name="selectedCategory" #selectedCategory="ngModel"
                  [ngModel]="page?.categories?.name" (ngModelChange)="showChildren($event)"
                  [ngClass]="{ 'is-invalid': myForm.submitted && selectedCategory.invalid }" required>
                  <option *ngFor="let level of categoryService.categories" [ngValue]="level" [selected]="level">
                    {{ level.name | translate }}
                  </option>
                </select>
                <div *ngIf="myForm.submitted && selectedCategory.invalid" class="invalid-feedback">
                  <div *ngIf="selectedCategory.errors.required">{{'error.select' | translate}} {{'pagesInfo.categories'
                    | translate}}</div>
                </div>
              </div>
            </div>
            <div class="inputbox mt-3">
              <div class="col-md-12 p-0">
                <label>{{ "categories.subCategories.name" | translate }} </label>
                <select class="col-md-12 form-control" name="selectedSubCategory" #selectedSubCategory="ngModel"
                  [(ngModel)]="selectedChild" (ngModelChange)="showKeywords($event)"
                  [ngClass]="{ 'is-invalid': myForm.submitted && selectedSubCategory.invalid }" required>
                  <option hidden value="" disabled selected>{{'Choose one SubCategory'}}</option>
                  <option *ngFor="let level of selectedChildren" [ngValue]="level"
                    (change)="showKeywords($event)">
                    {{ level.name | translate }}
                  </option>
                </select>
                <div *ngIf="myForm.submitted && selectedSubCategory.invalid" class="invalid-feedback">
                  <div *ngIf="selectedSubCategory.errors.required">
                    {{'pagesInfo.dialogSubCategory.errorDropdownSubCategory' | translate}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-12 p-0">
              <label>{{'pagesInfo.keywords' | translate}}</label>
                <div class="d-flex align-items-center card__body__list">
                  <div *ngFor="let sp of selectedKeywords; let i=index" class="d-flex justify-content-between pr-1">
                    <div class="card__text">
                      {{sp.name}}
                      <span class="fa fa-remove" (click)="removeKeyword(i)"></span>
                    </div>
                  </div>
                  <input [(ngModel)]="keyword" #key="ngModel" name="key" [required]="selectedKeywords?.length < 1" class="form-control col-md-9"
                    [ngClass]="{ 'is-invalid': myForm.submitted && selectedKeywords?.length < 1}">

                  <button type="button" class="btn btn-light ms-3 btn-light-keyword" [disabled]="!keyword" (click)="addKeyword()">
                    <i class="bi bi-plus fa-lg"></i></button>
                  <div class="invalid-feedback" *ngIf="myForm.submitted && selectedKeywords?.length < 1 && !keyword">
                    <p *ngIf="key?.errors?.required && selectedKeywords?.length < 1 && !key">
                      {{'pagesInfo.dialogSubCategory.errorInputKeyword' | translate}}</p>
                  </div>

                </div>
            </div>
            <div class="d-flex justify-content-between align-items-center pt-3">
              <div class="text-right button-width-100">
                <button type="submit" class="btn btn-success register btn-block">
                  Register
                </button>
              </div>
            </div>
          </form>
    </div>
  </section>
</aside>
<div class="main">
  <div class="d-flex flex-column border-shadow">
    <div class="jumbotron" style="padding-right: 0;
    padding-left: 0;
    padding-top: 0;">
      <div class="container px-0">

        <div class="cover-photo">
          <div class="cover-image">

            <img style="width: 100%;" src="../../../assets/img/User_BG.jpg">

          </div>

          <div class="profile-picture">
            <img [round]="false" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="140" cornerRadius="10">


          </div>
        </div>
        <div class="d-flex column justify-content-center" style="transform: translate(12rem, 2rem);
      height: 97px;
      flex-direction: column;
      align-items: start;">
          <h1 class="mb-0">{{page?.name}}</h1>
          <h4 class="mb-0">{{page?.slogan}}</h4>
          <h4 class="pt-0" *ngIf="page?.pageUrl">@{{page?.pageUrl}}
            <ng-container *ngIf="page.branches.length > 0"> - </ng-container>
            <ng-container *ngIf="page.branches.length > 0">
              <ng-container *ngFor="let branch of page.branches; let last = last">
                <span>{{branch.description | translate}}<ng-container *ngIf="!last"> · </ng-container></span>
              </ng-container>
            </ng-container>
            <ng-container *ngFor="let br of branch">
              <span>{{br.description}}</span>
            </ng-container>
            <ng-container *ngFor="let location of page.locations | slice:0:1;">
              <h5 *ngIf="location">
                {{location.city}} {{location.country}}
              </h5>
            </ng-container>

          </h4>
        </div>
        <br>
        <hr class="pt-16">
        <div class="d-flex justify-content-between px-0" style="position: sticky">
          <div class="d-flex justify-content-start">
            <a class="btn btn-light">
              <div class="px-2 font-weight-600 active-route">
                {{'userProfile.startPage' | translate}}
              </div>
            </a>
            <a class="btn btn-light active-route">
              <div class="px-2 font-weight-600">
                {{'userProfile.shop' | translate}}
              </div>
            </a>

          </div>
          <div class="d-flex">
            <a class="px-1">
              <button class="px-3 font-weight-600 btn btn-light" [tooltip]="'Add Post'">
                <span class="bi bi-plus"></span>
              </button>
            </a>
            <a class="px-1" routerLink="info">
              <button class="px-3 font-weight-600 btn btn-light" tooltip="Edit Page">
                <span class="bi bi-pencil"></span>
              </button>
            </a>

            <a class="px-1">
              <button class="px-3 font-weight-600 btn btn-light" [tooltip]="'userProfileTooltips.viewAs' | translate">
                <span class="fa fa-eye"></span>
              </button>
            </a>
            <a class="px-1">
              <button class="px-3 font-weight-600 btn btn-light"
                [tooltip]="'userProfileTooltips.searchProfile' | translate">
                <span class="fa fa-search"></span>
              </button>
            </a>
            <a class="px-1">
              <button class="px-3 font-weight-600 btn btn-light">
                <span class="fa fa-ellipsis-h"></span>
              </button>
            </a>


          </div>
        </div>

      </div>
    </div>
  </div> -->

  <main>

    <!-- Container START -->
    <div class="container">
      <div class="row g-4">

         <!-- Sidenav START -->
         <div class="col-lg-3">

          <!-- Advanced filter responsive toggler START -->
          <div class="d-flex align-items-center d-lg-none">
              <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSideNavbar" aria-controls="offcanvasSideNavbar">
                  <span class="btn btn-primary"><i class="bi bi-sliders"></i></span>
                  <span class="h6 mb-0 fw-bold d-lg-none ms-2">My profile</span>
              </button>
          </div>
          <!-- Advanced filter responsive toggler END -->

          <!-- Navbar START-->
          <nav class="navbar navbar-expand-lg mx-0">
              <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasSideNavbar">
                  <!-- Offcanvas header -->
                  <div class="offcanvas-header">
                      <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                  </div>

                  <!-- Offcanvas body -->
                  <div class="offcanvas-body d-block px-2 px-lg-0">
                      <!-- Card START -->
                      <div class="card overflow-hidden">
                          <!-- Cover image -->

                              <!-- Card body START -->
                              <div class="card-body pt-0">
                              <!-- Side Nav START -->
                              <ul class="nav nav-link-secondary flex-column fw-bold gap-2">
                                      <!-- Avatar -->
                                      <button type="button" class="button-f button-sc px-0" (click)="navigateToUser()">
                                          <img *ngIf="hasPhoto" [src]="this.image" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="32">
                                            <img *ngIf="!hasPhoto" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="32">
                                             <span class="span-padding-left10">{{currentUser?.firstName}} {{currentUser?.lastName}}</span>
                                        </button>
                                      <!-- Post input -->
                                  <li class="nav-item" [routerLink]="'../' + currentUser?.userUrl + '/cv'">
                                      <a class="nav-link" > <i class="bi bi-file-text"></i>
                                          <span class="ms-2">{{'header.cv' | translate}} </span></a>
                                  </li>
                                  <li class="nav-item" routerLink="/pages">
                                      <a class="nav-link" > <i class="bi bi-flag"></i><span class="ms-2">{{'header.pages' | translate}} </span></a>
                                  </li>
                                  <li class="nav-item" routerLink="/groups">
                                      <a class="nav-link" > <i class="bi bi-people"></i>
                                          <span class="ms-2">{{'header.groups' | translate}} </span></a>
                                  </li>
                                  <li class="nav-item" routerLink="/contacts">
                                      <a class="nav-link"><i class="bi bi-person"></i>
                                           <span class="ms-2">{{'header.contacts' | translate}} </span></a>
                                  </li>
                                  <li class="nav-item" [routerLink]="'../' + currentUser?.userUrl + '/jobs'">
                                      <a class="nav-link" ><i class="bi bi-list-task"></i>
                                          <span class="ms-2">{{'sidebar.jobs' | translate}} </span></a>
                                  </li>
                                  <li class="nav-item" [routerLink]="'../'+ currentUser?.userUrl + '/projects'">
                                      <a class="nav-link"><i class="bi bi-bar-chart-steps fs-6"></i><span class="ms-2">{{'sidebar.projects' | translate}} </span></a>
                                  </li>
                                  <li class="nav-item" (click)="navigateToPayment()">
                                      <a class="nav-link"> <i class="bi bi-credit-card"></i><span class="ms-2">{{'sidebar.payment' | translate}} </span></a>
                                  </li>
                              </ul>
                              <!-- Side Nav END -->
                          </div>
                          <!-- Card body END -->
                          <!-- Card footer -->
                          <div class="card-footer text-center py-2">
                              <a class="btn btn-link btn-sm" href="my-profile.html">View Profile </a>
                          </div>
                      </div>
                      <!-- Card END -->

                      <!-- Helper link START -->
                      <ul class="nav small mt-4 justify-content-center lh-1">
                          <li class="nav-item">
                              <a class="nav-link" href="my-profile-about.html">About</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="settings.html">Settings</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" target="_blank" href="https://support.webestica.com/login">Support </a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" target="_blank" href="docs/index.html">Docs </a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="help.html">Help</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="privacy-and-terms.html">Privacy & terms</a>
                          </li>
                      </ul>
                      <!-- Helper link END -->
                      <!-- Copyright -->
                      <p class="small text-center mt-1">©2023 <a class="text-body" target="_blank" href="https://www.webestica.com/"> Webestica </a></p>
                  </div>
              </div>
          </nav>
          <!-- Navbar END-->
      </div>
        <!-- Sidenav END -->

        <!-- Main content START -->
        <div class="col-md-8 col-lg-6 vstack gap-4">
          <!-- Create a page START -->
          <div class="card">
            <!-- Title START -->
            <div class="card-header border-0 pb-0">
              <h1 class="h4 card-title mb-0">Create a page</h1>
            </div>
            <!-- Title END -->
            <!-- Create a page form START -->
            <div class="card-body">
              <form class="row g-3" name="ngForm" #myForm="ngForm" (ngSubmit)="myForm.form.valid && createPage(myForm)" >
                <!-- Page information -->
                <div class="col-12">
                  <label class="form-label">{{ "pagesInfo.pageName" | translate }}</label>
                  <input type="text" class="form-control"  name="pageName" [(ngModel)]="page.name" #pageName="ngModel">
                  <small>Name that describes what the page is about.</small>
                </div>
                <!-- Display name -->
                <div class="col-sm-6 col-lg-4">
                  <label class="form-label">Display name</label>
                  <input type="text" class="form-control" placeholder="Display name (Required)">
                </div>
                <!-- Email -->
                <div class="col-sm-6 col-lg-4">
                  <label class="form-label">Email</label>
                  <input type="text" class="form-control" placeholder="Email (Required)">
                </div>
                <!-- Category -->
                <div class="col-sm-6 col-lg-4">
                  <label class="form-label">Branch (required)</label>
                  <select class="form-select js-choice" data-search-enabled="true" id="selectedBranch" name="selectedBranch" #selectedBranch="ngModel"
                  [(ngModel)]="page.branches">
                    <option *ngFor="let level of branchesService.branches" [ngValue]="level.id" [selected]="level">    {{ level.description | translate }}</option>
                  </select>
                </div>
                <div class="col-sm-6 col-lg-6">
                  <label class="form-label">{{ "pagesInfo.categories" | translate }}</label>
                  <select class="form-select js-choice" name="selectedCategory" #selectedCategory="ngModel" [ngModel]="page?.categories?.name" (ngModelChange)="showChildren($event)" data-search-enabled="true">
                    <option *ngFor="let level of categoryService.categories" [ngValue]="level" [selected]="level">    {{ level.name | translate }}</option>
                  </select>
                </div>
                <div class="col-sm-6 col-lg-6">
                  <label class="form-label">{{ "categories.subCategories.name" | translate }}</label>
                  <select class="form-select js-choice" name="selectedSubCategory" #selectedSubCategory="ngModel" [(ngModel)]="selectedChild" (ngModelChange)="showKeywords($event)" data-search-enabled="true">
                    <option *ngFor="let level of selectedChildren" [ngValue]="level"
                    (change)="showKeywords($event)">    {{ level.name | translate }}</option>
                  </select>
                </div>
                <div class="col-sm-6 col-lg-12">
                  <label>{{'pagesInfo.keywords' | translate}}</label>
                  <div class="d-flex align-items-center card__body__list">
                    <div *ngFor="let sp of selectedKeywords; let i=index" class="d-flex justify-content-between pr-1">
                      <div class="card__text">
                        {{sp.name}}
                        <button class="btn btn-empty btn-sm py-0 px-0" type="button" (click)="removeKeyword(i)"><i class="fa fa-remove"></i></button>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex col-lg-12 col-sm-6">
                    <input [(ngModel)]="keyword" #key="ngModel" name="key" [required]="selectedKeywords?.length < 1" class="form-control">

                    <button type="button" class="btn btn-light ms-3 btn-light-keyword" [disabled]="!keyword" (click)="addKeyword()">
                      <i class="bi bi-plus fa-lg"></i></button>
                  </div>

                </div>
                <!-- Website URL -->
                <div class="col-sm-6">
                  <label class="form-label">Website URL</label>
                  <input type="text" class="form-control" placeholder="https://www.webestica.com">
                </div>
                <!-- Phone number -->
                <div class="col-lg-6">
                  <label class="form-label">Phone number</label>
                  <input type="text" class="form-control" placeholder="Phone number (Required)">
                </div>
                <!-- Page information -->
                <div class="col-12">
                  <label class="form-label">About page</label>
                  <textarea class="form-control" rows="3" placeholder="Description (Required)"></textarea>
                  <small>Character limit: 300</small>
                </div>

                <!-- Divider -->
                <hr>

                <!-- Social Links START -->
                <div class="col-12">
                  <h5 class="card-title mb-0">Social Links</h5>
                </div>
                <!-- Facebook -->
                <div class="col-sm-6">
                  <label  class="form-label">Facebook</label>
                  <div class="input-group">
                    <span class="input-group-text border-0"> <i class="bi bi-facebook text-facebook"></i> </span>
                    <input type="text" class="form-control" placeholder="https://www.facebook.com">
                  </div>
                </div>
                <!-- Twitter -->
                <div class="col-sm-6">
                  <label class="form-label">Twitter</label>
                  <div class="input-group">
                    <span class="input-group-text border-0"> <i class="bi bi-twitter text-twitter"></i> </span>
                    <input type="text" class="form-control" placeholder="https://www.twitter.com">
                  </div>
                </div>
                <!-- Instagram -->
                <div class="col-sm-6">
                  <label class="form-label">Instagram</label>
                  <div class="input-group">
                    <span class="input-group-text border-0"> <i class="bi bi-instagram text-instagram"></i> </span>
                    <input type="text" class="form-control" placeholder="https://www.instagram.com">
                  </div>
                </div>
                <!-- Pinterest -->
                <div class="col-sm-6">
                  <label class="form-label">Pinterest</label>
                  <div class="input-group">
                    <span class="input-group-text border-0"> <i class="bi bi-pinterest text-pinterest"></i> </span>
                    <input type="text" class="form-control" placeholder="https://www.pinterest.com">
                  </div>
                </div>
                <!-- Button  -->
                <div class="col-12 text-end">
                  <button type="submit" class="btn btn-primary mb-0">Create a page</button>
                </div>
              </form>
            </div>
            <!-- Create a page form END -->
          </div>
          <!-- Create a page END -->
        </div>

      </div> <!-- Row END -->
    </div>
    <!-- Container END -->

  </main>
