import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { Role } from "src/app/shared/enums/role";
import { Model, Page, PageRoles } from "src/app/shared/interfaces/model";
import { User } from "src/app/shared/interfaces/user.model";
import { AuthService } from "src/app/shared/services/auth.service";
import { BranchesService } from "src/app/shared/services/branches.service";
import { ImageService } from "src/app/shared/services/image.service";
import { LanguagesService } from "src/app/shared/services/languages.service";
import { PagesService } from "src/app/shared/services/pages.service";
import { SenderService } from "src/app/shared/services/sender.service";
import { UserService } from "src/app/shared/services/user.service";
import { PagesSettingsComponent } from "../pages-settings.component";
import _ from 'lodash'


@Component({
  selector: "app-roles-details",
  templateUrl: "./roles-details.component.html",
  styleUrls: ["./roles-details.component.scss"]
})
export class RolesDetailsComponent extends PagesSettingsComponent implements OnInit {
  @Input() roleDetail: PageRoles;
  @Input() rolePages: Page;
  @Input() index;
  @Output() changeEvent = new EventEmitter<boolean>();
  @Input() dataRole;
  @Input() newDesign: boolean = false;
  public model: Model;
  public user: User;
  public userName;
  public imgProfile;
  public model$: Observable<Model>;
  public role = new PageRoles();
  public page: Page;

  public privacies = [
    { isChecked: false, id: Role.Owner, name: "role.owner", role: Role.Owner, show: false },
    { isChecked: false, id: Role.Admin, name: "role.admin", role: Role.Admin, show: false},
    { isChecked: false, id: Role.Editor, name: "role.editor", role: Role.Editor, show: false },
    { isChecked: false, id: Role.Moderator, name: "role.moderator", role: Role.Moderator, show: false },
    { isChecked: false, id: Role.Advertiser, name: "role.advertiser", role: Role.Advertiser, show: false },
    { isChecked: false, id: Role.Analyst, name: "role.analyst", role: Role.Analyst, show: false },
    { isChecked: false, id: Role.User, name: "role.user", role: Role.User, show: false },
    { isChecked: false, id: Role.Jobs, name: "role.jobs", role: Role.Jobs, show: false },
    { isChecked: false, id: Role.Projects, name: "role.projects", role: Role.Projects, show: false },
    { isChecked: false, id: Role.Messages, name: "role.messages", role: Role.Messages, show: false },
    { isChecked: false, id: Role.Notifications, name: "role.notifications", role: Role.Notifications, show: false },
    { isChecked: false, id: Role.Payments, name: "role.payments", role: Role.Payments, show: false },
    { isChecked: false, id: Role.Marketing, name: "role.marketing", role: Role.Marketing, show: false },
  ];

  editMode = false;
  constructor(public router: Router,
    public branchesService: BranchesService,
    public languageService: LanguagesService,
    public http: HttpClient,
    public authService: AuthService,
    public pageService: PagesService,
    public dialog: MatDialog,
    public imageService: ImageService,
    public shareData: SenderService,
    public receiver: SenderService,
    public cd: ChangeDetectorRef,
    public userService?: UserService,
    public toast?: ToastrService) {
    super(router, branchesService, languageService, http, authService, pageService, dialog, imageService, shareData, receiver, cd);
  }

  ngOnInit(): void {
    this.receiver.dataUpdate$.subscribe((success: any) => {
      this.page = success;
      this.name = this.page.pageUrl || this.page._id;
      this.userService.getUserContactData(this.roleDetail.userID).subscribe(res => {
        if (res) {
          this.user = res;
          this.userName = `${this.user.firstName} ${this.user.lastName}`;
        }
      });



    });
  }

  editRole(test) {
    this.editMode = true;

      if (this.rolePages.roles.Owner.find(r => r.userID === this.roleDetail.userID)) {
        this.privacies.find(rt => rt.id === Role.Owner).isChecked = true;
      }
      if (this.rolePages.roles.Admin.find(r => r.userID === this.roleDetail.userID)) {
        this.privacies.find(rt => rt.id === Role.Admin).isChecked = true;
      }
      if (this.rolePages.roles.Advertiser.find(r => r.userID === this.roleDetail.userID)) {
        this.privacies.find(rt => rt.id === Role.Advertiser).isChecked = true;
      }
      if (this.rolePages.roles.Moderator.find(r => r.userID === this.roleDetail.userID)) {
        this.privacies.find(rt => rt.id === Role.Moderator).isChecked = true;
      }
      if (this.rolePages.roles.Editor.find(r => r.userID === this.roleDetail.userID)) {
        this.privacies.find(rt => rt.id === Role.Editor).isChecked = true;
      }
      if (this.rolePages.roles.Analyst.find(r => r.userID === this.roleDetail.userID)) {
        this.privacies.find(rt => rt.id === Role.Analyst).isChecked = true;
      }
      if (this.rolePages.roles.User.find(r => r.userID === this.roleDetail.userID)) {
        this.privacies.find(rt => rt.id === Role.User).isChecked = true;
      }
      if (this.rolePages.roles.Jobs.find(r => r.userID === this.roleDetail.userID)) {
        this.privacies.find(rt => rt.id === Role.Jobs).isChecked = true;
      }
      if (this.rolePages.roles.Projects.find(r => r.userID === this.roleDetail.userID)) {
        this.privacies.find(rt => rt.id === Role.Projects).isChecked = true;
      }
      if (this.rolePages.roles.Messages.find(r => r.userID === this.roleDetail.userID)) {
        this.privacies.find(rt => rt.id === Role.Messages).isChecked = true;
      }
      if (this.rolePages.roles.Notifications.find(r => r.userID === this.roleDetail.userID)) {
        this.privacies.find(rt => rt.id === Role.Notifications).isChecked = true;
      }
      if (this.rolePages.roles.Payments.find(r => r.userID === this.roleDetail.userID)) {
        this.privacies.find(rt => rt.id === Role.Payments).isChecked = true;
      }
      if (this.rolePages.roles.Marketing.find(r => r.userID === this.roleDetail.userID)) {
        this.privacies.find(rt => rt.id === Role.Marketing).isChecked = true;
      }


  }
  save(role) {
    this.role = role;
    this.pageService.updatePage(this.page._id, this.page);
    this.editMode = false;
    this.changeEvent.emit(true);

  }
  cancelRole() {
    this.role = this.role;
    this.editMode  = false;
  }
    addRole(event, id) {

      const role = {
        userID: this.roleDetail.userID,
        timestamp: new Date()
      }

      console.log(event)

      console.log(this.role.userID, "userID", id.role, "idroel")
      switch (id.role) {
        case Role.Admin:
          if (id.isChecked) {
            return this.page.roles.Admin.unshift(role);
          } else {
            this.page.roles.Admin.splice(_.indexOf(this.page.roles.Admin, _.find(this.page.roles.Admin, { userID: this.role.userID })), 1);
          }
          break;
        case Role.Owner:
          if (id.isChecked) {
            return this.page.roles.Owner.unshift(role);
          }
          this.page.roles.Owner.splice(_.indexOf(this.page.roles.Owner, _.find(this.page.roles.Owner, { userID: this.role.userID })), 1);
          break;
        case Role.Advertiser:
          if (id.isChecked) {
            this.page.roles.Advertiser.unshift(role);
          } else {
            this.page.roles.Advertiser.splice(_.indexOf(this.page.roles.Advertiser, _.find(this.page.roles.Advertiser, { userID: this.role.userID })), 1);
          }
          break;
        case Role.Moderator:
          if (id.isChecked) {
            this.page.roles.Moderator.unshift(role);
          } else {
            this.page.roles.Moderator.splice(_.indexOf(this.page.roles.Moderator, _.find(this.page.roles.Moderator, { userID: this.role.userID })), 1);
          }
          break;
        case Role.Editor:
          if (id.isChecked) {
            this.page.roles.Editor.unshift(role);
          } else {
            this.page.roles.Editor.splice(_.indexOf(this.page.roles.Editor, _.find(this.page.roles.Editor, { userID: this.role.userID })), 1);
          }
          break;
        case Role.User:
          if (id.isChecked) {
            this.page.roles.User.unshift(role);
          } else {
            this.page.roles.User.splice(_.indexOf(this.page.roles.User, _.find(this.page.roles.User, { userID: this.role.userID })), 1);
          }
          break;
        case Role.Analyst:
          if (id.isChecked) {
            this.page.roles.Analyst.unshift(role);
          } else {
            this.page.roles.Analyst.splice(_.indexOf(this.page.roles.Analyst, _.find(this.page.roles.Analyst, { userID: this.role.userID })), 1);
          }
          break;
        case Role.Projects:
          if (id.isChecked) {
            this.page.roles.Projects.unshift(role);
          } else {
            this.page.roles.Projects.splice(_.indexOf(this.page.roles.Projects, _.find(this.page.roles.Projects, { userID: this.role.userID })), 1);
          }
          break;
        case Role.Jobs:
          if (id.isChecked) {
            this.page.roles.Jobs.unshift(role);
          } else {
            this.page.roles.Jobs.splice(_.indexOf(this.page.roles.Jobs, _.find(this.page.roles.Jobs, { userID: this.role.userID })), 1);
          }
          break;
        case Role.Messages:
          if (id.isChecked) {
            this.page.roles.Messages.unshift(role);
          } else {
            this.page.roles.Messages.splice(_.indexOf(this.page.roles.Messages, _.find(this.page.roles.Messages, { userID: this.role.userID })), 1);
          }
          break;
        case Role.Notifications:
          if (id.isChecked) {
            this.page.roles.Notifications.unshift(role);
          } else {
            this.page.roles.Notifications.splice(_.indexOf(this.page.roles.Notifications, _.find(this.page.roles.Notifications, { userID: this.role.userID })), 1);
          }
          break;
        case Role.Payments:
          if (id.isChecked) {
            this.page.roles.Payments.unshift(role);
          } else {
            this.page.roles.Payments.splice(_.indexOf(this.page.roles.Payments, _.find(this.page.roles.Payments, { userID: this.role.userID })), 1);
          }
          break;
        case Role.Marketing:
          if (id.isChecked) {
            this.page.roles.Marketing.unshift(role);
          } else {
            this.page.roles.Marketing.splice(_.indexOf(this.page.roles.Marketing, _.find(this.page.roles.Marketing, { userID: this.role.userID })), 1);
          }
          break;
        default:
          break;
      }

    }

  remove() {

    console.log(this.dataRole);

    this.dataRole.splice(this.index, 1);

    console.log(this.roleDetail.userID, "zest");


  /*  this.rolePages.roles.splice(this.index, 1) */
    this.pageService.updatePage(this.rolePages._id, this.rolePages);
   // this.pageService.getAllPages();
    this.toast.success(`The user ${this.userName} was removed from page roles`);
    this.changeEvent.emit(true);
  }
  isChecked(privacy) {
   // return this.rolePages.userRole.userID.indexOf(privacy.role) >= 0;
  }
}
