import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Duration, EmploymentType, Incomes, Remote } from 'src/app/shared/enums/enums-model';
import { Job } from 'src/app/shared/interfaces/model';
import { CountryDataService } from 'src/app/shared/services/country-data.service';
import { JobsService } from 'src/app/shared/services/jobs.service';
import { ProjectService } from 'src/app/shared/services/project.service';
import { SenderService } from 'src/app/shared/services/sender.service';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss']
})
export class BoardComponent implements OnInit {
public job: Job;
public paymentID;
public remote;
public jobDurationString;
public employmentType = [
  {
    id: EmploymentType.EmployedFullTime,
    description: "employmentType.EmployedFullTime",
  },
  {
    id: EmploymentType.EmployedPartTime,
    description: "employmentType.EmployedPartTime",
  },
  { id: EmploymentType.Internship, description: "employmentType.Internship" },
  { id: EmploymentType.Owner, description: "employmentType.Owner" },
  {
    id: EmploymentType.BordMember,
    description: "employmentType.Boardmember",
  },
  { id: EmploymentType.Volunteer, description: "employmentType.Volunteer" },
  {
    id: EmploymentType.SelfEmployed,
    description: "employmentType.SelfEmployed",
  },
  {
    id: EmploymentType.Shareholder,
    description: "employmentType.Shareholder",
  },
  { id: EmploymentType.Official, description: "employmentType.Official" },
  { id: EmploymentType.Recruiter, description: "employmentType.Recruiter" },
  { id: EmploymentType.Freelancer, description: "employmentType.Freelancer" },
  { id: EmploymentType.Partner, description: "employmentType.Partner" },
];
public remoteArray = [
  { id: Remote.onSite, description: "project.firstPage.onSite", percent: '0%' },
  { id: Remote.hybrid, description: "project.firstPage.hybrid", percent: '50%' },
  { id: Remote.remote, description: "project.firstPage.remote", percent: '100%' },
];
public salaryArray = [
  { id: Incomes.hour, description: "incomes.hour" },
  { id: Incomes.month, description: "incomes.month" },
  { id: Incomes.year, description: "incomes.year" },
  { id: Incomes.total, description: "incomes.total" },
]

public jobDurationArray = [
  { id: Duration.unlimited, description: "job.fourthPage.duration.unlimited" },
  { id: Duration.temporary, description: "job.fourthPage.duration.temporary" },
]
public employmentTypeString;
countryJsonObject;
countryJsonArray;
currencyObject;
isProject: boolean = false;


  constructor(
    public senderService: SenderService,
    public jobService: JobsService,
    private http: HttpClient,
    private countryDataService: CountryDataService,
    private projectService: ProjectService

  ) { }

  ngOnInit(): void {
    this.countryDataService.countryData$.pipe().subscribe(res => this.countryJsonArray = res);
    this.senderService.manageProjectID.subscribe(res => {
     if (res.isProject) {
      this.isProject = true;
      this.projectService.getProject(res.ID).subscribe(response => {
        this.job =response;
        this.getEmploymentTypeID(this.job.employmentType);
        this.getRemoteID(this.job.remote);
        this.getCountryID(this.job.country);
        this.getPaymentFormID(this.job.incomes.status);
        this.getJoDurationID(this.job.duration);
        this.getCurrencyID(this.job.currency)

      })
     } else {
      this.senderService.manageJobID.subscribe(res => {
        this.jobService.getJob(res).subscribe((job) => {
          this.job = job;
          this.getEmploymentTypeID(this.job.employmentType);
          this.getRemoteID(this.job.remote);
          this.getCountryID(this.job.country);
          this.getPaymentFormID(this.job.incomes.status);
          this.getJoDurationID(this.job.duration);
          this.getCurrencyID(this.job.currency)
  
        })
      });
     }
    })
   

   
  }
  getEmploymentTypeID(id) {
    this.employmentTypeString = this.employmentType.find(
      (t) => t.id === id
    )?.description;
  }
  getCountryID(id) {
    this.countryJsonObject = this.countryJsonArray.find((t) => t.ISO_3 === id);
  }

  getRemoteID(id) {
    this.remote = this.remoteArray.find(test => test.id === id)?.description;
  }

  getPaymentFormID(id) {
    this.paymentID = this.salaryArray.find(t => t.id === id)?.description;
  }

  getJoDurationID(id) {
    this.jobDurationString = this.jobDurationArray.find(t => t.id === id)?.description;
  }
  getCurrencyID(id) {
    this.currencyObject = this.countryJsonArray.find(t => t.currency.Alphabeticcode === id);
  }




}
