import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/shared/interfaces/user.model';
import { ChangeDetectionService } from 'src/app/shared/services/change-detection.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-sidebar-language',
  templateUrl: './sidebar-language.component.html',
  styleUrls: ['./sidebar-language.component.scss']
})
export class SidebarLanguageComponent implements OnInit, AfterViewInit {
  @Input() data: any;
  @Input() isUser: boolean;
  languages = [];
  @Input() user: User;
  showLanguage;



  constructor(private userService: UserService, private changeDetectionService: ChangeDetectionService,  public http: HttpClient,) { }
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    console.log(this.data, "language")

    if (!!this.user) {
      this.http.get<any>(`assets/languages/${this.user?.language?.primary}/language.json`).subscribe(data => {
        this.languages = data;
        this.languages = Object.keys(this.languages).map(key => ({type: key, value: this.languages[key]}));

      })
    }


  }

  replaceLanguage() {
    const data = this.user.cv.data.map(t => t.skills[0].subCategories[0].languages.data);
    let  newArr = Array.prototype.concat(...data);

    newArr =  newArr.filter((el, i, a) => i === a.findIndex((findTest) => findTest.name === el.name));
    this.user.searchFilterSkills.searchLanguages.data = [];
    this.user.searchFilterSkills.searchLanguages.data = newArr;

     this.userService.updateUser(this.user._id, this.user);
      this.changeDetectionService.emitChanges(true);

  }

  removeLanguage(index) {
    this.user.searchFilterSkills.searchLanguages.data.splice(index, 1);
    this.userService.updateUser(this.user._id, this.user);
    this.changeDetectionService.emitChanges(true);

  }

  public getLanguageName(type) {
   return this.languages.find(t => t.type === type)?.value;
  }



}
