import { timestamp } from 'rxjs/operators';
import {
  AdsManagerArray,
  Categories,
  Model,
  Phone,
  SearchDriveLicense,
  SearchLanguage,
  SearchPCKnowledge,
  SearchQualifications,
  SearchSkillOffer,
  StateAddress,
  UserContact,
  UserJobs,
  UserPage,
  UserProjects,
  UserSearchLocation,
} from "./model";

export class User {
  _id?: string;
  pAccount?: boolean;
  firstName?: string;
  lastName?: string;
  offer?: {
    timestamp: Date,
    status: number
  }
  email?: {
    data: [
      {
        p?: number;
        name: string
      }
    ]
  };
  password?: string;
  confirmPassword?: string;
  uniqueId?: string;
  userId?: string;
  isFriend?: boolean;
  status?: boolean;
  nationality?: {
    p: number;
    data: string;
  };

  ads: {
    p: number,
    data?: AdsManagerArray[];
  }
  relationship: {
    p: number;
    status: number;
    user?: {
      id: string,
      status: number
    }
    date?: Date
  };

  familyMembers: [{
    _id?: false,
    p: number,
    s: number,
    user?: {
      id: string
      s: Number
    }
  }];
  socialLinks: {
    links: SocialLinks[];
  }
  websites: {
    p: string;
    urls: Websites[];
  }
  religion?: {
    name?: string;
    p?: number;
    pageID?: string
  };
  branches?: string;
  categories?: Categories;
  about?: String;
  phone?: {
    data: Phone[];
  };
  birthday?: {
    p?: string;
    date?: string | Date;
  };
  title: number;

  address: {
    p: number;
    data?:StateAddress[];
  }
  gender?: number;
  language?: {
    primary?: string,
    secondary?: string
  };
  birthday_day?: string;
  birthday_month?: string;
  birthday_year?: string;
  places: {
    hometown: {
      p: number;
      name?: string,
      pageID?: string
    },
    current: {
      p: number;
      name?: string,
      pageID?: string
    },
    cities: {
      p: number;
      data: Cities[];
    }
  };
  following?: {
    p: number;
    data: [{
      userId?: string;
      pageId?: string;
      status?: number;
      timestamp: Date;

    }]
  };
  followers?: {
    p: boolean;
    counter: number;
  }
  notifications?: {
    followers?: {
      counter?: number,
      seen?: boolean
    }
  };
  contacts?: {
    p: number;
    data: UserContact[];
  }
  contactExpect?: [
    {
      id?: string;
      contactId?: string;
      timestamp?: Date;
    }
  ];
  specificContacts?: [
    {
      id?: string;
      contactId?: string;
      timestamp?: Date;
    }
  ];
  profilePicture?: string;
  coverPicture?: string;
  userUrl?: string;
  userName?: string;
  cv?: {
    p: number;
    data: Model[];
  }
  messages?: [
    {
      _id?: string;
      userId?: string;
      messageID?: string;
      timestamp: Date;
      jobId?: string;
      projectId?: string;
    }
  ];
  premium?: {
    status: number;
    timestamp: Date;
    endDate: Date;
  };
  pages?: UserPage[];
  jobs?: {
    p: number;
    data: UserJobs[];
  };

  projects?: {
    p: number;
    data: UserProjects[];
  }

  searchLocation?: {
    active: {
      s: number,
      from: Date
    };
    remote: number;
    data: UserSearchLocation[];
  };
  searchFilterSkills?: {
    searchPCKnowledge?: {
      p: number;
      data:SearchPCKnowledge[];
    }
    searchLanguages?: {
      p: number;
      data: SearchLanguage[];
    }
    searchSkillsOffer?: {
      p: number;
      data:SearchSkillOffer[];
    }
    searchDriveLicenses?: {
      p: Number,
      data:    SearchDriveLicense[];
    }
    searchQualifications?: {
      p: number;
      data: SearchQualifications[];
    }
  };
  incomes?: {
    currency?: string;
    status?: string;
    budget?: number;
    hour: number;
    month: number;
    year: number;
  };
  remote?: number;
  savedJobs?: [
    {
      jobId: string;
      timestamp: Date;
    }
  ];
  appliedJobs?: [
    {
      jobId: string;
      timestamp: Date;
    }
  ];
  ignoredJobs: [
    {
      jobId: string;
      timestamp: Date;
    }
  ];
  savedProjects?: [
    {
      projectId: string;
      timestamp: Date;
    }
  ];
  appliedProjects?: [
    {
      projectId: string;
      timestamp: Date;
    }
  ];
  ignoredProjects?: [
    {
      projectId: string;
      timestamp: Date;
    }
  ];
  payment?: {
    counter: number,
    history: CreditsHistory[];
    transactions: Transactions[];
    CV: number;
    Page: number;
    Group: number;
    Job: number;
    Project: number;
    coins: number;
  };
  account?: {
    status?: number,
    startDate?: Date,
    endDate?: Date,
    deleted?: boolean;
  }
}

export interface CreditsHistory {
  date: Date;
  company: string;
  type_: string;
  account_number: string;
  amount: number;
  invoiceID: number
}
export interface Transactions {
  date: Date;
  company: String;
  type: String;
  account_number: String;
  reference: String;
  status: String;
  amount: Number;
  invoiceID: number
}

export class Cities {
  name?: string;
  pageID?: string;
  date: Date
}

export class SocialLinks {
  url: string;
  id: string;
  p: number;
}

export class Websites {
  url: string;
}
