<ng-container *ngIf="!isPublished && !isProject && !isProjectOutside && !isJobOutside">
  <div id="profile-item" class="summary-box profile-summary introjs-should-disabled">
    <div rel="group1" class="summary-link modal-link" data-bind="css: { 'ignored': isIgnored() }">
      <div class="card">
        <div class="flex col-md-12 job-buttons">
          <button [disabled]="!job?.public?.startDate" class="btn btn-sm btn-outline-success btn-sm btn-sm col-lg-3" (click)="searchJob(job?._id)"
            stopPropagation>
            {{'job.search' | translate}}
          </button>
          <button *ngIf="!selectedObject" class="btn btn-primary-soft btn-sm col-lg-2" (click)="manageJob()" stopPropagation>
            {{'job.manage' | translate}}
          </button>
          <button *ngIf="selectedObject" class="btn btn-primary-soft btn-sm col-lg-2" stopPropagation
            (click)="closeManageObject(data?._id)">
            {{'project.closeManage' | translate}}
          </button>
          <button *ngIf="!job?.public?.startDate" class="btn btn-success-soft d-block btn-sm col-lg-3"
            (click)="publicJob()" stopPropagation [disabled]="job?.public?.startDate">
            {{'job.public' | translate}}
          </button>
          <button *ngIf="job?.public?.startDate" class="btn btn-sm py-1 btn-danger-soft col-lg-3"
            (click)="removeFromPublic(job)" stopPropagation>
            {{'job.isPublic' | translate}}
          </button>
        </div>
        <div class="card-body">
          <div class="col-12 col-lg-12 d-block d-lg-flex mt-4 px-0">
            <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 px-0">
              <div class="d-flex justify-content-start col-12 row" *ngIf="isMyProject">
                <div class="form-group col-md-4 col-12">
                  <label for="exampleInputEmail1">Apply to</label>
                  <select class="form-select-sm ng-valid col-md-8 col-5 col-sm-12 col-xs-12"
                    [ngModel]="job?.receivedApplicationBy?.selectedApplication" stopPropagation
                    (ngModelChange)="changeApplication($event); selectCheck()" aria-label="Default select example">
                    <option *ngFor="let array of arrayOfApplication" [selected]="array" [value]="array.id">
                      {{array.description | translate}}</option>
                  </select>
                </div>
                <input stopPropagation *ngIf="job?.receivedApplicationBy?.selectedApplication === 1"
                  class="form-control-sm col-8 col-md-6" type="text" [(ngModel)]="externalLink">
                <div *ngIf="showButtonsForSave" class="flex
                  items-center" style="
                  flex-direction: row;
                  display: flex;
                  border:none;
              " class="specialty col-md-2 px-0 mt-1 col-2">
                  <ng-container>
                    <button class="btn btn-light me-2" stopPropagation (click)="saveProjectChanges()">
                      <i class="bi bi-check"></i>
                    </button>
                  </ng-container>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <img borderWidth="2" borderColor="#ffffff" ngx-gravatar [email]="'example@mail.com'" fallback="mp"
                  size="30">
                <div class="d-flex">

                  <h6 *ngIf="job?.userID" class="ms-1">{{userCreatedProject?.firstName}}
                    {{userCreatedProject?.lastName}}</h6>
                  <div class="dropdown">
                    <button type="button" stopPropagation class="btn btn-light btn-sm me-2 ms-1  dropdown-toggle"
                      data-bs-toggle="dropdown">
                    </button>
                    <ul class="dropdown-menu">
                      <li *ngFor="let privacy of dataArrProjects">
                        <app-user-dialog-name [privacy]="privacy"
                          (changeEventName)="changeUserOfProject($event)"></app-user-dialog-name>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
              <h6>{{job?.positionTitle}}</h6>
              <div style="margin-bottom: 0.5rem !important; display: flex; align-items: center;">
                <p style="margin-right: 0.25rem;"><i class="bi me-1 bi-briefcase"></i> {{'career.employmentType' |
                  translate}}</p>
                <p>{{employmentTypeString | translate}}</p>
              </div>
              <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi me-1 bi-person-workspace" viewBox="0 0 16 16">
                  <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                  <path
                    d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z" />
                </svg> {{'project.firstPage.workplace' | translate}}: {{remote | translate}}</p>
              <div style="display: flex; align-items: center">
                <p> <i class="bi me-1 bi-geo-alt"></i> {{'job.address' | translate}}:
                {{job?.street}}, {{job?.postalCode}}, {{job?.city}}, {{countryJsonObject?.EN}}</p>
              </div>
              <p><i class="bi me-1 bi-cash"></i>
                {{"job.fourthPage.paymentForm" | translate }}: {{paymentID | translate}} {{job?.incomes.budget}}+
                {{currencyObject?.currency.Symbol}} ({{currencyObject?.currency.Name}}) </p>
              <p><i class="bi me-1 bi-calendar-week"></i> {{'job.fourthPage.duration.jobDuration' | translate}}:
                {{jobDurationString | translate}} - Start date {{job?.startDate | date: "dd.MM.yyyy"}}</p>
            </div>
          </div>
          <div class="col-lg-12 mt-3  ml-lg-0 px-0" *ngIf="openMoreInfo">





            <div class="card-body px-0">

              <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2"
                [ngClass]="{ 'isActive': selectedSkillsOffer && isMyProject}" (mouseenter)="selectSkillsOffer()"
                (mouseleave)="removeClick()" (click)="selectSkillsOffer()">
                <div class="Text-Text-text">
                  <strong>
                    {{ "job.secondGroup.tasks" | translate}}
                  </strong>
                </div>

                <div class="Text-Text-text-wrapper">
                  <app-chips [chips]="job?.skillsOffer" stopPropagation></app-chips>
                </div>
                <div *ngIf="selectedSkillsOffer && isMyProject">
                  <div clickOutside (clickOutside)="removeClick()">
                    <span class="bi bi-pencil-fill edit-icon"
                      (click)="editSkillsOffer({project:project, jobSkillsOffer: true})"></span>


                  </div>
                </div>
              </div>

              <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="moreProjectInfo?.pcKnowledge?.length > 0"
                [ngClass]="{ 'isActive': selectedPCKnowledge && isMyProject}" (mouseenter)="selectPCKnowledge()"
                (mouseleave)="removeClick()" (click)="selectPCKnowledge()">
                <div class="Text-Text-text">
                  <strong>
                    {{ "skills.pcKnowledge" | translate}}
                  </strong>
                </div>

                <div class="Text-Text-text-wrapper">
                  <app-chips [chips]="moreProjectInfo?.pcKnowledge" stopPropagation></app-chips>
                </div>
                <div *ngIf="selectedPCKnowledge && isMyProject">
                  <div clickOutside (clickOutside)="removeClick()">
                    <span class="bi bi-pencil-fill edit-icon"
                      (click)="editPCKnowledge({project:project, jobPCKnowledge: true})"></span>
                  </div>
                </div>

              </div>
              <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="moreProjectInfo?.language?.length > 0"
                [ngClass]="{ 'isActive': selectedLanguage && isMyProject}" (mouseenter)="selectLanguage()"
                (mouseleave)="removeClick()" (click)="selectLanguage()">
                <div class="Text-Text-text">
                  <strong>
                    {{ "skills.language" | translate}}
                  </strong>

                  <div *ngFor="let language of job?.language; let iLang = index">
                    <div class="Field-field-fieldBase">
                      <div class="Text-Text-wrapper">
                        <div class="Text-Text-text-wrapper">
                          <div class="progress">
                            <div id="rate" [style.background]="'#d9d9d9'" [style.width]="language.rate + '20%'"
                              style="display: flex; white-space: pre-wrap;" [style.line-height.px]="13"
                              [style.font-size.px]="10">
                              <div style="display: flex; white-space: pre-wrap; padding-left: .5rem;" id="language">
                                {{language.name | translate}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="selectedLanguage && isMyProject">
                  <div clickOutside (clickOutside)="removeClick()">
                    <span class="bi bi-pencil-fill edit-icon"
                      (click)="editJobLanguage({project:project, jobLanguage: true, jobDescription: false})"></span>


                  </div>
                </div>
              </div>
              <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="job?.driveLicense?.length > 0"
                [ngClass]="{ 'isActive': selectedDriveLicense && isMyProject}" (mouseenter)="selectDriveLicense()"
                (mouseleave)="removeClick()" (click)="selectDriveLicense()">
                <div class="Text-Text-text">
                  <strong>
                    {{ "skills.driveLicense" | translate}}
                  </strong>

                  <div class="Text-Text-text-wrapper">
                    <app-chips [chips]="job?.driveLicense" stopPropagation></app-chips>
                  </div>
                </div>
                <div *ngIf="selectedDriveLicense && isMyProject">
                  <div clickOutside (clickOutside)="removeClick()">
                    <span class="bi bi-pencil-fill edit-icon"
                      (click)="editJobDriveLicense({project:project, jobDriveLicense: true})"></span>


                  </div>
                </div>
              </div>
              <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="job?.qualifications?.length > 0"
                [ngClass]="{ 'isActive': selectedQualifications && isMyProject}" (mouseenter)="selectQualifications()"
                (mouseleave)="removeClick()" (click)="selectQualifications()">
                <div class="Text-Text-text">
                  <strong>
                    {{ "skills.qualifications" | translate}}
                  </strong>

                  <div class="Text-Text-text-wrapper">
                    <app-chips [chips]="moreProjectInfo?.qualifications" stopPropagation></app-chips>
                  </div>
                </div>
                <div *ngIf="selectedQualifications && isMyProject">
                  <div clickOutside (clickOutside)="removeClick()">
                    <span class="bi bi-pencil-fill edit-icon"
                      (click)="editQualifications({project:project, jobQualifications: true})"></span>
                  </div>
                </div>
              </div>

            </div>





            <br>
            <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2"
              [ngClass]="{ 'isActive': selectedDescription && isMyProject}" (mouseenter)="selectDescription()"
              (mouseleave)="removeClick()" (click)="selectDescription()">
              <div class="Text-Text-text">
                <strong>
                  {{ "description" | translate}}
                </strong>
              </div>
              <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2">
                <div class="Text-Text-text-wrapper" [innerHTML]="job?.description | noSanitize">
                </div>
              </div>
              <div *ngIf="selectedDescription && isMyProject">
                <div clickOutside (clickOutside)="removeClick()">
                  <span class="bi bi-pencil-fill edit-icon"
                    (click)="editJobDescription({project:project, jobDescription: true})"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="button-bottom">

            <span  style="align-items: self-end;
            display: flex;" *ngIf="job?.public?.startDate">Published: {{job?.public?.startDate | date:"dd.MM.yyyy HH:mm"}} <br> End:  {{job?.public?.endDate | date:"dd.MM.yyyy HH:mm"}}

            </span>
            <button type="button" class="btn btn-light" stopPropagation (click)="jobInfo()"
              *ngIf="!openMoreInfo"><i class="bi bi-plus"></i> More</button>
            <button type="button" class="btn btn-light" (click)="lessInfo()" *ngIf="openMoreInfo" stopPropagation><i
                class="bi bi-dash"></i> Less</button>
          </div>
        </div>

      </div>
      <div class="card mt-3 bg-none" *ngIf="selectedObject" style="background: none;">
        <app-board-dnd></app-board-dnd>
      </div>
    </div>

  </div>

</ng-container>
<div (click)="emitID(job._id)" *ngIf="isPublished && job?.public && !isProject && !isJobOutside"
  class="summary-box profile-summary introjs-should-disabled">

  <div id="profile-item" stopPropagation class="summary-box profile-summary introjs-should-disabled">
    <div rel="group1" class="summary-link modal-link" data-bind="css: { 'ignored': isIgnored() }">
      <div class="card mb-2">
        <div class="flex col-12 job-buttons">

          <ul class="nav nav-pills nav-stack small fw-normal">
            <li class="nav-item" (click)="apply(job?._id)">
              <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                  class="bi me-1 bi-box-arrow-up-right text-success pe-2"></i>{{'job.apply' | translate}}</a>
            </li>

            <li class="nav-item">
              <a  (click)="openShare({data: job, isPage: true, url: page.pageUrl, isProject: true })" class="nav-link bg-light py-1 px-2 mb-0"> <i
                  class="bi me-1 bi-share text-warning pe-2"></i><span>{{'job.share' | translate}}</span></a>
            </li>
            <li class="nav-item dropdown ms-lg-auto">
              <a class="nav-link bg-light py-1 px-2 mb-0" href="#" id="feedActionShare" data-bs-toggle="dropdown"
                aria-expanded="false">
                <i class="bi bi-three-dots"></i>
              </a>
              <!-- Dropdown menu -->
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="feedActionShare">
                <li><a class="dropdown-item" href="#"> <i class="bi bi-envelope fa-fw pe-2"></i>Create a poll</a></li>
                <li><a class="dropdown-item" href="#"> <i class="bi bi-bookmark-check fa-fw pe-2"></i>Ask a question
                  </a></li>
                <li>
                  <hr class="dropdown-divider">
                </li>
                <li><a class="dropdown-item" href="#"> <i class="bi bi-pencil-square fa-fw pe-2"></i>Help</a></li>
              </ul>
            </li>
          </ul>
        </div>
        <ng-container *ngIf="applyForm">
          <div class="d-sm-flex align-items-end mt-1">
            <quill-editor name="editor" [styles]="editorConfig" stopPropagation style="width:80%" [modules]="config"
              [(ngModel)]="description">
            </quill-editor>
            <button *ngIf="!isProjectSearchCompany" (click)="apply(data)" stopPropagation [disabled]="!description"
              class="btn btn-sm btn-primary ms-2"><i class="bi bi-send  fs-6"></i></button>
            <button *ngIf="!!isProjectSearchCompany" (click)="applyForPageProject()" stopPropagation
              [disabled]="!description" class="btn btn-sm btn-primary ms-2"><i
                class="bi bi-send  fs-6"></i></button>
            <button (click)="cancelApplyForm()" stopPropagation class="btn btn-sm btn-primary ms-2"><i
                class="bi bi-x  fs-6"></i></button>

          </div>
        </ng-container>

        <div class="card-body">
          <div class="col-12 col-lg-12 d-block d-lg-flex mt-4 px-0">
            <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 px-0">

              <h6>{{job?.positionTitle}}</h6>
              <p style="margin-right: 0.25rem">
                <i class="bi me-1 bi-briefcase"></i>
                {{ "career.employmentType" | translate }}:

              {{ employmentTypeString | translate }}</p>
              <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi me-1 bi-person-workspace" viewBox="0 0 16 16">
                  <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                  <path
                    d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z" />
                </svg> {{'project.firstPage.workplace' | translate}}: {{remote | translate}}</p>
              <div style="display: flex; align-items: center">
                <p> <i class="bi me-1 bi-geo-alt"></i> {{'job.address' | translate}}:
              {{job?.street}}, {{job?.postalCode}}, {{job?.city}},
                  {{countryJsonObject?.EN}}</p>
              </div>



              <p><i class="bi me-1 bi-cash"></i>
                {{"job.fourthPage.paymentForm" | translate }}: {{paymentID | translate}} {{job?.incomes.budget}}+
                {{currencyObject?.currency.Symbol}} ({{currencyObject?.currency.Name}}) </p>
              <p><i class="bi me-1 bi-calendar-week"></i> {{'job.fourthPage.duration.jobDuration' | translate}}:
                {{jobDurationString | translate}} - Start date {{job?.startDate | date: "dd.MM.yyyy"}}</p>
              <div class="col-lg-12 mt-3  ml-lg-0 px-0" *ngIf="openMoreInfo">
                <div *ngIf="project.company" class="remove-icon-border">
                  <div class="card-header px-0">

                    <strong>{{ 'project.company' | translate }}</strong>
                  </div>

                  <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2">
                    <div class="Text-Text-text pt-2">
                      <strong>
                        {{ "project.secondPage.tasks" | translate}} {{ "project.secondPage.keywords" | translate }}
                      </strong>
                    </div>
                    <div class="Text-Text-text-wrapper">
                      <app-chips [chips]="job?.skillsOffer" stopPropagation></app-chips>
                    </div>
                  </div>
                </div>
                  <div class="card-body px-0">

                    <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "job.secondGroup.tasks" | translate}}
                        </strong>
                      </div>

                      <div class="Text-Text-text-wrapper">
                        <app-chips [chips]="job?.skillsOffer" stopPropagation></app-chips>
                      </div>

                    </div>

                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.pcKnowledge" | translate}}
                        </strong>
                      </div>

                      <div class="Text-Text-text-wrapper">
                        <app-chips [chips]="job?.pcKnowledge" stopPropagation></app-chips>
                      </div>


                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="job?.language?.length > 0">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.language" | translate}}
                        </strong>

                        <div *ngFor="let language of job?.language; let iLang = index">
                          <div class="Field-field-fieldBase">
                            <div class="Text-Text-wrapper">
                              <div class="Text-Text-text-wrapper">
                                <div class="progress">
                                  <div id="rate" [style.background]="'#d9d9d9'" [style.width]="language.rate + '20%'"
                                    style="display: flex; white-space: pre-wrap;" [style.line-height.px]="13"
                                    [style.font-size.px]="10">
                                    <div style="display: flex; white-space: pre-wrap; padding-left: .5rem;"
                                      id="language">
                                      {{language.name | translate}}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="job?.driveLicense?.length > 0">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.driveLicense" | translate}}
                        </strong>

                        <div class="Text-Text-text-wrapper">
                          <app-chips [chips]="job?.driveLicense" stopPropagation></app-chips>
                        </div>
                      </div>

                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.qualifications" | translate}}
                        </strong>

                        <div class="Text-Text-text-wrapper">
                          <app-chips [chips]="job?.qualifications" stopPropagation></app-chips>
                        </div>
                      </div>

                    </div>

                  </div>




                <br>
                <div class="card-header px-0">
                  <strong>{{"description" | translate}}</strong>
                </div>
                <div class="card-body px-0">
                  <div class="col-12 col-lg-12 d-block d-lg-flex px-0">
                    <div class="col-lg-12 mt-lg-0 ml-lg-0 px-0">
                      <div class="Text-Text-text-wrapper" [innerHTML]="job?.description | noSanitize">
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>

          </div>
          <div class="button-bottom">
            <span  style="align-items: self-end;
            display: flex;" *ngIf="job?.public?.startDate">Published: {{job?.public?.startDate | date:"dd.MM.yyyy HH:mm"}} <br> End:  {{job?.public?.endDate | date:"dd.MM.yyyy HH:mm"}}
            </span>


            <ng-container>
              <button type="button" class="btn btn-light" (click)="moreInfo(job?._id)" *ngIf="!openMoreInfo"
                stopPropagation><i class="bi me-1 bi-plus"></i> {{'header.more' | translate}}</button>
            </ng-container>
            <button type="button" class="btn btn-light" (click)="lessInfo()" *ngIf="openMoreInfo" stopPropagation><i
                class="bi me-1 bi-dash"></i> Less</button>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="isPageProject && project?.public && isPublished">
  <div id="profile-item" stopPropagation class="summary-box profile-summary introjs-should-disabled">
    <div rel="group1" class="summary-link modal-link" data-bind="css: { 'ignored': isIgnored() }">
      <div class="card mb-2">
        <div class="flex col-12 job-buttons">

          <ul class="nav nav-pills nav-stack small fw-normal">
            <li class="nav-item" (click)="apply(data?._id)">
              <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                  class="bi me-1 bi-box-arrow-up-right text-success pe-2"></i>{{'job.apply' | translate}}</a>
            </li>

            <li class="nav-item">
              <a  (click)="openShare({data: project, isPage: true, url: page.pageUrl, isProject: true })" class="nav-link bg-light py-1 px-2 mb-0"> <i
                  class="bi me-1 bi-share text-warning pe-2"></i><span>{{'job.share' | translate}}</span></a>
            </li>
            <li class="nav-item dropdown ms-lg-auto">
              <a class="nav-link bg-light py-1 px-2 mb-0" href="#" id="feedActionShare" data-bs-toggle="dropdown"
                aria-expanded="false">
                <i class="bi bi-three-dots"></i>
              </a>
              <!-- Dropdown menu -->
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="feedActionShare">
                <li><a class="dropdown-item" href="#"> <i class="bi bi-envelope fa-fw pe-2"></i>Create a poll</a></li>
                <li><a class="dropdown-item" href="#"> <i class="bi bi-bookmark-check fa-fw pe-2"></i>Ask a question
                  </a></li>
                <li>
                  <hr class="dropdown-divider">
                </li>
                <li><a class="dropdown-item" href="#"> <i class="bi bi-pencil-square fa-fw pe-2"></i>Help</a></li>
              </ul>
            </li>
          </ul>
        </div>

        <ng-container *ngIf="applyForm">
          <form class="ms-2" name="ngForm" #myForm="ngForm">

            <div class="col-sm-6">
              <label class="form-label">{{'job.apply' | translate}} {{'as' | translate}}</label>
              <select name="selectedValue" id="selectedValue" [(ngModel)]="isSelected" class="col-md-12 form-control-sm"
                required (ngModelChange)="test($event)">
                <ng-container *ngIf="project.freelancer">
                  <option [ngValue]="{user: user, isUser: true}">{{user?.firstName}} {{user?.lastName}}</option>
                </ng-container>
                <ng-container *ngIf="project.company && !checkForFreelancerAndCompany">
                  <option *ngFor="let employment of arrayPages" [ngValue]="employment._id">
                    {{ employment?.name }}
                  </option>
                </ng-container>

              </select>
              <div *ngIf="myForm.submitted && selectedValue.invalid" class="invalid-feedback">
                <div *ngIf="selectedValue.errors.required">
                  {{ "career.employmentType" | translate }}
                  {{ "error.isRequired" | translate }}
                </div>
              </div>
            </div>
          </form>
          <div class="d-sm-flex mt-1 ms-2">
            <span *ngIf="project.apply.status !== 7">Kostenpflichtig</span>

          </div>
          <br>
          <div class="d-sm-flex align-items-end mt-1 ms-2" *ngIf="!hasAppliedToProject">
            <quill-editor name="editor" [styles]="editorConfig" stopPropagation style="width:80%" [modules]="config"
              [(ngModel)]="description">
            </quill-editor>

            <button *ngIf="!isProjectSearchCompany" stopPropagation [disabled]="myForm.form.invalid"
              (click)="applyForProject()" type="submit" class="btn btn-sm btn-primary ms-2"><i
                class="bi bi-send  fs-6"></i></button>
            <button *ngIf="!!isProjectSearchCompany" stopPropagation [disabled]="myForm.form.invalid" type="submit"
              class="btn btn-sm btn-primary ms-2"><i class="bi bi-send  fs-6"></i></button>
            <button (click)="cancelApplyForm()" stopPropagation class="btn btn-sm btn-primary ms-2"><i
                class="bi bi-x  fs-6"></i></button>


          </div>
          <div class="d-sm-flex align-items-end mt-1" *ngIf="hasAppliedToProject">
            <span class="ms-2">
              Applied {{showDateApplied | date: "dd.MM.yyyy HH:mm" }}

            </span>
          </div>
        </ng-container>
        <div class="card-body">
          <div class="col-12 col-lg-12 d-block d-lg-flex mt-4 px-0">
            <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 px-0">

              <h6>{{project?.positionTitle}}</h6>
              <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi me-1 bi-person-workspace" viewBox="0 0 16 16">
                  <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                  <path
                    d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z" />
                </svg> {{'project.firstPage.workplace' | translate}}: {{remote | translate}}</p>
              <div style="display: flex; align-items: center">
                <p> <i class="bi me-1 bi-geo-alt"></i> {{'job.address' | translate}}:
              {{project?.street}}, {{project?.postalCode}}, {{project?.city}},
                  {{countryJsonObject?.EN}}</p>
              </div>

              <div *ngIf="selectedFirstGroup && isMyProject">
                <div clickOutside (clickOutside)="removeClick()">
                  <ul>
                    <li class="bi bi-pencil addIconTop" (click)="editFirstGroup({project:project, firstGroup: true})">
                    </li>
                    <li class="bi bi-plus  addIconBottomCategory" (click)="editJobDescription({project:project})">
                    </li>

                  </ul>
                </div>
              </div>


              <p><i class="bi me-1 bi-cash"></i>
                {{"job.fourthPage.paymentForm" | translate }}: {{paymentID | translate}} {{job?.incomes.budget}}+
                {{currencyObject?.currency.Symbol}} ({{currencyObject?.currency.Name}}) </p>
              <p><i class="bi me-1 bi-calendar-week"></i> {{'job.fourthPage.duration.jobDuration' | translate}}:
                {{jobDurationString | translate}} - Start date {{project?.startDate | date: "dd.MM.yyyy"}}</p>
              <div class="col-lg-12 mt-3  ml-lg-0 px-0" *ngIf="openMoreInfo">
                <div *ngIf="project.company" class="remove-icon-border">
                  <div class="card-header px-0">

                    <strong>{{ 'project.company' | translate }}</strong>
                  </div>

                  <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2">
                    <div class="Text-Text-text pt-2">
                      <strong>
                        {{ "project.secondPage.tasks" | translate}} {{ "project.secondPage.keywords" | translate }}
                      </strong>
                    </div>

                    <div class="Text-Text-text-wrapper">
                      <app-chips [chips]="project?.tasks" stopPropagation></app-chips>
                    </div>

                  </div>


                </div>

                <div *ngIf="project?.freelancer" class="remove-icon-border mt-4">


                  <div class="card-header px-0">
                    <strong>{{'project.freelancer' | translate}}</strong>
                  </div>
                  <div class="card-body px-0">

                    <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "job.secondGroup.tasks" | translate}}
                        </strong>
                      </div>

                      <div class="Text-Text-text-wrapper">
                        <app-chips [chips]="project?.skillsOffer" stopPropagation></app-chips>
                      </div>

                    </div>

                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.pcKnowledge" | translate}}
                        </strong>
                      </div>

                      <div class="Text-Text-text-wrapper">
                        <app-chips [chips]="moreProjectInfo?.pcKnowledge" stopPropagation></app-chips>
                      </div>


                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="project?.language?.length > 0">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.language" | translate}}
                        </strong>

                        <div *ngFor="let language of project?.language; let iLang = index">
                          <div class="Field-field-fieldBase">
                            <div class="Text-Text-wrapper">
                              <div class="Text-Text-text-wrapper">
                                <div class="progress">
                                  <div id="rate" [style.background]="'#d9d9d9'" [style.width]="language.rate + '20%'"
                                    style="display: flex; white-space: pre-wrap;" [style.line-height.px]="13"
                                    [style.font-size.px]="10">
                                    <div style="display: flex; white-space: pre-wrap; padding-left: .5rem;"
                                      id="language">
                                      {{language.name | translate}}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="project?.driveLicense?.length > 0">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.driveLicense" | translate}}
                        </strong>

                        <div class="Text-Text-text-wrapper">
                          <app-chips [chips]="project?.driveLicense" stopPropagation></app-chips>
                        </div>
                      </div>

                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.qualifications" | translate}}
                        </strong>

                        <div class="Text-Text-text-wrapper">
                          <app-chips [chips]="project?.qualifications" stopPropagation></app-chips>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>



                <br>
                <div class="card-header px-0">
                  <strong>{{"description" | translate}}</strong>
                </div>
                <div class="card-body px-0">
                  <div class="col-12 col-lg-12 d-block d-lg-flex px-0">
                    <div class="col-lg-12 mt-lg-0 ml-lg-0 px-0">
                      <div class="Text-Text-text-wrapper" [innerHTML]="project?.description | noSanitize">
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>

          </div>
          <div class="button-bottom">
            <span  style="align-items: self-end;
            display: flex;" *ngIf="project?.public?.startDate">Published: {{project?.public?.startDate | date:"dd.MM.yyyy HH:mm"}} <br> End:  {{project?.public?.endDate | date:"dd.MM.yyyy HH:mm"}}
            </span>


            <ng-container>
              <button type="button" class="btn btn-light" (click)="moreInfo(data?._id)" *ngIf="!openMoreInfo"
                stopPropagation><i class="bi me-1 bi-plus"></i> {{'header.more' | translate}}</button>
            </ng-container>
            <button type="button" class="btn btn-light" (click)="lessInfo()" *ngIf="openMoreInfo" stopPropagation><i
                class="bi me-1 bi-dash"></i> Less</button>

          </div>

        </div>
      </div>
    </div>
  </div>

</ng-container>


<ng-container *ngIf="isProject">
  <div id="profile-item" stopPropagation class="summary-box profile-summary introjs-should-disabled">
    <div rel="group1" class="summary-link modal-link mb-3">
      <div class="card">
        <div class="flex col-md-12 job-buttons">
          <button [disabled]="!project?.public?.startDate" class="btn btn-sm btn-outline-success btn-sm btn-sm col-lg-3" (click)="searchProject(project?._id)">
            {{'project.search' | translate}}
          </button>
          <button *ngIf="!selectedProject" class="btn btn-primary-soft btn-sm col-lg-2" stopPropagation
            (click)="manageProject(project?._id)">
            {{'project.manage' | translate}}
          </button>
          <button *ngIf="selectedProject" class="btn btn-primary-soft btn-sm col-lg-2" stopPropagation
            (click)="closeManageProject(project?._id)">
            {{'project.closeManage' | translate}}
          </button>

          <button *ngIf="!project?.public?.startDate" class="btn btn-success-soft d-block btn-sm col-lg-3"
            (click)="publicProject(project?._id)" stopPropagation [disabled]="project?.public?.startDate">
            {{'project.public' | translate}}
          </button>
          <button *ngIf="project?.public?.startDate" class="btn btn-sm py-1 btn-danger-soft col-lg-3"
            (click)="removeProjectFromPublic(project)" stopPropagation>
            {{'job.isPublic' | translate}}
          </button>
          <li class="nav-item dropdown me-2">
            <a class="nav-link bg-light py-1 px-2 mb-0" href="#" id="feedActionShare" data-bs-toggle="dropdown"
              aria-expanded="false">
              <i class="bi bi-three-dots"></i>
            </a>
            <!-- Dropdown menu -->
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="feedActionShare">
              <li><a class="dropdown-item" href="#"> <i class="bi bi-envelope fa-fw pe-2"></i>Create a poll</a></li>
              <li><a class="dropdown-item" href="#"> <i class="bi bi-bookmark-check fa-fw pe-2"></i>Ask a question </a>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li><a class="dropdown-item" href="#"> <i class="bi bi-pencil-square fa-fw pe-2"></i>Help</a></li>
            </ul>
          </li>
        </div>
        <div class="card-body">
          <div class="col-12 col-lg-12 d-block d-lg-flex mt-4 px-0">
            <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 px-0">
              <div class="d-flex justify-content-start col-12 row">
                <div class="accordion" id="communications">
                  <!-- Accordion item -->
                  <div class="accordion-item bg-transparent">
                    <h2 class="accordion-header" id="communicationOne">
                      <button class="accordion-button mb-0 h6" type="button" data-bs-toggle="collapse" data-bs-target="#communicationcollapseOne" aria-expanded="true" aria-controls="communicationcollapseOne">
                       Privacy
                      </button>
                    </h2>
                    <!-- Accordion info -->
                    <div id="communicationcollapseOne" class="accordion-collapse collapse" aria-labelledby="communicationOne" data-bs-parent="#communications">
                      <div class="accordion-body">
                         <!-- Notification list item -->
                         <div class="form-check">
                          <div class="form-group col-md-12 col-12">

                            <div class="d-flex justify-content-end">
                              <img borderWidth="2" borderColor="#ffffff" ngx-gravatar [email]="'example@mail.com'" fallback="mp"
                                size="30">
                              <div class="d-flex">

                                <h6 *ngIf="project?.userID" class="ms-1">{{userCreatedProject?.firstName}}
                                  {{userCreatedProject?.lastName}}</h6>

                                <div class="dropdown">
                                  <button type="button" stopPropagation class="btn btn-light btn-sm me-2 ms-1  dropdown-toggle"
                                    data-bs-toggle="dropdown">
                                  </button>
                                  <ul class="dropdown-menu">
                                    <li *ngFor="let privacy of dataArrProjects">
                                      <app-user-dialog-name [privacy]="privacy"
                                        (changeEventName)="changeUserOfProject($event)"></app-user-dialog-name>
                                    </li>
                                  </ul>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                        <!-- Notification list item -->
                        <div class="form-check px-0">
                          <label class="form-check-label" for="ComRadio2">
                            Who can see this Project
                          </label>
                          <button  class="btn btn-outline-light" style="border: none">
                            <i [class]="icon" (click)="openPrivacy(project?.privacy?.status)" [tooltip]="text"></i>
                          </button>
                        </div>
                        <!-- Notification list item -->
                        <div class="form-check px-0">
                          <label class="form-check-label" for="ComRadio3">
                            Who can Apply
                          </label>
                          <button  class="btn btn-outline-light" style="border: none">
                            <i [class]="iconOffer" (click)="openPrivacyOffer({enum: project?.apply?.status, offer: true, isProject: true})"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>


                  <div class="d-flex mt-3" *ngIf="isMyProject">
                    <label for="exampleInputEmail1">Apply to</label>
                    <select class="form-select-sm ng-valid col-md-4 col-5 col-sm-12 col-xs-12"
                      [ngModel]="project?.receivedApplicationBy?.selectedApplication" stopPropagation
                      (ngModelChange)="changeApplication($event); selectCheck()" aria-label="Default select example">
                      <option *ngFor="let array of arrayOfApplication" [selected]="array" [value]="array.id">
                        {{array.description | translate}}</option>
                    </select>


                <input stopPropagation *ngIf="project?.receivedApplicationBy.selectedApplication === 1"
                  class="form-control-sm col-8 col-md-6" type="text" [(ngModel)]="externalLink">
                <div *ngIf="showButtonsForSave" class="flex
                  items-center" style="
                  flex-direction: row;
                  display: flex;
                  border:none;
              " class="specialty col-md-2 px-0 mt-1 col-2">
                  <ng-container>
                    <button class="btn btn-light me-2" stopPropagation (click)="saveProjectChanges()">
                      <i class="bi bi-check"></i>
                    </button>
                  </ng-container>
                </div>
              </div>
              </div>

              <div [ngClass]="{ 'isActive': selectedFirstGroup && isMyProject}" (mouseenter)="selectFirstGroup()"
                (click)="selectFirstGroup()" (mouseleave)="removeClick()">
                <div>


                  <h5>{{project?.positionTitle}}</h5>
                  <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi me-1 bi-person-workspace" viewBox="0 0 16 16">
                      <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                      <path
                        d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z" />
                    </svg> {{'project.firstPage.workplace' | translate}}: {{remote | translate}}</p>
                  <p> <i class="bi me-1 bi-geo-alt"></i> {{'job.address' | translate}}: {{project?.street}},
                    {{project?.postalCode}}, {{project?.city}}, {{countryJsonObject?.EN}}</p>
                  <p><i class="bi me-1 bi-cash"></i>
                    {{"job.fourthPage.paymentForm" | translate }}: {{paymentID | translate}}
                    {{project?.incomes?.budget}}+
                    {{currencyObject?.currency.Symbol}} ({{currencyObject?.currency.Name}}) </p>

                </div>
                <div *ngIf="selectedFirstGroup && isMyProject">
                  <div clickOutside (clickOutside)="removeClick()">
                    <span class="bi bi-pencil-fill edit-icon"
                      (click)="editFirstGroup({project:project, firstGroup: true})"></span>
                  </div>
                </div>
              </div>
              <div [ngClass]="{ 'isActive': selectedSecondGroup && isMyProject}" (mouseenter)="selectSecondGroup()"
                (mouseleave)="removeClick()" (click)="selectSecondGroup()">
                <div>
                  <p><i class="bi me-1 bi-calendar-week"></i> {{'job.fourthPage.duration.jobDuration' | translate}}:
                    {{jobDurationString | translate}} - Start date {{project?.startDate | date: "dd.MM.yyyy"}}</p>
                </div>
                <div *ngIf="selectedSecondGroup && isMyProject">
                  <div clickOutside (clickOutside)="removeClick()">
                    <span class="bi bi-pencil-fill edit-icon"
                      (click)="editSecondGroup({projectSearch:projectSearch, secondGroup: true})"></span>


                  </div>
                </div>
              </div>
              <div class="col-lg-12 mt-3  ml-lg-0 px-0" *ngIf="openMoreInfo">
                <div *ngIf="project.company" class="remove-icon-border">
                  <div class="card-header px-0">

                    <span class="bi bi-trash3-fill remove-icon" *ngIf="project?.freelancer && isMyProject"
                      (click)="deleteProjectTasks(index)"></span>
                    <span class="bi bi-plus-circle-fill remove-icon" *ngIf="!project?.freelancer && isMyProject"
                      (click)="addFreelancer(index)"></span>

                    <strong>{{ 'project.company' | translate }}</strong>
                  </div>

                  <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2"
                    [ngClass]="{ 'isActive': selectedTasks && isMyProject}" (mouseenter)="selectTasks()"
                    (mouseleave)="removeClick()" (click)="selectTasks()">
                    <div class="Text-Text-text pt-2">
                      <strong>
                        {{ "project.secondPage.tasks" | translate}} {{ "project.secondPage.keywords" | translate }}
                      </strong>
                    </div>

                    <div class="Text-Text-text-wrapper">
                      <app-chips [chips]="moreProjectInfo?.tasks" stopPropagation></app-chips>
                    </div>
                    <div *ngIf="selectedTasks && isMyProject">
                      <div clickOutside (clickOutside)="removeClick()">
                        <span class="bi bi-pencil-fill edit-icon"
                          (click)="editJobTasks({project:project, jobTasks: true})"></span>


                      </div>
                    </div>
                  </div>


                </div>

                <div *ngIf="project?.freelancer" class="remove-icon-border mt-4">


                  <div class="card-header px-0">
                    <span class="bi bi-trash3-fill remove-icon" *ngIf="project?.company > 0 && isMyProject"
                      (click)="deleteProjectFreelancer(index)"></span>
                    <span class="bi bi-plus-circle-fill remove-icon" (click)="addCompany()"
                      *ngIf="!project?.company && isMyProject"></span>
                    <strong>{{'project.freelancer' | translate}}</strong>
                  </div>
                  <div class="card-body px-0">

                    <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2"
                      [ngClass]="{ 'isActive': selectedSkillsOffer && isMyProject}" (mouseenter)="selectSkillsOffer()"
                      (mouseleave)="removeClick()" (click)="selectSkillsOffer()">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "job.secondGroup.tasks" | translate}}
                        </strong>
                      </div>

                      <div class="Text-Text-text-wrapper">
                        <app-chips [chips]="moreProjectInfo?.skillsOffer" stopPropagation></app-chips>
                      </div>
                      <div *ngIf="selectedSkillsOffer && isMyProject">
                        <div clickOutside (clickOutside)="removeClick()">
                          <span class="bi bi-pencil-fill edit-icon"
                            (click)="editSkillsOffer({project:project, jobSkillsOffer: true})"></span>


                        </div>
                      </div>
                    </div>

                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="moreProjectInfo?.pcKnowledge?.length > 0"
                      [ngClass]="{ 'isActive': selectedPCKnowledge && isMyProject}" (mouseenter)="selectPCKnowledge()"
                      (mouseleave)="removeClick()" (click)="selectPCKnowledge()">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.pcKnowledge" | translate}}
                        </strong>
                      </div>

                      <div class="Text-Text-text-wrapper">
                        <app-chips [chips]="moreProjectInfo?.pcKnowledge" stopPropagation></app-chips>
                      </div>
                      <div *ngIf="selectedPCKnowledge && isMyProject">
                        <div clickOutside (clickOutside)="removeClick()">
                          <span class="bi bi-pencil-fill edit-icon"
                            (click)="editPCKnowledge({project:project, jobPCKnowledge: true})"></span>
                        </div>
                      </div>

                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="moreProjectInfo?.language?.length > 0"
                      [ngClass]="{ 'isActive': selectedLanguage && isMyProject}" (mouseenter)="selectLanguage()"
                      (mouseleave)="removeClick()" (click)="selectLanguage()">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.language" | translate}}
                        </strong>

                        <div *ngFor="let language of moreProjectInfo?.language; let iLang = index">
                          <div class="Field-field-fieldBase">
                            <div class="Text-Text-wrapper">
                              <div class="Text-Text-text-wrapper">
                                <div class="progress">
                                  <div id="rate" [style.background]="'#d9d9d9'" [style.width]="language.rate + '20%'"
                                    style="display: flex; white-space: pre-wrap;" [style.line-height.px]="13"
                                    [style.font-size.px]="10">
                                    <div style="display: flex; white-space: pre-wrap; padding-left: .5rem;"
                                      id="language">
                                      {{language.name | translate}}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="selectedLanguage && isMyProject">
                        <div clickOutside (clickOutside)="removeClick()">
                          <span class="bi bi-pencil-fill edit-icon"
                            (click)="editJobLanguage({project:project, jobLanguage: true, jobDescription: false})"></span>


                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="moreProjectInfo?.driveLicense?.length > 0"
                      [ngClass]="{ 'isActive': selectedDriveLicense && isMyProject}" (mouseenter)="selectDriveLicense()"
                      (mouseleave)="removeClick()" (click)="selectDriveLicense()">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.driveLicense" | translate}}
                        </strong>

                        <div class="Text-Text-text-wrapper">
                          <app-chips [chips]="moreProjectInfo?.driveLicense" stopPropagation></app-chips>
                        </div>
                      </div>
                      <div *ngIf="selectedDriveLicense && isMyProject">
                        <div clickOutside (clickOutside)="removeClick()">
                          <span class="bi bi-pencil-fill edit-icon"
                            (click)="editJobDriveLicense({project:project, jobDriveLicense: true})"></span>


                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="moreProjectInfo?.qualifications?.length > 0"
                      [ngClass]="{ 'isActive': selectedQualifications && isMyProject}"
                      (mouseenter)="selectQualifications()" (mouseleave)="removeClick()"
                      (click)="selectQualifications()">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.qualifications" | translate}}
                        </strong>

                        <div class="Text-Text-text-wrapper">
                          <app-chips [chips]="moreProjectInfo?.qualifications" stopPropagation></app-chips>
                        </div>
                      </div>
                      <div *ngIf="selectedQualifications && isMyProject">
                        <div clickOutside (clickOutside)="removeClick()">
                          <span class="bi bi-pencil-fill edit-icon"
                            (click)="editQualifications({project:project, jobQualifications: true})"></span>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>




                <br>
                <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2"
                  [ngClass]="{ 'isActive': selectedDescription && isMyProject}" (mouseenter)="selectDescription()"
                  (mouseleave)="removeClick()" (click)="selectDescription()">
                  <div class="Text-Text-text">
                    <strong>
                      {{ "project.description" | translate}}
                    </strong>
                  </div>

                  <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2">
                    <div class="Text-Text-text-wrapper" [innerHTML]="project?.description | noSanitize">
                    </div>
                  </div>
                  <div *ngIf="selectedDescription && isMyProject">
                    <div clickOutside (clickOutside)="removeClick()">
                      <span class="bi bi-pencil-fill edit-icon"
                        (click)="editJobDescription({project:project, jobDescription: true})"></span>


                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="button-bottom">
            <span  style="align-items: self-end;
            display: flex;" *ngIf="project?.public?.startDate">Published: {{project?.public?.startDate | date:"dd.MM.yyyy HH:mm"}} <br> End:  {{project?.public?.endDate | date:"dd.MM.yyyy HH:mm"}}
            </span>

            <button type="button" class="btn btn-light" stopPropagation (click)="projectInfo(project?._id, $event)"
              *ngIf="!openMoreInfo"><i class="bi bi-plus"></i> More</button>
            <button type="button" class="btn btn-light" (click)="lessInfo()" *ngIf="openMoreInfo" stopPropagation><i
                class="bi bi-dash"></i> Less</button>

          </div>
        </div>
      </div>
      <div class="card mt-3 bg-none" style="background: none;">
        <app-board-dnd *ngIf="selectedProject"></app-board-dnd>

      </div>

    </div>
  </div>
</ng-container>
<ng-container *ngIf="isProjectOutside">
  <div id="profile-item" stopPropagation class="summary-box profile-summary introjs-should-disabled">
    <div rel="group1" class="summary-link modal-link" data-bind="css: { 'ignored': isIgnored() }">
      <div class="card mb-2">
        <div class="flex col-12 job-buttons">

          <ul class="nav nav-pills nav-stack small fw-normal">
            <li class="nav-item" (click)="apply(projectOut?._id)" *ngIf="projectOut?.public?.startDate">
              <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                  class="bi me-1 bi-box-arrow-up-right text-success pe-2"></i>{{'job.apply' | translate}}</a>
            </li>

            <li class="nav-item">
              <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                  class="bi me-1 bi-share text-warning pe-2"></i><span>{{'job.share' | translate}}</span></a>
            </li>
            <li class="nav-item dropdown ms-lg-auto">
              <a class="nav-link bg-light py-1 px-2 mb-0" href="#" id="feedActionShare" data-bs-toggle="dropdown"
                aria-expanded="false">
                <i class="bi bi-three-dots"></i>
              </a>
              <!-- Dropdown menu -->
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="feedActionShare">
                <li><a class="dropdown-item" href="#"> <i class="bi bi-envelope fa-fw pe-2"></i>Create a poll</a></li>
                <li><a class="dropdown-item" href="#"> <i class="bi bi-bookmark-check fa-fw pe-2"></i>Ask a question
                  </a></li>
                <li>
                  <hr class="dropdown-divider">
                </li>
                <li><a class="dropdown-item" href="#"> <i class="bi bi-pencil-square fa-fw pe-2"></i>Help</a></li>
              </ul>
            </li>
          </ul>
        </div>

        <ng-container *ngIf="applyForm">
          <form class="ms-2" name="ngForm" #myForm="ngForm">

            <div class="col-sm-6">
              <label class="form-label">{{'job.apply' | translate}} {{'as' | translate}}</label>
              <select name="selectedValue" id="selectedValue" [(ngModel)]="isSelected" class="col-md-12 form-control-sm"
                required (ngModelChange)="test($event)">
                <ng-container *ngIf="projectOut.freelancer">
                  <option [ngValue]="{user: user, isUser: true}">{{user?.firstName}} {{user?.lastName}}</option>
                </ng-container>
                <ng-container *ngIf="projectOut.company && !checkForFreelancerAndCompany">
                  <option *ngFor="let employment of arrayPages" [ngValue]="employment._id">
                    {{ employment?.name }}
                  </option>
                </ng-container>

              </select>
              <div *ngIf="myForm.submitted && selectedValue.invalid" class="invalid-feedback">
                <div *ngIf="selectedValue.errors.required">
                  {{ "career.employmentType" | translate }}
                  {{ "error.isRequired" | translate }}
                </div>
              </div>
            </div>
          </form>
          <div class="d-sm-flex align-items-end mt-1 ms-2" *ngIf="!hasAppliedToProject">
            <quill-editor name="editor" [styles]="editorConfig" stopPropagation style="width:80%" [modules]="config"
              [(ngModel)]="description">
            </quill-editor>
            <button *ngIf="!isProjectSearchCompany" stopPropagation [disabled]="myForm.form.invalid"
              (click)="applyForProject()" type="submit" class="btn btn-sm btn-primary ms-2"><i
                class="bi bi-send  fs-6"></i></button>
            <button *ngIf="!!isProjectSearchCompany" stopPropagation [disabled]="myForm.form.invalid" type="submit"
              class="btn btn-sm btn-primary ms-2"><i class="bi bi-send  fs-6"></i></button>
            <button (click)="cancelApplyForm()" stopPropagation class="btn btn-sm btn-primary ms-2"><i
                class="bi bi-x  fs-6"></i></button>

          </div>
          <div class="d-sm-flex align-items-end mt-1" *ngIf="hasAppliedToProject">
            <span class="ms-2">
              Applied {{showDateApplied | date: "dd.MM.yyyy HH:mm" }}

            </span>
          </div>
        </ng-container>
        <div class="card-body">
          <div class="col-12 col-lg-12 d-block d-lg-flex mt-4 px-0">
            <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 px-0">

              <h6>{{projectOut?.positionTitle}}</h6>
              <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi me-1 bi-person-workspace" viewBox="0 0 16 16">
                  <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                  <path
                    d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z" />
                </svg> {{'project.firstPage.workplace' | translate}}: {{remote | translate}}</p>
              <div style="display: flex; align-items: center">
                <p> <i class="bi me-1 bi-geo-alt"></i> {{'job.address' | translate}}:
              {{projectOut?.street}}, {{projectOut?.postalCode}}, {{projectOut?.city}},
                  {{countryJsonObject?.EN}}</p>
              </div>

              <div *ngIf="selectedFirstGroup && isMyProject">
                <div clickOutside (clickOutside)="removeClick()">
                  <ul>
                    <li class="bi bi-pencil addIconTop" (click)="editFirstGroup({project:project, firstGroup: true})">
                    </li>
                    <li class="bi bi-plus  addIconBottomCategory" (click)="editJobDescription({project:project})">
                    </li>

                  </ul>
                </div>
              </div>


              <p><i class="bi me-1 bi-cash"></i>
                {{"job.fourthPage.paymentForm" | translate }}: {{paymentID | translate}} {{projectOut?.incomes?.budget}}+
                {{currencyObject?.currency.Symbol}} ({{currencyObject?.currency.Name}}) </p>
              <p><i class="bi me-1 bi-calendar-week"></i> {{'job.fourthPage.duration.jobDuration' | translate}}:
                {{jobDurationString | translate}} - Start date {{projectOut?.startDate | date: "dd.MM.yyyy"}}</p>
              <div class="col-lg-12 mt-3  ml-lg-0 px-0" *ngIf="openMoreInfo">
                <div *ngIf="projectOut.company" class="remove-icon-border">
                  <div class="card-header px-0">

                    <strong>{{ 'project.company' | translate }}</strong>
                  </div>

                  <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2">
                    <div class="Text-Text-text pt-2">
                      <strong>
                        {{ "project.secondPage.tasks" | translate}} {{ "project.secondPage.keywords" | translate }}
                      </strong>
                    </div>

                    <div class="Text-Text-text-wrapper">
                      <app-chips [chips]="projectOut?.tasks" stopPropagation></app-chips>
                    </div>

                  </div>


                </div>

                <div *ngIf="projectOut?.freelancer" class="remove-icon-border mt-4">


                  <div class="card-header px-0">
                    <strong>{{'project.freelancer' | translate}}</strong>
                  </div>
                  <div class="card-body px-0">

                    <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "job.secondGroup.tasks" | translate}}
                        </strong>
                      </div>

                      <div class="Text-Text-text-wrapper">
                        <app-chips [chips]="projectOut?.skillsOffer" stopPropagation></app-chips>
                      </div>

                    </div>

                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.pcKnowledge" | translate}}
                        </strong>
                      </div>

                      <div class="Text-Text-text-wrapper">
                        <app-chips [chips]="projectOut?.pcKnowledge" stopPropagation></app-chips>
                      </div>


                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="projectOut?.language?.length > 0">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.language" | translate}}
                        </strong>

                        <div *ngFor="let language of projectOut?.language; let iLang = index">
                          <div class="Field-field-fieldBase">
                            <div class="Text-Text-wrapper">
                              <div class="Text-Text-text-wrapper">
                                <div class="progress">
                                  <div id="rate" [style.background]="'#d9d9d9'" [style.width]="language.rate + '20%'"
                                    style="display: flex; white-space: pre-wrap;" [style.line-height.px]="13"
                                    [style.font-size.px]="10">
                                    <div style="display: flex; white-space: pre-wrap; padding-left: .5rem;"
                                      id="language">
                                      {{language.name | translate}}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="projectOut?.driveLicense?.length > 0">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.driveLicense" | translate}}
                        </strong>

                        <div class="Text-Text-text-wrapper">
                          <app-chips [chips]="projectOut?.driveLicense" stopPropagation></app-chips>
                        </div>
                      </div>

                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.qualifications" | translate}}
                        </strong>

                        <div class="Text-Text-text-wrapper">
                          <app-chips [chips]="projectOut?.qualifications" stopPropagation></app-chips>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>



                <br>
                <div class="card-header px-0">
                  <strong>{{"description" | translate}}</strong>
                </div>
                <div class="card-body px-0">
                  <div class="col-12 col-lg-12 d-block d-lg-flex px-0">
                    <div class="col-lg-12 mt-lg-0 ml-lg-0 px-0">
                      <div class="Text-Text-text-wrapper" [innerHTML]="projectOut?.description | noSanitize">
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>

          </div>
          <div class="button-bottom">
            <span  style="align-items: self-end;
            display: flex;" *ngIf="projectOut?.public?.startDate">Published: {{projectOut?.public?.startDate | date:"dd.MM.yyyy HH:mm"}} <br> End:  {{projectOut?.public?.endDate | date:"dd.MM.yyyy HH:mm"}}
            </span>

            <ng-container>
              <button type="button" class="btn btn-light" (click)="moreInfo(data?._id)" *ngIf="!openMoreInfo"
                stopPropagation><i class="bi me-1 bi-plus"></i> {{'header.more' | translate}}</button>
            </ng-container>
            <button type="button" class="btn btn-light" (click)="lessInfo()" *ngIf="openMoreInfo" stopPropagation><i
                class="bi me-1 bi-dash"></i> Less</button>

          </div>

        </div>
      </div>
    </div>
  </div>

</ng-container>

<div (click)="emitID(job._id)" *ngIf="isJobOutside && job?.public"
  class="summary-box profile-summary introjs-should-disabled">

  <div id="profile-item" stopPropagation class="summary-box profile-summary introjs-should-disabled">
    <div rel="group1" class="summary-link modal-link" data-bind="css: { 'ignored': isIgnored() }">
      <div class="card mb-2">
        <div class="flex col-12 job-buttons">

          <ul class="nav nav-pills nav-stack small fw-normal">
            <li class="nav-item" (click)="apply(job?._id)">
              <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                  class="bi me-1 bi-box-arrow-up-right text-success pe-2"></i>{{'job.apply' | translate}}</a>
            </li>

            <li class="nav-item">
              <a  (click)="openShare({data: job, isPage: true, url: page.pageUrl, isProject: true })" class="nav-link bg-light py-1 px-2 mb-0"> <i
                  class="bi me-1 bi-share text-warning pe-2"></i><span>{{'job.share' | translate}}</span></a>
            </li>
            <li class="nav-item dropdown ms-lg-auto">
              <a class="nav-link bg-light py-1 px-2 mb-0" href="#" id="feedActionShare" data-bs-toggle="dropdown"
                aria-expanded="false">
                <i class="bi bi-three-dots"></i>
              </a>
              <!-- Dropdown menu -->
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="feedActionShare">
                <li><a class="dropdown-item" href="#"> <i class="bi bi-envelope fa-fw pe-2"></i>Create a poll</a></li>
                <li><a class="dropdown-item" href="#"> <i class="bi bi-bookmark-check fa-fw pe-2"></i>Ask a question
                  </a></li>
                <li>
                  <hr class="dropdown-divider">
                </li>
                <li><a class="dropdown-item" href="#"> <i class="bi bi-pencil-square fa-fw pe-2"></i>Help</a></li>
              </ul>
            </li>
          </ul>
        </div>
        <ng-container *ngIf="applyForm">
          <div class="d-sm-flex align-items-end mt-1">
            <quill-editor name="editor" [styles]="editorConfig" stopPropagation style="width:80%" [modules]="config"
              [(ngModel)]="description">
            </quill-editor>
            <button *ngIf="!isProjectSearchCompany" (click)="apply(data)" stopPropagation [disabled]="!description"
              class="btn btn-sm btn-primary ms-2"><i class="bi bi-send  fs-6"></i></button>
            <button *ngIf="!!isProjectSearchCompany" (click)="applyForPageProject()" stopPropagation
              [disabled]="!description" class="btn btn-sm btn-primary ms-2"><i
                class="bi bi-send  fs-6"></i></button>
            <button (click)="cancelApplyForm()" stopPropagation class="btn btn-sm btn-primary ms-2"><i
                class="bi bi-x  fs-6"></i></button>

          </div>
        </ng-container>

        <div class="card-body">
          <div class="col-12 col-lg-12 d-block d-lg-flex mt-4 px-0">
            <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 px-0">

              <h6>{{job?.positionTitle}}</h6>
              <p style="margin-right: 0.25rem">
                <i class="bi me-1 bi-briefcase"></i>
                {{ "career.employmentType" | translate }}:

              {{ employmentTypeString | translate }}</p>
              <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi me-1 bi-person-workspace" viewBox="0 0 16 16">
                  <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                  <path
                    d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z" />
                </svg> {{'project.firstPage.workplace' | translate}}: {{remote | translate}}</p>
              <div style="display: flex; align-items: center">
                <p> <i class="bi me-1 bi-geo-alt"></i> {{'job.address' | translate}}:
              {{job?.street}}, {{job?.postalCode}}, {{job?.city}},
                  {{countryJsonObject?.EN}}</p>
              </div>



              <p><i class="bi me-1 bi-cash"></i>
                {{"job.fourthPage.paymentForm" | translate }}: {{paymentID | translate}} {{job?.incomes.budget}}+
                {{currencyObject?.currency.Symbol}} ({{currencyObject?.currency.Name}}) </p>
              <p><i class="bi me-1 bi-calendar-week"></i> {{'job.fourthPage.duration.jobDuration' | translate}}:
                {{jobDurationString | translate}} - Start date {{job?.startDate | date: "dd.MM.yyyy"}}</p>
              <div class="col-lg-12 mt-3  ml-lg-0 px-0" *ngIf="openMoreInfo">
                <div *ngIf="project.company" class="remove-icon-border">
                  <div class="card-header px-0">

                    <strong>{{ 'project.company' | translate }}</strong>
                  </div>

                  <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2">
                    <div class="Text-Text-text pt-2">
                      <strong>
                        {{ "project.secondPage.tasks" | translate}} {{ "project.secondPage.keywords" | translate }}
                      </strong>
                    </div>
                    <div class="Text-Text-text-wrapper">
                      <app-chips [chips]="job?.skillsOffer" stopPropagation></app-chips>
                    </div>
                  </div>
                </div>
                  <div class="card-body px-0">

                    <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "job.secondGroup.tasks" | translate}}
                        </strong>
                      </div>

                      <div class="Text-Text-text-wrapper">
                        <app-chips [chips]="job?.skillsOffer" stopPropagation></app-chips>
                      </div>

                    </div>

                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.pcKnowledge" | translate}}
                        </strong>
                      </div>

                      <div class="Text-Text-text-wrapper">
                        <app-chips [chips]="job?.pcKnowledge" stopPropagation></app-chips>
                      </div>


                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="job?.language?.length > 0">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.language" | translate}}
                        </strong>

                        <div *ngFor="let language of job?.language; let iLang = index">
                          <div class="Field-field-fieldBase">
                            <div class="Text-Text-wrapper">
                              <div class="Text-Text-text-wrapper">
                                <div class="progress">
                                  <div id="rate" [style.background]="'#d9d9d9'" [style.width]="language.rate + '20%'"
                                    style="display: flex; white-space: pre-wrap;" [style.line-height.px]="13"
                                    [style.font-size.px]="10">
                                    <div style="display: flex; white-space: pre-wrap; padding-left: .5rem;"
                                      id="language">
                                      {{language.name | translate}}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="job?.driveLicense?.length > 0">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.driveLicense" | translate}}
                        </strong>

                        <div class="Text-Text-text-wrapper">
                          <app-chips [chips]="job?.driveLicense" stopPropagation></app-chips>
                        </div>
                      </div>

                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.qualifications" | translate}}
                        </strong>

                        <div class="Text-Text-text-wrapper">
                          <app-chips [chips]="job?.qualifications" stopPropagation></app-chips>
                        </div>
                      </div>

                    </div>

                  </div>




                <br>
                <div class="card-header px-0">
                  <strong>{{"description" | translate}}</strong>
                </div>
                <div class="card-body px-0">
                  <div class="col-12 col-lg-12 d-block d-lg-flex px-0">
                    <div class="col-lg-12 mt-lg-0 ml-lg-0 px-0">
                      <div class="Text-Text-text-wrapper" [innerHTML]="job?.description | noSanitize">
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>

          </div>
          <div class="button-bottom">
            <span  style="align-items: self-end;
            display: flex;" *ngIf="job?.public?.startDate">Published: {{job?.public?.startDate | date:"dd.MM.yyyy HH:mm"}} <br> End:  {{job?.public?.endDate | date:"dd.MM.yyyy HH:mm"}}
            </span>


            <ng-container>
              <button type="button" class="btn btn-light" (click)="moreInfo(job?._id)" *ngIf="!openMoreInfo"
                stopPropagation><i class="bi me-1 bi-plus"></i> {{'header.more' | translate}}</button>
            </ng-container>
            <button type="button" class="btn btn-light" (click)="lessInfo()" *ngIf="openMoreInfo" stopPropagation><i
                class="bi me-1 bi-dash"></i> Less</button>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>
