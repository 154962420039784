import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Job, UserJobs } from 'src/app/shared/interfaces/model';
import { User } from 'src/app/shared/interfaces/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { InitialState } from 'src/app/store/jobs-state/+state/jobs-state.reducer';
import { selectUserJobs } from 'src/app/store/jobs-state/+state/jobs-state.selectors';
import { JobsService } from '../../../shared/services/jobs.service';
import { UserService } from '../../../shared/services/user.service';
import * as fromTodoStore from '../../../store/jobs-state/+state/jobs-state.actions';
import { userJobsLoad } from '../../../store/jobs-state/+state/jobs-state.actions';

@Component({
  selector: 'app-your-job',
  templateUrl: './your-job.component.html',
  styleUrls: ['./your-job.component.scss']
})
export class YourJobComponent implements OnInit {
  user: User;
  checkIfPublicJob: boolean;
  userJobs$: Observable<UserJobs[]>;
  public userJobs = [];
  public subscription: Subscription;
  routerLinkUrl = "";


  constructor(public userService: UserService, public router: Router,  public jobService: JobsService, private authService: AuthService) {

   }
  ngOnInit(): void {
    if (this.router.url.endsWith("applied")) {
      this.routerLinkUrl = "pagesSettings.appliedProjects"
    } else if (this.router.url.endsWith("ignored_projects")) {
      this.routerLinkUrl = "pagesSettings.ignoredProjects"
    } else if (this.router.url.endsWith("saved_projects")) {
      this.routerLinkUrl = "pagesSettings.savedProjects"
    } else if (this.router.url.endsWith("create")) {
      this.routerLinkUrl = "job.create"
    } else if (this.router.url.endsWith("jobs")) {
      this.routerLinkUrl = "job.myJobs"
    }

  /*  this.userJobs$ = this.store.pipe(select(selectUserJobs));
    this.store.dispatch(fromTodoStore.userLoadJobs()); */
    this.userService.getUserContactData(this.authService.userID).subscribe(res => {
      this.user = res;
      })
      this.userService.getUserJobs();
      this.subscription = this.userService.userJobs().subscribe(res => {
        this.userJobs = res.jobs.data;
      })
  }

  publicJob(jobID) {
    this.jobService.getUserJobs(jobID).subscribe(res => {
      console.log(res);
  });

}
checkIfPublic(id): void {
  this.jobService.getUserJobs(id).pipe(take(1)).subscribe(res => {
   if (res.public) {
    this.checkIfPublicJob = true;
   }
});
}

navigateToSaved() {
    this.router.navigateByUrl(this.user?.userUrl + "/jobs/saved");
}
goToMyJobs() {
  if (this.router.url.endsWith("search")) {
    this.router.navigateByUrl(this.user.userUrl + "/jobs")
  } else {
    this.router.navigateByUrl(this.user.userUrl + "/jobs")
  }
}



}
