import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainHomeComponent } from './main-home.component';
import { MainHomeRoutingModule } from './main-home.routing';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GravatarModule } from 'ngx-gravatar';
import { BookmarksModule } from '../components/bookmarks/bookmarks.module';
import { AdvertisingModule } from '../sharedComponents/advertising/advertising.module';



@NgModule({
  declarations: [MainHomeComponent],
  imports: [
    CommonModule,
    MainHomeRoutingModule,
    TranslateModule,
    FontAwesomeModule,
    GravatarModule,
    BookmarksModule,
    AdvertisingModule
  ]
})
export class MainHomeModule { }
