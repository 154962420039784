import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreditsHistory, Transactions, User } from 'src/app/shared/interfaces/user.model';
import _ from "lodash";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Page } from 'src/app/shared/interfaces/model';
import { PagesService } from 'src/app/shared/services/pages.service';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'src/app/shared/services/user.service';
import { CountryDataService } from 'src/app/shared/services/country-data.service';

@Component({
  selector: 'app-print-dialog',
  templateUrl: './print-dialog.component.html',
  styleUrls: ['./print-dialog.component.scss']
})
export class PrintDialogComponent implements OnInit {
  @ViewChild('printPDF') printPDF!: ElementRef;
  public page: Page;
  public user: User;
  countryJsonObject;
  countryJsonObjectForUser;
  countryJsonArray;
  primaryLocation;
  constructor(@Inject(MAT_DIALOG_DATA) public data: DataPrint, public pageService: PagesService, public http: HttpClient, public userService: UserService,  private countryDataService: CountryDataService
  ) { }

  ngOnInit(): void {
    this.countryDataService.countryData$.pipe().subscribe(res => this.countryJsonArray = res);

    if (this.data.pageDetails) {
     this.primaryLocation = this.data.pageDetails.locations.find(t => t.headquarters === true);
    }
    if (this.data.isPayment) {
      if (this.data.isUser) {
        console.log(this.data, "data")
        this.data.creditHistory = _.cloneDeep(this.data.creditHistory);
        this.userService.getUserData(this.data.user._id).subscribe(res => {
          this.user = res;
          console.log(this.user, "usr");
          this.getCountryID(this.user.address[0]?.country);
          const primaryLocation =  this.data.pageDetails.locations.find(t => t.headquarters === true);

          this.getCountryIDForPage(primaryLocation.country);
        });
      } else if (this.data.isPage) {
        this.data.creditHistory = _.cloneDeep(this.data.creditHistory);
        this.pageService.getPage(this.data.page._id).subscribe(res => {
          this.page = res;
          this.getCountryID(this.page?.location?.country);
          const primaryLocation =  this.data.pageDetails.locations.find(t => t.headquarters === true);

          this.getCountryIDForPage(primaryLocation.country);
        });
      }
    } else if (!this.data.isPayment) {
      if (this.data.isPage) {
        this.data.creditHistory = _.cloneDeep(this.data.creditHistory);
        this.pageService.getPage(this.data.page._id).subscribe(res => {

          this.page = res;
          this.getCountryID(this.page?.location?.country);
          const primaryLocation =  this.data.pageDetails.locations.find(t => t.headquarters === true);
          this.getCountryIDForPage(primaryLocation.country);

        })
      } else if (this.data.isUser) {
        this.data.creditHistory = _.cloneDeep(this.data.creditHistory);
        this.userService.getUserData(this.data.user._id).subscribe(res => {
          this.user = res;
          this.getCountryID(this.user.cv[0].personalData[0].country);
          const primaryLocation =  this.data.pageDetails.locations.find(t => t.headquarters === true);

          this.getCountryIDForPage(primaryLocation.country);
        });
      }
    }
  }

  printData() {
    const doc = new jsPDF({
      unit: 'pt', orientation: 'p', format: 'a4'
    }) // create jsPDF object

    const test = new Date(this.data.creditHistory.date)
    const pdfElement = document.getElementById('printPDF');
  //  window.print();
    // HTML element to be converted to PDF
    doc.html(pdfElement, {
      callback: (pdf) => {
        pdf.save(`${this.user._id + "-" + test.getFullYear() + "-" + this.data.creditHistory.invoiceID}.pdf`);
      },
      x: 45,
      y: 13,
      autoPaging: true,
      windowWidth: 100,
      html2canvas: {
        scale: 0.7,
      },
    });
  }
  getCountryID(id) {
    this.countryJsonObject = this.countryJsonArray.find(t => t.ISO_3 === id);
  }
  getCountryIDForPage(id) {
    this.countryJsonObjectForUser = this.countryJsonArray.find(t => t.ISO_2 === id);
  }


}

export interface DataPrint {
  creditHistory?: CreditsHistory;
  transactions?: Transactions;
  user?: User;
  page?: Page;
  isUser?: boolean;
  isPage?: boolean;
  pageName?: string;
  pageDetails?: Page;
  isPayment?: boolean;

}
