<mat-chip-listbox #chipList aria-label="Skills selection">
  <mat-chip *ngFor="let skill of chips" [selectable]="selectable"
            [removable]="removable" (removed)="remove(skill)">
     <div class="md-chip" style="display: flex; flex-direction: row; flex-wrap: wrap;">
                                                                {{skill.name}}
                                                        </div>
    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
  </mat-chip>
  <input *ngIf="show"
         [placeholder]="placeholder"
         [matChipInputFor]="chipList"
         [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
         [matChipInputAddOnBlur]="addOnBlur"
         (matChipInputTokenEnd)="add($event)">
</mat-chip-listbox>
