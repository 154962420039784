import { Component, OnInit } from '@angular/core';
import { Duration, EmploymentType, Incomes, Remote } from 'src/app/shared/enums/enums-model';
import { Job, Page, Project } from 'src/app/shared/interfaces/model';
import { User } from 'src/app/shared/interfaces/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CountryDataService } from 'src/app/shared/services/country-data.service';
import { JobsService } from 'src/app/shared/services/jobs.service';
import { PagesService } from 'src/app/shared/services/pages.service';
import { ProjectService } from 'src/app/shared/services/project.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-advertising',
  templateUrl: './advertising.component.html',
  styleUrls: ['./advertising.component.scss']
})
export class AdvertisingComponent implements OnInit {

  project = new Project();

  public jobDurationString;
  public employmentTypeString;
  public currencyObject;
  public countryJsonArray;
  public paymentID;
  countryJsonObject;
  public openMoreInfo = false;
  remote
 user: User;
 public jobSalary;
 public currencyUserSearchObject;



  public job: Job;
  userCreatedJob: User;
  page: Page;

  public employmentType = [
    { id: EmploymentType.EmployedFullTime, description: "employmentType.EmployedFullTime" },
    { id: EmploymentType.EmployedPartTime, description: "employmentType.EmployedPartTime" },
    { id: EmploymentType.Internship, description: "employmentType.Internship" },
    { id: EmploymentType.Owner, description: "employmentType.Owner" },
    { id: EmploymentType.BordMember, description: "employmentType.Boardmember" },
    { id: EmploymentType.Volunteer, description: "employmentType.Volunteer" },
    { id: EmploymentType.SelfEmployed, description: "employmentType.SelfEmployed" },
    { id: EmploymentType.Shareholder, description: "employmentType.Shareholder" },
    { id: EmploymentType.Official, description: "employmentType.Official" },
    { id: EmploymentType.Recruiter, description: "employmentType.Recruiter" },
    { id: EmploymentType.Freelancer, description: "employmentType.Freelancer" },
    { id: EmploymentType.Partner, description: "employmentType.Partner" }
  ];
  public remoteArray = [
    { id: Remote.onSite, description: "project.firstPage.onSite", percent: '0%' },
    { id: Remote.hybrid, description: "project.firstPage.hybrid", percent: '50%' },
    { id: Remote.remote, description: "project.firstPage.remote", percent: '100%' },
  ];


  public salaryArray = [
    { id: Incomes.hour, description: "incomes.hour" },
    { id: Incomes.month, description: "incomes.month" },
    { id: Incomes.year, description: "incomes.year" },
    { id: Incomes.total, description: "incomes.total" },
  ]

  public jobDurationArray = [
    { id: Duration.unlimited, description: "job.fourthPage.duration.unlimited" },
    { id: Duration.temporary, description: "job.fourthPage.duration.temporary" },
  ]



  constructor(    public jobService: JobsService,
    private userService: UserService,
    private authService: AuthService,
    private pageService: PagesService,
    private countryDataService: CountryDataService,
    private projectService: ProjectService


    ) { }

  ngOnInit(): void {

    this.userService.getUserContactData(this.authService.userID).subscribe(r => {
      this.user = r;
    })
    this.jobService.getJob('6488bd04b81b258bc3d7b1e6').subscribe(res => {
      this.job = res;
      if (this.job.pageID) {
        this.pageService.getPage(this.job.pageID).subscribe(t => {
          this.page = t;
        })
      } else {
        this.userService.getUserContactData(this.job.userID).subscribe(t => {
          this.userCreatedJob = t;
        })
      }

      this.countryDataService.countryData$
      .pipe()
      .subscribe((res) => (this.countryJsonArray = res));


      this.getRemoteID(this.job.remote);
      this.getEmploymentTypeID(this.job.employmentType);
      this.getJoDurationID(this.job.duration);
      this.getCountryID(this.job.country);
      this.getCurrencyID(this.job.currency);
      this.getPaymentFormID(this.user?.incomes?.status);
      this.incomes(this.user?.incomes?.status, this.job);
    })

    this.projectService.getProject('6420d2fab4e42f004e55267f').subscribe(res => {
      this.project = res;
      if (this.project.pageID) {
        this.pageService.getPage(this.project.pageID).subscribe(t => {
          this.page = t;
        })
      } else {
        this.userService.getUserContactData(this.project.userID).subscribe(t => {
          this.userCreatedJob = t;
        })
      }

      this.countryDataService.countryData$
      .pipe()
      .subscribe((res) => (this.countryJsonArray = res));


      this.getRemoteID(this.project.remote);
      this.getEmploymentTypeID(this.project.employmentType);
      this.getJoDurationID(this.project.duration);
      this.getCountryID(this.project.country);
      this.getCurrencyID(this.project.currency);
      this.getPaymentFormID(this.user?.incomes?.status);
      this.incomes(this.user?.incomes?.status, this.project);
    })
  }

  public incomes(id, job) {
    if (id === Incomes.month) {
      this.jobSalary = job.incomes?.month.toFixed(0);
    } else if (id === Incomes.year) {
      this.jobSalary = job.incomes?.year.toFixed(0);
    } else if (id === Incomes.hour) {
      this.jobSalary = Math.trunc(job.incomes?.hour)
    }
  }

  getRemoteID(id) {
    this.remote = this.remoteArray.find(test => test.id === id).description;
  }
  getEmploymentTypeID(id) {
    this.employmentTypeString = this.employmentType.find(t => t.id === id)?.description;
  }
  getJoDurationID(id) {
    this.jobDurationString = this.jobDurationArray.find(t => t.id === id).description;
  }

  getCountryID(id) {
    this.countryJsonObject = this.countryJsonArray.find(t => t?.ISO_2 === id);
  }
  getCurrencyID(id) {
    this.currencyObject = this.countryJsonArray.find(t => t.currency.Alphabeticcode === id);
  }
  getCurrencyForUserSearch(id) {
    this.currencyUserSearchObject = this.countryJsonArray.find(t => t?.ISO_2 === id);
  }

  getPaymentFormID(id) {
    this.paymentID = this.salaryArray.find(t => t?.id === id)?.description;
  }

}
