import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { shareReplay } from "rxjs/operators";
import {Page, Image, Roles, UserPage} from "src/app/shared/interfaces/model";
import { ImageService } from "src/app/shared/services/image.service";
import { PagesService } from "src/app/shared/services/pages.service";
import { SenderService } from "src/app/shared/services/sender.service";
import { UserService } from "src/app/shared/services/user.service";
import {AuthService} from "../../shared/services/auth.service";
import {Status} from "../../shared/utils/page-helper.service";
import _ from 'lodash'
import { BranchesService } from "src/app/shared/services/branches.service";
import { CategoryService } from "src/app/shared/services/category.service";
import { O } from "@angular/cdk/keycodes";
import { User } from "src/app/shared/interfaces/user.model";


@Component({
  selector: "app-pages-list",
  templateUrl: "./pages-list.component.html",
  styleUrls: ["./pages-list.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class PagesListComponent implements OnInit {
  public showWeb = false;
  public hasPhoto: boolean;
  public image;


  @Input() page: Page;
  @Input() details;
  @Input()sidebar;
  @Input() sidebarPage;
  @Input() userPage: UserPage;
  @Input() pageDetails: Page;
  @Input() pageProjects: Page;
  @Input() projects;
  @Input() fromJobs;
  @Input() projectPageID;
  @Output() selectedIndex: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedPageIndex: EventEmitter<any> = new EventEmitter<any>();
  public pageID = "";
  public branchTitle = "";
  selectedID;
  public pending = false;
  subCategoryTitle = "";
  public filterPage;
  public filterPageJobs;
  constructor(
    public router: Router,
    public pageService: PagesService,
    public imageService: ImageService,
    public sendPageData: SenderService,
    public userService: UserService,
    private authService: AuthService,
    private branchesService: BranchesService,
    public categoryService: CategoryService,
    ) {

  }
  ngOnInit(): void {
    this.branchesService.getBranches();
    this.categoryService.getCategories();
    if (this.projects) {
      if (this.userPage.pageId) {
        this.pageService.getPage(this.userPage?.pageId).subscribe(data => {
          this.page = data;
          this.filterPage = this.page?.roles?.Projects.find(t => t.userID === this.authService.userID);
          this.getBranch(this.page?.branches);
          this.getSubCategoryTitle(this.page?.categories?.name);

        })
      }

    } else {
      if (!!this.userPage) {
        this.pageService.getPage(this.userPage?.pageId).subscribe(data => {
          this.page = data;
          console.log(this.page, "page")
         this.filterPageJobs  = this.page?.roles?.Jobs.find(t => t.userID === this.authService.userID);
          this.getBranch(this.page?.branches);
          this.getSubCategoryTitle(this.page?.categories?.name);

        })
      }

    }

  }

  goToPageProject(page: Page) {
    localStorage.setItem("pageID",page._id)
    this.selectedIndex.emit(this.projectPageID);
    this.sendPageData.sendDataForProject(page);
    this.selectedPage.emit(this.userPage);
  }

  goToPageProjectDetails(page: Page) {


    this.pageService.getPage(page._id).subscribe(res => {

      localStorage.setItem("pageID", this.pageProjects._id)
      this.selectedIndex.emit(this.pageProjects._id);
      this.sendPageData.sendDataForProject(res);
      this.selectedPage.emit(res);

    })
  }

  selectPage(page: Page) {
    this.selectedPageIndex.emit(this.projectPageID);
    this.sendPageData.sendDataForProject(page);
    this.selectedPage.emit(this.userPage);
  }

  goToWeb(id) {
    this.showWeb = true;
   /* if (this.userPage.status === Status.Pending) {
      return;
    } */
    this.pageService.getPage(this.pageDetails._id).subscribe(data => {
      this.page = data;
      if(this.page.pageUrl) {
      this.router.navigate(['', this.page.pageUrl]);
      } else {
        this.router.navigate(['', this.page._id]);

      }
    })
    this.pageID = id;
    localStorage.setItem("pageID", id)
    this.pageService.changeMessage(id);
    this.sendPageData.send(this.page);
  }
  getBranch(id) {
    this.branchTitle = this.branchesService.branches.find(t => t.id === id);
  }
  getSubCategoryTitle(id) {
    const t = this.categoryService.categories.find(t => t.id === id);
    const tt = this.page?.categories.subCategory.find(t => t.name);
    this.subCategoryTitle =  t?.subCategory.find(rtt => rtt.id === tt.name);
  }


  acceptPage(page:Page) {
    this.userService.getUserContactData(this.authService.userID).pipe(shareReplay(1)).subscribe((res:User) => {
      res.pages.find(r => r.pageId === page._id).status = Status.Added;
      const userData = {
        userID: this.authService.userID,
        timestamp: new Date()
      };
      const userAcceptPage = {
        pageId: page._id,
        timestamp: new Date()
      };

      res.following.data.unshift(userAcceptPage);
      this.userPage.status = Status.Added;
      this.pageService.updatePage(page._id, page);
      this.userService.updateUser(res._id, res);
    });
  }

  rejectPage(page: Page) {
    this.userService.getUserContactData(this.authService.userID).pipe(shareReplay(1)).subscribe((res:User) => {
      page.roles.Owner.splice(_.indexOf(page.roles.Owner, _.find(page.roles.Owner, { userID: res._id })), 1);
      page.roles.Admin.splice(_.indexOf(page.roles.Admin, _.find(page.roles.Admin, { userID: res._id })), 1);
      page.roles.Editor.splice(_.indexOf(page.roles.Editor, _.find(page.roles.Editor, { userID: res._id })), 1);
      page.roles.Moderator.splice(_.indexOf(page.roles.Moderator, _.find(page.roles.Moderator, { userID: res._id })), 1);
      page.roles.Analyst.splice(_.indexOf(page.roles.Analyst, _.find(page.roles.Analyst, { userID: res._id })), 1);
      page.roles.Advertiser.splice(_.indexOf(page.roles.Advertiser, _.find(page.roles.Advertiser, { userID: res._id })), 1);
      page.roles.User.slice(_.indexOf(page.roles.User, _.find(page.roles.User, { userID: res._id })), 1);
      res.pages.splice(_.indexOf(res.pages, _.find(res.pages, { pageId: page._id })));
      this.pageService.updatePage(page._id, page);
      this.userService.updateUser(res._id, res);
    });
  }
}
