import { Component, Input, OnInit} from "@angular/core";
import {Observable} from "rxjs";
import {AuthService} from "../../shared/services/auth.service";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {Carrier, CarrierSubCategory, Model} from "../../shared/interfaces/model";
import {Category} from "../../shared/enums/enums-model";
import {DialogService} from "../../shared/services/dialog.service";
import {DataDialogComponent, EditCareer} from "../modals/data-dialog/data-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import { SubCareerDialogComponent } from './sub-career-dialog/sub-career-dialog.component';
@Component({
  selector: "app-career",
  templateUrl: "./career.component.html",
  styleUrls: ["./career.component.scss"]
})
export class CareerComponent implements OnInit {
  @Input() career: Carrier;
  @Input() id: number;
  @Input() model: Model;
  @Input() index: number;
  @Input() careerObj: CarrierSubCategory;

  public carrier$: Observable<Carrier[]>;
  public toggle = false;
  public ID = this.authService.userID;
  public selectedIndex: number;
  public selectedCategory: number = null;
  public category = Category;

  form = new UntypedFormGroup({
    startDate: new UntypedFormControl(),
    endDate: new UntypedFormControl(),
    name: new UntypedFormControl(),
    description: new UntypedFormControl()
  });

  constructor(
              private authService: AuthService,
              public dialogService: DialogService,
              public dialog: MatDialog) {
  }

  ngOnInit() {
  }

  public setIndex(id) {
   this.selectedIndex = id;
   this.selectedCategory = null;
  }
  public setCategory(index: Category) {
    this.selectedCategory = index;
    this.selectedIndex = undefined;
  }
  public removeClick() {
    this.selectedIndex = undefined;
    this.selectedCategory = undefined;
  }
  public editData(data: EditCareer) {
    this.dialog.open(DataDialogComponent, {
        data,
        });
  }
  public addData(data: EditCareer) {
    this.dialog.open(SubCareerDialogComponent, {
      data,
    });
  }

  deleteCategory(index) {
    this.model.careers.splice(index, 1);
   // this.dataService.updateDate(this.model._id, this.model);
  }
  deleteSubCategory(index) {
   this.career.subCategories.splice(index, 1);
  // this.dataService.updateDate(this.model._id, this.model);
  }

  public autogrow() {
    const textArea = document.getElementById("description");
    textArea.style.overflow = "hidden";
    textArea.style.height = "auto";
    textArea.style.height = textArea.scrollHeight + "px";
  }

}
