<div class="px-0 container">
    <app-issue-details *ngIf="!data.project"
                  [issue]="data.issue"
                  [job]="data.job"
                  [isShowFullScreenButton]="true"
                  [isFullView]="false"
                  [isProject]="false"
                  [isShowCloseButton]="true">
    </app-issue-details>
    <app-issue-details *ngIf="data.project" [issue]="data.issue"
    [project]="data.project"
    [isShowFullScreenButton]="true"
    [isFullView]="false"
    [isProject]="true"
    [isShowCloseButton]="true">
</app-issue-details>
</div>
