import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Project, UserProjects } from '../shared/interfaces/model';
import { User } from '../shared/interfaces/user.model';
import { AuthService } from '../shared/services/auth.service';
import { ProjectService } from '../shared/services/project.service';
import { UserService } from '../shared/services/user.service';

@Component({
  selector: 'app-your-projects',
  templateUrl: './your-projects.component.html',
  styleUrls: ['./your-projects.component.scss']
})
export class YourProjectsComponent implements OnInit {

  projects: UserProjects[];
  routerLinkUrl = "";
  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
  });
  isLinear = false;
  user: User;
  constructor(private _formBuilder: UntypedFormBuilder, private projectService: ProjectService, private userService: UserService, public router: Router,     public authService: AuthService,
    ) {

    this.userService.getUserContactData(this.authService.userID).subscribe(res => {
      this.user = res;
    })
  
   }
  ngOnInit(): void {
    if (this.router.url.endsWith("applied")) {
      this.routerLinkUrl = "pagesSettings.appliedProjects"
    } else if (this.router.url.endsWith("ignored_projects")) {
      this.routerLinkUrl = "pagesSettings.ignoredProjects"
    } else if (this.router.url.endsWith("saved_projects")) {
      this.routerLinkUrl = "pagesSettings.savedProjects"
    } else if (this.router.url.endsWith("create")) {
      this.routerLinkUrl = "project.create"
    } else if (this.router.url.endsWith("projects")) {
      this.routerLinkUrl = "project.myProjects"
    }

  }

  createProject() {
    this.router.navigateByUrl(this.router.url + "/create")
  }
  goToProjects() {
    this.router.navigateByUrl(this.router.url + "/projects")
  }
  navigateToSaved() {
    this.router.navigateByUrl(this.router.url + "/saved")

  }

}
