import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/shared/interfaces/user.model';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-qualifications',
  templateUrl: './qualifications.component.html',
  styleUrls: ['./qualifications.component.scss']
})
export class QualificationsComponent implements OnInit {

  @Input() user: User;
  @Input() isUser: boolean;

  constructor(private userService: UserService) { }

  ngOnInit(): void {
  }

  replaceQualifications() {
    const data = this.user.cv.data.map(t => t.skills[0].subCategories[0].qualifications.data);
    let newArr = Array.prototype.concat(...data);

    newArr =  newArr.filter((el, i, a) => i === a.findIndex((findTest) => findTest.name === el.name));
    this.user.searchFilterSkills.searchQualifications.data = [];
    this.user.searchFilterSkills.searchQualifications.data = newArr;


    this.userService.updateUser(this.user._id, this.user);
  }

  removeQualifications(index) {
    this.user.searchFilterSkills.searchQualifications.data.splice(index, 1);
    this.userService.updateUser(this.user._id, this.user);
  }



}
