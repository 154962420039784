import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { Job, Page, Project } from '../shared/interfaces/model';
import { User } from '../shared/interfaces/user.model';
import { AuthService } from '../shared/services/auth.service';
import { ChangeDetectionService } from '../shared/services/change-detection.service';
import { PagesService } from '../shared/services/pages.service';
import { SenderService } from '../shared/services/sender.service';
import { UserService } from '../shared/services/user.service';
import { UserJobDialog, UserJobDialogComponent } from '../user-job-dialog/user-job-dialog.component';
import { JobsService } from '../shared/services/jobs.service';
import { DataObject, DialogDataDetailsComponent } from '../components/dialog-data-details/dialog-data-details.component';

@Component({
  selector: 'app-message-right-side-job',
  templateUrl: './message-right-side-job.component.html',
  styleUrls: ['./message-right-side-job.component.scss']
})
export class MessageRightSideJobComponent implements OnInit, OnChanges {
  public data: Job;
  public page: Page;
  public jobManage;
  public userApplied: User;
  public cancelledUser: User;
  @Input() selectedIndex;
  deleteUser: User;
  cancelMessage;
  cancelJob;

  editorConfig = {
    padding: "0",
  }
  public config = {
    toolbar: false
  };

  pageId;

  checkUserInPage: boolean = false;


  public userHasWritten: User;

  commentOutUser: string;
  userMsg: User;



  public project: Project;
  public user: User;
  constructor(public receiver: SenderService,
    private userService: UserService,
    private pageService: PagesService,
    private authService: AuthService,
    private dialog: MatDialog,
    private changeDetector: ChangeDetectionService,
    private jobService: JobsService,
    private cd: ChangeDetectorRef) { }
  ngOnChanges(changes: SimpleChanges): void {
    this.cd.detectChanges();

  }

  ngOnInit(): void {
    this.receiver.dataMessageForJob.subscribe((success: any) => {
      this.data = success.data;
      this.jobManage = this.data?.manage_.filter(t => t.userId === this.authService.userID)[0];
      let id = this.data?.manage_?.find(t => t.message.userId)?.message.userId;
      this.pageId = this.data?.manage_?.find(t => t.message?.pageId)?.message.pageId;
      let cancelId = this.data?.manage_?.find(t => t.userId === this.authService.userID)?.cancel?.userId;



      if (this.pageId) {
        this.pageService.getPage(this.pageId).subscribe(resPage => {
          this.page = resPage;
          this.checkUserInPage = this.page.roles.Messages.find(t => t.userID === this.authService.userID)?.userID === this.authService.userID;
        })
      }
      if (id) {
        this.userService.getUserContactData(id).subscribe(tt => {
          this.userApplied = tt;
        })
      }
      if (!!cancelId) {
        this.userService.getUserContactData(cancelId).subscribe(tt => {
          this.cancelledUser = tt;
        })
      }
      this.userService.getUserContactData(this.authService.userID).subscribe(tt => {
        this.userHasWritten = tt;
      })

      let commentUserOutMsg = this.data?.manage_?.find(t => t.comments.find(t => t.userId !== this.authService.userID)?.userId !== this.authService.userID)?.comments.find(t3 => t3.userId !== this.authService.userID).userId;

      if (commentUserOutMsg) {
        this.userService.getUserContactData(commentUserOutMsg).subscribe(t => {
          this.userMsg = t;
        })
      }



      if (!!this.data?.pageID) {
        this.pageService.getPage(this.data?.pageID).subscribe(resPage => {
          this.page = resPage;
        })
      } if (!!this.data?.userID) {
        this.userService.getUserContactData(this.data?.userID).subscribe(t => {
          this.user = t;
        })
      }

    });
  }
  openJob(data: DataObject) {
    this.dialog.open(DialogDataDetailsComponent, {
      data,
    });
  }

  deleteChat() {
    this.userService.getUserContactData(this.authService.userID).pipe(take(1)).subscribe(res => {
      this.deleteUser = res;
      this.deleteUser.messages.splice(this.selectedIndex, 1);
      this.userService.updateUser(this.deleteUser._id, this.deleteUser)
      this.changeDetector.emitChanges(true);


    })
  }

  cancel() {
    this.cancelJob = true;
  }

  apply() {
    this.data.manage_.find(t => t.userId === this.authService.userID).cancel.status = 1;
    this.userService.getUserContactData(this.authService.userID).pipe(take(1)).subscribe(res => {
      this.user = res;
     })

     this.user?.appliedJobs.unshift({jobId: this.data._id, timestamp: new Date()})
     this.jobService.updateJob(this.data._id, this.data);
    this.userService.updateUser(this.user?._id, this?.user)
  }

  cancelJobButton() {
    const dataForCancel = {
      status: 2,
      timestamp: new Date(),
      description: this.cancelMessage,
      userId: this.authService.userID
    }

    this.jobManage.cancel = dataForCancel;
    this.jobService.updateJob(this.data._id, this.data);
    this.cancelJob = false;
  }




}
