import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Model, Page, Project, Qualifications } from 'src/app/shared/interfaces/model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PagesService } from 'src/app/shared/services/pages.service';
import { UserService } from 'src/app/shared/services/user.service';
import { WarningDialogComponent } from 'src/app/sharedComponents/warning-dialog/warning-dialog.component';
import _ from 'lodash'
import { User } from 'src/app/shared/interfaces/user.model';

@Component({
  selector: 'app-qualifications-dialog',
  templateUrl: './qualifications-dialog.component.html',
  styleUrls: ['./qualifications-dialog.component.scss']
})
export class QualificationsDialogComponent implements OnInit {
  public confirmationDialog: MatDialogRef<WarningDialogComponent>
  public user: User;
  public pageSelected = false;
  searchForPageTrue = false;
  pageActive = "";
  public searchForPage = "";
  public pages: Page[] = [];
  public website = "";

  public selectedPageID = "";
  public selectedPage: Page;

  public page: Page;




  constructor(@Inject(MAT_DIALOG_DATA) public data: EditQualifications,
  private dialogRef: MatDialogRef<QualificationsDialogComponent>,
  public authService: AuthService,
  public dialog: MatDialog,
  public pageService: PagesService,
  public userService: UserService) { }

  ngOnInit(): void {
    if (!this.data.projectQualifications) {
    if (!this.data.edit) {
      this.data.qualifications = {} as Qualifications;
      this.userService.getUserContactData(this.authService.userID).subscribe(res => {
        this.user = res;
    });
    } else {
      this.userService.getUserContactData(this.authService.userID).subscribe((res: User) => {
        this.user = res;
       res.cv.data[this.data.indexOfLang].skills[0].subCategories[0].qualifications[this.data.index] =  this.data.qualifications;
       this.data.qualifications =  _.cloneDeep(this.data.qualifications);
       if (this.data.qualifications?.company) {
        this.pageService.getPage(this.data.qualifications?.company).subscribe(res => {
          this.page = res;
          this.searchForPage = this.page?.name;
          this.pageActive = this.page?.name;
          this.pageSelected = true;
          this.website = this.page?.website;
          this.selectedPage = res;
          this.selectedPageID = this.page._id;
        });
      } else {
        this.searchForPage = this.data.qualifications.name;
        this.pageSelected = true;
      }
      });
    }
    this.pageService.allPages.subscribe(res => {
      this.pages = res;
    });
  } else if (this.data.projectQualifications) {
    this.data.project = new Project();
    this.data.project.qualifications = [];
  }

  }

  saveData() {
  if(!this.data.edit) {
    this.dialogRef.close(this.data.qualifications);

   // this.user.cv[this.data.index].skills[0].subCategories[0].qualifications.unshift(this.data.qualifications);

  }
    this.dialogRef.close(this.data);
  }
  deletePage() {
    this.pageActive = "";
    this.searchForPage = "";
  }
  addCompany(page: Page) {
    this.pageSelected = true;
    this.pageActive = page.name;
    if (page.name) {
      this.searchForPage = page.name;
    }
    if (page.website) {
      this.website = page.website;
    }
    this.data.qualifications.company = page._id;
    if (this.pageSelected) {

      this.selectedPageID = page._id;
      this.selectedPage = page;
      this.data.qualifications.company = page._id;
      const removeItem = "name";
  }
  this.searchForPageTrue = false;
  }

}

export interface EditQualifications {
  qualifications?: Qualifications;
  index?: number;
  edit?: boolean;
  model?: Model;
  qualificationsObj?: Qualifications;
  project?: Project;
  projectQualifications?: boolean;
  jobEdit?: boolean;
  indexOfLang?: number
}
