import { Component, Inject, OnInit } from "@angular/core";
import { Category } from "../../../shared/enums/enums-model";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Carrier, Education, Model, PersonalData, Skills } from "../../../shared/interfaces/model";
import { CareerComponent } from "../../career/career.component";
import { EmptyCat, EmptyCategoryDialogComponent } from '../editors/empty-category-dialog/empty-category-dialog.component';

@Component({
  selector: "app-category-dialog",
  templateUrl: "./category-dialog.component.html",
  styleUrls: ["./category-dialog.component.scss"],
})
export class CategoryDialogComponent implements OnInit {
  public personalData;
  public career;
  public education;
  public skills;
  public empty;
  public files;
  public category = [];

  // tslint:disable-next-line:ban-types
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CategoryDialog,
    private dialogRef: MatDialogRef<CategoryDialogComponent>,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.career = this.data.model.careers.length > 0;
    this.personalData = this.data.model.personalData.length > 1;
    this.education = this.data.model.education.length > 0;
    this.skills = this.data.model.skills.length > 0;
    this.files = this.data.model.files.length > 0;
    this.empty = this.data.model.emptyCategory.length > 0;
    this.category = [
      {
        id: "PersonalData",
        name: Category.PersonalData,
        description: "category.PersonalData",
        icon: "fa fa-user",
        disabled: this.personalData,
      },
      {
        id: "Skills",
        name: Category.Skills,
        description: "category.Skills",
        icon: "fa fa-graduation-cap",
        disabled: this.skills
      },
      {
        id: "Education",
        name: Category.Education,
        description: "category.Education",
        icon: "fa fa-graduation-cap",
        disabled: this.education,
      },

      {
        id: "Career",
        name: Category.Career,
        description: "category.Career",
        icon: "fa fa-black-tie",
        disabled: this.career,
      },

      {
        id: "Files",
        name: Category.Files,
        description: "category.AddFiles",
        icon: "fa fa-file",
        disabled: this.files
      },
      {
        id: "Another",
        name: Category.Another,
        description: "category.Another",
        icon: "fa fa-bars",
        disabled: this.empty,
      }
    ];  }

  public addCategory(category, data: EmptyCat ) {
    if (category === "Another") {
      this.dialog.open(EmptyCategoryDialogComponent, {
        data
      });
    }
    this.dialogRef.close(category);
  }
}

export interface CategoryDialog {
  model: Model;
  skills?: Skills;
  personalData?: PersonalData;
  education?: Education;
  career?: Carrier;
}
