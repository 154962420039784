import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Group, Page } from 'src/app/shared/interfaces/model';
import { User } from 'src/app/shared/interfaces/user.model';
import { ArraysService } from 'src/app/shared/services/arrays-service/title-service';
import { GroupService } from 'src/app/shared/services/group.service';
import { ImageService } from 'src/app/shared/services/image.service';
import { PagesService } from 'src/app/shared/services/pages.service';
import { UserService } from 'src/app/shared/services/user.service';
import _ from 'lodash'

@Component({
  selector: 'app-religion',
  templateUrl: './religion.component.html',
  styleUrls: ['./religion.component.scss']
})
export class ReligionComponent implements OnInit {
  public users: User[] = [];
  public pages: Page[] = [];
  public groups: Group[] = [];

  public pageSelected = false;
  public website = "";
  public searchForPage = "";
  public selectedPageID = "";
  public selectedPage: Page;
  public page: Page;
  pageActive = "";
searchForPageTrue = false;


  constructor(@Inject(MAT_DIALOG_DATA) public data: EditReligion,
  private dialogRef: MatDialogRef<ReligionComponent>,

  public arrayService: ArraysService,
  private userService: UserService,
  private pageService: PagesService,
  public imageService: ImageService,
  private groupService: GroupService,) { }

  ngOnInit(): void {
    this.arrayService.showReligions();
    this.pageSelected = true;
    if (!!this.data.user.religion.pageID) {
      this.pageService.getPage(this.data.user?.religion?.pageID).subscribe(res => {
        this.page = res;

        this.searchForPage = this.page?.name;
        this.pageActive = this.page?.name;
        this.pageSelected = true;
        this.website = this.page?.website;
        this.selectedPage = res;
        this.selectedPageID = this.page._id;
      })
    }
    this.userService.userModel().subscribe(res => {
      this.users = res;
    });
    /*  this.pageService.getAllPages().subscribe(t => {
        this.pages = t;
      }) */
      this.pageService.allPages.subscribe(res => {
        this.pages = res;
      });
      this.groupService.getAllGroups().subscribe(t => {
        this.groups = t;
      })

  }
  addNewCompany(page: Page) {
    this.pageSelected = true;
    this.pageActive = page.name;
    if (page.name) {
      this.searchForPage = page.name;
    }
    if (page.website) {
      this.website = page.website;
    }
    this.data.user.religion.pageID = page._id;
    if (this.pageSelected) {
      const data = {
        userID: this.data.user._id,
      }
      this.selectedPageID = page._id;
      this.selectedPage = page;
      this.data.user.religion.pageID = page._id;


    }
    this.searchForPageTrue = false;

  }
  deletePage() {
    this.pageActive = "";
    this.searchForPage = "";
    this.searchForPageTrue = false;
    // tslint:disable-next-line: max-line-length
    if(this.selectedPageID) {
      const removeItem = "pageID";
      const { [removeItem] : remove, ...rest } = this.data.user.religion;
      this.data.user.religion = rest;
      // tslint:disable-next-line: max-line-length
      if (this.selectedPage) {
       this.pageService.updatePage(this.selectedPageID, this.selectedPage);
       this.selectedPageID = "";
       this.website = "";
      }
     }

  }

  save() {
    this.userService.updateUser(this.data.user._id, this.data.user);
    this.dialogRef.close();
  }

}

export interface EditReligion{
user?: User;
}
