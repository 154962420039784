<div class="container">
  <div class="body-container">
    <h1>New SubCategory</h1>
    <div class="content">
      <div class="array" *ngFor="let cat of skillCategory; let i = index">
        <ul>
          <button [disabled]="cat.disabled" (click)="addCategory(cat, {model: data.model, indexOfLang: data.indexOfLang }, {qualifications: data.qualifications, edit: false})" type="button"><i class="fa-2x" [class]="cat.icon"></i></button>
        </ul>
        <h3> {{cat.description | translate}}</h3>
      </div>
    </div>
  </div>
</div>

