import { Injectable } from "@angular/core";
import { AuthService } from "../shared/services/auth.service";
import { Router } from "@angular/router";

@Injectable()
export class AuthGuard {
  constructor(private router: Router,
    private authService: AuthService) {
  }
  canActivate() {
    if (this.authService.isLoggedIn) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;

    }
  }

}
