import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Duration, EmploymentType, Status, Incomes, Remote } from 'src/app/shared/enums/enums-model';
import { JIssue, IssueStatus, IssueType, IssuePriority } from 'src/app/shared/interfaces/jira.model';
import { Job, Page, Project, UserJobs, UserProjects, UserSearch } from 'src/app/shared/interfaces/model';
import { User } from 'src/app/shared/interfaces/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ChangeDetectionService } from 'src/app/shared/services/change-detection.service';
import { CountryDataService } from 'src/app/shared/services/country-data.service';
import { JobsService } from 'src/app/shared/services/jobs.service';
import { PagesService } from 'src/app/shared/services/pages.service';
import { ProjectService } from 'src/app/shared/services/project.service';
import { SenderService } from 'src/app/shared/services/sender.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-job-item',
  templateUrl: './job-item.component.html',
  styleUrls: ['./job-item.component.scss']
})
export class JobItemComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() userJobs: UserJobs;
  @Input() myJobs: string;
  @Input() isTrue;
  @Input() isFalse: boolean = false;
  @Input() userUrl: string;
  @Input() savedJobs: boolean;
  @Input() ignoredJobs: boolean;
  @Input() userPublishedJobs;
  @Input() userJobSearchId;
  @Input() user: User;
  @Input() searchedJob: Job;
  @Input() appliedJobs: boolean;
  @Input() appliedJob: Job;
  @Input() hasUserProjects: boolean;
  @Input() userProjects: UserProjects
  @Input() isProjectSearch;
  @Input() isSavedProject;
  @Input() projectID: string
  @Input() isIgnoredProject;
  @Input() isProjectCompanySearch;
  @Input() pageID;
  @Input() appliedProjects: boolean = false;
  @Input() pageProjects;
  @Input() selectedPageID: any;
  @Input() isPage: boolean = false;
  @Input() selectedPage: any;
  @Input() isSearchUser: boolean = false;
  @Input() isSearchPage: boolean = false;
  @Input() pageData: Page;
  @Input() userData: User;
  @Input() pageObject: Page;
  @Input() projectSearch;
  @Input() appliedUserProjects;
  @Input() searchForJob: boolean = false;
  @Input() userSearch: boolean = false;
  @Input() searchUserID;
  @Input() userSearchFromJob: boolean = false;
  @Input() dataJob: Job;
  @Input() userJobsAd: boolean;
  public moreJobInfo: Job;
  public job: Job;
  public project: Project;
  public authUser: User;
  public userSearchObject: UserSearch;
  countryJsonObject;
  countryJsonArray;
  private isSaved;
  public isIgnored;
  public hasApplied;
  isProjectCompanySearchData;
  public userForSearch: User;

  public jobSalary;
  public remote;
  public employmentTypeString;
  public jobDurationString;
  public currencyObject;
  public currencyUserSearchObject;
  public paymentID;
  public subscription: Subscription;
  public jIssue = new JIssue();
  userCreatedJob: User;
  page: Page;
  savedProject: Project;
  ignoredProject: Project;
  pageAppliedProject: Project;
  appliedProjectUser: Project;
  userAppliedProject: Project;

  public openMoreInfo = false;
  public employmentType = [
    { id: EmploymentType.EmployedFullTime, description: "employmentType.EmployedFullTime" },
    { id: EmploymentType.EmployedPartTime, description: "employmentType.EmployedPartTime" },
    { id: EmploymentType.Internship, description: "employmentType.Internship" },
    { id: EmploymentType.Owner, description: "employmentType.Owner" },
    { id: EmploymentType.BordMember, description: "employmentType.Boardmember" },
    { id: EmploymentType.Volunteer, description: "employmentType.Volunteer" },
    { id: EmploymentType.SelfEmployed, description: "employmentType.SelfEmployed" },
    { id: EmploymentType.Shareholder, description: "employmentType.Shareholder" },
    { id: EmploymentType.Official, description: "employmentType.Official" },
    { id: EmploymentType.Recruiter, description: "employmentType.Recruiter" },
    { id: EmploymentType.Freelancer, description: "employmentType.Freelancer" },
    { id: EmploymentType.Partner, description: "employmentType.Partner" }
  ];
  public remoteArray = [
    { id: Remote.onSite, description: "project.firstPage.onSite", percent: '0%' },
    { id: Remote.hybrid, description: "project.firstPage.hybrid", percent: '50%' },
    { id: Remote.remote, description: "project.firstPage.remote", percent: '100%' },
  ];


  public salaryArray = [
    { id: Incomes.hour, description: "incomes.hour" },
    { id: Incomes.month, description: "incomes.month" },
    { id: Incomes.year, description: "incomes.year" },
    { id: Incomes.total, description: "incomes.total" },
  ]

  public jobDurationArray = [
    { id: Duration.unlimited, description: "job.fourthPage.duration.unlimited" },
    { id: Duration.temporary, description: "job.fourthPage.duration.temporary" },
  ]
  @Output() unsaveJobUsers: EventEmitter<any> = new EventEmitter<any>();
  @Output() unIgnoreJob: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeSavedProject: EventEmitter<any> = new EventEmitter<any>();
  @Output() unIgnoreProject: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeSavedProjectPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() addToIgnoreProjectPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeFromIgnorePageProject: EventEmitter<any> = new EventEmitter<any>();

  @Output() ignoreArray: EventEmitter<any> = new EventEmitter<any>();




  constructor(
    public jobService: JobsService,
    public senderService: SenderService,
    public router: Router,
    private http: HttpClient,
    private userService: UserService,
    private authService: AuthService,
    private countryDataService: CountryDataService,
    private cd: ChangeDetectorRef,
    private pageService: PagesService,
    private projectService: ProjectService,
    private cdServ: ChangeDetectionService,
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    this.cd.detectChanges();
  }

  ngOnInit(): void {
    this.countryDataService.countryData$.pipe().subscribe(res => this.countryJsonArray = res);
    this.userService.getUserContactData(this.authService.userID).subscribe(r => {
      this.user = r;
    })

    if (this.userSearch) {
      this.userService.getUserProfileForSearch(this.userJobs).subscribe(res => {
        this.userSearchObject = res;
        this.getEmploymentTypeID(this.userSearchObject.cv[0].employmentType);
        this.getRemoteID(this.userSearchObject.searchLocation.remote);
        this.getCountryID(this.userSearchObject.cv[0].country);
        this.getCurrencyForUserSearch(this.userSearchObject.cv[0].country);
        this.getPaymentFormID(this.searchedJob.incomes.status);
        this.incomes(this.searchedJob.incomes.status, this.userSearchObject.cv[0]);
      })

    } else if (this.userPublishedJobs) {
      this.userService.getUserProfileForSearch(this.userJobs.jobId).subscribe(res => {
        this.userSearchObject = res;
        this.getEmploymentTypeID(this.userSearchObject.cv[0].employmentType);
        this.getRemoteID(this.userSearchObject.searchLocation.remote);
        this.getCountryID(this.userSearchObject.cv[0].country);
      })
    } else if (this.searchForJob) {
      this.jobService.getJob(this.userJobSearchId).subscribe(res => {
        this.job = res;
        if (this.job.pageID) {
          this.pageService.getPage(this.job.pageID).subscribe(t => {
            this.page = t;
          })
        } else {
          this.userService.getUserContactData(this.job.userID).subscribe(t => {
            this.userCreatedJob = t;
          })
        }
        this.savedID = this.job._id;
        this.ignoredID = this.job._id;
        this.appliedID = this.user._id;

        this.getRemoteID(this.job.remote);
        this.getEmploymentTypeID(this.job.employmentType);
        this.getJoDurationID(this.job.duration);
        this.getCountryID(this.job.country);
        this.getCurrencyID(this.job.currency);
        this.getPaymentFormID(this.user.incomes.status);
        this.incomes(this.user.incomes.status, this.job);
      })
    } else if (this.savedJobs) {
      this.jobService.getUserJobs(this.userJobs.jobId).subscribe(res => {
        this.job = res;
        this.savedID = this.job._id;
        this.ignoredID = this.job._id;
        this.appliedID = this.user._id;
        this.getRemoteID(this.job.remote);
        this.getEmploymentTypeID(this.job.employmentType);
        this.getJoDurationID(this.job.duration);
        this.getCountryID(this.job.country);
        this.getCurrencyID(this.job.currency);
        this.getPaymentFormID(this.job.incomes.status);
      });
    } else if (this.ignoredJobs) {
      this.jobService.getUserJobs(this.userJobs.jobId).subscribe(res => {
        this.job = res;
        this.savedID = this.job._id;
        this.ignoredID = this.job._id;
        this.appliedID = this.user._id;
        this.getRemoteID(this.job.remote);
        this.getEmploymentTypeID(this.job.employmentType);
        this.getJoDurationID(this.job.duration);
        this.getCountryID(this.job.country);
        this.getCurrencyID(this.job.currency);
        this.getPaymentFormID(this.job.incomes.status);
      });
    } else if(this.userSearchFromJob) {
      this.userService.getUserContactData(this.searchUserID).subscribe(t => {
        this.userForSearch = t;
      });

    }


    else if (this.appliedJobs) {
        this.jobService.getUserJobs(this.userJobs.jobId).subscribe(res => {
          this.job = res;
          this.savedID = this.job._id;
          this.ignoredID = this.job._id;
          this.appliedID = this.user._id;
          this.getRemoteID(this.job.remote);
          this.getEmploymentTypeID(this.job.employmentType);
          this.getJoDurationID(this.job.duration);
          this.getCountryID(this.job.country);
          this.getCurrencyID(this.job.currency);
          this.getPaymentFormID(this.job.incomes.status);
        });


    }
    else if (this.isSavedProject) {
      this.projectService.getProject(this.projectID).subscribe(res => {
        this.savedProject = res;
      })
    } else if (this.isIgnoredProject) {
      this.projectService.getProject(this.projectID).subscribe(res => {
        this.ignoredProject = res;
      })
    } else if (this.appliedUserProjects) {
      this.projectService.getProject(this.projectID).subscribe(res => {
        this.appliedProjectUser = res;
      })
    }
    else if (this.isTrue) {
      this.jobService.getUserJobs(this.userJobs.jobId).subscribe(res => {
        this.job = res;
        this.getRemoteID(this.job.remote);
        this.getEmploymentTypeID(this.job.employmentType);
        this.getJoDurationID(this.job.duration);
        this.getCountryID(this.job.country);
        this.getCurrencyID(this.job.currency);
        this.getPaymentFormID(this.job.incomes.status);
      })
    }  else if(this.hasUserProjects){
      this.projectService.getProject(this.userProjects.projectId).subscribe((res: any) => {
        this.project = res;
      });
    } else if (this.isProjectSearch) {
      this.projectService.getProject(this.userJobSearchId).subscribe((project: Project) => {
        this.project = project;
      });

    } else if (this.isProjectCompanySearch) {
      this.projectService.getProject(this.pageID).subscribe(res => {
        this.isProjectCompanySearchData = res;
      })
    } else if (this.appliedProjects) {
      this.projectService.getProject(this.projectID).subscribe(res => {
        this.pageAppliedProject = res;
      })
    }

  }
  ngAfterViewInit() {
    if (this.appliedProjects) {
      this.projectService.getProject(this.projectID).subscribe(res => {
        this.pageAppliedProject = res;
      })
    }
    if (this.appliedUserProjects) {
      this.projectService.getProject(this.projectID).subscribe(res => {
        this.appliedProjectUser = res;
      })
    }
  }
  emmitID(jobID) {
    this.senderService.dataJobID(jobID);
    localStorage.setItem('jobID', jobID);
    localStorage.removeItem("projectID")
    this.router.navigate(["/jobs/public/", jobID]);
  }
  emmitIDTrue(jobID) {
    this.senderService.dataJobID(jobID);
    localStorage.setItem('jobID', jobID);
    this.router.navigate([this.user?.userUrl + "/jobs/", jobID]);
  }
  getRemoteID(id) {
    this.remote = this.remoteArray.find(test => test.id === id).description;
  }
  getEmploymentTypeID(id) {
    this.employmentTypeString = this.employmentType.find(t => t.id === id).description;
  }
  getJoDurationID(id) {
    this.jobDurationString = this.jobDurationArray.find(t => t.id === id).description;
  }

  getCountryID(id) {
    this.countryJsonObject = this.countryJsonArray.find(t => t.ISO_2 === id);
  }
  getCurrencyID(id) {
    this.currencyObject = this.countryJsonArray.find(t => t.currency.Alphabeticcode === id);
  }
  getCurrencyForUserSearch(id) {
    this.currencyUserSearchObject = this.countryJsonArray.find(t => t.ISO_2 === id);
  }

  getPaymentFormID(id) {
    this.paymentID = this.salaryArray.find(t => t.id === id).description;
  }

  goToMessages() {
    this.router.navigateByUrl("messages");
  }

  saveJob(jobID: string) {
    this.user.savedJobs.unshift({ jobId: jobID, timestamp: new Date() });
    this.userService.updateUser(this.user._id, this.user);
    this.savedID = this.job._id;

  }

  removeJobFromPublic() {
    this.job.public = {} as any;
    this.jobService.updateJob(this.job._id, this.job);

  }

  saveJobFromIgnored(jobID: string) {
    this.user.savedJobs.unshift({ jobId: jobID, timestamp: new Date() });
    this.unIgnoreJob.emit(this.user);
  }
  removeFromIgnored(event) {
    this.unIgnoreJob.emit(this.user);
  }
  ignoreProject() {
    this.unIgnoreProject.emit(this.user);
  }
  unIgnore(jobID) {
    const job = this.user.ignoredJobs.findIndex(t => t.jobId === jobID);
    this.user.ignoredJobs.splice(job, 1);
    this.isIgnored = this.job._id;
    this.ignoreArray.emit();
    this.userService.updateUser(this.user._id, this.user)

  }

  ignoreFromSave(jobID: string) {
    this.user.ignoredJobs.unshift({ jobId: jobID, timestamp: new Date() });
    const job = this.user.savedJobs.findIndex(t => t.jobId === jobID);
    this.user.savedJobs.splice(job, 1);
    this.userService.updateUser(this.user._id, this.user);
  }
  ignoreJob(jobID: string) {
    this.user.ignoredJobs.unshift({ jobId: jobID, timestamp: new Date() });
    this.userService.updateUser(this.user._id, this.user);
  }
  apply(job) {
    const jIssue: JIssue = {
      status: IssueStatus.Applied,
      message: {
        userId: this.authService.userID,
        description: this.jIssue.message.description
      },
      userId: this.authService.userID,
      createdAt: new Date(),
      id: Math.floor(Math.random() * 9).toString() + 1
    }
    // this.job.manage_.unshift(jIssue);
    // this.jobService.updateJob(this.job._id, this.job);

    this.router.navigateByUrl(this.router.url + "/public/" + job + "/apply");
  }

  applyForJob(job) {
    const jIssue: JIssue = {
      status: IssueStatus.Applied,
      message: {
        userId: this.authService.userID,
        description: this.jIssue.message.description,
      },
      userId: this.authService.userID,
      createdAt: new Date(),
      id: Math.floor(Math.random() * 9).toString() + 1
    }
    // this.job.manage_.unshift(jIssue);
    // this.jobService.updateJob(this.job._id, this.job);
    localStorage.setItem('jobID', job);

    this.router.navigateByUrl(this.router.url + "/public/" + job + "/apply");
  }
  manageJob(jobID: string) {
    this.senderService.dataManageProjectID({ID: jobID, isProject: false});
    this.senderService.dataManageJobID({ID: jobID});
    this.router.navigateByUrl(this.router.url + "/" + jobID + "/manage");
  }

  moreInfo(jobID) {
    this.openMoreInfo = true;
    this.subscription = this.jobService.getJobMoreInfo(jobID).subscribe((job: Job) => {
      this.moreJobInfo = job;
    });
  }
  lessInfo() {
    this.openMoreInfo = false;
  }

  publicJob() {
    localStorage.setItem('jobID', this.job._id);

    this.senderService.send({data: this.user, id: "user"})

    this.router.navigate([this.router.url + '/' + this.job._id + "/public"]);

  }

  searchJob(jobID) {
    localStorage.setItem("searchJobId", this.job._id);
    localStorage.setItem("jobUrl", this.router.url + `/${jobID}`);
    this.router.navigateByUrl(this.router.url + `/${jobID}` + "/search");
  }

  searchObject(id) {
    localStorage.setItem("searchJobId", this.job._id);
    localStorage.setItem("jobUrl", this.router.url + `/${id}`);
    this.router.navigateByUrl(this.router.url + `/${id}` + "/search");
  }

  contact(jobID, jobId) {
    localStorage.setItem('userID', this.authService.userID);
    this.senderService.sendDataForApply(jobID);
    this.router.navigateByUrl('/jobs/public/' + this.authService.userID + "/apply");

    //this.router.navigateByUrl(this.router.url + `/${jobID}` + "/search");
  }

  public incomes(id, job) {
    if (id === Incomes.month) {
      this.jobSalary = job.incomes?.month.toFixed(0);
    } else if (id === Incomes.year) {
      this.jobSalary = job.incomes?.year.toFixed(0);
    } else if (id === Incomes.hour) {
      this.jobSalary = Math.trunc(job.incomes?.hour)
    }
  }


  unsaveJob(jobID) {
    const job = this.user.savedJobs.findIndex(t => t.jobId === jobID);
   this.user.savedJobs.splice(job, 1);
   this.savedID = this.job._id;
   this.userService.updateUser(this.user._id, this.user)
  }

  test2(id) {
    this.removeSavedProject.emit(this.user)
  }

  removeProjectPage(id) {
    this.removeSavedProjectPage.emit(id)
  }

  ignoreProjectPage(id) {
    this.addToIgnoreProjectPage.emit(id)
  }

  unignoreProjectPage(id) {
    this.removeFromIgnorePageProject.emit(id);
  }

  goToApplied() {
    this.router.navigateByUrl(this.user.userUrl + "/jobs/applied")
  }

  showManage() {
    this.senderService.dataManageJobID({ID: this.job._id, isJob: true});
  }





  set savedID(id) {
    this.isSaved = this.user?.savedJobs.find(t => t.jobId === id);
  }
  get savedID() {
    return this.isSaved;
  }

  set ignoredID(id) {
    this.isIgnored = this.user?.ignoredJobs.find(t => t.jobId === id);
  }

  get ignoredID() {
    return this.isIgnored;
  }


  cancel(job) {
    localStorage.setItem('userID', this.authService.userID);
    this.senderService.sendDataForApply(this.job);
    this.router.navigateByUrl("jobs/public/" + this.job._id + "/cancel");
  }
  cancelApply(job) {
    const dataForCancel = {
      status: 2,
      timestamp: new Date(),
      description: job.data.cancelMessage,
      cancelID: this.authService.userID
    }
    this.job.manage_.find(t => t.userId === this.authService.userID).cancel = dataForCancel;

    let index =  this.user.appliedJobs.findIndex(t => t.jobId === job.data._id);
    this.user.appliedJobs.splice(index, 1);

    this.appliedID = this.job._id;
    this.userService.updateUser(this.user._id, this.user);
    this.jobService.updateJob(this.job._id, this.job);

    this.cdServ.emitChanges(true);

  }
  message(message) {
    this.router.navigateByUrl("messages");
  }


  applyJob(job) {
    this.user.appliedJobs.unshift({jobId: job.data._id, timestamp: new Date()})

    let jIssue: JIssue = {
      status: IssueStatus.Applied,
      message: {
        userId: this.authService.userID,
        description: job.description,
      },
      userId: this.authService.userID,
      createdAt: new Date(),
      id: Math.floor(Math.random() * 9).toString() + 1,
      cancel: {
        status: Status.Accepted,
        timestamp: new Date()
      },
    }
    job.data.manage_.unshift(jIssue);
    this.user.messages.unshift({jobId: job.data._id, timestamp: new Date()});

     this.userService.updateUser(this.user._id, this.user);
     this.jobService.updateJob(job.data._id, job.data);

     this.appliedID = this.user._id;
  }

  set appliedID(id: string) {
    this.hasApplied = this.job?.manage_?.find(t => t.userId === id)?.userId === this.user?._id;

  }

  get appliedID() {
    console.log(this.hasApplied, "hasApplied")
    return this.hasApplied;
  }

  contactUser(data) {

    if (localStorage.getItem("isPageSearchJob")) {
     const id =  localStorage.getItem("isPageSearchJob");
     this.pageService.getPage(id).subscribe((res: Page) => {
      let jIssue: JIssue = {
        status: IssueStatus.Applied,
        message: {
          userId: this.authService.userID,
          pageId: res._id,
          description: data.contactMessage
        },
        createdAt: new Date(),
        userId: data.data._id,
        pageId: res._id,
        cancel: {
          status: Status.Pending,
          timestamp: new Date(),
        },
        id: Math.floor(Math.random() * 9).toString() + 1,
      }

     data.data.messages.unshift({jobId: this.dataJob._id, timestamp: new Date()})
     this.dataJob.manage_.unshift(jIssue);

      this.userService.updateUser(data.data._id, data.data);
      this.jobService.updateJob(this.dataJob._id, this.dataJob);


     })



    //  this.pageService.updatePage(this.pageData._id, this.pageData)
    //  this.projectService.updateProject(this.projectSearch._id, this.projectSearch)

    } else {
      let jIssue: JIssue = {
        status: IssueStatus.Applied,
        message: {
          userId: this.authService.userID,
          pageId: this.pageData._id,
          description: data.contactMessage
        },
        createdAt: new Date(),
        userId: this.authService.userID,
        pageId: this.pageData._id,
        cancel: {
          status: Status.Pending,
          timestamp: new Date(),
        },
        id: Math.floor(Math.random() * 9).toString() + 1,
      }
      this.pageData.messages.unshift({projectId: this.projectSearch._id, timestamp: new Date()})
      this.projectSearch.manage_.unshift(jIssue);

     // this.pageService.updatePage(this.pageData._id, this.pageData)
     // this.projectService.updateProject(this.projectSearch._id, this.projectSearch)


    }

  }





}
