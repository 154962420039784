<main *ngIf="user?.account?.status === 2">
  <!-- Container START -->
  <div class="container">
    <div class="row g-4">
      <!-- Main content START -->
      <div class="col-lg-8 vstack gap-4">
        <!-- My profile START -->
        <div class="card">
          <!-- Cover image -->
          <div class="h-200px rounded-top" style="
              background-image: url(assets/images/bg/05.jpg);
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
            "></div>
          <!-- Card body START -->
          <div class="card-body py-0">
            <div class="d-sm-flex align-items-start text-center text-sm-start">
              <div>
                <!-- Avatar -->
                <div class="avatar avatar-xxl mt-n5 mb-3">
                  <img class="avatar-img rounded-circle border border-white border-3" src="assets/images/avatar/07.jpg"
                    alt="" />
                </div>
              </div>
              <div class="ms-sm-4 mt-sm-3">
                <!-- Info -->
                <h1 class="mb-0 h5">
                  {{ user?.firstName }} {{ user?.lastName }}
                  <i class="bi bi-patch-check-fill text-success small"></i>
                </h1>
                <div class="d-flex column">
                  <a [routerLink]="'/' + this.user?.userUrl + '/connections/followers'">{{ followersLength }}
                    {{'followers' | translate}}</a>
                  <a [routerLink]="'/' + this.user?.userUrl + '/connections/following'">{{ followingLength }}
                    {{'following' | translate}}</a>

                </div>
              </div>
              <!-- Button -->
              <div class="d-flex mt-3 justify-content-center ms-sm-auto">
                <button *ngIf="!!checkUser" class="btn btn-danger-soft me-2" type="button">
                  <i class="bi bi-pencil-fill pe-1"></i> Edit profile
                </button>
                <button *ngIf="!checkUser && !hasFollow && !isPendingFollow" class="btn btn-primary-soft me-2"
                  type="button" (click)="followContact()"> <i class="bi bi-plus pe-1"></i>
                  {{'follow' | translate}}
                </button>
                <button *ngIf="!checkUser && hasFollow" class="btn btn-primary-soft me-2" type="button"
                  (click)="unfollowUser()"><i class="bi bi-check2 pe-1"></i>
                  {{'following' | translate}}
                </button>
                <button *ngIf="!checkUser && isPendingFollow" class="btn btn-primary-soft me-2" type="button"
                  (click)="unfollowUser()"><i class="bi bi-clock-history pe-1"></i>
                  {{'request' | translate}}
                </button>
                <div class="dropdown" *ngIf="!!checkUser">
                  <!-- Card share action menu -->
                  <button class="icon-md btn btn-light" type="button" id="profileAction2" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <i class="bi bi-three-dots"></i>
                  </button>
                  <!-- Card share action dropdown menu -->
                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="profileAction2">
                    <li>
                      <a class="dropdown-item" href="#">
                        <i class="bi bi-bookmark fa-fw pe-2"></i>Share profile
                        in a message</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        <i class="bi bi-file-earmark-pdf fa-fw pe-2"></i>Save
                        your profile to PDF</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        <i class="bi bi-lock fa-fw pe-2"></i>Lock profile</a>
                    </li>
                    <li>
                      <hr class="dropdown-divider" />
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        <i class="bi bi-gear fa-fw pe-2"></i>Profile settings</a>
                    </li>
                  </ul>
                </div>
                <div class="dropdown" *ngIf="!checkUser">
                  <!-- Card share action menu -->
                  <button class="icon-md btn btn-light" type="button" id="profileAction2" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <i class="bi bi-three-dots"></i>
                  </button>
                  <!-- Card share action dropdown menu -->
                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="profileAction2">
                    <li>
                      <a class="dropdown-item" href="#">
                        <i class="bi bi-bookmark fa-fw pe-2"></i>Remove
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        <i class="bi bi-file-earmark-pdf fa-fw pe-2"></i>
                        Block
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        <i class="bi bi-lock fa-fw pe-2"></i>

                        Report</a>
                    </li>

                  </ul>
                </div>

              </div>
            </div>
            <!-- List profile -->
            <ul class="list-inline mb-0 text-center text-sm-start mt-3 mt-sm-0">
              <li class="list-inline-item">
                <i class="bi bi-briefcase me-1"></i>
                {{ user?.cv.data[0]?.newJobTitle }}
              </li>
              <li class="list-inline-item">
                <i class="bi bi-geo-alt me-1"></i> New Hampshire
              </li>
              <li class="list-inline-item">
                <i class="bi bi-calendar2-plus me-1"></i> Joined on Nov 26, 2019
              </li>
            </ul>
          </div>
          <!-- Card body END  -->
          <div class="card-footer mt-3 pt-2 pb-0">
            <!-- Nav profile pages -->
            <ul
              class="nav nav-bottom-line align-items-center justify-content-center justify-content-md-start mb-0 border-0">
              <li class="nav-item">
                <a [routerLinkActiveOptions]="{ exact: true }" class="nav-link" [routerLinkActive]="['active']"
                  [routerLink]="'/' + this.user?.userUrl + '/start'">
                  {{ "userProfile.posts" | translate }}
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']"
                  [routerLink]="'/' + this.user?.userUrl + '/about_user'">
                  {{ "userProfile.about" | translate }}
                </a>
              </li>
              <li class="nav-item" (click)="navigateToCV(this.user.userUrl)">
                <a [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']" class="nav-link">{{
                  "header.cv" | translate
                  }}</a>
              </li>
              <li class="nav-item">
                <a [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']"
                  [routerLink]="'/' + this.user?.userUrl + '/publicated/jobs'" class="nav-link">{{ "header.jobs" |
                  translate }}</a>
              </li>
              <li class="nav-item">
                <a [routerLinkActiveOptions]="{ exact: true }" class="nav-link" [routerLinkActive]="['active']"
                  [routerLink]="
                    '/' + this.user?.userUrl + '/publicated/projects'
                  ">{{ "header.projects" | translate }}</a>
              </li>
              <!-- <li class="nav-item">
                <a [routerLinkActiveOptions]="{ exact: true }" class="nav-link" [routerLinkActive]="['active']"
                  [routerLink]="'/' + this.user?.userUrl + '/connections/followers'">
                  {{ "userProfile.connections" | translate }}
                  <span class="badge bg-success bg-opacity-10 text-success small">
                    {{ contactsSize }}</span>
                </a>
              </li> -->
              <!--   <li class="nav-item">
                <a class="nav-link" href="my-profile-media.html"> Media</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="my-profile-videos.html"> Videos</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="my-profile-events.html"> Events</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="my-profile-activity.html">
                  Activity</a
                >
              </li>-->
            </ul>
          </div>
        </div>
        <!-- My profile END -->

        <ng-container *ngIf="router.url.endsWith('/start')">
          <!-- Share feed START -->
          <div class="card card-body">
            <div class="d-flex mb-3">
              <!-- Avatar -->
              <div class="avatar avatar-xs me-2">
                <a href="#">
                  <img class="avatar-img rounded-circle" src="assets/images/avatar/07.jpg" alt="" />
                </a>
              </div>
              <!-- Post input -->
              <form class="w-100">
                <input class="form-control pe-4 border-0" placeholder="Share your thoughts..." data-bs-toggle="modal"
                  data-bs-target="#modalCreateFeed" />
              </form>
            </div>
            <!-- Share feed toolbar START -->
            <ul class="nav nav-pills nav-stack small fw-normal">
              <li class="nav-item">
                <a class="nav-link bg-light py-1 px-2 mb-0" href="#!" data-bs-toggle="modal"
                  data-bs-target="#feedActionPhoto">
                  <i class="bi bi-image-fill text-success pe-2"></i>Photo</a>
              </li>
              <li class="nav-item">
                <a class="nav-link bg-light py-1 px-2 mb-0" href="#!" data-bs-toggle="modal"
                  data-bs-target="#feedActionVideo">
                  <i class="bi bi-camera-reels-fill text-info pe-2"></i>Video</a>
              </li>
              <li class="nav-item">
                <a href="#" class="nav-link bg-light py-1 px-2 mb-0" data-bs-toggle="modal"
                  data-bs-target="#modalCreateEvents">
                  <i class="bi bi-calendar2-event-fill text-danger pe-2"></i>Event
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link bg-light py-1 px-2 mb-0" href="#!" data-bs-toggle="modal"
                  data-bs-target="#modalCreateFeed">
                  <i class="bi bi-emoji-smile-fill text-warning pe-2"></i>Feeling /Activity</a>
              </li>
              <li class="nav-item dropdown ms-sm-auto">
                <a class="nav-link bg-light py-1 px-2 mb-0" href="#" id="feedActionShare" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <i class="bi bi-three-dots"></i>
                </a>
                <!-- Dropdown menu -->
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="feedActionShare">
                  <li>
                    <a class="dropdown-item" href="#">
                      <i class="bi bi-envelope fa-fw pe-2"></i>Create a poll</a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      <i class="bi bi-bookmark-check fa-fw pe-2"></i>Ask a
                      question
                    </a>
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      <i class="bi bi-pencil-square fa-fw pe-2"></i>Help</a>
                  </li>
                </ul>
              </li>
            </ul>
            <!-- Share feed toolbar END -->
          </div>
          <!-- Share feed END -->

          <!-- Card feed item START -->
          <div class="card">
            <!-- Card header START -->
            <div class="card-header border-0 pb-0">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <!-- Avatar -->
                  <div class="avatar avatar-story me-2">
                    <a href="#!">
                      <img class="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt="" />
                    </a>
                  </div>
                  <!-- Info -->
                  <div>
                    <div class="nav nav-divider">
                      <h6 class="nav-item card-title mb-0">
                        <a href="#!"> Lori Ferguson </a>
                      </h6>
                      <span class="nav-item small"> 2hr</span>
                    </div>
                    <p class="mb-0 small">Web Developer at Webestica</p>
                  </div>
                </div>
                <!-- Card feed action dropdown START -->
                <div class="dropdown">
                  <a href="#" class="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardFeedAction1"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="bi bi-three-dots"></i>
                  </a>
                  <!-- Card feed action dropdown menu -->
                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="cardFeedAction1">
                    <li>
                      <a class="dropdown-item" href="#">
                        <i class="bi bi-bookmark fa-fw pe-2"></i>Save post</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        <i class="bi bi-person-x fa-fw pe-2"></i>Unfollow lori
                        ferguson
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        <i class="bi bi-x-circle fa-fw pe-2"></i>Hide post</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        <i class="bi bi-slash-circle fa-fw pe-2"></i>Block</a>
                    </li>
                    <li>
                      <hr class="dropdown-divider" />
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        <i class="bi bi-flag fa-fw pe-2"></i>Report post</a>
                    </li>
                  </ul>
                </div>
                <!-- Card feed action dropdown END -->
              </div>
            </div>
            <!-- Card header END -->
            <!-- Card body START -->
            <div class="card-body">
              <p>
                I'm thrilled to share that I've completed a graduate certificate
                course in project management with the president's honor roll.
              </p>
              <!-- Card img -->
              <img class="card-img" src="assets/images/post/3by2/01.jpg" alt="Post" />
              <!-- Feed react START -->
              <ul class="nav nav-stack py-3 small">
                <li class="nav-item">
                  <a class="nav-link active" href="#!">
                    <i class="bi bi-hand-thumbs-up-fill pe-1"></i>Liked (56)</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#!">
                    <i class="bi bi-chat-fill pe-1"></i>Comments (12)</a>
                </li>
                <!-- Card share action START -->
                <li class="nav-item dropdown ms-sm-auto">
                  <a class="nav-link mb-0" href="#" id="cardShareAction8" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <i class="bi bi-reply-fill flip-horizontal ps-1"></i>Share
                    (3)
                  </a>
                  <!-- Card share action dropdown menu -->
                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction8">
                    <li>
                      <a class="dropdown-item" href="#">
                        <i class="bi bi-envelope fa-fw pe-2"></i>Send via Direct
                        Message</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        <i class="bi bi-bookmark-check fa-fw pe-2"></i>Bookmark
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        <i class="bi bi-link fa-fw pe-2"></i>Copy link to
                        post</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        <i class="bi bi-share fa-fw pe-2"></i>Share post via
                        …</a>
                    </li>
                    <li>
                      <hr class="dropdown-divider" />
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        <i class="bi bi-pencil-square fa-fw pe-2"></i>Share to
                        News Feed</a>
                    </li>
                  </ul>
                </li>
                <!-- Card share action END -->
              </ul>
              <!-- Feed react END -->

              <!-- Add comment -->
              <div class="d-flex mb-3">
                <!-- Avatar -->
                <div class="avatar avatar-xs me-2">
                  <a href="#!">
                    <img class="avatar-img rounded-circle" src="assets/images/avatar/12.jpg" alt="" />
                  </a>
                </div>
                <!-- Comment box  -->
                <form class="position-relative w-100">
                  <textarea class="form-control pe-4 bg-light" rows="1" placeholder="Add a comment..."></textarea>
                </form>
              </div>
              <!-- Comment wrap START -->
              <ul class="comment-wrap list-unstyled">
                <!-- Comment item START -->
                <li class="comment-item">
                  <div class="d-flex position-relative">
                    <!-- Avatar -->
                    <div class="avatar avatar-xs">
                      <a href="#!"><img class="avatar-img rounded-circle" src="assets/images/avatar/05.jpg"
                          alt="" /></a>
                    </div>
                    <div class="ms-2">
                      <!-- Comment by -->
                      <div class="bg-light rounded-start-top-0 p-3 rounded">
                        <div class="d-flex justify-content-between">
                          <h6 class="mb-1">
                            <a href="#!"> Frances Guerrero </a>
                          </h6>
                          <small class="ms-2">5hr</small>
                        </div>
                        <p class="small mb-0">
                          Removed demands expense account in outward tedious do.
                          Particular way thoroughly unaffected projection.
                        </p>
                      </div>
                      <!-- Comment react -->
                      <ul class="nav nav-divider py-2 small">
                        <li class="nav-item">
                          <a class="nav-link" href="#!"> Like (3)</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" href="#!"> Reply</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" href="#!"> View 5 replies</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- Comment item nested START -->
                  <ul class="comment-item-nested list-unstyled">
                    <!-- Comment item START -->
                    <li class="comment-item">
                      <div class="d-flex">
                        <!-- Avatar -->
                        <div class="avatar avatar-xs">
                          <a href="#!"><img class="avatar-img rounded-circle" src="assets/images/avatar/06.jpg"
                              alt="" /></a>
                        </div>
                        <!-- Comment by -->
                        <div class="ms-2">
                          <div class="bg-light p-3 rounded">
                            <div class="d-flex justify-content-between">
                              <h6 class="mb-1">
                                <a href="#!"> Lori Stevens </a>
                              </h6>
                              <small class="ms-2">2hr</small>
                            </div>
                            <p class="small mb-0">
                              See resolved goodness felicity shy civility
                              domestic had but Drawings offended yet answered
                              Jennings perceive.
                            </p>
                          </div>
                          <!-- Comment react -->
                          <ul class="nav nav-divider py-2 small">
                            <li class="nav-item">
                              <a class="nav-link" href="#!"> Like (5)</a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" href="#!"> Reply</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <!-- Comment item END -->
                    <!-- Comment item START -->
                    <li class="comment-item">
                      <div class="d-flex">
                        <!-- Avatar -->
                        <div class="avatar avatar-story avatar-xs">
                          <a href="#!"><img class="avatar-img rounded-circle" src="assets/images/avatar/07.jpg"
                              alt="" /></a>
                        </div>
                        <!-- Comment by -->
                        <div class="ms-2">
                          <div class="bg-light p-3 rounded">
                            <div class="d-flex justify-content-between">
                              <h6 class="mb-1">
                                <a href="#!"> Billy Vasquez </a>
                              </h6>
                              <small class="ms-2">15min</small>
                            </div>
                            <p class="small mb-0">
                              Wishing calling is warrant settled was lucky.
                            </p>
                          </div>
                          <!-- Comment react -->
                          <ul class="nav nav-divider py-2 small">
                            <li class="nav-item">
                              <a class="nav-link" href="#!"> Like</a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" href="#!"> Reply</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <!-- Comment item END -->
                  </ul>
                  <!-- Load more replies -->
                  <a href="#!" role="button"
                    class="btn btn-link btn-link-loader btn-sm text-secondary d-flex align-items-center mb-3 ms-5"
                    data-bs-toggle="button" aria-pressed="true">
                    <div class="spinner-dots me-2">
                      <span class="spinner-dot"></span>
                      <span class="spinner-dot"></span>
                      <span class="spinner-dot"></span>
                    </div>
                    Load more replies
                  </a>
                  <!-- Comment item nested END -->
                </li>
                <!-- Comment item END -->
                <!-- Comment item START -->
                <li class="comment-item">
                  <div class="d-flex">
                    <!-- Avatar -->
                    <div class="avatar avatar-xs">
                      <a href="#!"><img class="avatar-img rounded-circle" src="assets/images/avatar/05.jpg"
                          alt="" /></a>
                    </div>
                    <!-- Comment by -->
                    <div class="ms-2">
                      <div class="bg-light p-3 rounded">
                        <div class="d-flex justify-content-between">
                          <h6 class="mb-1">
                            <a href="#!"> Frances Guerrero </a>
                          </h6>
                          <small class="ms-2">4min</small>
                        </div>
                        <p class="small mb-0">
                          Removed demands expense account in outward tedious do.
                          Particular way thoroughly unaffected projection.
                        </p>
                      </div>
                      <!-- Comment react -->
                      <ul class="nav nav-divider pt-2 small">
                        <li class="nav-item">
                          <a class="nav-link" href="#!"> Like (1)</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" href="#!"> Reply</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" href="#!"> View 6 replies</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <!-- Comment item END -->
              </ul>
              <!-- Comment wrap END -->
            </div>
            <!-- Card body END -->
            <!-- Card footer START -->
            <div class="card-footer border-0 pt-0">
              <!-- Load more comments -->
              <a href="#!" role="button"
                class="btn btn-link btn-link-loader btn-sm text-secondary d-flex align-items-center"
                data-bs-toggle="button" aria-pressed="true">
                <div class="spinner-dots me-2">
                  <span class="spinner-dot"></span>
                  <span class="spinner-dot"></span>
                  <span class="spinner-dot"></span>
                </div>
                Load more comments
              </a>
            </div>
            <!-- Card footer END -->
          </div>
          <!-- Card feed item END -->

          <!-- Card feed item START -->
          <div class="card">
            <div class="border-bottom">
              <p class="small mb-0 px-4 py-2">
                <i class="bi bi-heart-fill text-danger pe-1"></i>Sam Lanson
                likes this post
              </p>
            </div>
            <!-- Card header START -->
            <div class="card-header border-0 pb-0">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <!-- Avatar -->
                  <div class="avatar me-2">
                    <a href="#">
                      <img class="avatar-img rounded-circle" src="assets/images/logo/13.svg" alt="" />
                    </a>
                  </div>
                  <!-- Title -->
                  <div>
                    <h6 class="card-title mb-0">
                      <a href="#!"> Apple Education </a>
                    </h6>
                    <p class="mb-0 small">9 November at 23:29</p>
                  </div>
                </div>
                <!-- Card share action menu -->
                <a href="#" class="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardShareAction5"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="bi bi-three-dots"></i>
                </a>
                <!-- Card share action dropdown menu -->
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction5">
                  <li>
                    <a class="dropdown-item" href="#">
                      <i class="bi bi-bookmark fa-fw pe-2"></i>Save post</a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      <i class="bi bi-person-x fa-fw pe-2"></i>Unfollow lori
                      ferguson
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      <i class="bi bi-x-circle fa-fw pe-2"></i>Hide post</a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      <i class="bi bi-slash-circle fa-fw pe-2"></i>Block</a>
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      <i class="bi bi-flag fa-fw pe-2"></i>Report post</a>
                  </li>
                </ul>
              </div>
              <!-- Card share action END -->
            </div>
            <!-- Card header START -->

            <!-- Card body START -->
            <div class="card-body pb-0">
              <p>
                Find out how you can save time in the classroom this year. Earn
                recognition while you learn new skills on iPad and Mac. Start
                recognition your first Apple Teacher badge today!
              </p>
              <!-- Feed react START -->
              <ul class="nav nav-stack pb-2 small">
                <li class="nav-item">
                  <a class="nav-link active text-secondary" href="#!">
                    <i class="bi bi-heart-fill me-1 icon-xs bg-danger text-white rounded-circle"></i>
                    Louis, Billy and 126 others
                  </a>
                </li>
                <li class="nav-item ms-sm-auto">
                  <a class="nav-link" href="#!">
                    <i class="bi bi-chat-fill pe-1"></i>Comments (12)</a>
                </li>
              </ul>
              <!-- Feed react END -->
            </div>
            <!-- Card body END -->
            <!-- Card Footer START -->
            <div class="card-footer py-3">
              <!-- Feed react START -->
              <ul class="nav nav-fill nav-stack small">
                <li class="nav-item">
                  <a class="nav-link mb-0 active" href="#!">
                    <i class="bi bi-heart pe-1"></i>Liked (56)</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link mb-0" href="#!">
                    <i class="bi bi-chat-fill pe-1"></i>Comments (12)</a>
                </li>
                <!-- Card share action dropdown START -->
                <li class="nav-item dropdown">
                  <a href="#" class="nav-link mb-0" id="cardShareAction6" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <i class="bi bi-reply-fill flip-horizontal ps-1"></i>Share
                    (3)
                  </a>
                  <!-- Card share action dropdown menu -->
                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction6">
                    <li>
                      <a class="dropdown-item" href="#">
                        <i class="bi bi-envelope fa-fw pe-2"></i>Send via Direct
                        Message</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        <i class="bi bi-bookmark-check fa-fw pe-2"></i>Bookmark
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        <i class="bi bi-link fa-fw pe-2"></i>Copy link to
                        post</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        <i class="bi bi-share fa-fw pe-2"></i>Share post via
                        …</a>
                    </li>
                    <li>
                      <hr class="dropdown-divider" />
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        <i class="bi bi-pencil-square fa-fw pe-2"></i>Share to
                        News Feed</a>
                    </li>
                  </ul>
                </li>
                <!-- Card share action dropdown END -->
                <li class="nav-item">
                  <a class="nav-link mb-0" href="#!">
                    <i class="bi bi-send-fill pe-1"></i>Send</a>
                </li>
              </ul>
              <!-- Feed react END -->
            </div>
            <!-- Card Footer END -->
          </div>
          <!-- Card feed item END -->
        </ng-container>
        <ng-container *ngIf="
            router.url === '/' + this.user?.userUrl + '/publicated/projects'
          ">
          <div class="main-content right-aligned">
            <section class="search-result-container">
              <div>
                <div class="result-list" *ngFor="let userProjects of user?.projects?.data">
                  <app-user-projects-published [userProjects]="userProjects"></app-user-projects-published>
                </div>
              </div>
            </section>
          </div>
        </ng-container>

        <ng-container *ngIf="router.url.includes(this.user?.userUrl + '/publicated/projects/')">
          <div class="main-content right-aligned">
            <section class="search-result-container">
              <ng-container *ngIf="!!project">
                <app-user-projects-published [project]="project" [isPublished]="true"></app-user-projects-published>
              </ng-container>

            </section>
          </div>
        </ng-container>
        <ng-container *ngIf="router.url === '/' + this.user?.userUrl + '/publicated/jobs'">
          <div class="main-content right-aligned">
            <section class="search-result-container">
              <div>
                <div class="result-list" *ngFor="let userJobs of user?.jobs?.data">
                  <app-user-jobs-published [userJobs]="userJobs"></app-user-jobs-published>
                </div>
              </div>
            </section>
          </div>
        </ng-container>
        <ng-container *ngIf="router.url.includes(this.user?.userUrl + '/publicated/jobs/')">
          <div class="main-content right-aligned">
            <section class="search-result-container">
              <ng-container *ngIf="!!job">
                <app-user-jobs-published [job]="job" [isPublished]="true"></app-user-jobs-published>
              </ng-container>

            </section>
          </div>
        </ng-container>
        <ng-container *ngIf="router.url === '/' + this.user?.userUrl + '/about_user'">
          <div class="card">
            <!-- Card header START -->
            <div class="card-header border-0 pb-0">
              <h5 class="card-title">{{ "userProfile.about" | translate }}</h5>
              <div class="card mb-4 border-0">
                <div class="rounded border px-3 py-2 mb-3">
                  <div class="d-flex align-items-center justify-content-between"></div>
                  <p [innerHTML]="user?.about"></p>
                </div>
              </div>
            </div>
            <!-- Card header END -->
            <!-- Card body START -->

            <div class="row gx-0">
              <!-- Sidenav START -->
              <div class="col-lg-4">
                <!-- Advanced filter responsive toggler START -->
                <!-- Divider -->
                <div class="d-flex align-items-center mb-4 d-lg-none">
                  <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                    <span class="btn btn-primary"><i class="bi bi-sliders"></i></span>
                    <span class="h6 mb-0 fw-bold d-lg-none ms-2">{{
                      "userProfile.about" | translate
                      }}</span>
                  </button>
                </div>
                <!-- Advanced filter responsive toggler END -->

                <nav class="navbar navbar-light navbar-expand-lg mx-0">
                  <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
                    <!-- Offcanvas header -->
                    <div class="offcanvas-header">
                      <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas"
                        aria-label="Close"></button>
                    </div>

                    <!-- Offcanvas body -->
                    <div class="offcanvas-body p-0">
                      <!-- Card START -->
                      <div class="card w-100">
                        <!-- Card body START -->
                        <div class="card-body px-1 py-1">
                          <!-- Side Nav START -->
                          <ul class="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                            <li class="nav-item" data-bs-dismiss="offcanvas">
                              <a class="nav-link d-flex mb-0 active" href="#nav-setting-tab-1" data-bs-toggle="tab">
                                <i class="bi bi-eye me-2"></i><span>{{ "userProfile.overview" | translate }}
                                </span></a>
                            </li>
                            <li class="nav-item" data-bs-dismiss="offcanvas">
                              <a class="nav-link d-flex mb-0" href="#nav-setting-tab-2" data-bs-toggle="tab">
                                <i class="bi bi-journal-bookmark-fill me-2"></i><span>{{ "category.Career" | translate
                                  }} &
                                  {{ "category.Education" | translate }}
                                </span></a>
                            </li>

                            <li class="nav-item" data-bs-dismiss="offcanvas">
                              <a class="nav-link d-flex mb-0" href="#nav-setting-tab-7" data-bs-toggle="tab">
                                <i class="bi bi-gear-wide-connected me-2"></i><span>{{ "category.Skills" | translate }}
                                </span></a>
                            </li>

                            <li class="nav-item" data-bs-dismiss="offcanvas">
                              <a class="nav-link d-flex mb-0" href="#nav-setting-tab-3" data-bs-toggle="tab">
                                <i class="bi bi-pin-map me-2"></i><span>{{
                                  "userProfile.placesLived" | translate
                                  }}</span></a>
                            </li>
                            <li class="nav-item" data-bs-dismiss="offcanvas">
                              <a class="nav-link d-flex mb-0" href="#nav-setting-tab-4" data-bs-toggle="tab">
                                <i class="bi bi-person-vcard me-2"></i><span>{{
                                  "userProfile.contactAndBasic" | translate
                                  }}
                                </span></a>
                            </li>
                            <li class="nav-item" data-bs-dismiss="offcanvas">
                              <a class="nav-link d-flex mb-0" href="#nav-setting-tab-5" data-bs-toggle="tab">
                                <i class="bi bi-person-hearts me-2"></i><span>Family and relationships </span></a>
                            </li>
                          </ul>
                          <!-- Side Nav END -->
                        </div>
                        <!-- Card body END -->
                        <!-- Card footer -->
                      </div>
                      <!-- Card END -->
                    </div>
                  </div>
                </nav>
              </div>
              <!-- Sidenav END -->

              <!-- Main content START -->
              <div class="col-lg-5 vstack gap-4">
                <!-- Setting Tab content START -->
                <div class="tab-content py-0 mb-0">
                  <!-- Account setting tab START -->
                  <div class="tab-pane show active fade" id="nav-setting-tab-1">
                    <!-- Account settings START -->
                    <div class="card mb-4">
                      <!-- Card body START -->
                      <div class="card-body">
                        <!-- Form settings START -->
                        <form class="row g-3">
                          <!-- First name -->

                          <!-- Card header START -->
                          <!-- Card body START -->

                          <div class="col-sm-12">
                            <!-- Birthday START -->
                            <div class="d-flex align-items-center px-0 py-2">
                              <!-- Date -->
                              <p class="mb-0">
                                <i class="bi bi-buildings"></i>
                                <strong>
                                  {{ branchTitle | translate }} -
                                  {{ subCategoryTitle | translate }}
                                </strong>
                              </p>
                              <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                <!-- Card share action menu -->
                                <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                  data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="bi bi-three-dots"></i>
                                </a>
                                <!-- Card share action dropdown menu -->
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                  <li>
                                    <a class="dropdown-item" [routerLink]="'../cv/branch'">
                                      <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <!-- Birthday END -->

                            <!-- Delete END -->
                          </div>

                          <div class="col-sm-12 position-relative" *ngIf="!!checkLoginID">
                            <!-- Workplace on START -->
                            <a class="btn btn-dashed rounded w-100 text-align-start" (click)="
                                  addOffer({
                                    edit: true,
                                    offer:
                                      user.cv.data[0]?.skills[0]?.subCategories[0]
                                        .skillsOffer.data,
                                    model: model
                                  })
                                ">
                              <i class="bi bi-plus-circle-dotted me-1"></i>{{ "add" | translate }}</a>
                            <!-- Workplace on END -->
                          </div>
                          <div class="d-flex pr-1 pt-1 flex-wrap col-sm-12">
                            <p class="mb-0">
                              <i class="bi bi-tags"></i>
                            </p>
                            <div class="col-sm-9 d-flex flex-wrap ">
                              <ng-container *ngFor="
                                  let keyword of user?.searchFilterSkills?.searchSkillsOffer?.data
                                ">
                                <p class="card__text me-1 mb-1">
                                  {{ keyword?.name }}
                                </p>
                              </ng-container>
                            </div>
                            <div class="d-flex align-items-start ms-auto">
                              <app-privacy-button *ngIf="!!checkLoginID"
                                [privacy]="user?.searchFilterSkills?.searchSkillsOffer?.privacy"
                                (saveObject)="updatePlaces($event)"></app-privacy-button>



                              <div class="dropdown col-sm-1" *ngIf="!!checkLoginID">
                                <!-- Card share action menu -->
                                <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                  data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="bi bi-three-dots"></i>
                                </a>
                                <!-- Card share action dropdown menu -->
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                  <li>
                                    <a class="dropdown-item" (click)="
                                        editOffer({
                                          edit: true,
                                          offer:
                                            user.cv.data[0]?.skills[0]?.subCategories[0]
                                              ?.skillsOffer,
                                          model:
                                            user.cv.data[0]?.skills[0]?.subCategories[0]
                                              ?.skillsOffer,
                                          indexOfLang: 0
                                        })
                                      ">
                                      <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                  </li>
                                  <li>
                                    <a class="dropdown-item" (click)="
                                        editOffer({
                                          edit: true,
                                          offer:
                                            user.cv.data[0]?.skills[0]?.subCategories[0]
                                              ?.skillsOffer,
                                          model:
                                            user.cv.data[0]?.skills[0]?.subCategories[0]
                                              ?.skillsOffer,
                                          indexOfLang: 0
                                        })
                                      ">
                                      <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <!-- Delete END -->


                          <div class="col-sm-12">
                            <div class="d-flex align-items-center py-2">
                              <!-- Date -->
                              <div class="mb-0 d-flex">
                                <i class="bi bi-briefcase"></i>
                                <div class="ms-1">
                                  <strong>
                                    {{getActualWorkingCompany()?.role}} {{ "at" | translate }}
                                    <app-education-name [careerObj]="getActualWorkingCompany()" [careerObject]="true">
                                    </app-education-name>

                                  </strong>
                                  <br>
                                  <div class="d-flex align-items-center">
                                    <p class="mb-0 small me-2">
                                      {{
                                      getActualWorkingCompany()?.startDate | date : "MM.yyyy"
                                      }}
                                    </p>
                                    -

                                    <p class="mb-0 small ms-2" *ngIf="getActualWorkingCompany()?.today">
                                      {{ "present" | translate }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="d-flex align-items-center ms-auto">
                                <app-privacy-button *ngIf="!!checkLoginID" [privacy]="user.cv.data[0]?.careers[0]
                                ?.subCategories[0]?.privacy" (saveObject)="updateCareer($event)"></app-privacy-button>

                                <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                  <!-- Card share action menu -->
                                  <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="bi bi-three-dots"></i>
                                  </a>
                                  <!-- Card share action dropdown menu -->
                                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                    <li>
                                      <a class="dropdown-item" (click)="
                                          editCareer({
                                            edit: true,
                                            career: careerObj,
                                            model: user.cv.data[0],
                                            index: i,
                                            indexOfLang: 0
                                          })
                                        ">
                                        <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                    </li>
                                    <li>
                                      <a *ngIf="
                                          user.cv.data[0].careers[0].subCategories
                                            .length > 1
                                        " class="dropdown-item" (click)="deleteCareerSubCategory(i)">
                                        <i class="bi bi-trash fa-fw pe-2"></i>Delete</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12" *ngIf="!!user?.places?.current?.pageID">
                            <!-- Birthday START -->
                            <div class="d-flex align-items-center py-2">
                              <!-- Date -->
                              <div class="mb-0 d-flex">
                                <i class="bi bi-house-door"></i>
                                <div class="ms-1">
                                  <strong>
                                    <app-education-name [hometown]="true" [hometownPageID]="
                                      user?.places?.current?.pageID
                                    ">
                                    </app-education-name>
                                    <p *ngIf="user?.places?.current?.name">
                                      {{ user?.places?.current?.name }}
                                    </p>
                                  </strong>
                                  <p class="small mb-0">
                                    {{ "Current city" }}
                                  </p>
                                </div>
                              </div>
                              <div class="d-flex align-items-center ms-auto">
                                <app-privacy-button *ngIf="!!checkLoginID"
                                  [privacy]="loggedUser?.places?.current.privacy"
                                  (saveObject)="updatePlaces($event)"></app-privacy-button>


                                <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                  <!-- Card share action menu -->
                                  <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="bi bi-three-dots"></i>
                                  </a>
                                  <!-- Card share action dropdown menu -->
                                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                    <li>
                                      <a class="dropdown-item" href="#">
                                        <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                    </li>
                                    <li>
                                      <a class="dropdown-item" (click)="deletePageCurrent()">
                                        <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <!-- Birthday END -->
                          </div>
                          <div class="col-sm-12" *ngIf="!!user?.places?.current?.name">
                            <!-- Birthday START -->
                            <div class="d-flex align-items-center py-2">
                              <div class="mb-0 d-flex">
                                <ul class="navbar-nav navbar-nav-scrol">
                                  <li class="nav-item me-2">
                                    <a class="nav-link icon-md btn btn-light p-0">
                                      <i class="bi bi-geo-alt fs-6"></i>
                                    </a>
                                  </li>
                                </ul>
                                <div>
                                  <strong>
                                    <p>{{ user?.places?.current?.name }}</p>
                                  </strong>
                                  <p class="small mb-0">
                                    {{ "Current city" }}
                                  </p>
                                </div>
                              </div>
                              <div class="d-flex align-items-center ms-auto">
                                <app-privacy-button *ngIf="!!checkLoginID"
                                  [privacy]="loggedUser?.places?.current.privacy"
                                  (saveObject)="updatePlaces($event)"></app-privacy-button>
                                <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                  <!-- Card share action menu -->
                                  <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="bi bi-three-dots"></i>
                                  </a>
                                  <!-- Card share action dropdown menu -->
                                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                    <li>
                                      <a class="dropdown-item" href="#">
                                        <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                    </li>
                                    <li>
                                      <a class="dropdown-item" (click)="deleteNameCurrent()">
                                        <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <!-- Birthday END -->
                          </div>
                          <div class="col-sm-12" *ngIf="!!user?.places?.hometown?.pageID">
                            <!-- Birthday START -->
                            <div class="d-flex align-items-center py-2">
                              <!-- Date -->
                              <div class="mb-0 d-flex">
                                <i class="bi bi-geo-alt"></i>
                                <div>
                                  <strong><app-education-name [hometown]="true" [hometownPageID]="
                                      user?.places?.hometown?.pageID
                                    "></app-education-name>
                                  </strong>
                                  <p class="small mb-0">{{ "Hometown" }}</p>
                                </div>
                              </div>
                              <div class="d-flex align-items-center ms-auto">


                                <app-privacy-button *ngIf="!!checkLoginID"
                                  [privacy]="loggedUser?.places?.hometown.privacy"
                                  (saveObject)="updateHometown($event)"></app-privacy-button>


                                <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                  <!-- Card share action menu -->
                                  <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="bi bi-three-dots"></i>
                                  </a>
                                  <!-- Card share action dropdown menu -->
                                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                    <li>
                                      <a class="dropdown-item">
                                        <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                    </li>
                                    <li>
                                      <a class="dropdown-item" (click)="deletePage()">
                                        <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <!-- Birthday END -->
                          </div>
                          <div class="col-sm-12" *ngIf="!!user?.places?.hometown?.name">
                            <!-- Birthday START -->
                            <div class="d-flex align-items-center py-2">
                              <!-- Date -->
                              <div class="mb-0 d-flex">
                                <ul class="navbar-nav navbar-nav-scrol">
                                  <li class="nav-item me-2">
                                    <a class="nav-link icon-md btn btn-light p-0">
                                      <i class="bi bi-geo-alt fs-6"></i>
                                    </a>
                                  </li>
                                </ul>
                                <div>
                                  <strong>{{
                                    user?.places?.hometown.name
                                    }}</strong>
                                  <p class="small mb-0">{{ "Hometown" }}</p>
                                </div>
                              </div>
                              <div class="d-flex align-items-center ms-auto">


                                <app-privacy-button *ngIf="!!checkLoginID"
                                  [privacy]="loggedUser?.places?.hometown.privacy"
                                  (saveObject)="updateHometown($event)"></app-privacy-button>


                                <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                  <!-- Card share action menu -->
                                  <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="bi bi-three-dots"></i>
                                  </a>
                                  <!-- Card share action dropdown menu -->
                                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                    <li>
                                      <a class="dropdown-item" href="#">
                                        <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                    </li>
                                    <li>
                                      <a class="dropdown-item" (click)="deleteNameOfHomeTown()">
                                        <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <!-- Birthday END -->
                          </div>
                          <ng-container *ngIf="!!user?.relationship?.status">
                            <ng-container *ngIf="
                                isEditingRelationship;
                                else readOnlyRelationship
                              ">

                            </ng-container>
                            <ng-template #readOnlyRelationship>
                              <div class="col-sm-12">
                                <div class="d-flex align-items-center py-2" *ngIf="
                                    !user?.relationship?.user?.status &&
                                    !!user?.relationship?.status
                                  ">
                                  <!-- Date -->
                                  <p class="mb-0">
                                    <i class="bi bi-heart"></i>
                                    <strong>
                                      {{
                                      relationshipStatus?.description
                                      | translate
                                      }}
                                      <ng-container *ngIf="!!user.relationship?.user?.id">
                                        <app-education-name [isStatusReceive]="true" [userID]="
                                            user.relationship?.user?.id
                                          "></app-education-name>
                                      </ng-container>
                                    </strong>
                                  </p>
                                  <div class="d-flex align-items-center ms-auto">
                                    <app-privacy-button *ngIf="!!checkLoginID" [privacy]="user?.relationship?.privacy"
                                      (saveObject)="updateRelationship($event)"></app-privacy-button>


                                    <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                      <!-- Card share action menu -->
                                      <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="bi bi-three-dots"></i>
                                      </a>
                                      <!-- Card share action dropdown menu -->
                                      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                        <li>
                                          <a class="dropdown-item" (click)="editRelationship()">
                                            <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                        </li>
                                        <li>
                                          <a class="dropdown-item" (click)="deleteRelationship()">
                                            <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <!-- Birthday START -->
                                <div class="d-flex align-items-center px-0 py-2" *ngIf="
                                    user?.relationship?.user?.status === 2
                                  ">
                                  <!-- Date -->
                                  <p class="mb-0">
                                    <i class="bi bi-heart-fill"></i>
                                    <strong>
                                      {{
                                      relationshipStatus?.description
                                      | translate
                                      }}
                                      <app-education-name [isStatusReceive]="true"
                                        [userID]="user.relationship?.user?.id"></app-education-name>
                                    </strong>
                                  </p>
                                  <app-privacy-button *ngIf="!!checkLoginID" [privacy]="user?.relationship?.privacy"
                                    (saveObject)="updateRelationship($event)"></app-privacy-button>

                                  <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                    <!-- Card share action menu -->
                                    <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                      data-bs-toggle="dropdown" aria-expanded="false">
                                      <i class="bi bi-three-dots"></i>
                                    </a>
                                    <!-- Card share action dropdown menu -->
                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                      <li>
                                        <a class="dropdown-item" (click)="editRelationship()">
                                          <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                      </li>
                                      <li>
                                        <a class="dropdown-item" (click)="deleteRelationship()">
                                          <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="d-flex align-items-center py-2" *ngIf="
                                    user?.relationship?.user?.status === 1
                                  ">
                                  <!-- Date -->
                                  <p class="mb-0">
                                    <i class="bi bi-heart-fill"></i>
                                    <strong>
                                      {{
                                      relationshipStatus?.description
                                      | translate
                                      }}

                                      <app-education-name [isStatusReceive]="true"
                                        [userID]="user.relationship?.user?.id"></app-education-name></strong>
                                  </p>
                                  <div class="d-flex align-items-center ms-auto">
                                    <app-privacy-button *ngIf="!!checkLoginID" [privacy]="user?.relationship?.privacy"
                                      (saveObject)="updateRelationship($event)"></app-privacy-button>

                                    <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                      <!-- Card share action menu -->
                                      <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="bi bi-three-dots"></i>
                                      </a>
                                      <!-- Card share action dropdown menu -->
                                      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                        <li>
                                          <a class="dropdown-item" (click)="editRelationship()">
                                            <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                        </li>
                                        <li>
                                          <a class="dropdown-item" (click)="deleteRelationship()">
                                            <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>


                                </div>
                                <div class="rounded badge-unread d-sm-flex border-0 mb-1 p-3 position-relative" *ngIf="
                                    user?.relationship?.user?.status === 0
                                  ">
                                  <!-- Avatar -->
                                  <div class="avatar text-center">
                                    <img class="avatar-img rounded-circle" src="assets/images/avatar/01.jpg" alt="" />
                                  </div>
                                  <!-- Info -->
                                  <div class="mx-sm-3 my-2 my-sm-0">
                                    <p class="small mb-2">
                                      <b><app-education-name [isStatusReceive]="true" [userID]="
                                            user.relationship?.user?.id
                                          "></app-education-name></b>
                                      added you to
                                      {{
                                      relationshipStatus?.description
                                      | translate
                                      }}
                                    </p>
                                    {{ "status 0" }}
                                    <!-- Button -->
                                    <div class="d-flex">
                                      <button class="btn btn-sm py-1 btn-primary me-2" (click)="acceptRelationship()">
                                        Accept
                                      </button>
                                      <button class="btn btn-sm py-1 btn-danger-soft">
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                  <!-- Action -->
                                  <div class="d-flex ms-auto">
                                    <p class="small me-5 text-nowrap">
                                      Just now
                                    </p>

                                    <div class="d-flex align-items-center ms-auto">
                                      <app-privacy-button *ngIf="!!checkLoginID" [privacy]="user?.relationship?.privacy"
                                        (saveObject)="updateRelationship($event)"></app-privacy-button>

                                      <!-- Notification action START -->
                                      <div class="dropdown position-absolute end-0 top-0 mt-3 me-3">
                                        <a href="#" class="z-index-1 text-secondary btn position-relative py-0 px-2"
                                          id="cardNotiAction1" data-bs-toggle="dropdown" aria-expanded="false">
                                          <i class="bi bi-three-dots"></i>
                                        </a>
                                        <!-- Card share action dropdown menu -->
                                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="cardNotiAction1">
                                          <li>
                                            <a class="dropdown-item" href="#">
                                              <i class="bi bi-trash fa-fw pe-2"></i>Delete</a>
                                          </li>
                                          <li>
                                            <a class="dropdown-item" href="#">
                                              <i class="bi bi-bell-slash fa-fw pe-2"></i>Turn off
                                            </a>
                                          </li>
                                          <li>
                                            <a class="dropdown-item" href="#">
                                              <i class="bi bi-volume-mute fa-fw fs-5 pe-2"></i>Mute Judy Nguyen
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>

                                    <!-- Notification action END -->
                                  </div>
                                </div>
                                <!-- Birthday END -->
                              </div>
                            </ng-template>
                          </ng-container>
                          <div class="col-sm-12">
                            <!-- Birthday START -->
                            <div class="d-flex align-items-center py-2">
                              <!-- Date -->
                              <p class="mb-0">
                                <i class="bi bi-telephone"></i> Phone:
                                <strong>
                                  {{ user?.phone.data[0]?.internationalNumber }}
                                </strong>
                              </p>
                              <div class="d-flex align-items-center ms-auto">
                                <app-privacy-button *ngIf="!!checkLoginID" [privacy]="user?.phone.data[0]?.privacy"
                                  (saveObject)="updatePhone($event)"></app-privacy-button>
                                <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                  <!-- Card share action menu -->
                                  <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="bi bi-three-dots"></i>
                                  </a>
                                  <!-- Card share action dropdown menu -->
                                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                    <li>
                                      <a class="dropdown-item" href="#">
                                        <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                    </li>
                                    <li>
                                      <a class="dropdown-item" href="#">
                                        <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>

                            </div>
                            <!-- Birthday END -->
                          </div>
                          <div class="col-sm-12">
                            <!-- Birthday START -->
                            <div class="d-flex align-items-center py-2">
                              <!-- Date -->
                              <p class="mb-0">
                                <i class="bi bi-envelope"></i>
                                {{ "data.email" | translate }}
                                <strong>{{ user?.email.data[0].name }} </strong>
                              </p>

                              <div class="ms-auto d-flex align-items-center">
                                <app-privacy-button *ngIf="!!checkLoginID"
                                  [privacy]="loggedUser?.email?.data[0]?.privacy"
                                  (saveObject)="updateEmail($event)"></app-privacy-button>
                                <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                  <!-- Card share action menu -->
                                  <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="bi bi-three-dots"></i>
                                  </a>
                                  <!-- Card share action dropdown menu -->
                                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                    <li>
                                      <a class="dropdown-item" [routerLink]="'../user_settings'">
                                        <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                    </li>

                                  </ul>
                                </div>
                              </div>

                            </div>
                            <!-- Birthday END -->
                          </div>
                        </form>
                        <!-- Settings END -->
                      </div>
                      <!-- Card body END -->
                    </div>
                    <!-- Account settings END -->

                    <!-- Card END -->
                  </div>
                  <!-- Account setting tab END -->

                  <!-- Notification tab START -->
                  <div class="tab-pane fade" id="nav-setting-tab-2">
                    <!-- Notification START -->
                    <div class="card">
                      <!-- Card header START -->
                      <div class="card-header border-0 pb-0">
                        <h5 class="card-title">
                          {{ "category.Career" | translate }}
                        </h5>
                      </div>
                      <!-- Card header START -->
                      <!-- Card body START -->
                      <div class="card-body">
                        <div class="col-sm-12 position-relative" *ngIf="!!checkLoginID">
                          <!-- Workplace on START -->
                          <a class="btn btn-dashed rounded w-100 text-align-start" (click)="
                              addWork({
                                edit: false,
                                model: user.cv[0],
                                career: false,
                                user: user,
                                indexOfLang: 0
                              })
                            ">
                            <i class="bi bi-plus-circle-dotted me-1"></i>{{ "category.AddCareer" | translate }}</a>
                          <!-- Workplace on END -->
                        </div>

                        <!-- Form settings START -->
                        <form class="row g-3">
                          <!-- First name -->
                          <ng-container *ngFor="
                              let careerObj of user.cv.data[0].careers[0]
                                .subCategories;
                              let i = index
                            ">
                            <div class="col-sm-12">
                              <div class="d-flex align-items-center py-2">
                                <!-- Date -->
                                <div class="mb-0 d-flex">
                                  <div class="avatar avatar-xs me-2">
                                    <a href="#"><img src="assets/images/avatar/03.jpg" alt=""
                                        class="avatar-img rounded-circle" /></a>
                                  </div>
                                  <div>
                                    <strong>{{ careerObj?.role }}
                                      {{ "at" | translate }}
                                      <app-education-name [careerObj]="careerObj" [careerObject]="true">
                                      </app-education-name>
                                    </strong>
                                    <br />
                                    <div class="d-flex align-items-center">
                                      <p class="mb-0 small me-2">
                                        {{
                                        careerObj.startDate | date : "MM.yyyy"
                                        }}
                                      </p>
                                      -
                                      <p class="mb-0 small me-2 ms-2" *ngIf="!careerObj.today">
                                        {{
                                        careerObj.endDate | date : "MM.yyyy"
                                        }}
                                      </p>
                                      <p class="mb-0 small ms-2" *ngIf="careerObj.today">
                                        {{ "present" | translate }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class="d-flex align-items-center ms-auto">

                                  <app-privacy-button *ngIf="!!checkLoginID" [privacy]="careerObj.privacy"
                                    (saveObject)="updateCareerObj($event, i)"></app-privacy-button>


                                  <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                    <!-- Card share action menu -->
                                    <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                      data-bs-toggle="dropdown" aria-expanded="false">
                                      <i class="bi bi-three-dots"></i>
                                    </a>
                                    <!-- Card share action dropdown menu -->
                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                      <li>
                                        <a class="dropdown-item" (click)="
                                          editCareer({
                                            edit: true,
                                            career: careerObj,
                                            model: user.cv[0],
                                            index: i,
                                            indexOfLang: 0
                                          })
                                        ">
                                          <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                      </li>
                                      <li>
                                        <a *ngIf="
                                          user.cv.data[0].careers[0].subCategories
                                            .length > 1
                                        " class="dropdown-item" (click)="deleteCareerSubCategory(i)">
                                          <i class="bi bi-trash fa-fw pe-2"></i>Delete</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>

                              <!-- Birthday END -->
                            </div>
                          </ng-container>
                        </form>
                        <!-- Settings END -->
                      </div>
                      <div class="card-header border-0 pb-0">
                        <h5 class="card-title">
                          {{ "category.Education" | translate }}
                        </h5>
                      </div>
                      <!-- Card header START -->
                      <!-- Card body START -->
                      <div class="card-body">
                        <div class="col-sm-12 position-relative" *ngIf="!!checkLoginID">
                          <!-- Workplace on START -->
                          <a class="btn btn-dashed rounded w-100 text-align-start" (click)="
                              addEducation({
                                edit: false,
                                model: user.cv[0],
                                languageIndex: 0
                              })
                            ">
                            <i class="bi bi-plus-circle-dotted me-1"></i>{{ "category.AddEducation" | translate }}</a>
                          <!-- Workplace on END -->
                        </div>
                        <!-- Form settings START -->
                        <form class="row g-3">
                          <!-- First name -->
                          <ng-container *ngFor="
                              let educationObj of user.cv.data[0].education[0]
                                .subCategories;
                              let i = index
                            ">
                            <div class="col-sm-12">
                              <div class="d-flex align-items-center py-2">
                                <!-- Date -->
                                <div class="mb-0 d-flex">
                                  <div class="avatar avatar-xs me-2">
                                    <a href="#"><img src="assets/images/avatar/03.jpg" alt=""
                                        class="avatar-img rounded-circle" /></a>
                                  </div>
                                  <div>
                                    <strong>{{ educationObj?.title }}
                                      {{ "at" | translate }}
                                      <app-education-name [educationObj]="educationObj" [educationObject]="true">
                                      </app-education-name>
                                    </strong>
                                    <br />
                                    <div class="d-flex align-items-center">
                                      <p class="mb-0 small me-2">
                                        {{
                                        educationObj.startDate
                                        | date : "MM.yyyy"
                                        }}
                                      </p>
                                      -
                                      <p class="mb-0 small me-2 ms-2" *ngIf="!educationObj.today">
                                        {{
                                        educationObj.endDate
                                        | date : "MM.yyyy"
                                        }}
                                      </p>
                                      <p class="mb-0 small ms-2" *ngIf="educationObj.today">
                                        {{ "present" | translate }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class="d-flex align-items-center ms-auto">


                                  <app-privacy-button *ngIf="!!checkLoginID" [privacy]="educationObj.privacy"
                                    (saveObject)="updateEducationObj($event, i)"></app-privacy-button>


                                  <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                    <!-- Card share action menu -->
                                    <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                      data-bs-toggle="dropdown" aria-expanded="false">
                                      <i class="bi bi-three-dots"></i>
                                    </a>
                                    <!-- Card share action dropdown menu -->
                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                      <li>
                                        <a class="dropdown-item" (click)="
                                          editEducation({
                                            edit: true,
                                            education: educationObj,
                                            model: user.cv.data[0],
                                            languageIndex: 0
                                          })
                                        ">
                                          <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                      </li>
                                      <li>
                                        <a class="dropdown-item" *ngIf="
                                          user.cv.data[0].education[0].subCategories
                                            .length > 1
                                        " (click)="deleteEducationSubCategory(i)">
                                          <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>

                              <!-- Birthday END -->
                            </div>
                          </ng-container>
                        </form>
                        <!-- Settings END -->
                      </div>
                      <!-- Card body END -->
                      <!-- Button save -->
                    </div>
                    <!-- Notification END -->
                  </div>
                  <!-- Notification tab END -->

                  <!-- Privacy and safety tab START -->
                  <div class="tab-pane fade" id="nav-setting-tab-3">
                    <!-- Privacy and safety START -->
                    <div class="card">
                      <!-- Card header START -->
                      <div class="card-header border-0 pb-0">
                        <h5 class="card-title">Places</h5>
                      </div>
                      <!-- Card header START -->
                      <!-- Card body START -->
                      <div class="card-body">
                        <!-- Privacy START -->
                        <ul class="list-group">
                          <!-- Privacy item -->
                          <div class="me-md-3">
                            <div class="mb-1 mt-1">
                              <div class="col-sm-12 position-relative">
                                <!-- Workplace on START -->
                                <a *ngIf="!cityAdd && !!checkLoginID"
                                  class="btn btn-dashed rounded w-100 text-align-start" (click)="cityAdd = !cityAdd">
                                  <i class="bi bi-plus-circle-dotted me-1"></i>Add City</a>
                                <!-- Workplace on END -->
                              </div>
                              <div class="tab-pane show active fade" id="addCity" *ngIf="cityAdd">
                                <div class="tab-pane show active fade" *ngIf="cityAdd">
                                  <input class="form-control" type="text" [ngModel]="searchForPage" (ngModelChange)="
                                      searchForPage = $event;
                                      searchForPageTrue = true
                                    " name="careerName" id="example-search-input5" autocomplete="off" />

                                  <ng-container *ngIf="searchForPageTrue">
                                    <div *ngFor="
                                        let page of pages
                                          | searchPage : searchForPage
                                      " class="card__list">
                                      <div class="d-flex row pr-2 pl-2 align-items-center"
                                        (click)="addCompanyCity(page)">
                                        <img alt="no-photo" ngx-gravatar [email]="'example@mail.com'" fallback="mp"
                                          size="35" />
                                        <span class="pr-2 pl-2">
                                          {{ page.name }}
                                          <span> </span>
                                        </span>
                                      </div>
                                    </div>
                                  </ng-container>
                                  <div class="col row col-sm-12 d-flex justify-content-start align-items-center">
                                    <div class="col-md-10">
                                      <label class="col-lg-3 me-2">{{ "Moved Date" }}
                                      </label>

                                      <input type="date" class="form-control col-lg-6" [ngModel]="
                                          cities.date | date : 'yyyy-MM-dd'
                                        " (ngModelChange)="cities.date = $event" name="movedDate" required />
                                    </div>
                                  </div>
                                  <div class="card-footer pt-0 text-end border-0 px-0">
                                    <button type="submit" class="btn btn-sm btn-primary mb-0 me-3"
                                      (click)="cityAdd = !cityAdd">
                                      {{ "cancel" | translate }}
                                    </button>

                                    <button type="submit" class="btn btn-sm btn-primary mb-0"
                                      (click)="addCity(); cityAdd = !cityAdd">
                                      {{ "save" | translate }}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <ng-container *ngIf="!!user?.places?.cities">
                              <ng-container *ngFor="let city of user?.places?.cities">
                                <div class="col-sm-12" *ngIf="!!city?.name">
                                  <!-- Birthday START -->
                                  <div class="d-flex align-items-center py-2">
                                    <!-- Date -->
                                    <div class="mb-0"></div>
                                    <div class="mb-0 d-flex">
                                      <ul class="navbar-nav navbar-nav-scrol">
                                        <li class="nav-item me-2">
                                          <a class="nav-link icon-md btn btn-light p-0">
                                            <i class="bi bi-geo-alt fs-6"></i>
                                          </a>
                                        </li>
                                      </ul>
                                      <div>
                                        <strong>
                                          <p>{{ city?.name }}</p>
                                        </strong>
                                        <p>
                                          Moved in
                                          {{ city?.date | date : "yyyy" }}
                                        </p>
                                      </div>
                                    </div>
                                    <div class="d-flex align-items-start ms-auto">

                                    <app-privacy-button *ngIf="!!checkLoginID"
                                    [privacy]="user?.places?.cities.privacy"
                                    (saveObject)="updateCitiesPrivacy($event)"></app-privacy-button>


                                    <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                      <!-- Card share action menu -->
                                      <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="bi bi-three-dots"></i>
                                      </a>
                                      <!-- Card share action dropdown menu -->
                                      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                        <li>
                                          <a class="dropdown-item" href="#">
                                            <i class="bi bi-pencil-square fa-fw pe-2"></i>Edit</a>
                                        </li>
                                        <li>
                                          <a class="dropdown-item" (click)="deleteNameCurrent()">
                                            <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                        </li>
                                      </ul>
                                    </div>
                                    </div>
                                  </div>
                                  <!-- Birthday END -->
                                </div>
                                <div class="col-sm-12" *ngIf="!!city?.pageID">
                                  <!-- Birthday START -->
                                  <div class="d-flex align-items-center py-2">
                                    <!-- Date -->
                                    <div class="mb-0 d-flex">
                                      <div class="avatar avatar-xs me-2">
                                        <a href="#"><img src="assets/images/avatar/03.jpg" alt=""
                                            class="avatar-img rounded-circle" /></a>
                                      </div>
                                      <div>
                                        <strong><app-education-name [hometown]="true"
                                            [hometownPageID]="city?.pageID"></app-education-name>
                                        </strong>
                                        <p class="small mb-0">
                                          Moved in
                                          {{ city?.date | date : "yyyy" }}
                                        </p>
                                      </div>
                                    </div>



                                    <div class="d-flex align-items-start ms-auto">

                                    <app-privacy-button *ngIf="!!checkLoginID"
                                    [privacy]="user?.places?.cities.privacy"
                                    (saveObject)="updateCitiesPrivacy($event)"></app-privacy-button>

                                    <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                      <!-- Card share action menu -->
                                      <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="bi bi-three-dots"></i>
                                      </a>
                                      <!-- Card share action dropdown menu -->
                                      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                        <li>
                                          <a class="dropdown-item" href="#">
                                            <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                        </li>
                                        <li>
                                          <a class="dropdown-item" (click)="deletePageCurrent()">
                                            <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                        </li>
                                      </ul>
                                    </div>
                                    </div>
                                  </div>
                                  <!-- Birthday END -->
                                </div>
                              </ng-container>
                            </ng-container>

                            <div class="mb-1 mt-1" *ngIf="
                                !user?.places?.current?.pageID &&
                                !user?.places?.current?.name
                              ">
                              <div class="col-sm-12 position-relative">
                                <!-- Workplace on START -->
                                <a *ngIf="!addressAdd && !!checkLoginID"
                                  class="btn btn-dashed rounded w-100 text-align-start"
                                  (click)="addressAdd = !addressAdd">
                                  <i class="bi bi-plus-circle-dotted me-1"></i>Add Current Addres</a>
                                <!-- Workplace on END -->
                              </div>
                              <div class="tab-pane show active fade" *ngIf="addressAdd">
                                <input class="form-control" type="text" [ngModel]="searchForPage" (ngModelChange)="
                                    searchForPage = $event;
                                    searchForPageTrue = true
                                  " name="careerName" id="example-search-input5" autocomplete="off" />

                                <ng-container *ngIf="searchForPageTrue">
                                  <div *ngFor="
                                      let page of pages
                                        | searchPage : searchForPage
                                    " class="card__list">
                                    <div class="d-flex row pr-2 pl-2 align-items-center"
                                      (click)="addCompanyAddress(page)">
                                      <img alt="no-photo" ngx-gravatar [email]="'example@mail.com'" fallback="mp"
                                        size="35" />
                                      <span class="pr-2 pl-2">
                                        {{ page.name }}
                                        <span> </span>
                                      </span>
                                    </div>
                                  </div>
                                </ng-container>
                                <div class="card-footer pt-0 text-end border-0 px-0">
                                  <button type="submit" class="btn btn-sm btn-primary mb-0 me-3"
                                    (click)="addressAdd = !addressAdd">
                                    {{ "cancel" | translate }}
                                  </button>

                                  <button type="submit" class="btn btn-sm btn-primary mb-0" (click)="
                                      addAddres(); addressAdd = !addressAdd
                                    ">
                                    {{ "save" | translate }}
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12" *ngIf="!!user?.places?.current?.pageID">
                              <!-- Birthday START -->
                              <div class="d-flex align-items-center py-2">
                                <!-- Date -->
                                <div class="mb-0 d-flex">
                                  <div class="avatar avatar-xs me-2">
                                    <a href="#"><img src="assets/images/avatar/03.jpg" alt=""
                                        class="avatar-img rounded-circle" /></a>
                                  </div>
                                  <div>
                                    <strong>
                                      <app-education-name [hometown]="true" [hometownPageID]="
                                          user?.places?.current?.pageID
                                        ">
                                      </app-education-name>
                                      <p *ngIf="user?.places?.current?.name">
                                        {{ user?.places?.current?.name }}
                                      </p>
                                    </strong>
                                    <p class="small mb-0">
                                      {{ "Current city" }}
                                    </p>
                                  </div>
                                </div>

                                <div class="d-flex align-items-start ms-auto">

                                  <app-privacy-button *ngIf="!!checkLoginID"
                                  [privacy]="user?.places?.cities.privacy"
                                  (saveObject)="updateCitiesPrivacy($event)"></app-privacy-button>

                                <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                  <!-- Card share action menu -->
                                  <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="bi bi-three-dots"></i>
                                  </a>
                                  <!-- Card share action dropdown menu -->
                                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                    <li>
                                      <a class="dropdown-item" href="#">
                                        <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                    </li>
                                    <li>
                                      <a class="dropdown-item" (click)="deletePageCurrent()">
                                        <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              </div>
                              <!-- Birthday END -->
                            </div>
                            <div class="col-sm-12" *ngIf="!!user?.places?.current?.name">
                              <!-- Birthday START -->
                              <div class="d-flex align-items-center py-2">
                                <div class="mb-0 d-flex">
                                  <ul class="navbar-nav navbar-nav-scrol">
                                    <li class="nav-item me-2">
                                      <a class="nav-link icon-md btn btn-light p-0">
                                        <i class="bi bi-geo-alt fs-6"></i>
                                      </a>
                                    </li>
                                  </ul>
                                  <div>
                                    <strong>
                                      <p>{{ user?.places?.current?.name }}</p>
                                    </strong>
                                    <p class="small mb-0">
                                      {{ "Current city" }}
                                    </p>
                                  </div>
                                </div>
                                <div class="d-flex align-items-start ms-auto">

                                  <app-privacy-button *ngIf="!!checkLoginID"
                                  [privacy]="user?.places?.current.privacy"
                                  (saveObject)="updateCitiesPrivacy($event)"></app-privacy-button>

                                <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                  <!-- Card share action menu -->
                                  <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="bi bi-three-dots"></i>
                                  </a>
                                  <!-- Card share action dropdown menu -->
                                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                    <li>
                                      <a class="dropdown-item" href="#">
                                        <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                    </li>
                                    <li>
                                      <a class="dropdown-item" (click)="deleteNameCurrent()">
                                        <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                    </li>
                                  </ul>
                                </div>
                                </div>
                              </div>
                              <!-- Birthday END -->
                            </div>
                            <ng-container>
                              <div class="mb-1 mt-1" *ngIf="
                                  !user?.places?.hometown?.pageID &&
                                  !user?.places?.hometown?.name
                                ">
                                <div class="col-sm-12 position-relative">
                                  <!-- Workplace on START -->
                                  <a *ngIf="!hometownAdd && !!checkLoginID"
                                    class="btn btn-dashed rounded w-100 text-align-start"
                                    (click)="hometownAdd = !hometownAdd">
                                    <i class="bi bi-plus-circle-dotted me-1"></i>Add Hometown</a>
                                  <!-- Workplace on END -->
                                </div>
                                <div class="tab-pane show active fade" id="addHometowm" *ngIf="hometownAdd">
                                  <input class="form-control" type="text" [ngModel]="searchForPage" (ngModelChange)="
                                      searchForPage = $event;
                                      searchForPageTrue = true
                                    " name="careerName" id="example-search-input5" autocomplete="off" />

                                  <ng-container *ngIf="searchForPageTrue">
                                    <div *ngFor="
                                        let page of pages
                                          | searchPage : searchForPage
                                      " class="card__list">
                                      <div class="d-flex row pr-2 pl-2 align-items-center" (click)="addCompany(page)">
                                        <img alt="no-photo" ngx-gravatar [email]="'example@mail.com'" fallback="mp"
                                          size="35" />
                                        <span class="pr-2 pl-2">
                                          {{ page.name }}
                                          <span> </span>
                                        </span>
                                      </div>
                                    </div>
                                  </ng-container>
                                  <div class="card-footer pt-0 text-end border-0 px-0">
                                    <button type="submit" class="btn btn-sm btn-primary mb-0 me-3"
                                      (click)="hometownAdd = !hometownAdd">
                                      {{ "cancel" | translate }}
                                    </button>

                                    <button type="submit" class="btn btn-sm btn-primary mb-0" (click)="
                                        addHomeTown();
                                        hometownAdd = !hometownAdd
                                      ">
                                      {{ "save" | translate }}
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <!-- First name -->
                              <div class="col-sm-12" *ngIf="!!user?.places?.hometown?.pageID">
                                <!-- Birthday START -->
                                <div class="d-flex align-items-center py-2">
                                  <!-- Date -->
                                  <div class="mb-0 d-flex">
                                    <div class="avatar avatar-xs me-2">
                                      <a href="#"><img src="assets/images/avatar/03.jpg" alt=""
                                          class="avatar-img rounded-circle" /></a>
                                    </div>
                                    <div>
                                      <strong><app-education-name [hometown]="true" [hometownPageID]="
                                            user?.places?.hometown?.pageID
                                          "></app-education-name>
                                      </strong>
                                      <p class="small mb-0">{{ "Hometown" }}</p>
                                    </div>
                                  </div>
                                  <div class="d-flex align-items-start ms-auto">

                                    <app-privacy-button *ngIf="!!checkLoginID"
                                    [privacy]="user?.places?.current.privacy"
                                    (saveObject)="updateCurrentPlacePrivacy($event)"></app-privacy-button>
                                  <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                    <!-- Card share action menu -->
                                    <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                      data-bs-toggle="dropdown" aria-expanded="false">
                                      <i class="bi bi-three-dots"></i>
                                    </a>
                                    <!-- Card share action dropdown menu -->
                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                      <li>
                                        <a class="dropdown-item">
                                          <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                      </li>
                                      <li>
                                        <a class="dropdown-item" (click)="deletePage()">
                                          <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                      </li>
                                    </ul>
                                  </div>
                                  </div>
                                </div>
                                <!-- Birthday END -->
                              </div>
                              <div class="col-sm-12" *ngIf="!!user?.places?.hometown?.name">
                                <!-- Birthday START -->
                                <div class="d-flex align-items-center py-2">
                                  <!-- Date -->
                                  <div class="mb-0 d-flex">
                                    <ul class="navbar-nav navbar-nav-scrol">
                                      <li class="nav-item me-2">
                                        <a class="nav-link icon-md btn btn-light p-0">
                                          <i class="bi bi-geo-alt fs-6"></i>
                                        </a>
                                      </li>
                                    </ul>
                                    <div>
                                      <strong>{{
                                        user?.places?.hometown.name
                                        }}</strong>
                                      <p class="small mb-0">{{ "Hometown" }}</p>
                                    </div>
                                  </div>

                                  <div class="d-flex align-items-start ms-auto">

                                    <app-privacy-button *ngIf="!!checkLoginID"
                                    [privacy]="user?.places?.hometown.privacy"
                                    (saveObject)="updateCitiesPrivacy($event)"></app-privacy-button>

                                  <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                    <!-- Card share action menu -->
                                    <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                      data-bs-toggle="dropdown" aria-expanded="false">
                                      <i class="bi bi-three-dots"></i>
                                    </a>
                                    <!-- Card share action dropdown menu -->
                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                      <li>
                                        <a class="dropdown-item" href="#">
                                          <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                      </li>
                                      <li>
                                        <a class="dropdown-item" (click)="deleteNameOfHomeTown()">
                                          <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                      </li>
                                    </ul>
                                  </div>
                                  </div>
                                </div>
                                <!-- Birthday END -->
                              </div>
                            </ng-container>
                          </div>
                        </ul>
                        <!-- Privacy END -->
                      </div>
                      <!-- Card body END -->
                      <!-- Button save -->
                    </div>
                    <!-- Privacy and safety END -->
                  </div>
                  <!-- Privacy and safety tab END -->

                  <!-- Communications tab START -->
                  <div class="tab-pane fade" id="nav-setting-tab-4">
                    <!-- Communications START -->
                    <div class="card">
                      <!-- Title START -->
                      <div class="card-header border-0 pb-0">
                        <h5 class="card-title">
                          {{ "userProfile.contactInfo" | translate }}
                        </h5>
                      </div>
                      <!-- Title START -->
                      <!-- Card body START -->
                      <div class="card-body">
                        <!-- Accordion START -->
                        <form class="row g-3">
                          <div class="col-sm-12">
                            <!-- Birthday START -->
                            <ng-container *ngFor="let phone of user?.phone.data; let i = index">

                            <div class="d-flex px-0 py-2">
                                <p class="mb-0">
                                  <i class="bi bi-telephone"></i> Phone:
                                  <strong>
                                    {{ user?.phone.data[0]?.internationalNumber }}
                                  </strong>
                                </p>
                              <!-- Date -->


                              <div class="d-flex align-items-start ms-auto">

                                <app-privacy-button *ngIf="!!checkLoginID"
                                [privacy]="phone.privacy"
                                (saveObject)="updatePhonePrivacy($event, i)"></app-privacy-button>

                              <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                <!-- Card share action menu -->
                                <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                  data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="bi bi-three-dots"></i>
                                </a>
                                <!-- Card share action dropdown menu -->
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                  <li>
                                    <a class="dropdown-item" [routerLink]="'../user_settings'">
                                      <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                  </li>

                                </ul>
                              </div>

                              </div>

                            </div>
                          </ng-container>

                            <!-- Birthday END -->
                          </div>
                          <!-- First name -->
                          <div class="col-sm-12">
                            <ng-container *ngFor="let email of user?.email.data; let i = index">

                            <!-- Birthday START -->
                            <div class="d-flex align-items-center px-0 py-2">
                              <!-- Date -->
                              <p class="mb-0">
                                <i class="bi bi-envelope"></i>
                                {{ "data.email" | translate }}
                                <strong>{{ email.name }} </strong>
                              </p>

                              <div class="d-flex align-items-start ms-auto">

                                <app-privacy-button *ngIf="!!checkLoginID"
                                [privacy]="email?.privacy"
                                (saveObject)="updateEmailPrivacy($event, i)"></app-privacy-button>

                              <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                <!-- Card share action menu -->
                                <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                  data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="bi bi-three-dots"></i>
                                </a>
                                <!-- Card share action dropdown menu -->
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                  <li>
                                    <a class="dropdown-item" [routerLink]="'../user_settings'">
                                      <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                  </li>

                                </ul>
                              </div>
                              </div>
                            </div>
                            </ng-container>
                            <!-- Birthday END -->
                          </div>
                        </form>
                        <!-- Accordion END -->
                      </div>

                      <div class="card-header border-0 pb-0">
                        <h5 class="card-title">
                          {{ "Websites and social links" }}
                        </h5>
                      </div>
                      <div class="card-body">
                        <div class="row g-3">
                          <div class="col-sm-12">
                            <div class="d-flex">
                              <div class="col-sm-12 position-relative">
                                <!-- Workplace on START -->

                                <a *ngIf="!!checkLoginID" class="btn btn-dashed rounded w-100 text-align-start"
                                  (click)="addWebsites()">
                                  <i class="bi bi-plus-circle-dotted me-1"></i>{{ "Add a Website" }}</a>
                                <ng-container *ngIf="newWebsite">
                                  <div class="col-sm-12 d-flex">
                                    <div class="col-sm-12">
                                      <input type="text" class="form-control" placeholder="Add Website Url"
                                        [(ngModel)]="addWebsiteUrl" />
                                    </div>
                                  </div>
                                  <div class="flex items-center" style="
                                      flex-direction: row;
                                      display: flex;
                                      border: none;
                                    " class="specialty col-12 mt-1">
                                    <button class="btn btn-light me-2" type="submit" (click)="saveWebsiteUrl()">
                                      <i class="bi bi-check"></i>
                                    </button>
                                  </div>
                                </ng-container>
                                <ng-container *ngFor="let website of user.websites?.urls; let i = index">
                                  <div class="col-sm-12">
                                    <ng-container *ngIf="isEditingWebsite === i; else readonlyWebsite">
                                      <div class="col-sm-12 d-flex">
                                        <div class="col-sm-12">
                                          <input type="text" class="form-control" placeholder="Add Website Url"
                                            [(ngModel)]="website.url" />
                                        </div>
                                      </div>
                                      <div class="flex items-center" style="
                                            flex-direction: row;
                                            display: flex;
                                            border: none;
                                          " class="specialty col-12 mt-1">
                                        <button class="btn btn-light me-2" type="submit"
                                          (click)="updateWebsite(website, i)">
                                          <i class="bi bi-check"></i>
                                        </button>
                                      </div>
                                    </ng-container>
                                    <ng-template #readonlyWebsite>
                                      <div class="d-flex align-items-center px-0 py-2">
                                        <!-- Date -->
                                        <div></div>

                                        <div class="ms-2">
                                          <a class="btn-link" stopPropagation *ngIf="!!website.url"
                                            (click)="openLink(website.url)">
                                            {{ website.url }}
                                          </a>

                                          <br />
                                          <div class="d-flex align-items-center">
                                            <p class="mb-0 small me-2">
                                              {{ "Website" }}
                                            </p>
                                          </div>
                                        </div>

                                        <div class="d-flex align-items-start ms-auto">

                                          <app-privacy-button *ngIf="!!checkLoginID"
                                          [privacy]="user.websites?.privacy"
                                          (saveObject)="updateWebsitePrivacy($event)"></app-privacy-button>



                                        <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                          <!-- Card share action menu -->
                                          <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="bi bi-three-dots"></i>
                                          </a>
                                          <!-- Card share action dropdown menu -->
                                          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                            <li>
                                              <a class="dropdown-item" (click)="editWebsite(i)">
                                                <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate
                                                }}</a>
                                            </li>
                                            <li>
                                              <a class="dropdown-item" (click)="deleteWebsite(i)">
                                                <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      </div>
                                    </ng-template>

                                  </div>
                                </ng-container>

                                <a *ngIf="!!checkLoginID" class="btn btn-dashed rounded w-100 text-align-start"
                                  (click)="addSocialLinks()">
                                  <i class="bi bi-plus-circle-dotted me-1"></i>{{ "Add Social Links" }}</a>
                                <!-- Workplace on END -->
                              </div>
                            </div>
                          </div>

                          <ng-container *ngIf="newSocialLink">
                            <div class="col-sm-12 d-flex">
                              <div class="col-sm-9">
                                <input type="text" class="form-control" placeholder="Add username"
                                  [(ngModel)]="addSocialLinkUrl" />
                              </div>
                              <div class="col-sm-3">
                                <select name="selectedSocialLink" id="selectedSocialLink" [ngModel]="socialLinks?.id"
                                  (ngModelChange)="changeSocialLink($event)" class="col-md-12 form-control mb-2">
                                  <option class="dropdown-item" disabled selected value="undefined">Select</option>

                                  <option *ngFor="
                                      let socialLink of arrayService.socialLinks
                                    " [ngValue]="socialLink.id" [selected]="socialLink">
                                    {{ socialLink.description }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="flex items-center" style="
                                flex-direction: row;
                                display: flex;
                                border: none;
                              " class="specialty col-12 mt-1">
                              <button class="btn btn-light me-2" type="submit" (click)="saveSocialLink()">
                                <i class="bi bi-check"></i>
                              </button>
                            </div>
                          </ng-container>
                          <ng-container *ngFor="
                              let socialLink of user.socialLinks?.links;
                              let i = index
                            ">
                            <div class="col-sm-12">
                              <ng-container *ngIf="isEditingSocialLink === i; else readOnlySocialLink">

                                <div class="col-sm-12 d-flex">
                                  <div class="col-sm-9">
                                    <input type="text" class="form-control" [(ngModel)]="socialLink.url" />
                                  </div>
                                  <div class="col-sm-3">
                                    <select name="selectedSocialLink" id="selectedSocialLink"
                                      [(ngModel)]="socialLink.id" class="col-md-12 form-control mb-2">
                                      <option *ngFor="
                                            let socialLink of arrayService.socialLinks
                                          " [ngValue]="socialLink.id" [selected]="socialLink">
                                        {{ socialLink.description }}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div class="flex items-center" style="
                                      flex-direction: row;
                                      display: flex;
                                      border: none;
                                    " class="specialty col-12 mt-1">
                                  <button class="btn btn-light me-2" type="submit"
                                    (click)="updateSocialLink(socialLink, i)">
                                    <i class="bi bi-check"></i>
                                  </button>
                                </div>
                              </ng-container>
                              <ng-template #readOnlySocialLink>
                                <div class="d-flex align-items-center px-0 py-2">
                                  <!-- Date -->
                                  <div>
                                    <i *ngIf="!!socialLink.id" [class]="
                                          getSocialLinkIcons(socialLink.id).icon +
                                          ' mr-1'
                                        " style="font-size: 32px"></i>
                                  </div>

                                  <div class="ms-2">
                                    <a target="_blank" *ngIf="
                                          !!socialLink.url &&
                                          getSocialLinkIcons(socialLink?.id)?.url
                                        " [href]="
                                          getSocialLinkIcons(socialLink?.id)?.url +
                                          socialLink?.url
                                        ">
                                      {{ socialLink?.url }}
                                    </a>
                                    <span *ngIf="
                                          !!socialLink.url &&
                                          !getSocialLinkIcons(socialLink?.id)?.url
                                        ">
                                      {{ socialLink?.url }}
                                    </span>

                                    <br />
                                    <div class="d-flex align-items-center">
                                      <p class="mb-0 small me-2">
                                        {{
                                        getSocialLinkIcons(socialLink.id)
                                        ?.description
                                        }}
                                      </p>
                                    </div>
                                  </div>

                                  <div class="d-flex align-items-start ms-auto">

                                    <app-privacy-button *ngIf="!!checkLoginID"
                                    [privacy]="socialLink?.privacy"
                                    (saveObject)="updateSocialLinksPrivacy($event, i)"></app-privacy-button>

                                  <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                    <!-- Card share action menu -->
                                    <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                      data-bs-toggle="dropdown" aria-expanded="false">
                                      <i class="bi bi-three-dots"></i>
                                    </a>
                                    <!-- Card share action dropdown menu -->
                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                      <li>
                                        <a class="dropdown-item" (click)="editSocialLink(i)">
                                          <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                      </li>
                                      <li>
                                        <a class="dropdown-item" (click)="deleteSocialLink(i)">
                                          <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                      </li>
                                    </ul>
                                  </div>
                                  </div>
                                </div>
                              </ng-template>

                            </div>
                          </ng-container>
                        </div>
                      </div>
                      <div class="card-header border-0 pb-0">
                        <h5 class="card-title">
                          {{ "userProfile.basicInfo" | translate }}
                        </h5>
                      </div>
                      <!-- Title START -->
                      <!-- Card body START -->
                      <div class="card-body">
                        <!-- Accordion START -->
                        <form class="row g-3">
                          <div class="col-sm-12">
                            <!-- Birthday START -->
                            <div class="d-flex align-items-center px-0 py-2">
                              <!-- Date -->
                              <p class="mb-0">
                                <i class="bi bi-person"></i>{{ "userProfile.gender" | translate }}
                                <strong> {{ getGender | translate }} </strong>
                              </p>




                              <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                <!-- Card share action menu -->
                                <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                  data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="bi bi-three-dots"></i>
                                </a>
                                <!-- Card share action dropdown menu -->
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                  <li>
                                    <a class="dropdown-item" [routerLink]="'../user_settings'">
                                      <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                  </li>

                                </ul>
                              </div>
                            </div>
                            <!-- Birthday END -->
                          </div>
                          <!-- First name -->
                          <div class="col-sm-12">
                            <!-- Birthday START -->
                            <div class="d-flex align-items-center px-0 py-2">
                              <!-- Date -->
                              <p class="mb-0">
                                <i class="fa-regular fa-cake-candles"></i> Born:
                                <strong>{{user?.birthday.date| date: "MMMM dd yyyy"}}
                                </strong>
                              </p>

                              <div class="d-flex align-items-start ms-auto">

                                <app-privacy-button *ngIf="!!checkLoginID"
                                [privacy]="user?.birthday.privacy"
                                (saveObject)="updateBirthdayPrivacy($event, i)"></app-privacy-button>
                              <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                <!-- Card share action menu -->
                                <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                  data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="bi bi-three-dots"></i>
                                </a>
                                <!-- Card share action dropdown menu -->
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                  <li>
                                    <a class="dropdown-item" [routerLink]="'../user_settings'">
                                      <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                  </li>

                                </ul>
                              </div>
                              </div>
                            </div>
                            <!-- Birthday END -->
                          </div>
                          <div class="col-sm-12">
                            <!-- Birthday START -->
                            <div class="d-flex align-items-center px-0 py-2">
                              <!-- Date -->
                              <p class="mb-0" *ngIf="!!user.religion.pageID">
                                <i class="bi bi-hand-index"></i>
                                {{ "religionName" | translate }}:
                                <strong>{{ religion.description | translate }} -
                                  <app-education-name [religion]="true" [religionPageID]="user?.religion?.pageID">
                                  </app-education-name>
                                </strong>
                              </p>
                              <p class="mb-0" *ngIf="!user.religion.pageID">
                                <i class="bi bi-hand-index"></i>
                                {{ "religionName" | translate }}:
                                <strong>{{ religion.description | translate }}
                                </strong>
                              </p>

                              <div class="d-flex align-items-start ms-auto">

                                <app-privacy-button *ngIf="!!checkLoginID"
                                [privacy]="user?.religion.privacy"
                                (saveObject)="updateReligionPrivacy($event, i)"></app-privacy-button>

                              <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                <!-- Card share action menu -->
                                <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                  data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="bi bi-three-dots"></i>
                                </a>
                                <!-- Card share action dropdown menu -->
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                  <li>
                                    <a class="dropdown-item" (click)="addReligion({ user: user })">
                                      <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                  </li>
                                  <li>
                                    <a class="dropdown-item">
                                      <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                  </li>
                                </ul>
                              </div>
                              </div>
                            </div>
                            <!-- Birthday END -->
                          </div>
                        </form>
                        <!-- Accordion END -->
                      </div>
                      <!-- Card body END -->
                      <!-- Button save -->
                    </div>
                    <!-- Communications  END -->
                  </div>
                  <!-- Communications tab END -->

                  <!-- Messaging tab START -->
                  <div class="tab-pane fade" id="nav-setting-tab-5">
                    <!-- Messaging privacy START -->
                    <div class="card mb-4">
                      <!-- Title START -->
                      <div class="card-header border-0 pb-0">
                        <h5 class="card-title">
                          {{ "relationsship.name" | translate }}
                        </h5>
                      </div>
                      <!-- Title START -->
                      <div class="card-body pt-1">
                        <ul class="list-group">
                          <div class="me-md-3">
                            <div class="col-sm-12 position-relative" *ngIf="!user?.relationship?.status">
                              <a class="btn btn-dashed rounded w-100 text-align-start" (click)="addRelationShip()">
                                <i class="bi bi-plus-circle-dotted me-1"></i>Add
                                Relationship</a>
                            </div>
                            <ng-container *ngIf="!!user?.relationship?.status">
                              <ng-container *ngIf="
                                  isEditingRelationship;
                                  else readOnlyRelationship
                                ">
                                <div class="col-sm-12">
                                  <!-- Birthday START -->
                                  <div class="d-flex align-items-center px-0 py-2 col-lg-12">
                                    <!-- Date -->
                                    <form name="ngForm" #relationshipForm="ngForm" class="col-lg-12" (ngSubmit)="
                                        saveUserRelationship(relationshipForm)
                                      ">
                                      <div class="col-lg-12">
                                        <select name="relationshipStatus" id="selectedValue"
                                          [(ngModel)]="user.relationship.status" class="col-md-12 form-control mb-2"
                                          #relationshipStatus="ngModel" (ngModelChange)="
                                            changeRelationship($event)
                                          " [ngClass]="{
                                            'is-invalid':
                                              relationshipForm.submitted &&
                                              relationshipStatus.invalid
                                          }" required>
                                          <option *ngFor="
                                              let relationship of arrayService.relationsship
                                            " [ngValue]="relationship.id" [selected]="relationship">
                                            {{
                                            relationship.description
                                            | translate
                                            }}
                                          </option>
                                        </select>
                                        <ng-container *ngIf="user.relationship.status !== 1">
                                          <ng-container *ngIf="
                                              !!user?.relationship?.user?.id
                                            ">
                                            <app-education-name [relationshipUser]="true" (removeUserFromRelationship)="
                                                removeUser()
                                              " [relationshipUserID]="
                                                user?.relationship?.user?.id
                                              "></app-education-name>
                                          </ng-container>
                                          <ng-container *ngIf="
                                              !user?.relationship?.user?.id
                                            ">
                                            <input class="form-control mb-2" type="text" name="searchForUser"
                                              [ngModel]="searchForUser" (ngModelChange)="
                                                searchForUser = $event;
                                                searchForUserTrue = true
                                              " autocomplete="off" />
                                          </ng-container>

                                          <ng-container *ngIf="searchForUserTrue">
                                            <div *ngFor="
                                                let userObject of usersArray
                                                  | searchUser : searchForUser
                                              " class="card__list">
                                              <div class="d-flex row pr-2 pl-2 align-items-center" (click)="
                                                  addSearchedUser(userObject)
                                                ">
                                                <img alt="no-photo" ngx-gravatar [email]="'example@mail.com'"
                                                  fallback="mp" size="35" />
                                                <span class="pr-2 pl-2">
                                                  {{ userObject?.firstName }}
                                                  {{ userObject?.lastName }}
                                                  <span> </span>
                                                </span>
                                              </div>
                                            </div>
                                          </ng-container>
                                        </ng-container>
                                        <input *ngIf="user.relationship.status !== 1" type="date"
                                          name="relationshipDate" #relationshipDate="ngModel"
                                          [ngModel]="user?.relationship?.date" (ngModelChange)="
                                            (user?.relationship?.date ===
                                              $event)
                                          " class="form-control col-sm-4" />

                                        <div class="flex items-center" style="
                                            flex-direction: row;
                                            display: flex;
                                            border: none;
                                          " class="specialty col-12 px-0 mt-1">
                                          <button class="btn btn-light me-2" type="submit">
                                            <i class="bi bi-check"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                  <!-- Birthday END -->
                                </div>
                              </ng-container>
                              <ng-template #readOnlyRelationship>
                                <div class="col-sm-12">
                                  <div class="d-flex align-items-center px-0 py-2" *ngIf="
                                      !user?.relationship?.user?.status &&
                                      !!user?.relationship?.status
                                    ">
                                    <!-- Date -->
                                    <p class="mb-0">
                                      <i class="bi bi-heart-fill"></i>
                                      <strong>
                                        {{
                                        relationshipStatus?.description
                                        | translate
                                        }}
                                        <ng-container *ngIf="!!user.relationship?.user?.id">
                                          <app-education-name [isStatusReceive]="true" [userID]="
                                              user.relationship?.user?.id
                                            "></app-education-name>
                                        </ng-container>
                                      </strong>
                                    </p>
                                    <div class="d-flex align-items-center ms-auto">

                                      <app-privacy-button *ngIf="!!checkLoginID" [privacy]="user.relationship.privacy" (saveObject)="updateRelationship($event)"></app-privacy-button>

                                    <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                      <!-- Card share action menu -->
                                      <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="bi bi-three-dots"></i>
                                      </a>
                                      <!-- Card share action dropdown menu -->
                                      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                        <li>
                                          <a class="dropdown-item" (click)="editRelationship()">
                                            <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                        </li>
                                        <li>
                                          <a class="dropdown-item" (click)="deleteRelationship()">
                                            <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                        </li>
                                      </ul>
                                    </div>
                                    </div>
                                  </div>
                                  <!-- Birthday START -->
                                  <div class="d-flex align-items-center px-0 py-2" *ngIf="
                                      user?.relationship?.user?.status === 2
                                    ">
                                    <!-- Date -->
                                    <p class="mb-0">
                                      <i class="bi bi-heart-fill"></i>
                                      <strong>
                                        {{
                                        relationshipStatus?.description
                                        | translate
                                        }}
                                        <app-education-name [isStatusReceive]="true"
                                          [userID]="user.relationship?.user?.id"></app-education-name>
                                      </strong>
                                    </p>
                                    <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                      <!-- Card share action menu -->
                                      <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="bi bi-three-dots"></i>
                                      </a>
                                      <!-- Card share action dropdown menu -->
                                      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                        <li>
                                          <a class="dropdown-item" (click)="editRelationship()">
                                            <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                        </li>
                                        <li>
                                          <a class="dropdown-item" (click)="deleteRelationship()">
                                            <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="d-flex align-items-center px-0 py-2" *ngIf="
                                      user?.relationship?.user?.status === 1
                                    ">
                                    <!-- Date -->
                                    <p class="mb-0">
                                      <i class="bi bi-heart-fill"></i>
                                      <strong>
                                        {{
                                        relationshipStatus?.description
                                        | translate
                                        }}

                                        <app-education-name [isStatusReceive]="true"
                                          [userID]="user.relationship?.user?.id"></app-education-name></strong>
                                    </p>
                                    <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                      <!-- Card share action menu -->
                                      <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="bi bi-three-dots"></i>
                                      </a>
                                      <!-- Card share action dropdown menu -->
                                      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                        <li>
                                          <a class="dropdown-item" (click)="editRelationship()">
                                            <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                        </li>
                                        <li>
                                          <a class="dropdown-item" (click)="deleteRelationship()">
                                            <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="rounded badge-unread d-sm-flex border-0 mb-1 p-3 position-relative" *ngIf="
                                      user?.relationship?.user?.status === 0
                                    ">
                                    <!-- Avatar -->
                                    <div class="avatar text-center">
                                      <img class="avatar-img rounded-circle" src="assets/images/avatar/01.jpg" alt="" />
                                    </div>
                                    <!-- Info -->
                                    <div class="mx-sm-3 my-2 my-sm-0">
                                      <p class="small mb-2">
                                        <b><app-education-name [isStatusReceive]="true" [userID]="
                                              user.relationship?.user?.id
                                            "></app-education-name></b>
                                        added you to
                                        {{
                                        relationshipStatus?.description
                                        | translate
                                        }}
                                      </p>
                                      {{ "status 0" }}
                                      <!-- Button -->
                                      <div class="d-flex">
                                        <button class="btn btn-sm py-1 btn-primary me-2" (click)="acceptRelationship()">
                                          Accept
                                        </button>
                                        <button class="btn btn-sm py-1 btn-danger-soft">
                                          Delete
                                        </button>
                                      </div>
                                    </div>
                                    <!-- Action -->
                                    <div class="d-flex ms-auto">
                                      <p class="small me-5 text-nowrap">
                                        Just now
                                      </p>
                                      <!-- Notification action START -->
                                      <div class="dropdown position-absolute end-0 top-0 mt-3 me-3">
                                        <a href="#" class="z-index-1 text-secondary btn position-relative py-0 px-2"
                                          id="cardNotiAction1" data-bs-toggle="dropdown" aria-expanded="false">
                                          <i class="bi bi-three-dots"></i>
                                        </a>
                                        <!-- Card share action dropdown menu -->
                                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="cardNotiAction1">
                                          <li>
                                            <a class="dropdown-item" href="#">
                                              <i class="bi bi-trash fa-fw pe-2"></i>Delete</a>
                                          </li>
                                          <li>
                                            <a class="dropdown-item" href="#">
                                              <i class="bi bi-bell-slash fa-fw pe-2"></i>Turn off
                                            </a>
                                          </li>
                                          <li>
                                            <a class="dropdown-item" href="#">
                                              <i class="bi bi-volume-mute fa-fw fs-5 pe-2"></i>Mute Judy Nguyen
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                      <!-- Notification action END -->
                                    </div>
                                  </div>
                                  <!-- Birthday END -->
                                </div>
                              </ng-template>
                            </ng-container>
                          </div>
                        </ul>
                        <!-- Settings style START -->

                        <!-- Message END -->
                      </div>
                      <div class="card-header border-0 pb-0">
                        <h5 class="card-title">
                          {{ "familyMembers.name" | translate }}
                        </h5>
                      </div>
                      <div class="card-body pt-1">
                        <div class="list group">
                          <div class="me-md-3">
                            <a *ngIf="!!checkLoginID" class="btn btn-dashed rounded w-100 text-align-start"
                              (click)="addFamilymember()"><i class="bi bi-plus-circle-dotted me-1"></i>Add
                              Family Member</a>
                          </div>
                          <ng-container *ngIf="newFamilyMember">
                            <div class="col-sm-12">
                              <div class="d-flex align-items-center px-0 py-2 col-lg-12">
                                <form name="ngForm" #familyMember="ngForm" class="col-lg-12"
                                  (ngSubmit)="saveNewFamilyMember(familyMember)">
                                  <div class="col-lg-12">
                                    <input class="form-control mb-2" type="text" name="searchForUser"
                                      [ngModel]="searchForUser" (ngModelChange)="
                                        searchForUser = $event;
                                        searchForUserTrue = true
                                      " autocomplete="off" />
                                    <ng-container *ngIf="searchForUserTrue">
                                      <div *ngFor="
                                          let userObject of usersArray
                                            | searchUser : searchForUser
                                        " class="card__list">
                                        <div class="d-flex row pr-2 pl-2 align-items-center"
                                          (click)="addSearchedUser(userObject)">
                                          <img alt="no-photo" ngx-gravatar [email]="'example@mail.com'" fallback="mp"
                                            size="35" />
                                          <span class="pr-2 pl-2">
                                            {{ userObject?.firstName }}
                                            {{ userObject?.lastName }}
                                            <span> </span>
                                          </span>
                                        </div>
                                      </div>
                                    </ng-container>
                                    <select name="familyMemberStatus" id="familyMemberStatus"
                                      [(ngModel)]="user.relationship.status" class="col-md-12 form-control mb-2"
                                      #familyMemberStatus="ngModel" (ngModelChange)="
                                        changeRelationship($event)
                                      " [ngClass]="{
                                        'is-invalid':
                                          familyMember.submitted &&
                                          familyMemberStatus.invalid
                                      }" required>
                                      <option *ngFor="
                                          let relationship of arrayService.familyMembers
                                        " [ngValue]="relationship.id" [selected]="relationship">
                                        {{ relationship.description }}
                                      </option>
                                    </select>
                                    <div class="flex items-center" style="
                                        flex-direction: row;
                                        display: flex;
                                        border: none;
                                      " class="specialty col-12 px-0 mt-1">
                                      <button class="btn btn-light me-2" type="submit">
                                        <i class="bi bi-check"></i>
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </ng-container>
                          <ng-container *ngFor="
                              let familyMember of user?.familyMembers;
                              let i = index
                            ">
                            <div class="col-sm-12">
                              <!-- Birthday START -->
                              <div class="d-flex align-items-center px-0 py-2" *ngIf="familyMember?.user?.status === 2">
                                <!-- Date -->
                                <div class="mb-0 d-flex">
                                  <div class="avatar avatar-xs me-2">
                                    <a href="#"><img src="assets/images/avatar/03.jpg" alt=""
                                        class="avatar-img rounded-circle" /></a>
                                  </div>
                                  <div>
                                    <strong>
                                      <app-education-name [familyMember]="true"
                                        [userID]="familyMember?.user?.id"></app-education-name>
                                    </strong>
                                    <br />
                                    <div class="d-flex align-items-center">
                                      <p class="mb-0 small">
                                        {{
                                        getFamilyMemberStatus(
                                        familyMember.status
                                        ).description
                                        }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="d-flex align-items-center px-0 py-2" *ngIf="familyMember?.user?.status === 1">
                                <!-- Date -->
                                <div class="mb-0 d-flex">
                                  <div class="avatar avatar-xs me-2">
                                    <a href="#"><img src="assets/images/avatar/03.jpg" alt=""
                                        class="avatar-img rounded-circle" /></a>
                                  </div>
                                  <div>
                                    <strong>
                                      <app-education-name [familyMember]="true"
                                        [userID]="familyMember?.user?.id"></app-education-name>
                                    </strong>
                                    <br />
                                    <div class="d-flex align-items-center">
                                      <p class="mb-0 small">
                                        {{
                                        getFamilyMemberStatus(
                                        familyMember.status
                                        ).description
                                        }}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="d-flex align-items-center ms-auto">
                                  <app-privacy-button *ngIf="!!checkLoginID" [privacy]="familyMember.privacy" (saveObject)="updateFamilyMember($event, i)"></app-privacy-button>


                                <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                  <!-- Card share action menu -->
                                  <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="bi bi-three-dots"></i>
                                  </a>
                                  <!-- Card share action dropdown menu -->
                                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                    <li>
                                      <a class="dropdown-item" (click)="editFamilyMember()">
                                        <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                    </li>
                                    <li>
                                      <a class="dropdown-item" (click)="deleteFamilyMember(i)">
                                        <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                    </li>
                                  </ul>
                                </div>
                                </div>
                              </div>
                              <div class="rounded badge-unread d-sm-flex border-0 mb-1 p-3 position-relative"
                                *ngIf="familyMember?.user?.status === 0">
                                <!-- Avatar -->
                                <div class="avatar text-center">
                                  <img class="avatar-img rounded-circle" src="assets/images/avatar/01.jpg" alt="" />
                                </div>
                                <!-- Info -->
                                <div class="mx-sm-3 my-2 my-sm-0">
                                  <p class="small mb-2">
                                    <b><app-education-name [familyMember]="true"
                                        [userID]="familyMember?.user?.id"></app-education-name></b>
                                    added you as
                                    {{
                                    getFamilyMemberStatus(familyMember.status)
                                    .description
                                    }}
                                  </p>
                                  <!-- Button -->
                                  <div class="d-flex">
                                    <button class="btn btn-sm py-1 btn-primary me-2" (click)="acceptFamilyMember(i)">
                                      Accept
                                    </button>
                                    <button class="btn btn-sm py-1 btn-danger-soft">
                                      Delete
                                    </button>
                                  </div>
                                </div>
                                <!-- Action -->
                                <div class="d-flex ms-auto">
                                  <p class="small me-5 text-nowrap">Just now</p>
                                  <!-- Notification action START -->
                                  <div class="dropdown position-absolute end-0 top-0 mt-3 me-3">
                                    <a href="#" class="z-index-1 text-secondary btn position-relative py-0 px-2"
                                      id="cardNotiAction1" data-bs-toggle="dropdown" aria-expanded="false">
                                      <i class="bi bi-three-dots"></i>
                                    </a>
                                    <!-- Card share action dropdown menu -->
                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="cardNotiAction1">
                                      <li>
                                        <a class="dropdown-item" href="#">
                                          <i class="bi bi-trash fa-fw pe-2"></i>Delete</a>
                                      </li>
                                      <li>
                                        <a class="dropdown-item" href="#">
                                          <i class="bi bi-bell-slash fa-fw pe-2"></i>Turn off
                                        </a>
                                      </li>
                                      <li>
                                        <a class="dropdown-item" href="#">
                                          <i class="bi bi-volume-mute fa-fw fs-5 pe-2"></i>Mute Judy Nguyen
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <!-- Notification action END -->
                                </div>
                              </div>
                              <!-- Birthday END -->
                            </div>
                          </ng-container>
                        </div>
                      </div>
                      <!-- Button save -->
                    </div>

                    <!-- Messaging experience END -->
                  </div>
                  <!-- Messaging tab END -->

                  <!-- Close account tab START -->

                  <div class="tab-pane fade" id="nav-setting-tab-7">
                    <!-- Card START -->
                    <div class="card">
                      <!-- Card header START -->
                      <div class="card-header border-0 pb-0">
                        <h5 class="card-title">
                          {{ "Branchen.name" | translate }}
                        </h5>
                      </div>
                      <!-- Card header START -->
                      <!-- Card body START -->
                      <div class="card-body">
                        <div class="col-sm-12">
                          <!-- Birthday START -->
                          <div class="d-flex align-items-center px-0 py-2">
                            <!-- Date -->
                            <p class="mb-0">
                              <i class="bi bi-buildings"></i>
                              <strong>
                                {{ branchTitle | translate }} -
                                {{ subCategoryTitle | translate }}
                              </strong>
                            </p>
                            <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                              <!-- Card share action menu -->
                              <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="bi bi-three-dots"></i>
                              </a>
                              <!-- Card share action dropdown menu -->
                              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                <li>
                                  <a class="dropdown-item" [routerLink]="'../cv/branch'">
                                    <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <!-- Birthday END -->
                        </div>

                        <!-- Delete END -->
                      </div>

                      <div class="card-header border-0 pb-0">
                        <h5 class="card-title">
                          {{ "skills.offer" | translate }}
                        </h5>
                      </div>
                      <!-- Card header START -->
                      <!-- Card body START -->
                      <div class="card-body">
                        <div class="col-sm-12 position-relative" *ngIf="!!checkLoginID">
                          <!-- Workplace on START -->
                          <a class="btn btn-dashed rounded w-100 text-align-start" (click)="
                              addOffer({
                                edit: true,
                                offer: user.searchFilterSkills.searchSkillsOffer.data,
                                model: model
                              })
                            ">
                            <i class="bi bi-plus-circle-dotted me-1"></i>{{ "add" | translate }}</a>
                          <!-- Workplace on END -->
                        </div>
                        <div class="d-flex pr-1 pt-1 flex-wrap col-sm-12">
                          <div class="col-sm-9 d-flex flex-wrap ">
                            <ng-container *ngFor="
                            let keyword of user.searchFilterSkills.searchSkillsOffer.data">
                              <p class="card__text me-1 mb-1">
                                {{ keyword?.name }}
                              </p>
                            </ng-container>
                          </div>

                          <div class="d-flex align-items-start ms-auto">



                            <app-privacy-button *ngIf="!!checkLoginID"
                              [privacy]="user.searchFilterSkills.searchSkillsOffer.privacy"
                              (saveObject)="updateSearchSkillsOffer($event)"></app-privacy-button>

                            <div class="dropdown" *ngIf="!!checkLoginID">
                              <!-- Card share action menu -->
                              <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="bi bi-three-dots"></i>
                              </a>
                              <!-- Card share action dropdown menu -->
                              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                <li>
                                  <a class="dropdown-item" (click)="
                                    editOffer({
                                      edit: true,
                                      offer:
                                        user.cv.data[0]?.skills[0]?.subCategories[0]
                                          ?.skillsOffer,
                                      model:
                                        user.cv.data[0]?.skills[0]?.subCategories[0]
                                          ?.skillsOffer,
                                      indexOfLang: 0
                                    })
                                  ">
                                    <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                </li>
                                <li>
                                  <a class="dropdown-item" (click)="
                                    editOffer({
                                      edit: true,
                                      offer:
                                        user.cv.data[0]?.skills[0]?.subCategories[0]
                                          ?.skillsOffer,
                                      model:
                                        user.cv.data[0]?.skills[0]?.subCategories[0]
                                          ?.skillsOffer,
                                      indexOfLang: 0
                                    })
                                  ">
                                    <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <!-- Delete END -->
                      </div>

                      <div class="card-header border-0 pb-0 py-1">
                        <h5 class="card-title">
                          {{ "skills.pcKnowledge" | translate }}
                        </h5>
                      </div>
                      <!-- Card header START -->
                      <!-- Card body START -->
                      <div class="card-body">
                        <div class="col-sm-12 position-relative" *ngIf="!!checkLoginID">
                          <!-- Workplace on START -->
                          <a class="btn btn-dashed rounded w-100 text-align-start" (click)="
                              editPCKnowledge({
                                edit: true,
                                pcKnowledge:
                                  user.cv.data[0]?.skills[0]?.subCategories[0]
                                    ?.pcKnowledge,
                                model: user.cv.data[0],
                                indexOfLang: 0
                              })
                            ">
                            <i class="bi bi-plus-circle-dotted me-1"></i>{{ "add" | translate }}</a>
                          <!-- Workplace on END -->
                        </div>
                        <div class="d-flex pr-1 pt-1 flex-wrap col-sm-12">
                          <div class="col-sm-9 d-flex flex-wrap">
                            <ng-container *ngFor="
                            let keyword of user.searchFilterSkills.searchPCKnowledge.data">
                              <p class="card__text me-1 mb-1">
                                {{ keyword?.name }}
                              </p>
                            </ng-container>
                          </div>


                          <div class="d-flex align-items-start ms-auto">

                            <app-privacy-button *ngIf="!!checkLoginID"
                              [privacy]="user.searchFilterSkills.searchPCKnowledge.privacy"
                              (saveObject)="updatePcKnowledge($event)"></app-privacy-button>

                            <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                              <!-- Card share action menu -->
                              <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="bi bi-three-dots"></i>
                              </a>
                              <!-- Card share action dropdown menu -->
                              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                <li>
                                  <a class="dropdown-item" (click)="
                                    editPCKnowledge({
                                      edit: true,
                                      pcKnowledge:
                                        user.cv.data[0]?.skills[0]?.subCategories[0]
                                          ?.pcKnowledge,
                                      model: user.cv.data[0],
                                      indexOfLang: 0
                                    })
                                  ">
                                    <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                </li>
                                <li>
                                  <a class="dropdown-item" (click)="
                                    editPCKnowledge({
                                      edit: true,
                                      pcKnowledge:
                                        user.cv.data[0]?.skills[0]?.subCategories[0]
                                          ?.pcKnowledge,
                                      model: user.cv.data[0],
                                      indexOfLang: 0
                                    })
                                  ">
                                    <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <!-- Delete END -->
                      </div>

                      <div class="card-header border-0 pb-0 py-1">
                        <h5 class="card-title">
                          {{ "skills.language" | translate }}
                        </h5>
                      </div>
                      <!-- Card header START -->
                      <!-- Card body START -->
                      <div class="card-body">
                        <div class="col-sm-12 position-relative" *ngIf="!!checkLoginID">
                          <!-- Workplace on START -->
                          <a class="btn btn-dashed rounded w-100 text-align-start" (click)="
                              editLanguages({
                                edit: true,
                                language:
                                  user.cv.data[0]?.skills[0]?.subCategories[0]
                                    ?.pcKnowledge,
                                model: user.cv.data[0]
                              })
                            ">
                            <i class="bi bi-plus-circle-dotted me-1"></i>{{ "add" | translate }}</a>
                          <!-- Workplace on END -->
                        </div>
                        <div class="d-flex pr-1 pt-1 flex-wrap col-sm-12">
                          <div class="col-sm-9 d-flex flex-wrap ">

                            <ng-container *ngFor="
                              let language of user.searchFilterSkills.searchLanguages.data
                            ">
                              <p class="card__text me-1 mb-1">
                                {{ getLanguageName(language.name)?.value }}
                              </p>
                            </ng-container>
                          </div>

                          <div class="d-flex align-items-start ms-auto">


                            <app-privacy-button *ngIf="!!checkLoginID"
                              [privacy]="user.searchFilterSkills.searchLanguages.privacy"
                              (saveObject)="updateSearchLanguages($event)"></app-privacy-button>

                            <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                              <!-- Card share action menu -->
                              <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="bi bi-three-dots"></i>
                              </a>
                              <!-- Card share action dropdown menu -->
                              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                <li>
                                  <a class="dropdown-item" (click)="
                                    editLanguages({
                                      edit: true,
                                      language:
                                        user.cv.data[0]?.skills[0]?.subCategories[0]
                                          ?.pcKnowledge.data,
                                      model: user.cv.data[0]
                                    })
                                  ">
                                    <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                </li>
                                <li>
                                  <a class="dropdown-item">
                                    <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <!-- Delete END -->
                      </div>
                      <div class="card-header border-0 pb-0 py-1">
                        <h5 class="card-title">
                          {{ "skills.driveLicense" | translate }}
                        </h5>
                      </div>
                      <!-- Card header START -->
                      <!-- Card body START -->
                      <div class="card-body">
                        <div class="col-sm-12 position-relative" *ngIf="!!checkLoginID">
                          <!-- Workplace on START -->
                          <a class="btn btn-dashed rounded w-100 text-align-start" (click)="
                              editDriveLicense({
                                edit: true,
                                model: user.cv.data[0],
                                driveLicense:
                                  user.cv.data[0]?.skills[0]?.subCategories[0]
                                    ?.driveLicenses.data,
                                indexOfLang: 0
                              })
                            ">
                            <i class="bi bi-plus-circle-dotted me-1"></i>{{ "add" | translate }}</a>
                          <!-- Workplace on END -->
                        </div>
                        <div class="d-flex pr-1 pt-1 flex-wrap col-sm-12">
                          <ng-container *ngFor="
                              let keyword of user.searchFilterSkills.searchDriveLicenses.data">
                            <p class="card__text me-1 mb-1">
                              {{ keyword?.name | translate }}
                            </p>
                          </ng-container>

                          <div class="d-flex align-items-start ms-auto">


                          <app-privacy-button *ngIf="!!checkLoginID"
                          [privacy]="user.searchFilterSkills.searchDriveLicenses.privacy"
                          (saveObject)="updateDriveLicense($event)"></app-privacy-button>


                          <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                            <!-- Card share action menu -->
                            <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                              data-bs-toggle="dropdown" aria-expanded="false">
                              <i class="bi bi-three-dots"></i>
                            </a>
                            <!-- Card share action dropdown menu -->
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                              <li>
                                <a class="dropdown-item" (click)="
                                    editDriveLicense({
                                      edit: true,
                                      model: user.cv.data[0],
                                      driveLicense:
                                        user.cv.data[0]?.skills[0]?.subCategories[0]
                                          ?.driveLicenses.data,
                                      indexOfLang: 0
                                    })
                                  ">
                                  <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                              </li>
                              <li>
                                <a class="dropdown-item" (click)="
                                    editDriveLicense({
                                      edit: true,
                                      model: user.cv.data[0],
                                      driveLicense:
                                        user.cv.data[0]?.skills[0]?.subCategories[0]
                                          ?.driveLicenses.data,
                                      indexOfLang: 0
                                    })
                                  ">
                                  <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        </div>

                        <!-- Delete END -->
                      </div>

                      <div class="card-header border-0 pb-0 py-1">
                        <h5 class="card-title">
                          {{ "skills.qualifications" | translate }}
                        </h5>
                      </div>
                      <!-- Card header START -->
                      <!-- Card body START -->
                      <div class="card-body">
                        <div class="col-sm-12 position-relative" *ngIf="!!checkLoginID">
                          <!-- Workplace on START -->
                          <a class="btn btn-dashed rounded w-100 text-align-start" (click)="
                              editQualifications({
                                edit: true,
                                model: user.cv.data[0],
                                qualifications:
                                  user.searchFilterSkills.searchQualifications.data,
                                user: user
                              })
                            ">
                            <i class="bi bi-plus-circle-dotted me-1"></i>{{ "add" | translate }}</a>
                          <!-- Workplace on END -->
                        </div>
                        <div class="d-flex pr-1 pt-1 flex-wrap col-sm-12">
                          <div class="col-sm-9 d-flex flex-wrap ">

                          <ng-container *ngFor="
                              let keyword of  user.searchFilterSkills.searchQualifications.data">
                            <p class="card__text me-1 mb-1">
                              {{ keyword?.name }}
                            </p>
                          </ng-container>
                          </div>
                          <div class="d-flex align-items-start ms-auto">

                          <app-privacy-button *ngIf="!!checkLoginID"
                          [privacy]="user.searchFilterSkills.searchQualifications.privacy"
                          (saveObject)="updateSearchQualifications($event)"></app-privacy-button>


                          <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                            <!-- Card share action menu -->
                            <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                              data-bs-toggle="dropdown" aria-expanded="false">
                              <i class="bi bi-three-dots"></i>
                            </a>
                            <!-- Card share action dropdown menu -->
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                              <li>
                                <a class="dropdown-item" (click)="
                                    editQualifications({
                                      edit: true,
                                      model: user.cv.data[0],
                                      qualifications:
                                        user.cv.data[0]?.skills[0]?.subCategories[0]
                                          ?.qualifications.data,
                                      user: user
                                    })
                                  ">
                                  <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                              </li>
                              <li>
                                <a class="dropdown-item" (click)="
                                    editQualifications({
                                      edit: true,
                                      model: user.cv.data[0],
                                      qualifications:
                                        user.cv.data[0]?.skills[0]?.subCategories[0]
                                          ?.qualifications.data,
                                      user: user
                                    })
                                  ">
                                  <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        </div>

                        <!-- Delete END -->
                      </div>

                      <!-- Card body END -->
                    </div>
                    <!-- Card END -->
                  </div>
                  <div class="tab-pane fade" id="nav-setting-tab-8">
                    <!-- Card START -->
                    <div class="card">
                      <!-- Card header START -->
                      <div class="card-header border-0 pb-0">
                        <h5 class="card-title">I offer</h5>
                        <p class="mb-0">
                          He moonlights difficult engrossed it, sportsmen.
                          Interested has all Devonshire difficulty gay
                          assistance joy. Unaffected at ye of compliment
                          alteration to.
                        </p>
                      </div>
                      <!-- Card header START -->
                      <!-- Card body START -->
                      <div class="card-body">
                        <!-- Delete START -->
                        <h6>Before you go...</h6>
                        <ul>
                          <li>
                            Take a backup of your data <a href="#">Here</a>
                          </li>
                          <li>
                            If you delete your account, you will lose your all
                            data.
                          </li>
                        </ul>
                        <div class="form-check form-check-md my-4">
                          <input class="form-check-input" type="checkbox" value="" id="deleteaccountCheck" />
                          <label class="form-check-label" for="deleteaccountCheck">Yes, I'd like to delete my
                            account</label>
                        </div>
                        <a href="#" class="btn btn-success-soft btn-sm mb-2 mb-sm-0">Keep my account</a>
                        <a href="#" class="btn btn-danger btn-sm mb-0">Delete my account</a>
                        <!-- Delete END -->
                      </div>
                      <!-- Card body END -->
                    </div>
                    <!-- Card END -->
                  </div>
                  <!-- Close account tab END -->
                </div>
                <!-- Setting Tab content END -->
              </div>
            </div>
            <!-- Row END -->
            <!-- Card body END -->
          </div>
        </ng-container>
        <ng-container
          *ngIf="router.url === '/' + this.user?.userUrl + '/connections' || router.url === '/' + this.user?.userUrl + '/connections/followers'  || router.url === '/' + this.user?.userUrl + '/connections/following'  || router.url === '/' + this.user?.userUrl + '/connections/contacts'  || router.url === '/' + this.user?.userUrl + '/connections/hashtags' ">
          <div class="card">
            <ul
              class="nav nav-bottom-line align-items-center justify-content-center justify-content-md-start mb-0 border-0 ms-3">
              <li class="nav-item">
                <a class="nav-link" [routerLinkActive]="['active']"
                  [routerLink]="'/' + this.user?.userUrl + '/connections/followers'">
                  {{ "followers" | translate }}
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']"
                  [routerLink]="'/' + this.user?.userUrl + '/connections/following'">
                  {{ "following" | translate }}
                </a>
              </li>
              <!--  <li class="nav-item">
                <a class="nav-link" [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']"
                  [routerLink]="'/' + this.user?.userUrl + '/connections/contacts'">
                  {{ "header.contacts" | translate }}
                </a>
              </li> -->
              <!--  <li class="nav-item">
                <a class="nav-link" [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']"
                  [routerLink]="'/' + this.user?.userUrl + '/connections/hashtags'">
                  {{ "hashtags" | translate }}
                </a>
              </li> -->

            </ul>

            <ng-container *ngIf="router.url === '/' + this.user?.userUrl + '/connections/followers'">
              <div class="card-body position-relative pt-0 mt-4">
                <div class="row g-4">
                  <div class="col-sm-6" *ngFor="let userFollowers of userFollowers$">
                    <app-user-followers [loggedUser]="loggedUser" [user]="userFollowers"
                      [followers]="true"></app-user-followers>
                    <!-- Birthday START -->
                    <!-- Birthday END -->
                  </div>
                </div>

              </div>
            </ng-container>
            <ng-container *ngIf="router.url === '/' + this.user?.userUrl + '/connections/following'">
              <div class="card-body position-relative pt-0 mt-4">
                <div class="row g-4">
                  <ng-container *ngFor="let userFollowing of user.following.data">
                    <div class="col-sm-6" *ngIf="userFollowing.status !== 9">
                      <app-user-followers [userFollowing]="userFollowing" [following]="true"
                        [loggedUser]="loggedUser"></app-user-followers>

                      <!-- Birthday START -->
                      <!-- Birthday END -->
                    </div>
                  </ng-container>

                </div>



              </div>

            </ng-container>
            <ng-container *ngIf="router.url === '/' + this.user?.userUrl + '/connections/contacts'">
              <div class="card-body position-relative pt-0 mt-4">
                <div class="row g-4">
                  <div class="col-sm-6" *ngFor="let userContacts of   userContacts$ | async">
                    <app-user-followers [userFollowing]="userContacts" [contacts]="true"
                      [loggedUser]="loggedUser"></app-user-followers>
                    <!-- Birthday START -->
                    <!-- Birthday END -->
                  </div>
                </div>



              </div>

            </ng-container>
          </div>


        </ng-container>
      </div>
      <!-- Main content END -->

      <!-- Right sidebar START -->
      <div class="col-lg-4">
        <div class="row g-4">
          <!-- Card START -->
          <div class="col-md-6 col-lg-12">
            <div class="card">
              <div class="card-header border-0 pb-0">
                <h5 class="card-title">About</h5>
                <!-- Button modal -->
              </div>
              <!-- Card body START -->
              <div class="card-body position-relative pt-0">
                <p>
                  He moonlights difficult engrossed it, sportsmen. Interested
                  has all Devonshire difficulty gay assistance joy.
                </p>
                <!-- Date time -->
                <ul class="list-unstyled mt-3 mb-0">
                  <li class="mb-2">
                    <i class="bi bi-calendar-date fa-fw pe-1"></i> Born:
                    <strong> October 20, 1990 </strong>
                  </li>
                  <li class="mb-2">
                    <i class="bi bi-heart fa-fw pe-1"></i> Status:
                    <strong> Single </strong>
                  </li>
                  <li>
                    <i class="bi bi-envelope fa-fw pe-1"></i> Email:
                    <strong> webestica@gmail.com </strong>
                  </li>
                </ul>
              </div>
              <!-- Card body END -->
            </div>
          </div>
          <!-- Card END -->

          <!-- Card START -->
          <div class="col-md-6 col-lg-12">
            <div class="card">
              <!-- Card header START -->
              <div class="card-header d-flex justify-content-between border-0">
                <h5 class="card-title">Experience</h5>
                <a class="btn btn-primary-soft btn-sm" href="#!">
                  <i class="fa-solid fa-plus"></i>
                </a>
              </div>
              <!-- Card header END -->
              <!-- Card body START -->
              <div class="card-body position-relative pt-0">
                <!-- Experience item START -->
                <div class="d-flex">
                  <!-- Avatar -->
                  <div class="avatar me-3">
                    <a href="#!">
                      <img class="avatar-img rounded-circle" src="assets/images/logo/08.svg" alt="" />
                    </a>
                  </div>
                  <!-- Info -->
                  <div>
                    <h6 class="card-title mb-0">
                      <a href="#!"> Apple Computer, Inc. </a>
                    </h6>
                    <p class="small">
                      May 2015 – Present Employment Duration 8 mos
                      <a class="btn btn-primary-soft btn-xs ms-2" href="#!">{{ "edit" | translate }}
                      </a>
                    </p>
                  </div>
                </div>
                <!-- Experience item END -->

                <!-- Experience item START -->
                <div class="d-flex">
                  <!-- Avatar -->
                  <div class="avatar me-3">
                    <a href="#!">
                      <img class="avatar-img rounded-circle" src="assets/images/logo/09.svg" alt="" />
                    </a>
                  </div>
                  <!-- Info -->
                  <div>
                    <h6 class="card-title mb-0">
                      <a href="#!"> Microsoft Corporation </a>
                    </h6>
                    <p class="small">
                      May 2017 – Present Employment Duration 1 yrs 5 mos
                      <a class="btn btn-primary-soft btn-xs ms-2" href="#!">Edit
                      </a>
                    </p>
                  </div>
                </div>
                <!-- Experience item END -->

                <!-- Experience item START -->
                <div class="d-flex">
                  <!-- Avatar -->
                  <div class="avatar me-3">
                    <a href="#!">
                      <img class="avatar-img rounded-circle" src="assets/images/logo/10.svg" alt="" />
                    </a>
                  </div>
                  <!-- Info -->
                  <div>
                    <h6 class="card-title mb-0">
                      <a href="#!"> Tata Consultancy Services. </a>
                    </h6>
                    <p class="small mb-0">
                      May 2022 – Present Employment Duration 6 yrs 10 mos
                      <a class="btn btn-primary-soft btn-xs ms-2" href="#!">{{ "edit" | translate }}
                      </a>
                    </p>
                  </div>
                </div>
                <!-- Experience item END -->
              </div>
              <!-- Card body END -->
            </div>
          </div>
          <!-- Card END -->

          <!-- Card START -->
          <div class="col-md-6 col-lg-12">
            <div class="card">
              <!-- Card header START -->
              <div class="card-header d-sm-flex justify-content-between border-0">
                <h5 class="card-title">Photos</h5>
                <a class="btn btn-primary-soft btn-sm" href="#!">
                  See all photo</a>
              </div>
              <!-- Card header END -->
              <!-- Card body START -->
              <div class="card-body position-relative pt-0">
                <div class="row g-2">
                  <!-- Photos item -->
                  <div class="col-6">
                    <a href="assets/images/albums/01.jpg" data-gallery="image-popup" data-glightbox="">
                      <img class="rounded img-fluid" src="assets/images/albums/01.jpg" alt="" />
                    </a>
                  </div>
                  <!-- Photos item -->
                  <div class="col-6">
                    <a href="assets/images/albums/02.jpg" data-gallery="image-popup" data-glightbox="">
                      <img class="rounded img-fluid" src="assets/images/albums/02.jpg" alt="" />
                    </a>
                  </div>
                  <!-- Photos item -->
                  <div class="col-4">
                    <a href="assets/images/albums/03.jpg" data-gallery="image-popup" data-glightbox="">
                      <img class="rounded img-fluid" src="assets/images/albums/03.jpg" alt="" />
                    </a>
                  </div>
                  <!-- Photos item -->
                  <div class="col-4">
                    <a href="assets/images/albums/04.jpg" data-gallery="image-popup" data-glightbox="">
                      <img class="rounded img-fluid" src="assets/images/albums/04.jpg" alt="" />
                    </a>
                  </div>
                  <!-- Photos item -->
                  <div class="col-4">
                    <a href="assets/images/albums/05.jpg" data-gallery="image-popup" data-glightbox="">
                      <img class="rounded img-fluid" src="assets/images/albums/05.jpg" alt="" />
                    </a>
                    <!-- glightbox Albums left bar END  -->
                  </div>
                </div>
              </div>
              <!-- Card body END -->
            </div>
          </div>
          <!-- Card END -->

          <!-- Card START -->
          <div class="col-md-6 col-lg-12">
            <div class="card">
              <!-- Card header START -->
              <div class="card-header d-sm-flex justify-content-between align-items-center border-0">
                <h5 class="card-title">
                  Friends
                  <span class="badge bg-danger bg-opacity-10 text-danger">230</span>
                </h5>
                <a class="btn btn-primary-soft btn-sm" href="#!">
                  See all friends</a>
              </div>
              <!-- Card header END -->
              <!-- Card body START -->
              <div class="card-body position-relative pt-0">
                <div class="row g-3">
                  <div class="col-6">
                    <!-- Friends item START -->
                    <div class="card shadow-none text-center h-100">
                      <!-- Card body -->
                      <div class="card-body p-2 pb-0">
                        <div class="avatar avatar-story avatar-xl">
                          <a href="#!"><img class="avatar-img rounded-circle" src="assets/images/avatar/02.jpg"
                              alt="" /></a>
                        </div>
                        <h6 class="card-title mb-1 mt-3">
                          <a href="#!"> Amanda Reed </a>
                        </h6>
                        <p class="mb-0 small lh-sm">16 mutual connections</p>
                      </div>
                      <!-- Card footer -->
                      <div class="card-footer p-2 border-0">
                        <button class="btn btn-sm btn-primary" data-bs-toggle="tooltip" data-bs-placement="top"
                          title="Send message">
                          <i class="bi bi-chat-left-text"></i>
                        </button>
                        <button class="btn btn-sm btn-danger" data-bs-toggle="tooltip" data-bs-placement="top"
                          title="Remove friend">
                          <i class="bi bi-person-x"></i>
                        </button>
                      </div>
                    </div>
                    <!-- Friends item END -->
                  </div>

                  <div class="col-6">
                    <!-- Friends item START -->
                    <div class="card shadow-none text-center h-100">
                      <!-- Card body -->
                      <div class="card-body p-2 pb-0">
                        <div class="avatar avatar-xl">
                          <a href="#!"><img class="avatar-img rounded-circle" src="assets/images/avatar/03.jpg"
                              alt="" /></a>
                        </div>
                        <h6 class="card-title mb-1 mt-3">
                          <a href="#!"> Samuel Bishop </a>
                        </h6>
                        <p class="mb-0 small lh-sm">22 mutual connections</p>
                      </div>
                      <!-- Card footer -->
                      <div class="card-footer p-2 border-0">
                        <button class="btn btn-sm btn-primary" data-bs-toggle="tooltip" data-bs-placement="top"
                          title="Send message">
                          <i class="bi bi-chat-left-text"></i>
                        </button>
                        <button class="btn btn-sm btn-danger" data-bs-toggle="tooltip" data-bs-placement="top"
                          title="Remove friend">
                          <i class="bi bi-person-x"></i>
                        </button>
                      </div>
                    </div>
                    <!-- Friends item END -->
                  </div>

                  <div class="col-6">
                    <!-- Friends item START -->
                    <div class="card shadow-none text-center h-100">
                      <!-- Card body -->
                      <div class="card-body p-2 pb-0">
                        <div class="avatar avatar-xl">
                          <a href="#!"><img class="avatar-img rounded-circle" src="assets/images/avatar/04.jpg"
                              alt="" /></a>
                        </div>
                        <h6 class="card-title mb-1 mt-3">
                          <a href="#"> Bryan Knight </a>
                        </h6>
                        <p class="mb-0 small lh-sm">1 mutual connection</p>
                      </div>
                      <!-- Card footer -->
                      <div class="card-footer p-2 border-0">
                        <button class="btn btn-sm btn-primary" data-bs-toggle="tooltip" data-bs-placement="top"
                          title="Send message">
                          <i class="bi bi-chat-left-text"></i>
                        </button>
                        <button class="btn btn-sm btn-danger" data-bs-toggle="tooltip" data-bs-placement="top"
                          title="Remove friend">
                          <i class="bi bi-person-x"></i>
                        </button>
                      </div>
                    </div>
                    <!-- Friends item END -->
                  </div>

                  <div class="col-6">
                    <!-- Friends item START -->
                    <div class="card shadow-none text-center h-100">
                      <!-- Card body -->
                      <div class="card-body p-2 pb-0">
                        <div class="avatar avatar-xl">
                          <a href="#!"><img class="avatar-img rounded-circle" src="assets/images/avatar/05.jpg"
                              alt="" /></a>
                        </div>
                        <h6 class="card-title mb-1 mt-3">
                          <a href="#!"> Amanda Reed </a>
                        </h6>
                        <p class="mb-0 small lh-sm">15 mutual connections</p>
                      </div>
                      <!-- Card footer -->
                      <div class="card-footer p-2 border-0">
                        <button class="btn btn-sm btn-primary" data-bs-toggle="tooltip" data-bs-placement="top"
                          title="Send message">
                          <i class="bi bi-chat-left-text"></i>
                        </button>
                        <button class="btn btn-sm btn-danger" data-bs-toggle="tooltip" data-bs-placement="top"
                          title="Remove friend">
                          <i class="bi bi-person-x"></i>
                        </button>
                      </div>
                    </div>
                    <!-- Friends item END -->
                  </div>
                </div>
              </div>
              <!-- Card body END -->
            </div>
          </div>
          <!-- Card END -->
        </div>
      </div>
      <!-- Right sidebar END -->
    </div>
    <!-- Row END -->
  </div>
  <!-- Container END -->
</main>


<main *ngIf="user?.account?.status === 0  && isSameUser || user?.account?.status === 1 && isSameUser">

  <div class="container">
    <div class="row g-4">
      <!-- Main content START -->
      <div class="col-lg-8 mx-auto">
        <!-- Privacy & terms START -->
        <div class="card">
          <div class="card-header">
            <h1 class="card-title h4 mb-0">Your account {{user?.firstName}} {{user?.lastName}} is blocked</h1>
          </div>
          <div class="card-body">
            <!-- Title -->
            <p class="mt-3 mb-3">For violating our terms of use Account has been suspended until
              <b>{{user?.account?.endDate | date: 'dd.MM.yyyy'}}</b><br>

              Activate this account now for <b> 3 Coins</b>

            </p>

            <button class="btn btn-primary" (click)="changeAccountStatus()">Activate</button>

            <!-- Divider -->
            <hr class="mt-2 mb-4">

            <!-- Table of Contents -->

            <!-- Divider -->
          </div>

        </div>
        <!-- Privacy & terms END -->
      </div>
    </div> <!-- Row END -->
  </div>
</main>
<main *ngIf="user?.account?.status === 0  && !isSameUser || user?.account?.status === 1  && !isSameUser">
  <div class="container">
    <!-- Extra space -->
    <div class="h-100px d-none d-lg-block"></div>
    <div class="row align-items-center text-center py-sm-5">
      <!-- Error 404 START -->
      <div class="col-lg-8 mx-auto">
        <!-- SVG START -->
        <figure class="m-0 pt-5 pt-md-0">
          <svg class="col-sm-7 col-lg-5" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1285.3 712.4"
            style="enable-background:new 0 0 1285.3 712.4;" xml:space="preserve">

            <g id="illustration">
              <g>
                <ellipse class="st0" cx="642.7" cy="698.2" rx="642.6" ry="14.2" />
                <g>
                  <path class="st1" d="M37,400.5c-17.9-0.5-37.4-6.8-37-35.6s21.3-33.3,23.5-54.8c2.1-21.5,2.2-41.6,13.5-41.8s4.1,9,9.4,19
                    c5.2,10,15.4,22.8,13.4,41.4c-2,18.7,11.3,25.8,10.6,41.1C69.8,385,60.2,401.2,37,400.5z" />
                  <path class="st2" d="M43.7,327c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1c0,0,4.7,4.7,4.2,20.6c-0.4,13.8-7.5,35.1-9.8,41.5
                    c-2.1-5-4.9-10.5-7.2-15.2c-1.6-3.1-2.9-5.9-3.6-7.6c-2.7-6.3,1.4-19.4,1.4-19.6c0.1-0.4-0.1-0.9-0.5-1s-0.9,0.1-1,0.5
                    c-0.2,0.6-4.3,13.8-1.4,20.6c0.7,1.8,2.1,4.5,3.7,7.7c4.2,8.5,10.6,21.3,10.5,26.3c-0.1,6.9-1,16.9-1,17c0,0.4,0.3,0.8,0.7,0.8
                    c0,0,0,0,0.1,0c0.4,0,0.7-0.3,0.8-0.7c0-0.1,0.8-10.1,1-17.1c0-2.2-1-5.7-2.6-9.6c1-2.8,10-28,10.5-43.6
                    C48.9,332,43.9,327.2,43.7,327z" />
                </g>
                <g>
                  <path class="st1"
                    d="M1056.6,282.4c-15.3-0.5-31.9-5.8-31.5-30.4c0.4-24.6,18.2-28.4,20-46.7c1.8-18.4,1.9-35.5,11.5-35.6
                    c9.6-0.2,3.5,7.7,8,16.2s13.1,19.4,11.5,35.3c-1.7,15.9,9.7,22,9.1,35C1084.5,269.2,1076.4,283,1056.6,282.4z" />
                  <path class="st2" d="M1062.3,219.7c-0.3-0.3-0.7-0.2-0.9,0c-0.3,0.3-0.2,0.7,0,0.9c0,0,4,4,3.6,17.6c-0.3,11.7-6.4,29.9-8.4,35.4
                    c-1.8-4.3-4.1-9-6.1-13c-1.3-2.7-2.5-5-3.1-6.5c-2.3-5.3,1.2-16.6,1.2-16.7c0.1-0.4-0.1-0.7-0.4-0.8c-0.4-0.1-0.7,0.1-0.8,0.4
                    c-0.1,0.5-3.6,11.8-1.2,17.6c0.6,1.5,1.8,3.8,3.1,6.5c3.6,7.2,9,18.2,8.9,22.4c-0.1,5.9-0.8,14.4-0.8,14.5c0,0.4,0.2,0.7,0.6,0.7
                    c0,0,0,0,0.1,0c0.3,0,0.6-0.3,0.7-0.6c0-0.1,0.7-8.6,0.8-14.5c0-1.9-0.9-4.9-2.2-8.2c0.9-2.4,8.5-23.8,8.9-37.2
                    C1066.7,224,1062.4,219.9,1062.3,219.7z" />
                </g>
                <g>
                  <path class="st1"
                    d="M198.6,254.9c-10.5-0.3-21.9-4-21.7-20.9c0.3-16.9,12.5-19.5,13.8-32.2c1.3-12.6,1.3-24.4,7.9-24.5
                    c6.6-0.1,2.4,5.3,5.5,11.1c3.1,5.9,9,13.4,7.9,24.3c-1.2,11,6.6,15.1,6.2,24.1C217.8,245.9,212.2,255.4,198.6,254.9z" />
                  <path class="st2" d="M202.5,211.8c-0.2-0.2-0.5-0.2-0.6,0c-0.2,0.2-0.2,0.5,0,0.6c0,0,2.7,2.8,2.5,12.1
                    c-0.2,8.1-4.4,20.6-5.8,24.3c-1.2-2.9-2.8-6.2-4.2-8.9c-0.9-1.8-1.7-3.4-2.1-4.4c-1.6-3.7,0.8-11.4,0.8-11.5
                    c0.1-0.2-0.1-0.5-0.3-0.6s-0.5,0.1-0.6,0.3c-0.1,0.3-2.5,8.1-0.8,12.1c0.4,1,1.2,2.6,2.2,4.5c2.5,5,6.2,12.5,6.1,15.4
                    c-0.1,4.1-0.6,9.9-0.6,9.9c0,0.3,0.2,0.5,0.4,0.5l0,0c0.2,0,0.4-0.2,0.5-0.4c0-0.1,0.5-5.9,0.6-10c0-1.3-0.6-3.3-1.5-5.7
                    c0.6-1.7,5.9-16.4,6.1-25.6C205.5,214.8,202.6,211.9,202.5,211.8z" />
                </g>
                <g>
                  <path class="st1" d="M1251,411.8c-18.4-0.6-38.3-7-37.9-36.5s21.9-34.1,24.1-56.2s2.3-42.6,13.8-42.9c11.5-0.2,4.2,9.2,9.6,19.5
                    s15.8,23.3,13.8,42.5c-2,19.1,11.6,26.5,10.9,42.1C1284.6,395.9,1274.8,412.5,1251,411.8z" />
                  <path class="st2" d="M1257.8,336.4c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1c0,0,4.8,4.8,4.3,21.1
                    c-0.4,14.1-7.7,36-10.1,42.5c-2.2-5.1-5-10.8-7.3-15.6c-1.6-3.2-3-6-3.7-7.8c-2.7-6.4,1.4-19.9,1.5-20c0.1-0.4-0.1-0.9-0.5-1
                    s-0.9,0.1-1,0.5c-0.2,0.6-4.4,14.2-1.4,21.1c0.8,1.8,2.2,4.6,3.8,7.9c4.3,8.7,10.8,21.8,10.7,27c-0.1,7.1-1,17.3-1,17.4
                    c0,0.4,0.3,0.8,0.7,0.9c0,0,0,0,0.1,0c0.4,0,0.8-0.3,0.8-0.7c0-0.1,0.9-10.3,1-17.5c0-2.3-1-5.8-2.6-9.9
                    c1.1-2.9,10.3-28.7,10.7-44.7C1263.2,341.6,1258.1,336.6,1257.8,336.4z" />
                </g>
                <g>
                  <g>
                    <path class="st3" d="M896.2,474.7c-12.9,4.3-3.2,22.5-3.2,35.3s-6.4,48.1,6.4,54.5s32.1,19.3,32.1,32.1s3.2,28.9,16,35.3
                      s25.7,6.4,25.7,16s-6.4,12.8-3.2,22.5c3.2,9.6,9.6,12.8,6.4,16h83.4c0,0,16-25.7,12.8-41.7s-19.3-16-22.5-28.9
                      c-3.2-12.8-19.3-16-19.3-28.9c0-12.8-6.4-32.1-16-38.5s-28.9,0-35.3-12.8s0-25.7-9.6-32.1c-9.6-6.4-28.9,3.2-38.5-6.4
                      C921.9,487.5,905.8,471.5,896.2,474.7z" />
                    <path class="st4" d="M909.1,540c0.2-0.9,1.1-1.3,2-1.1c0.2,0,14.8,4.2,25.2,4.1c-7.2-27.4-22.8-33.1-22.9-33.1
                      c-0.8-0.3-1.3-1.2-1-2s1.2-1.3,2-1s20.2,7.1,26.7,42.9c1.5,8.3,6.9,16.2,14.2,23.7c6.2-6.2,8.1-25.7,8.3-33.1
                      c0-0.9,0.7-1.6,1.6-1.6l0,0c0.9,0,1.6,0.8,1.6,1.6c0,1.1-0.9,26.8-9.3,35.3c8.1,7.9,18.2,15.5,28.1,23
                      c10.6,8,20.9,15.8,28.8,23.7c1.3-3.2,1.5-13.4,0.5-24.2c-0.1-0.9,0.6-1.7,1.5-1.7s1.7,0.6,1.7,1.5c0.6,6.8,1.6,21.8-1.5,26.8
                      c5.3,5.7,9.2,11.6,11.1,17.8c0.3,0.8-0.2,1.7-1.1,2c-0.2,0-0.3,0.1-0.5,0.1c-0.7,0-1.3-0.4-1.5-1.1
                      c-3.9-13.1-18.4-25.1-34.8-37.6c-8.9,8.4-33.1,9.8-34.2,9.8h-0.1c-0.8,0-1.6-0.7-1.6-1.5c0-0.9,0.6-1.6,1.5-1.7
                      c0.2,0,23.4-1.4,31.8-8.6c-1.2-0.9-2.4-1.8-3.6-2.7c-20.8-15.6-42.2-31.8-45.7-51c-0.3-1.5-0.6-2.8-0.9-4.2
                      c-0.5,0-0.9,0.1-1.4,0.1c-10.8,0-24.8-4-25.5-4.2C909.3,541.8,908.8,540.9,909.1,540z" />
                  </g>
                  <path class="st5"
                    d="M1088.4,481.1c16.7,8.4,0,44.9,3.2,57.8c3.2,12.8,12.8,22.5,9.6,35.3c-3.2,12.8-9.6,22.5-9.6,35.3
                    s19.3,25.7,16,48.1c-3.2,22.5-12.8,28.9-12.8,28.9h-70.6c0,0-6.4-6.4-6.4-19.3c0-12.8,16-25.7,12.8-32.1s-16-19.3-12.8-41.7
                    c3.2-22.5,12.8-22.5,16-35.3s-3.2-19.3,0-28.9c3.2-9.6,19.3-19.3,22.5-25.7C1059.5,497.2,1069.1,471.5,1088.4,481.1z" />
                  <path class="st6"
                    d="M1034.4,593.8c0.7-0.6,1.7-0.5,2.3,0.2c3.2,3.8,11.4,13.1,16.6,17.3c-0.1-2.2-0.2-4.4-0.2-6.6
                    c0-10,0-20.1,0.5-29.9c0-0.1,0-0.1,0-0.2c0.4-8.7,1.2-17.1,2.7-24.8c3.2-16.2,18.9-38.2,19.5-39.1c0.5-0.7,1.5-0.9,2.2-0.4
                    s0.9,1.5,0.4,2.2c-0.2,0.2-15.9,22.4-19,37.9c-1.2,5.9-1.9,12.4-2.4,19.1c5.8-6,15.6-11,16.1-11.2c0.8-0.4,1.8-0.1,2.2,0.7
                    c0.4,0.8,0.1,1.8-0.7,2.2c-0.1,0.1-14.3,7.2-17.9,14c-0.5,9.6-0.5,19.6-0.5,29.5c0,8.5,1.3,17.7,2.4,25.8
                    c1.1,7.7,2,14.2,1.6,18.8c5.6-3.2,14.9-7,16.2-7.5c0.8-0.3,1.8,0.1,2.1,0.9c0.3,0.8-0.1,1.8-0.9,2.1c-4.3,1.7-15.9,6.7-18.5,9.1
                    c-3.6,7.3-9.4,22.4-9.9,32.8c0,0.9-0.7,1.5-1.6,1.5c0,0,0,0-0.1,0c-0.9,0-1.6-0.8-1.5-1.7c0.5-11,6.6-26.9,10.3-34.3
                    c1.6-3.1,0.3-11.9-1-21.2c-0.7-4.9-1.4-10.1-1.8-15.4c-6.5-3.9-18.9-18.8-19.5-19.4C1033.7,595.4,1033.8,594.4,1034.4,593.8z" />
                </g>
                <g>
                  <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="612.1077" y1="-352.8986"
                    x2="612.7188" y2="-370.0066" gradientTransform="matrix(1 0 0 -1 0 310)">
                    <stop offset="0" style="stop-color:#14336D" />
                    <stop offset="1" style="stop-color:#0E2253" />
                  </linearGradient>
                  <path class="st7" d="M1103.1,685.3c0,6.2-55.7,11.8-145.7,15.9l0,0c-10.7,0.5-21.9,0.9-33.6,1.4c-52.4,2-114.1,3.4-181.4,4.3
                    c-1.2,0-2.5,0-3.7,0c-5.9,0.1-11.9,0.2-17.9,0.2c-34.7,0.3-70.8,0.5-107.9,0.5c-11.3,0-22.5,0-33.5-0.1c-16,0-31.8-0.1-47.3-0.3
                    c-83.2-0.6-159.4-2.2-222.8-4.5c-3.1-0.1-6.3-0.3-9.4-0.3c-24.7-0.9-47.3-2-67.5-3.1c-68.6-3.8-109.7-8.7-109.7-14.1
                    c0-5.7,47-10.9,124.4-14.9c13-0.7,26.9-1.3,41.5-1.9c3.5-0.2,7-0.3,10.6-0.4c1.5,0,3-0.1,4.5-0.2c0.7,0,1.4-0.1,2.1-0.1
                    c59.7-2.2,130.9-3.8,208.9-4.5c16.5-0.2,33.3-0.3,50.3-0.4c15.7-0.1,31.7-0.1,47.9-0.1c37.3,0,73.7,0.2,108.6,0.5
                    c6.2,0.1,12.4,0.2,18.5,0.2c3.8,0.1,7.7,0.1,11.5,0.2c61.5,0.8,117.9,2.2,166.7,4c8.7,0.3,17.2,0.6,25.4,1
                    C1041.5,672.8,1103.1,678.7,1103.1,685.3z" />
                  <g>
                    <polygon class="st8" points="317,29 304.8,0 156.2,34.9 186.7,64.2 				" />
                    <path class="st9" d="M317,29L186.7,64.2c0,0,2.1,31.6,3.8,41.9l145-40.7L317,29z" />

                    <linearGradient id="SVGID_00000017490826837838658910000002142626527740690084_"
                      gradientUnits="userSpaceOnUse" x1="-1035.0624" y1="723.5262" x2="-1213.3068" y2="725.9439"
                      gradientTransform="matrix(-1 -7.900000e-03 -7.900000e-03 1 -664.6019 -403.5178)">
                      <stop offset="0" style="stop-color:#14336D" />
                      <stop offset="1" style="stop-color:#0E2253" />
                    </linearGradient>
                    <path style="fill:url(#SVGID_00000017490826837838658910000002142626527740690084_);" d="M484,594.6
                      c0,0-144.6-153.4-197.1-240.3c-52.5-87-81.5-150.6-100.5-247.1L340,63.8c0,0,79,172.5,146.3,248.7S657,560.6,657,560.6
                      L484,594.6z" />
                    <polygon class="st8" points="829.9,267.8 866.9,239.3 759.3,96.2 740.4,142.8 				" />
                    <polygon class="st9" points="829.9,267.8 740.4,142.8 689.2,158.7 790.8,296.3 				" />
                    <path class="st8" d="M738.1,494c0,0-24.4-68-90.3-88.8c0,0,136.4-95.8,148.2-102.4L686.5,154.2c0,0-152.7,44.5-209.4,124.6
                      S463.2,422,503.9,471.2c40.8,49.3,98.9,89,98.9,89L738.1,494z" />
                    <path class="st9" d="M309.1,702.8c-3.1-0.1-6.3-0.3-9.4-0.3c-24.7-0.9-47.3-2-67.5-3.1c3.5-9.2,8.6-19.4,14.6-28.9
                      c9.5-15.1,21.5-28.8,33.8-35.4c2.6-1.4,5.3-2.5,7.9-3.2l10.5,36.2L309.1,702.8z" />

                    <linearGradient id="SVGID_00000034068006629406653660000012692420182534256547_"
                      gradientUnits="userSpaceOnUse" x1="437.5333" y1="-327.5063" x2="414.9105" y2="-426.9531"
                      gradientTransform="matrix(1 0 0 -1 0 310)">
                      <stop offset="0" style="stop-color:#3162DF" />
                      <stop offset="0.9649" style="stop-color:#173DB8" />
                    </linearGradient>
                    <path style="fill:url(#SVGID_00000034068006629406653660000012692420182534256547_);" d="M579.3,707.5c-16,0-31.8-0.1-47.3-0.3
                      c-83.2-0.6-159.4-2.2-222.8-4.5c-3.1-0.1-6.3-0.3-9.4-0.3c-3.7-9.3-7.8-21.9-11.3-33.9c-4.2-14.4-7.5-27.8-7.8-33.5
                      c0-0.3,0-0.7,0-1c0.1-11.5,77-64.1,190.8-42.1c4.1,0.8,8.3,1.7,12.5,2.7c39.6,9.4,65.5,35.8,80.9,68.4
                      C571.5,677,576.3,692.2,579.3,707.5z" />

                    <linearGradient id="SVGID_00000101803975007610050020000011470946412395280313_"
                      gradientUnits="userSpaceOnUse" x1="859.6946" y1="-341.0738" x2="765.2094" y2="-442.8716"
                      gradientTransform="matrix(1 0 0 -1 0 310)">
                      <stop offset="0" style="stop-color:#3162DF" />
                      <stop offset="0.9649" style="stop-color:#173DB8" />
                    </linearGradient>
                    <path style="fill:url(#SVGID_00000101803975007610050020000011470946412395280313_);" d="M957.4,701.2
                      c-10.7,0.5-21.9,0.9-33.6,1.4c-52.4,2-114.1,3.4-181.4,4.3c-1.2,0-2.5,0-3.7,0c-5.9,0.1-11.9,0.2-17.9,0.2
                      c6.4-14.7,12.9-29.4,19.2-43.4c7.4-16.2,14.5-31.4,21.1-44.7l0,0c12.9-25.9,23.6-44.5,29.4-48.3c23.7-15.6,51.6-12.5,99.9,25.1
                      c21.4,16.7,39.7,45.4,53.1,73C948.9,680.1,953.6,691.2,957.4,701.2z" />

                    <linearGradient id="SVGID_00000030466211624571626890000017464207134027537062_"
                      gradientUnits="userSpaceOnUse" x1="626.2271" y1="-346.4812" x2="646.8491" y2="-515.5172"
                      gradientTransform="matrix(1 0 0 -1 0 310)">
                      <stop offset="0" style="stop-color:#3162DF" />
                      <stop offset="0.9649" style="stop-color:#173DB8" />
                    </linearGradient>
                    <path style="fill:url(#SVGID_00000030466211624571626890000017464207134027537062_);" d="M761,619L761,619c-0.2,2.1-0.5,4-0.8,6
                      c-1.6,10.1-4.7,23.6-8.8,38.9c-3.6,13.3-8,27.9-12.8,43c-5.9,0.1-11.9,0.2-17.9,0.2c-34.7,0.3-70.8,0.5-107.9,0.5
                      c-11.3,0-22.5,0-33.5-0.1c-16,0-31.8-0.1-47.3-0.3c-4.6-16.6-10.7-31.1-17.4-43.9c-17.1-33.2-37.5-55-43.1-71.5
                      c-0.4-1.3-0.8-2.5-1-3.7c-0.3-1.5,0.6-3.3,2.5-5.5c9.8-11.5,47.5-32,91.4-52.1l0,0c18.9-8.7,38.9-17.4,58.4-25.2
                      c45.9-18.5,88.6-32.6,105.1-32.4c2.5,0,4.3,0.4,5.5,1.1C755,486.3,767.7,565.1,761,619z" />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </figure>
        <!-- SVG END -->
        <!-- 404 info -->
        <h1 class="display-1 mt-4">404</h1>
        <h2 class="mb-2 h1">Page Not Found!</h2>
        <p>Either something went wrong or this page doesn't exist anymore.</p>
        <!-- Button -->
        <a class="btn btn-primary-soft btn-sm" href="index.html">Got to home page</a>
      </div>
      <!-- Error 404 START -->
    </div>
    <!-- Extra space -->
    <div class="h-100px d-none d-lg-block"></div>
    <!-- Row END -->
  </div>
</main>
