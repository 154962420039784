import { Component, Inject, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IssueType, JIssue } from 'src/app/shared/interfaces/jira.model';
import { Job, Project } from 'src/app/shared/interfaces/model';

@Component({
  selector: 'app-issue-modal',
  templateUrl: './issue-modal.component.html',
  styleUrls: ['./issue-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IssueModalComponent implements OnInit, OnChanges {

  constructor(@Inject(MAT_DIALOG_DATA) public data: Issue) { }
  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnInit(): void {
    
  }

}

export interface Issue {
  issue: JIssue,
  job: Job,
  project?: Project
}