import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostBinding, OnDestroy, OnInit, Output} from "@angular/core";
import { Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../../shared/services/auth.service";
import {Model, PersonalData, StateAddress, Image, Notification, Page} from "../../shared/interfaces/model";
import { fromEvent, Observable, Subscription } from "rxjs";
import { LanguagesService } from "src/app/shared/services/languages.service";
import { faShare, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import { ImageService } from "src/app/shared/services/image.service";
import { UserService } from "src/app/shared/services/user.service";
import { SenderService } from "src/app/shared/services/sender.service";
import { NotificationHelperService } from "src/app/shared/utils/notification-helper.service";
import { io, Socket } from 'socket.io-client';
import { environment } from "src/assets/environments/environment";
import {NodeNotifications} from "../../shared/interfaces/node-notifications";
import {NodeNotificationHelperService} from "../../shared/utils/node-notification-helper.service";
import { distinctUntilChanged, filter, map, pairwise, share, shareReplay, throttleTime } from "rxjs/operators";
import { PagesService } from "src/app/shared/services/pages.service";
import { User } from "src/app/shared/interfaces/user.model";
import { trigger, state, style, transition, animate } from "@angular/animations";


enum VisibilityState {
  Visible = 'visible',
  Hidden = 'hidden'
}

enum Direction {
  Up = 'Up',
  Down = 'Down'
}


@Component({
  selector: "app-bottom-nav",
  templateUrl: "./bottom-nav.component.html",
  styleUrls: ["./bottom-nav.component.scss"]
})
export class BottomNavComponent implements OnInit, OnDestroy, AfterViewInit {
  faCoffee = faShare;
  theme;
  public authStatus = "Login";
  public currentUser: User;
  public personalData: PersonalData;
  public addressData: StateAddress;
  public model = new Model();
  private subscriptions: Subscription;
  public model$: Observable<Model>;
  public language = [];
  public image;
  public hasPhoto: boolean;
  public getFirstName;
  public getLastName;
  public users: User[] = [];
  public searchUsers: User[] = [];
  public search = "";
  public notifications: Notification[] =[];
  private socket: Socket;
  notificationNumber;
  public pages: Page[] = [];
  private isVisible = true;
  notifications$:  Observable<User[]>;

  @Output() emitThemeChange: EventEmitter<any> = new EventEmitter<any>();




  constructor(public router: Router,
              public translate: TranslateService,
              public authService: AuthService,
              public cd: ChangeDetectorRef,
              public languagesService: LanguagesService,
              public imageService: ImageService,
              public userService?: UserService,
              public sendData?: SenderService,
              public notificationHelper?: NotificationHelperService,
              public pageService?: PagesService,
              public nodeNotification?: NodeNotificationHelperService) {
                // this.socket = io(environment.backend);
              }

  ngOnInit() {
    const id = this.authService.userID;
    if(this.authService.isLoggedIn) {
      this.authStatus = "Logout";
      this.numberOfNotifications();
      this.userService.getUserContactData(id).subscribe(res => {
        this.currentUser = res;
        console.log(this.currentUser?.notifications?.followers?.counter)
      })

    this.userService.getUsers();
    this.userService.userModel().subscribe(res => {
      this.users = res;
    });
    this.pageService.allPages.subscribe(res => {
      this.pages = res;
    });

  } else if (this.router.url.startsWith("/user/")) {
      // tslint:disable-next-line:no-shadowed-variable
    const id = this.router.url.slice(6, 100);
  }
  }
  ngAfterViewInit() {
    const scroll$ = fromEvent(window, 'scroll').pipe(
      throttleTime(10),
      map(() => window.pageYOffset),
      pairwise(),
      map(([y1, y2]): Direction => (y2 < y1 ? Direction.Up : Direction.Down)),
      distinctUntilChanged(),
      share()
    );

    const goingUp$ = scroll$.pipe(
      filter(direction => direction === Direction.Up)
    );

    const goingDown$ = scroll$.pipe(
      filter(direction => direction === Direction.Down)
    );

    goingUp$.subscribe(() => (this.isVisible = true));
    goingDown$.subscribe(() => (this.isVisible = false));
  }
  ngOnDestroy():void {
    this.subscriptions.unsubscribe();
  }
  public login() {
    this.router.navigateByUrl("/login");
  }
  switchLang(event) {
    const language = event.substring(0, 2);
    this.translate.use(language);
    this.model.language = language;
    // this.modelDataService.updateDate(this.model._id, this.model);
  }
  navigate(url) {
    if (this.authService.isLoggedIn && url === "/cv") {
      this.router.navigate(["/cv/" + this.currentUser._id], {replaceUrl: true});
    }
    return this.router.navigateByUrl(url);
  }
  navigateToNotifications() {
    this.router.navigateByUrl("/notifications");

  }


  public logOut() {
    this.authService.logout();
    this.authStatus = "Login";
    const browserLang = this.translate.getBrowserLang();
    this.translate.use(browserLang);

  }
  addItem(newItem: string) {
    this.search = newItem;
  }

  makeSidebarActive() {
    document.getElementById("sidebar__left").style.display = "block";
  }
  public numberOfNotifications() {
    this.notificationNumber = this.notifications.filter(t => t.seen === false).length;
  /*  this.socket.on("newTaskAdded", (clientId) => {
        this.nodeNotification.loadNodeNotifications(this.authService.userID).subscribe((result: NodeNotifications) => {

          if(result.reminders.all) {
            this.notificationNumber = this.notifications.filter(t => t.seen === false).length;
          }
    });
  }); */
  }

  searchMore(event) {
   this.router.navigate(["search"], {state: {example: event}});
   this.search = "";
  }
  addDarkMode() {
   localStorage.setItem("theme", "dark")
   this.emitThemeChange.emit()
  }

  addLightMode() {
    localStorage.setItem("theme", "light");
    this.emitThemeChange.emit()

  }
  navigateToUser() {
    const test = [this.currentUser, "outside"];
       this.sendData.send(test);
       this.router.navigate([ '../' +this.currentUser.userUrl, "start" ]);
    }


    acceptFollow(user: User) {
      user.following.data.find(t => t.userId === this.currentUser._id).status = 1;
      user.following.data.find(t => t.userId === this.currentUser._id).timestamp = new Date();
      this.currentUser.followers.counter = this.currentUser.followers.counter + 1;

      this.currentUser.notifications.followers.counter =  this.currentUser.notifications.followers.counter - 1;

      this.userService.updateUser(this.currentUser._id, this.currentUser);
      this.userService.updateUser(user._id, user);
    }

    deleteFollow(user: User) {
      const userData  =  user.following.data.findIndex(t => t.userId === this.currentUser._id);
      user.following.data.splice(userData, 1);
      this.currentUser.notifications.followers.counter =   this.currentUser.notifications.followers.counter - 1;
      this.userService.updateUser(this.currentUser._id, this.currentUser);
      this.userService.updateUser(user._id, user);
    }

}

