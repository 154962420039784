import { HttpClient } from "@angular/common/http";
import { Component, NgZone, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription, Observable } from "rxjs";
import { take } from "rxjs/operators";
import { Role } from "src/app/shared/enums/role";
import { Categories, CategoryChildren, Keywords, Page, Roles } from "src/app/shared/interfaces/model";
import { User } from "src/app/shared/interfaces/user.model";
import { AuthService } from "src/app/shared/services/auth.service";
import { BranchesService } from "src/app/shared/services/branches.service";
import { CategoryService } from "src/app/shared/services/category.service";
import { ImageService } from "src/app/shared/services/image.service";
import { LanguagesService } from "src/app/shared/services/languages.service";
import { PagesService } from "src/app/shared/services/pages.service";
import { SenderService } from "src/app/shared/services/sender.service";
import { UserService } from "src/app/shared/services/user.service";

@Component({
  selector: "app-create-page",
  templateUrl: "./create-page.component.html",
  styleUrls: ["./create-page.component.scss"]
})
export class CreatePageComponent implements OnInit {
  public page = new Page();
  search;
  searchCategory;
  public branch = "";
  public categories: Categories;
  selectedKeywords: Keywords[];
  selectedChildren: CategoryChildren[];
  keyword = "";
  selectedCategory = "";
  selectedSubCategory = "";
  public selectedChild = null
  public currentUser: User;
  hasPhoto;


  constructor(public router: Router,
    public branchesService: BranchesService,
    public authService: AuthService,
    public categoryService: CategoryService,
    public pageService: PagesService,
    public userService: UserService,
    public shareData: SenderService) { }

  ngOnInit(): void {
    this.categoryService.getCategories();
    this.branchesService.getBranches();
    this.userService.getUserContactData(this.authService.userID).pipe(take(1)).subscribe(res => {
      if (res) {
        this.currentUser = res;
      }
    });
  }
  addNewPage(myForm: NgForm) {
    this.page.branches = this.branch;
    this.page.categories = this.categories;
    this.page.locations = [];
    this.page.phone = this.page.phone;
    this.page.pageUrl = `${this.page.name.replace(/\s/g, "")}${"-Page"}${Math.floor(Math.random() * 1000000000)}`;
    this.page.roles = {};
    this.page.roles.Admin = [];
    this.page.roles.Owner = [];
    this.page.roles.Advertiser = [];
    this.page.roles.Analyst = [];
    this.page.roles.Editor = [];
    this.page.roles.Moderator = [];
    this.page.roles.User = [];
    const selectedBranch = myForm.controls[("selectedBranch")].value;
    this.page.branches = selectedBranch;
    this.page.categories = {
      name: this.selectedCategory, subCategory: [
        { name: this.selectedSubCategory, keywords: this.selectedKeywords }]
    };
    // this.page.categories.name = this.selectedCategory;
    //  this.page.categories.subCategory.unshift({name: this.selectedSubCategory, keywords: this.selectedKeywords})
    this.page.roles.Admin.unshift({ userID: this.authService.userID, timestamp: new Date() });
    this.page.roles.Owner.unshift({ userID: this.authService.userID, timestamp: new Date() });
    this.page.roles.Advertiser.unshift({ userID: this.authService.userID, timestamp: new Date() });
    this.page.roles.Analyst.unshift({ userID: this.authService.userID, timestamp: new Date() });
    this.page.roles.Editor.unshift({ userID: this.authService.userID, timestamp: new Date() });
    this.page.roles.Moderator.unshift({ userID: this.authService.userID, timestamp: new Date() });
    this.page.roles.User.unshift({ userID: this.authService.userID, timestamp: new Date() });
    this.pageService.setPage(this.page).subscribe((r) => {
    }
    );
    this.selectedKeywords = [];
    setTimeout(() => {
      this.router.navigateByUrl("/pages");
    }, 1000);

  }


  createPage(myForm: NgForm) {
    this.page.branches = this.branch;
    this.page.categories = this.categories;
    this.page.locations = [];
    this.page.phone = this.page.phone;
    this.page.pageUrl = `${this.page.name.replace(/\s/g, "")}${"-Page"}${Math.floor(Math.random() * 1000000000)}`;
    this.page.roles = {};
    this.page.roles.Admin = [];
    this.page.roles.Owner = [];
    this.page.roles.Advertiser = [];
    this.page.roles.Analyst = [];
    this.page.roles.Editor = [];
    this.page.roles.Moderator = [];
    this.page.roles.User = [];
    this.page.roles.Jobs = [];
    this.page.roles.Projects = [];
    this.page.roles.Messages = [];
    this.page.roles.Notifications = [];
    this.page.roles.Payments = [];
    this.page.roles.Marketing = [];
    const selectedBranch = myForm.controls[("selectedBranch")].value;
    this.page.branches = selectedBranch;
    this.page.categories = {
      name: this.selectedCategory, subCategory: [
        { name: this.selectedSubCategory, keywords: this.selectedKeywords }]
    };
    // this.page.categories.name = this.selectedCategory;
    //  this.page.categories.subCategory.unshift({name: this.selectedSubCategory, keywords: this.selectedKeywords})
    this.page.roles.Admin.unshift({ userID: this.authService.userID, timestamp: new Date() });
    this.page.roles.Owner.unshift({ userID: this.authService.userID, timestamp: new Date() });
    this.page.roles.Advertiser.unshift({ userID: this.authService.userID, timestamp: new Date() });
    this.page.roles.Analyst.unshift({ userID: this.authService.userID, timestamp: new Date() });
    this.page.roles.Editor.unshift({ userID: this.authService.userID, timestamp: new Date() });
    this.page.roles.Moderator.unshift({ userID: this.authService.userID, timestamp: new Date() });
    this.page.roles.User.unshift({ userID: this.authService.userID, timestamp: new Date() });
    this.page.roles.Jobs.unshift({ userID: this.authService.userID, timestamp: new Date() });
    this.page.roles.Projects.unshift({ userID: this.authService.userID, timestamp: new Date() });
    this.page.roles.Messages.unshift({ userID: this.authService.userID, timestamp: new Date() });
    this.page.roles.Notifications.unshift({ userID: this.authService.userID, timestamp: new Date() });
    this.page.roles.Payments.unshift({ userID: this.authService.userID, timestamp: new Date() });
    this.page.roles.Marketing.unshift({ userID: this.authService.userID, timestamp: new Date() });
    this.pageService.setPage(this.page).subscribe((r) => {
    }
    );
    this.selectedKeywords = [];
    setTimeout(() => {
      this.router.navigateByUrl("/pages");
    }, 1000);

    this.selectedKeywords = [];
  }
  addBranch(event) {
    this.branch = event.id;
    this.search = "";
  }
  addCategory(event) {
    this.categories = event;
    this.searchCategory = "";
  }
  removeBranch(index) {
    return index;
  }
  showChildren(event) {
    this.selectedCategory = event.id;
    console.log(this.selectedCategory);
    this.selectedChildren = event.subCategory;
    this.selectedKeywords = [];
    //  this.selectedChildren.unshift(event.subCategories);
  }

  addKeyword() {
    if (this.selectedKeywords?.length > 0) {
    } else {
      this.selectedKeywords = [];
    }
    this.selectedKeywords.unshift({ name: this.keyword });
    this.keyword = null;
  }
  showKeywords(event) {
    console.log(event);
    this.selectedSubCategory = event.id;
    this.selectedKeywords = event.keywords;
    this.selectedKeywords = [];
  }
  removeKeyword(index) {
    console.log(this.selectedKeywords, "test")
    this.selectedKeywords.splice(index, 1);

  }
  /* removeCategory(index) {
    this.categories.splice(index, 1);
  } */

  navigateToPayment() {
    localStorage.setItem("payment", "isUser");
    this.shareData.paymentDataVisibility(this.currentUser);
    this.router.navigateByUrl(this.currentUser?.userUrl + '/payments');
  }

  navigateToUser() {
    const test = [this.currentUser, "outside"];
    this.shareData.send(test);
    this.router.navigate([this.currentUser.userUrl, "start"]);
  }

}
