<div class="Unit-unit">

  <div *ngIf="skillObj?.skillsOffer.data?.length > 0" class="Unit-unit"
    [ngClass]="{ 'isActive': selectedSkill === id && checkId}" (mouseenter)="setIndexForSkill(id)"
    (mouseleave)="removeClick()" (click)="setIndexForSkill(id)">
    <div class="Line-line-container" pageContent>
      <div class="Line-line-line">
        <div class="Field-field-fieldBase first-template-fieldField">
          <div class="Text-Text-wrapper">
            <div class="Text-Text-text">
              {{'skills.offer' | translate}}
            </div>
          </div>
        </div>
        <div class="Field-field-fieldBase first-template-fieldValue">
          <div class="Text-Text-wrapper">
            <div class="Text-Text-text-wrapper">
              <app-chips [chips]="skillObj?.skillsOffer.data" stopPropagation></app-chips>
            </div>
          </div>
        </div>
        <ng-container *ngIf="selectedSkill === id && checkId ">
          <div clickOutside (clickOutside)="removeClick()">
            <ul>
              <li class="bi bi-pencil addIconTop"
                (click)="editOffer({edit: true, offer: skillObj.skillsOffer.data, model: model, indexOfLang: indexOfLang})"></li>
              <li class="bi bi-plus addIconBottom" (click)="openSubCatDialog({skillObj: skillObj, model: model, indexOfLang: indexOfLang})"></li>
              <li class="bi bi-trash deleteIconRight" (click)="deleteSkillOffer(id)"></li>
              <li class="bi bi-arrow-down moveIconDown"></li>
              <button class="btn" [disabled]="isFirst" (click)="swapArrayTop(skillObj, id, id)">
                <li class="bi bi-arrow-up moveIconTop"></li>
              </button>
            </ul>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <!-- PC Knowledge -->
  <div *ngIf="skillObj?.pcKnowledge.length > 0" class="Unit-unit"
    [ngClass]="{ 'isActive': selectedPCKnowledge === id  && checkId}" (mouseenter)="setIndexForPcKnowledge(id)"
    (mouseleave)="removeClick()" (click)="setIndexForPcKnowledge(id)">
    <div class="Line-line-container">
      <div class="Line-line-line">
        <div class="Field-field-fieldBase first-template-fieldField">
          <div class="Text-Text-wrapper">
            <div class="Text-Text-text">
              {{'skills.pcKnowledge' | translate}}
            </div>
          </div>
        </div>
        <div class="Field-field-fieldBase first-template-fieldValue">
          <div class="Text-Text-wrapper">
            <div class="Text-Text-text-wrapper">
              <app-chips [chips]="skillObj.pcKnowledge.data" stopPropagation></app-chips>
            </div>
          </div>
        </div>
        <ng-container *ngIf="selectedPCKnowledge === id && checkId ">
          <div clickOutside (clickOutside)="removeClick()">
            <ul>
              <li class="bi bi-pencil addIconTop"
                (click)="editPCKnowledge({edit: true, pcKnowledge: skillObj.pcKnowledge, model: model, indexOfLang: indexOfLang})"></li>
              <li class="bi bi-plus addIconBottom" (click)="openSubCatDialog({skillObj: skillObj, model: model})"></li>
              <li class="bi bi-trash deleteIconRight" (click)="deletePCKnowledge(id)"></li>
              <li class="bi bi-arrow-down moveIconDown"></li>
              <li class="bi bi-arrow-up moveIconTop"></li>
            </ul>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <!-- Languages -->

  <div pageContent class="Unit-unit" *ngIf="skillObj.languages.data.length > 0">
    <div class="Unit-unit" (mouseenter)="setIndexLanguageFirst(id)" (mouseleave)="removeClick()"
      (click)="setIndexLanguageFirst(id)">
      <div [ngClass]="{ 'isActive': selectedLanguageFirst === id  && checkId}" class="Line-line-container">
        <div class="Line-line-line">
          <div class="Field-field-fieldBase first-template-fieldField">
            <div class="Text-Text-wrapper">
              <div class="Text-Text-text">
                {{'skills.language' | translate}}
              </div>
            </div>
          </div>
          <div *ngFor="let language of skillObj.languages.data; let iLang = index">
            <div pageContent class="Field-field-fieldBase first-template-fieldValue">
              <div class="Text-Text-wrapper">
                <div class="Text-Text-text-wrapper">
                  <div class="progress">
                    <div id="rate" [style.background]="'#d9d9d9'" [style.width]="language.rate + '%'"
                      style="display: flex; white-space: pre-wrap;" [style.line-height.px]="13"
                      [style.font-size.px]="10">
                      <div style="display: flex; white-space: pre-wrap; padding-left: .5rem;" id="language">
                        {{getLanguageName(language.name)?.value}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="selectedLanguageFirst === id && checkId">
            <div clickOutside (clickOutside)="removeClick()">
              <ul>
                <li class="bi bi-pencil addIconTop"
                  (click)="editLanguages({edit: true, language: skillObj.languages, model: model, indexOfLang: indexOfLang})"></li>
                <li class="bi bi-plus addIconBottom"
                  (click)="openSubCatDialog({model: model, language: true, skills: skills, skillObj: skillObj})"></li>
                <li class="bi bi-trash deleteIconRight" (click)="deleteLanguageFirst(id)"></li>
                <li class="bi bi-arrow-down moveIconDown"></li>
                <li class="bi bi-arrow-up moveIconTop"></li>
              </ul>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div *ngFor="let language of skillObj?.languages" class="Unit-unit">
    <div class="Unit-unit" [ngClass]="{ 'isActive': selectedRow && checkId}" (clickOutsideInner)="removeClick()">
      <app-empty-row *ngFor="let empty of language.spacesBetween; let id = index" [space]="empty" [id]="id"
        [model]="model" (eventName)="doSomething($event)" [selectedRow]="selectedRow" [skillObj]="skillObj">
      </app-empty-row>
    </div>
  </div>


  <!-- Drive Licence -->
  <div pageContent *ngIf="skillObj?.driveLicenses?.data?.length > 0" class="Unit-unit"
    [ngClass]="{ 'isActive': selectedDriveLicense === id  && checkId}" (mouseenter)="setIndexDriveLicense(id)"
    (mouseleave)="removeClick()" (click)="setIndexDriveLicense(id)">
    <div class="Line-line-container">
      <div class="Line-line-line">
        <div class="Field-field-fieldBase first-template-fieldField">
          <div class="Text-Text-wrapper">
            <div class="Text-Text-text">
              {{'skills.driveLicense' | translate}}
            </div>
          </div>
        </div>
        <div class="Field-field-fieldBase first-template-fieldValue">
          <div class="Text-Text-wrapper">
            <div class="Text-Text-text-wrapper">
              <app-chips [chips]="skillObj?.driveLicenses?.data" stopPropagation></app-chips>
            </div>
          </div>
        </div>

        <ng-container *ngIf="selectedDriveLicense === id && checkId">
          <div clickOutside (clickOutside)="removeClick()">
            <ul>
              <li class="bi bi-pencil addIconTop"
                (click)="editDriveLicense({edit: true, driveLicense: skillObj.driveLicenses.data, model: model, indexOfLang: indexOfLang})"></li>
              <li class="bi bi-plus addIconBottom" (click)="openSubCatDialog({skillObj: skillObj, model: model})"></li>
              <li class="bi bi-trash deleteIconRight" (click)="deleteDriveLicense(id)"></li>
              <button class="btn" [disabled]="isLast" (click)="swapArray(skillObj, id, id)">
                <li class="bi bi-arrow-down moveIconDown"></li>
              </button>
              <button class="btn" [disabled]="isFirst" (click)="swapArrayTop(skillObj, id, id)">
                <li class="bi bi-arrow-up moveIconTop"></li>
              </button>
            </ul>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div *ngIf="skillObj?.qualifications?.data?.length > 0" class="Line-line-container" pageContent
    [ngClass]="{ 'isActive': selectedQualifications === id}"
    (mouseenter)="setQualifications(id)"
   (mouseleave)="removeClick()" (click)="setQualifications(id)"
   >
    <div class="Line-line-line">
     <div class="Field-field-fieldBase first-template-fieldField">
       <div class="Text-Text-wrapper">
         <div class="Text-Text-text">
           {{'skills.qualifications' | translate}}
         </div>
       </div>
     </div>
     <div *ngFor="let qualificationsObj of skillObj.qualifications.data; let iLang = index">
       <div pageContent class="Field-field-fieldBase first-template-fieldValue">
         <div class="Text-Text-wrapper">
           <div class="Text-Text-text-wrapper">
                   {{qualificationsObj.name}}
           </div>
         </div>
       </div>
     <ng-container *ngIf="selectedQualifications === id && checkId">
       <div clickOutside (clickOutside)="removeClick()">
         <ul>
           <li class="bi bi-pencil addIconTop"
             (click)="editQualifications({edit: true, qualifications: skillObj.qualifications.data, model: model, user: user, index: indexOfSelected})"></li>
           <li class="bi bi-plus addIconBottom"
             (click)="openSubCatDialog({model: model, language: true, skills: skills, skillObj: skillObj})"></li>
           <li class="bi bi-trash deleteIconRight" (click)="deleteLanguageFirst(id)"></li>
           <li class="bi bi-arrow-down moveIconDown"></li>
           <li class="bi bi-arrow-up moveIconTop"></li>
         </ul>
       </div>
     </ng-container>
   </div>
   </div>
  </div>
 <!-- Offer -->

  <!--Empty Object -->
  <div pageContent class="Unit-unit">
    <ng-container *ngFor="let emptyObj of skillObj?.emptySubContents; let iEmpty = index">
      <app-empty-object [emptyObj]="emptyObj" [iEmpty]="iEmpty" [model]="model"></app-empty-object>
    </ng-container>
  </div>

</div>
<ng-container *ngIf="selectedIndex === id && checkId ">
  <div clickOutside (clickOutside)="removeClick()">
    <ul>
      <li class="bi bi-plus addIconTop" (click)="openSubCatDialog({skillObj: skillObj, model: model})"></li>
      <li class="bi bi-arrow-down moveIconDown"></li>
      <li class="bi bi-arrow-up moveIconTop"></li>
      <li class="bi bi-trash deleteIconRight" (click)="deleteSubCategory(id)"></li>
    </ul>
  </div>
</ng-container>
