import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Page } from 'src/app/shared/interfaces/model';
import { User } from 'src/app/shared/interfaces/user.model';
import { ChangeDetectionService } from 'src/app/shared/services/change-detection.service';
import { ImageService } from 'src/app/shared/services/image.service';
import { PagesService } from 'src/app/shared/services/pages.service';
import { SenderService } from 'src/app/shared/services/sender.service';

@Component({
  selector: 'app-search-users',
  templateUrl: './search-users.component.html',
  styleUrls: ['./search-users.component.scss']
})
export class SearchUsersComponent implements OnInit {

  @Input() user: User;
  @Input() isBottomSearch = false;
  @Input() isPageSearch = false;
  @Output() newItemEvent = new EventEmitter<string>();
  @Output() addUser = new EventEmitter<User>();
  @Input() page: Page;
  public image;
  public hasPhoto: boolean;

  constructor(
    public sendData: SenderService,
    private router: Router,
    private imageService: ImageService,
    public pageService: PagesService,
    public changeDetector: ChangeDetectionService

    ) { }

  ngOnInit(): void {

   /* this.modelDataService.getUserModel(this.user._id).subscribe((data:Model) => {
      if(data.profilePicture) {
      this.imageService.getImageByID(data.profilePicture).subscribe((res: Image) => {
        if(res.imageUrl) {
          this.image = res.imageUrl;
          this.hasPhoto = true;
        }
      });
    }
    })*/

  }
  navigateToUser(id) {
     this.router.navigate([this.user.userUrl, "start"]);
     localStorage.setItem("userProfileID", this.user._id);
    this.addNewItem("");
  }
  navigateToPage(id) {
    localStorage.setItem("pageID", this.page._id)
    this.pageService.changeMessage(this.page._id);
    this.sendData.send(this.page);
    this.router.navigate([this.page.pageUrl]);
    this.changeDetector.emitChanges(true)

   this.addNewItem("");
 }
  addNewItem(value: string) {
    this.newItemEvent.emit(value);
  }

  addNewUser(user: User) {
    this.addUser.emit(user);
  }
}
