import { Component, Inject, OnInit } from "@angular/core";
import { Carrier, CarrierSubCategory, Group, Image, Model, Page } from "../../../shared/interfaces/model";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import {
  ActivityField, ArchitectureArea, BankArea, CarArea,
  CareerLevel,
  EmploymentSize,
  EmploymentType,
  Organization, WaterArea
} from "../../../shared/enums/enums-model";
import { BranchesService } from "../../../shared/services/branches.service";
import { WarningDialogComponent } from "src/app/sharedComponents/warning-dialog/warning-dialog.component";
import { AuthService } from "src/app/shared/services/auth.service";
import { GroupService } from "src/app/shared/services/group.service";
import { PagesService } from "src/app/shared/services/pages.service";
import { UserService } from "src/app/shared/services/user.service";
import * as QuillNamespace from 'quill';
let Quill: any = QuillNamespace;
import ImageResize from 'quill-image-resize-module';
import { ImageHandler, Options, VideoHandler } from "ngx-quill-upload";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/assets/environments/environment";
import { ImageService } from "src/app/shared/services/image.service";
Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageHandler', ImageHandler);
Quill.register('modules/videoHandler', VideoHandler);
import _ from 'lodash'
import { User } from "src/app/shared/interfaces/user.model";

@Component({
  selector: "app-data-dialog",
  templateUrl: "./data-dialog.component.html",
  styleUrls: ["./data-dialog.component.scss"]
})
export class DataDialogComponent implements OnInit {
  public isHiddenEndDate = false;
  public career: Carrier;
  public selectedValue;
  public selectedArea = [];
  public pageSelected = false;
  public website = "";
  public searchForPage = "";
  public selectedPageID = "";
  public selectedPage: Page;
  public page: Page;
  pageActive = "";
searchForPageTrue = false;



  public confirmationDialog: MatDialogRef<WarningDialogComponent>


  public employmentType = [
    { id: EmploymentType.EmployedFullTime, description: "employmentType.EmployedFullTime" },
    { id: EmploymentType.EmployedPartTime, description: "employmentType.EmployedPartTime" },
    { id: EmploymentType.Internship, description: "employmentType.Internship" },
    { id: EmploymentType.Owner, description: "employmentType.Owner" },
    { id: EmploymentType.BordMember, description: "employmentType.Boardmember" },
    { id: EmploymentType.Volunteer, description: "employmentType.Volunteer" },
    { id: EmploymentType.SelfEmployed, description: "employmentType.SelfEmployed" },
    { id: EmploymentType.Shareholder, description: "employmentType.Shareholder" },
    { id: EmploymentType.Official, description: "employmentType.Official" },
    { id: EmploymentType.Recruiter, description: "employmentType.Recruiter" },
    { id: EmploymentType.Freelancer, description: "employmentType.Freelancer" },
    { id: EmploymentType.Partner, description: "employmentType.Partner" }
  ];

  public employmentSize = [
    { id: EmploymentSize.Employment0, description: "---" },
    { id: EmploymentSize.Employment1, description: "employmentSize.only" },
    { id: EmploymentSize.Employment2, description: "employmentSize.1to10" },
    { id: EmploymentSize.Employment3, description: "employmentSize.11to50" },
    { id: EmploymentSize.Employment4, description: "employmentSize.51to200" },
    { id: EmploymentSize.Employment5, description: "employmentSize.201to1000" },
    { id: EmploymentSize.Employment6, description: "employmentSize.moreThan1000" },
  ];

  public careerLevel = [
    { id: CareerLevel.CareerLevel, description: "---" },
    { id: CareerLevel.CareerLevel1, description: "careerLevel.student" },
    { id: CareerLevel.CareerLevel2, description: "careerLevel.starter" },
    { id: CareerLevel.CareerLevel3, description: "careerLevel.professionalExperience" },
    { id: CareerLevel.CareerLevel4, description: "careerLevel.manager" },
    { id: CareerLevel.CareerLevel5, description: "careerLevel.director" },
    { id: CareerLevel.CareerLevel6, description: "careerLevel.managingDirector" },
  ];
  public organizations = [
    { id: Organization.Organization0, description: "---" },
    { id: Organization.Organization1, description: "organization.listedAG" },
    { id: Organization.Organization2, description: "organization.privateCompany" },
    { id: Organization.Organization3, description: "organization.nonProfit" },
    { id: Organization.Organization4, description: "organization.partnership" },
    { id: Organization.Organization5, description: "organization.oneManBusiness" },
    { id: Organization.Organization6, description: "organization.selfEmployed" }
  ];
  public activityField = [
    { id: ActivityField.EmptyField, description: "---" },
    { id: ActivityField.Finanzen, description: "activityField.finance" },
    { id: ActivityField.Forschung, description: "activityField.research" },
    { id: ActivityField.IT, description: "activityField.it" },
    { id: ActivityField.Kundenbetreuung, description: "activityField.customerCare" },
    { id: ActivityField.Management, description: "activityField.management" },
    { id: ActivityField.Marketing, description: "activityField.marketing" },
    { id: ActivityField.Personalwessen, description: "activityField.humanResource" },
    { id: ActivityField.Produktion, description: "activityField.production" },
    { id: ActivityField.Vertrieb, description: "activityField.distribution" },
    { id: ActivityField.Project, description: "activityField.project" },
    { id: ActivityField.Sonstige, description: "activityField.other" }
  ];
  public architectureArea = [
    { id: ArchitectureArea.Empty, description: "---" },
    { id: ArchitectureArea.Architecture, description: "architectureArea.achitektur" },
    { id: ArchitectureArea.Baugenwerbe, description: "architectureArea.baugewerbe" },
    { id: ArchitectureArea.Bauingenierwesen, description: "architectureArea.bauingenieurwesen" },
    { id: ArchitectureArea.Baustoffe, description: "architectureArea.baustoffe" },
    { id: ArchitectureArea.Garten, description: "architectureArea.garten" },
  ];
  public carArea = [
    { id: CarArea.Empty, description: "---" },
    { id: CarArea.Car, description: "carArea.car" },
    { id: CarArea.Fahrzeug, description: "carArea.fahrzeug" },
    { id: CarArea.Luft, description: "carArea.luft" },
    { id: CarArea.Schienen, description: "carArea.schienen" },
    { id: CarArea.Schiffbau, description: "carArea.schiffbau" },
    { id: CarArea.Verkehrstechnik, description: "carArea.verkehrstechnik" },
  ];
  public bankArea = [
    { id: BankArea.Empty, description: "---" },
    { id: BankArea.Bankwessen, description: "bankArea.bankwesen" },
    { id: BankArea.Finanz, description: "bankArea.finanz" },
    { id: BankArea.Invest, description: "bankArea.invest" },
    { id: BankArea.Risikokapital, description: "bankArea.risikokapital" }
  ];

  public waterArea = [
    { id: WaterArea.Empty, description: "---" },
    { id: WaterArea.Recycling, description: "waterArea.recycling" },
    { id: WaterArea.Energie, description: "waterArea.energyIndustry" },
    { id: WaterArea.EnergieErneuerbare, description: "waterArea.renewableEnergy" },
    { id: WaterArea.Umweltschutz, description: "waterArea.environmentalProtection" },
    { id: WaterArea.Wasserversorgung, description: "waterArea.waterSupply" },
  ];
  public users: User[] = [];
  public pages: Page[] = [];
  public groups: Group[] = [];
  public modules;
  public user: User;
  public searchCompany = "";

  constructor(@Inject(MAT_DIALOG_DATA) public data: EditCareer,
    private dialogRef: MatDialogRef<DataDialogComponent>,
    public auth: AuthService,
    public dialog: MatDialog,
    public http: HttpClient,
    public bsService: BranchesService,
    private userService: UserService,
    private pageService: PagesService,
    public imageService: ImageService,
    private groupService: GroupService) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.bsService.getBranches();
    if (!this.data.edit) {
      this.data.career = {} as CarrierSubCategory;
      this.pageSelected = true;
      this.userService.getUserContactData(this.auth.userID).subscribe(res => {
        this.user = res;
      })

    } else {
      this.userService.getUserContactData(this.auth.userID).subscribe((res: User) => {
        this.user = res;
        res.cv.data[this.data.indexOfLang].careers[0].subCategories[this.data.index] = this.data.career;
        this.data.career =  _.cloneDeep(this.data.career);
        if (this.data.career?.pageID) {
          this.pageService.getPage(this.data.career?.pageID).subscribe(res => {
            this.page = res;

            this.searchForPage = this.page?.name;
            this.pageActive = this.page?.name;
            this.pageSelected = true;
            this.website = this.page?.website;
            this.selectedPage = res;
            this.selectedPageID = this.page._id;
          })
        } else {
          this.searchForPage = this.data.career.name;
          this.pageSelected = true;
          this.website = this.data.career.url;
        }
      });
      ({career: this.data.career} = this.data);
    }
    this.userService.userModel().subscribe(res => {
      this.users = res;
    /*  this.pageService.getAllPages().subscribe(t => {
        this.pages = t;
      }) */
      this.pageService.allPages.subscribe(res => {
        this.pages = res;
      });
      this.groupService.getAllGroups().subscribe(t => {
        this.groups = t;
      })

      this.modules = {
        "emoji-toolbar": true,
        "emoji-textarea": true,
        "emoji-shortname": true,
        mention: {
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ["@", "#"],
          linkTarget: "_blank",
          source: (searchTerm, renderList, mentionChar) => {

            let values = [{ id: "", value: "", link: null }];

            if (mentionChar === "@") {
              this.users.forEach(t => {
                values.unshift({ id: t._id, value: `${t.firstName} ${t.lastName}`, link:window.location.origin + '/' + t._id + "/cv" });

              })
              this.pages.forEach(t => {
                values.unshift({ id: t._id, value: `${t.name}`, link:window.location.origin + '/' + t.pageUrl  });
              });
              this.groups.forEach(t => {
                values.unshift({id: t._id, value: `${t.name}`, link:window.location.origin + '/groups/' + t.url});
              })

            } else if (mentionChar === "@") {


            }
            if (searchTerm.length === 0) {

              renderList(values, searchTerm);
            } else {
              const matches = [];
              for (let i = 0; i < values.length; i++) {
                if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) {
                /*  this.dataService.getUserModel(values[i].id).subscribe((data: Model) => {                  });
                  matches.unshift(values[i]); */
                }


                renderList(matches, searchTerm);

              }
            }
          },
        },
        imageResize: true,
        imageDrop: true,
        imageHandler: {
          upload: (file) => {
            return new Promise((resolve, reject) => {

          if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') { // File types supported for image
            if (file.size < 1000000) { // Customize file size as per requirement

            // Sample API Call
              const uploadData = new FormData();
              uploadData.append('file', file, file.name);

              return this.http.post(`${environment.backend}/image/${file}`, uploadData).toPromise()
              .then((result: Image) => {
                this.imageService.getImageByID(result._id).subscribe((t: Image) => {
                  resolve(t.imageUrl); // RETURN IMAGE URL from response
                 });
            })
                .catch(error => {
                  reject('Upload failed');
                  // Handle error control
                  console.error('Error:', error);
                });
            } else {
              reject('Size too large');
             // Handle Image size large logic
            }
          } else {
            reject('Unsupported type');
           // Handle Unsupported type logic
          }
        });
      },
          accepts: ['png', 'jpg', 'jpeg', 'jfif'] // Extensions to allow for images (Optional) | Default - ['jpg', 'jpeg', 'png']
        } as Options,
      }
    });

  }

  saveData() {
    if (this.auth.isLoggedIn) {
      if (!this.data.edit) {
        this.data.model.careers.map(sub => sub.subCategories.unshift(this.data.career));
        this.user.cv.data[this.data.indexOfLang].careers.map(sub => sub.subCategories.unshift(this.data.career));
        this.userService.updateUser(this.user._id, this.user)
        this.dialogRef.close(this.data);


      if (!this.selectedPageID) {
        const input = document.getElementById("example-search-input5") as HTMLInputElement | null;
        this.data.career.name = input.value;
      }
     } else {
      if (!this.selectedPageID) {
        const input = document.getElementById("example-search-input5") as HTMLInputElement | null;
        if (!!input) {
          this.data.career.name = input.value;
        }
        this.user.cv[this.data.indexOfLang].careers[0].subCategories[this.data.index] = this.data.career;
      }

      }
    //  this.userService.updateUser(this.user._id, this.user);
      if (!!this.selectedPageID && this.data.career.pageID === this.selectedPageID) {
        return;
      } else if (!!this.selectedPageID && this.data.career.pageID !== this.selectedPageID) {
        if (this.data.career.today) {
          this.selectedPage.employers.counter = this.selectedPage.employers.counter + 1;
        } else {
          if ( this.selectedPage.employers.counter > 0) {
            this.selectedPage.employers.counter = this.selectedPage.employers.counter - 1;
          }

        }
        if (typeof this.selectedPage?.notifications?.followers?.counter === 'undefined') {
          const notifications = {
                followers: {
                  counter: +1,
                  seen: false
              }
          }
          this.selectedPage.notifications = notifications;
        } else {
          this.selectedPage.notifications.followers.counter =  this.selectedPage.notifications.followers.counter + 1;
        }




    this.pageService.updatePage(this.selectedPageID, this.selectedPage);
      }


    }
    this.dialogRef.close(this.data);

  }

  test(event?) {
    if (event === 0) {
      this.selectedArea = this.architectureArea;
    } else if (event === 1) {
      this.selectedArea = this.carArea;
    } else if (event === 2) {
      this.selectedArea = this.bankArea;
    } else if (event === 3) {
      this.selectedArea = [];
    } else if (event === 4) {
      this.selectedArea = this.waterArea;
    }
  }
  public autogrow() {
    const textArea = document.getElementById("dialogDescription");
    textArea.style.overflow = "hidden";
    textArea.style.height = "auto";
    textArea.style.height = textArea.scrollHeight + "px";
  }

   addCompany(page: Page) {
    this.pageSelected = true;
    this.pageActive = page.name;
    if (page.name) {
      this.searchForPage = page.name;
    }
    if (page.website) {
      this.website = page.website;
    }
    this.data.career.pageID = page._id;
    if (this.pageSelected) {
      const data = {
        userID: this.auth.userID,
        startDate: this.data.career.startDate,
        endDate: this.data.career.endDate,
        today: this.data.career?.today
      }
      this.selectedPageID = page._id;
      this.selectedPage = page;
      this.data.career.pageID = page._id;
      this.data.career.name = "";
      const removeItem = "name";
      const { [removeItem]: remove, ...rest } = this.data.career;
      this.data.career = rest;
      this.user.cv[this.data.indexOfLang].careers[0].subCategories[this.data.index] = this.data.career;


    }
    this.searchForPageTrue = false;
  }

  addNewCompany(page: Page) {
    this.pageSelected = true;
    this.pageActive = page.name;
    if (page.name) {
      this.searchForPage = page.name;
    }
    if (page.website) {
      this.website = page.website;
    }
    this.data.career.pageID = page._id;
    if (this.pageSelected) {
      const data = {
        userID: this.auth.userID,
        startDate: this.data.career.startDate,
        endDate: this.data.career.endDate,
        today: this.data.career?.today
      }
      this.selectedPageID = page._id;
      this.selectedPage = page;
      this.data.career.pageID = page._id;
      this.data.career.name = "";
      const removeItem = "name";
      const { [removeItem]: remove, ...rest } = this.data.career;
      this.data.career = rest;



    }
    this.searchForPageTrue = false;

  }
  deletePage() {
    this.pageActive = "";
    this.searchForPage = "";
    this.searchForPageTrue = false;
    // tslint:disable-next-line: max-line-length
    if(this.selectedPageID) {
      const removeItem = "pageID";
      const removeCompanyUrl = "companyUrl";
      const { [removeItem] : remove, ...rest } = this.data.career;
      this.data.career = rest;
      this.data.career.companyUrl = "";
      this.user.cv[this.data.indexOfLang].careers[0].subCategories[this.data.index] =  this.data.career;
      // tslint:disable-next-line: max-line-length
      if (this.selectedPage) {
       this.pageService.updatePage(this.selectedPageID, this.selectedPage);
       this.selectedPageID = "";
       this.website = "";
      }
     }
     console.log(this.data.career)

  }
}

export interface EditCareer {
  career?: CarrierSubCategory;
  edit?: boolean;
  model?: Model;
  index?: number;
  indexOfLang?: number;
}
