<div class="container" *ngIf="!data.createJob && !data.createProject && !data.createProjectSkills">
  <div class="modal-header">
    <h3>{{'skills.offer' | translate}}</h3>
  </div>
    <div class="modal-body">
    
      <div class="line" *ngFor="let offer of data.offer; let i = index">
        <div class="row col-12">
          <div class="container d-flex justify-content-center col-12">
            <div class ="col-12 row">
              <div class="col-8">
                <div class="form-outline">
                  <input [(ngModel)]="offer.name"  class="form-control form-control-sm col-md-7">
                </div>
              </div>
              <div class="col-md-3 px-0">
                <app-star-rating stopPropagation [rating]="offer.rate / 20" [starCount]="starCount"
                (ratingUpdated)="onRatingChanged(i, $event)" class="col-4">
              </app-star-rating>
              </div>
              <div class="col-1 px-0">
                <li class="bi bi-dash deleteIcon" (click)="deleteOffer(i)"></li>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container d-flex justify-content-center col-12">
        <div class ="row col-12 row">
          <div class="px-0 col-10">
            <div class="form-outline">
              <input autofocus [(ngModel)]="name"  class="form-control form-control-sm col-8">
            </div>
          </div>
          <div class="col-1" style="right: 1.35rem;
          position: absolute;">
            <button class="addIcon" [disabled]="!name" (click)="addSkill()"> <i class="bi bi-plus"></i></button>
          </div>
          <p class="red" *ngIf="buttonDisabled">You have this skill in your list</p>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <mat-dialog-actions>
        <button type="button" class="btn btn-secondary" stopPropagation mat-button mat-dialog-close>{{'cancel' |
          translate}}</button>
        <button type="button" class="btn btn-success" mat-button (click)="saveData()">{{'save' | translate}}
        </button>
      </mat-dialog-actions>
    </div>
</div>
<div class="container" *ngIf="data.createJob">
  <div class="modal-header">
    <h3>{{'job.secondGroup.tasks' | translate}}</h3>
  </div>
    <div class="modal-body">
      <div class="line" *ngFor="let job of data.job.skillsOffer; let i = index">
        <div class="row col-12">
        <div class="container d-flex justify-content-center">
          <div class ="row col-12">
            <div class="col-md-7">
              <div class="form-outline">
                <input [(ngModel)]="job.name" class="form-control form-control-sm col-md-7">
              </div>
            </div>
            <div class="col-md-3">
              <app-star-rating stopPropagation [rating]="job.rate / 20" [starCount]="starCount"
              (ratingUpdated)="ratingJobSkill(i, $event)" class="col-4">
            </app-star-rating>  
            </div>
            <div class="col-1">
              <li class="fa fa-minus deleteIcon" (click)="deleteOffer(i)"></li>

            </div>
          </div>
        </div>
      </div>
      </div>
      <div class="container d-flex justify-content-center col-12">
        <div class ="row col-12">
          <div class="col-md-9">
            <div class="form-outline">
              <input autofocus [(ngModel)]="name" class="form-control form-control-sm col-10">
            </div>
          </div>
          <div class="col-1" style="right: 4.1rem;
          position: absolute;">
            <button class="addIcon" [disabled]="!name" (click)="addJobSkillOffer()"> <i class="bi bi-plus"></i></button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <mat-dialog-actions>
        <button type="button" class="btn btn-secondary" stopPropagation mat-button mat-dialog-close>{{'cancel' |
          translate}}</button>
        <button type="button" class="btn btn-success" mat-button (click)="saveJobSkills()">{{'save' | translate}}
        </button>
      </mat-dialog-actions>
    </div>
</div>
<div class="container" *ngIf="data.createProject">
  <div class="modal-header">
    <h3>{{'project.dialog.createProjectTitle' | translate}}</h3>
  </div>

    <div class="modal-body">
      <li class="line form-row">
        <div class="col-12 pb-2 pt-2 specialty" id="sp">
          <label>{{"project.secondPage.tasks" | translate}} {{"project.secondPage.keywords" | translate}}</label>
          <div class="card__body d-flex column">
            <div class="d-flex align-items-center card__body__list">
                  <div *ngFor="let sp of data?.project?.tasks; let i=index" class="d-flex justify-content-between pr-1">
                    <div class="card__text">
                      {{sp.name}}
                      <span class="fa fa-remove" (click)="removeProjectTask(i)"></span>
                    </div>
                  </div>
              <input
              class="form-control-sm col-10"
              name="key"
              [(ngModel)]="name">
              <button type="button" class="btn btn-light ms-1 col-1" [disabled]="!name && data?.project?.tasks?.length < 1  " (click)="addProjectTasks()">
                <span> <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                    class="bi bi-plus" viewBox="0 0 16 16">
                    <path
                      d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg></span></button>
            </div>
          </div>
        </div>
      </li>
    </div>
    <div class="modal-footer">
      <mat-dialog-actions>
        <button type="button" class="btn btn-secondary" stopPropagation mat-button mat-dialog-close>{{'cancel' |
          translate}}</button>
        <button type="submit" class="btn btn-success" mat-button (click)="saveProjectTasks()">{{'save' | translate}}
        </button>
      </mat-dialog-actions>
    </div>
</div>
<div class="container" *ngIf="data.createProjectSkills">
  <div class="modal-header">
    <h3>{{'project.dialog.createProjectTitle' | translate}}</h3>
  </div>

    <div class="modal-body">
      <li class="line form-row">
        <div class="col-.form-control-sm {
          border: 1px solid #ced4da;
        }12 pb-2 pt-2 specialty" id="sp">
          <label>{{"project.secondPage.tasks" | translate}}</label>
          <div class="card__body d-flex column">
            <div class="d-flex align-items-center card__body__list">
                  <div *ngFor="let sp of data?.project?.skillsOffer let i=index" class="d-flex justify-content-between pr-1">
                    <div class="card__text">
                      {{sp.name}}
                      <span class="fa fa-remove" (click)="removeProjectTask(i)"></span>
                    </div>
                  </div>
              <input
              class="form-control-sm col-10"
              name="key"
              [(ngModel)]="name">
              <button type="button" class="btn btn-light ms-1 col-1" [disabled]="!name && data?.project?.skillsOffer?.length < 1  " (click)="addProjectSkillOffer()">
                <span> <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                    class="bi bi-plus" viewBox="0 0 16 16">
                    <path
                      d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg></span></button>
            </div>
          </div>
        </div>
      </li>
    </div>
    <div class="modal-footer">
      <mat-dialog-actions>
        <button type="button" class="btn btn-secondary" stopPropagation mat-button mat-dialog-close>{{'cancel' |
          translate}}</button>
        <button type="submit" class="btn btn-success" mat-button (click)="saveProjectSkillsOffer()">{{'save' | translate}}
        </button>
      </mat-dialog-actions>
    </div>
</div>


