import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from "@angular/core";
import { Space, Model, SkillsSub } from "src/app/shared/interfaces/model";

@Component({
  selector: "app-empty-row",
  templateUrl: "./empty-row.component.html",
  styleUrls: ["./empty-row.component.scss"],
})
export class EmptyRowComponent implements OnInit {
  @Input() space: Space;
  @Input() id: number;
  @Input() model: Model;
  @Input() skillObj: SkillsSub;
  @Output() public eventName: EventEmitter<any> = new EventEmitter();
  @Input() selectedRow: boolean;

  constructor() {}

  ngOnInit(): void {}

  public setRow() {
    this.selectedRow = true;
    this.eventName.emit();
  }
  addNewLine() {
    this.skillObj.languages[0].spacesBetween.unshift({ description: "<br>" });
    // this.dataService.updatePage(this.model._id, this.model);
  }
  deleteRow(index) {
    this.model.skills.map((test) =>
      test.subCategories.map((t) =>
        t.languages.data.map((t1) => t1.spacesBetween.splice(index, 1))
      )
    );
   // this.dataService.updatePage(this.model._id, this.model);
  }
  removeClick() {
    this.selectedRow = undefined;
  }
}
