import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/shared/interfaces/user.model';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-sidebar-pc-knowledge',
  templateUrl: './sidebar-pc-knowledge.component.html',
  styleUrls: ['./sidebar-pc-knowledge.component.scss']
})
export class SidebarPcKnowledgeComponent implements OnInit {

  @Input() user: User;
  @Input() isUser: boolean;

  constructor(private userService: UserService) { }

  ngOnInit(): void {
  }


  replacePCKnowledge() {

    const data = this.user.cv.data.map(t => t.skills[0].subCategories[0].pcKnowledge.data);
    let  newArr = Array.prototype.concat(...data);

    newArr =  newArr.filter((el, i, a) => i === a.findIndex((findTest) => findTest.name === el.name));

    this.user.searchFilterSkills.searchPCKnowledge.data = [];
    this.user.searchFilterSkills.searchPCKnowledge.data = newArr;
    this.userService.updateUser(this.user._id, this.user);
  }

  removePcKnowledge(index) {
this.user.searchFilterSkills.searchPCKnowledge.data.splice(index, 1);
this.userService.updateUser(this.user._id, this.user);

  }

}
