<main>
  <div class="container">
    <div class="row">
      <div class="col-lg 3">
        <div class="d-flex align-items-center mb-4 d-lg-none">
          <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <span class="btn btn-primary"><i class="bi bi-sliders"></i></span>
            <span class="h6 mb-0 fw-bold d-lg-none ms-2">{{'header.cv' | translate}}</span>
          </button>
        </div>
        <nav class="navbar navbar-light navbar-expand-lg mx-0">
          <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
            <!-- Offcanvas header -->
            <div class="offcanvas-header">
              <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas"
                aria-label="Close"></button>
            </div>

            <!-- Offcanvas body -->
            <div class="offcanvas-body p-0">
              <!-- Card START -->
              <div class="card w-100">
                <!-- Card body START -->
                <div class="card-body">

                  <!-- Side Nav START -->
                  <ul class="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a  class="nav-link d-flex mb-0 active" href="#nav-setting-tab-1" data-bs-toggle="tab"> <img
                          class="me-2 h-20px fa-fw" src="assets/images/icon/person-outline-filled.svg"
                          alt=""><span>{{'header.contacts' | translate}} </span></a>
                    </li>

                 <!--   <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a  routerLink="search_locations" class="nav-link d-flex mb-0"
                       data-bs-toggle="tab"> <i class="fa-solid fa-magnifying-glass-location fa-fw me-2 h-20px fa-fw"></i><span>{{'search_filter' | translate}} </span></a>
                    </li>
                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a  routerLink="branch" class="nav-link d-flex mb-0"
                       data-bs-toggle="tab"> <i class="bi bi-buildings fa-fw me-2 h-20px"></i><span>{{'Branchen.name' | translate}} </span></a>
                    </li>
                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a  routerLink="privacy" class="nav-link d-flex mb-0"
                       data-bs-toggle="tab">  <i class="bi bi-lock  fa-fw me-2 h-20px"></i><span>{{'settings.privacy' | translate}} </span></a>
                    </li> -->


                  </ul>
                  <!-- Side Nav END -->

                </div>
                <!-- Card body END -->
                <!-- Card footer -->
                <div  class="card-footer text-center py-2">
                  <a [routerLink]="" class="btn btn-link text-secondary btn-sm">View Profile </a>
                </div>
              </div>
              <!-- Card END -->
            </div>
            <!-- Offcanvas body -->


          </div>
        </nav>
      </div>
      <div class="col-lg-6 vstack gap-4">
        <div class="tab-content py-0 mb-0">
          <div class="card">
            <div class="card-body position-relative pt-0 mt-4">
              <div class="row g-4">
                <div class="col-sm-6" *ngFor="let userContacts of filterContacts">
                  <app-user-followers [userFollowing]="userContacts" [contacts]="true"
                    [loggedUser]="loggedUser"></app-user-followers>
                  <!-- Birthday START -->
                  <!-- Birthday END -->
                </div>
              </div>



            </div>
          </div>


      </div>


    </div>
    </div>
  </div>
</main>






<!-- <div *ngIf="allContacts"  style="
display: flex;
flex-wrap: wrap;
">
  <div *ngFor="let contact of contacts; let i = index">
   <app-contact-item [contact]="contact"></app-contact-item>
  </div>
  </div>


<div *ngIf="requests"  style="
display: flex;
flex-wrap: wrap;
">
  <div *ngFor="let contact of requestContacts; let i = index">
    <app-contact-item [contact]="contact" (outside)="onAcceptButtonClick(contact)"></app-contact-item>
  </div>
  </div> -->
