import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { JUser } from 'src/app/shared/interfaces/jira-user.model';
import { JIssue, IssuePriorityIcon, IssueType } from 'src/app/shared/interfaces/jira.model';
import { Job, Page } from 'src/app/shared/interfaces/model';
import { User } from 'src/app/shared/interfaces/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PagesService } from 'src/app/shared/services/pages.service';
import { UserService } from 'src/app/shared/services/user.service';
import { IssueUtil } from 'src/app/shared/utils/issue';
import { IssueModalComponent } from '../issue-modal/issue-modal.component';

@Component({
  selector: 'app-smart-career-card',
  templateUrl: './smart-career-card.component.html',
  styleUrls: ['./smart-career-card.component.scss']
})
export class SmartCareerCardComponent implements OnInit, OnChanges {
  @Input() issue: JIssue;
  @Input() job: Job;
  @Input() isProject: boolean = false;;
  assignees: JUser[];
  public page: Page;
  issueTypeIcon: string;
  user: User;
  issueTypeIconColor: string;
  issueTypeIconBackground: string;
  priorityIcon: IssuePriorityIcon;
  dialogRef: MatDialogRef<IssueModalComponent>;

  constructor(public dialog: MatDialog,
    private userService: UserService,
    private pageService: PagesService) {
     
     }

  ngOnInit(): void {
  
    this.userService.getUser(this.issue.userId);
    if (this.issue?.pageId) {
      this.pageService.getPage(this.issue?.pageId).subscribe(t => {
        this.page = t;
      });
    }
   

    this.userService.getUserContactData(this.issue.userId).subscribe(response => {
      this.user = response;
    })
  
  }
  ngOnChanges(changes: SimpleChanges): void {
    const issueChange = changes.issue;
    if (issueChange?.currentValue !== issueChange.previousValue) {
    }    
  }

  openIssueModal(issueId: JIssue) {
    this.dialog.open(IssueModalComponent, {
      data: issueId
    })
  }
  openProjectModal(issueId: JIssue) {
    this.dialog.open(IssueModalComponent, {
      data: issueId
    })
  }

}
