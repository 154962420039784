import { Injectable } from '@angular/core';
import { AdsManager, AdsManagerArray, Page } from '../interfaces/model';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { PagesService } from './pages.service';
import { UserService } from './user.service';
import { environment } from 'src/assets/environments/environment';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { User } from '../interfaces/user.model';

@Injectable({
  providedIn: 'root'
})
export class AdsManagerService {
  api = `${environment.backend}/ads`;
  userID = this.authService.userID;

  constructor(private http: HttpClient,
    private userService: UserService,
    private authService: AuthService,
    private pageService: PagesService) { }

  createAds(ads: AdsManager, isUser: boolean, pageID?: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      observe: 'response' as 'body'
    };

    return this.http.post<any>(this.api, ads, httpOptions).pipe(map((response: HttpResponse<any>) => {
      const adsManager: AdsManagerArray = {
        adsId: response.body._id,
        timestamp: new Date(),
        country: response.body.country
      };
      if (isUser) {
        this.userService.getUserData(this.authService.userID).pipe(shareReplay(1)).subscribe((res: User) => {

          if (res.ads) {
            res.ads.data.unshift(adsManager);
          } else {
            res.ads.data = [];
            res.ads.data.unshift(adsManager);
          }
        })

      } else {
        this.pageService.getPage(pageID).pipe(shareReplay(1)).subscribe((res: Page) => {
          const adsManager = {
            adsId: response.body._id,
            timestamp: new Date(),
            country: response.body.country
          };
          if (res.adsM) {
            res.adsM.data.unshift(adsManager);
          } else if (res.adsM.data === undefined){
            res.adsM.data = [] as AdsManagerArray[];
            res.adsM.data.unshift(adsManager);
          }
          setTimeout(() => {
            this.pageService.updatePage(res._id, res);
          }, 3000);
        })
      }
    }));
  }


  getAds(pageId): Observable<any> {
    return this.http.get(`${this.api}/${pageId}`);
  }

  updateAds(id: string, adsData) {
    const api = `${this.api}/${id}`
     this.http.put<any>(api, adsData).subscribe();
  }
}
