import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Page, Project } from '../shared/interfaces/model';
import { User } from '../shared/interfaces/user.model';
import { PagesService } from '../shared/services/pages.service';
import { ProjectService } from '../shared/services/project.service';
import { UserService } from '../shared/services/user.service';

@Component({
  selector: 'app-page-project-message',
  templateUrl: './page-project-message.component.html',
  styleUrls: ['./page-project-message.component.scss']
})
export class PageProjectMessageComponent implements OnInit {

  @Input() pageMessage;
  @Output() selectedProject: EventEmitter<any> = new EventEmitter<any>();
  public page: Page;

  public project: Project;
  public user: User;
  constructor(private projectService: ProjectService, private userservice: UserService, private pageService: PagesService) { }

  ngOnInit(): void {


    this.projectService.getProject(this.pageMessage.projectId).subscribe(res => {
      this.project = res;
      console.log(this.project, "project")

      if (!!this.project?.pageID) {
        this.pageService.getPage(this.project.pageID).subscribe(resPage => {
          this.page = resPage;
        })
      } else {
        if (!!this.project.userID) {
          this.userservice.getUserContactData(this.project.userID).subscribe(t => {
            this.user = t;
          })
        }
       
      }
     
    })
  }


  selectProject() {
    this.selectedProject.emit()
  }

}
