import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Category,  MaritualStatus, Status, StatusTitle, Title} from "../../shared/enums/enums-model";
import {AuthService} from "../../shared/services/auth.service";
import {DialogService} from "../../shared/services/dialog.service";
import {MatDialog} from "@angular/material/dialog";
import {PersonalDataDialogComponent} from "../modals/editors/personal-data-dialog/personal-data-dialog.component";
import {
  EditPersonalizedData,
  PersonalizedDataDialogComponent
} from "../modals/personaldata-dialog/personalized-data-dialog.component";
import {TranslateService} from "@ngx-translate/core";
import { PersonalDataSubDialogComponent } from "./personal-data-sub-dialog/personal-data-sub-dialog.component";
import { UserService } from "src/app/shared/services/user.service";
import { NavigationEnd, Router } from "@angular/router";
import { PersonalData, StateAddress, Model } from "src/app/shared/interfaces/model";
import { User } from "src/app/shared/interfaces/user.model";
import { HttpClient } from "@angular/common/http";
import { ArraysService } from "src/app/shared/services/arrays-service/title-service";
import { CountryDataService } from "src/app/shared/services/country-data.service";

@Component({
  selector: "app-personal-data",
  templateUrl: "./personal-data.component.html",
  styleUrls: ["./personal-data.component.scss"]
})
export class PersonalDataComponent implements OnInit {
  @Input() index: number;
  editMode = false;
  @Input() personalData: PersonalData;
  @Input() id: number;
  @Input() user: User;
  @Output() deselectCategory: EventEmitter<any> = new EventEmitter<any>();
  public currentUser: User;
  public addressData: StateAddress;
  public selectedCategory: number = null;
  public category = Category;
  public selectedIndex: number;
  @Input() model: Model;
  public selectedPersonalData: number = null;
  public stateAndCountry = "";
  public postalCodeAndCity = "";
  public getGender;
  public getTitle;
  public title;
  public getStatus;
  public phoneNumber;


  public housingTypeOptions = [
    { id: StatusTitle.Mr, description: "data.mr"},
    { id: StatusTitle.Mrs, description: "data.mrs"},
  ];
  public statusObject = [
    {id: 1, name: MaritualStatus.Single, description: "status.single"},
    {id: 2, name: MaritualStatus.Married, description: "status.married"},
    {id: 0, name: MaritualStatus.NotKnow, description: "status.nk"}
  ];
  countryJsonObject;
  countryJsonArray;
  languages = [];

  constructor(public authService: AuthService,
              public dialogService: DialogService,
              public dialog: MatDialog,
              public userService?: UserService,
              public router?: Router,
              public http?: HttpClient,
              public arrayService?: ArraysService,
              private countryDataService?: CountryDataService
              ) {
  }

  ngOnInit() {
    this.arrayService.showTitles();
    this.countryDataService.countryData$.pipe().subscribe(res => this.countryJsonArray = res);
    this.http.get<any>(`assets/languages/${this.model?.language?.primary}/country.json`).subscribe(data => {
      this.languages = data;
      this.languages = Object.keys(this.languages).map(key => ({type: key, value: this.languages[key]}));
      this.getCountryID(this.user.address.data[0].country);

    });
    this.stateAndCountry = `${this.personalData.state} ${this.personalData.country}`;
    this.postalCodeAndCity = `${this.personalData.postalCode} ${this.personalData.city}`;
    this.getIdOfGender(this.user?.gender);
    this.getIdOfTitle(this.user.title);
    this.getIdOfStatus(this.user.status);
    if (this.user.phone.data[0].internationalNumber) {
    this.phoneNumber = this.user.phone.data[0].internationalNumber;
    }
  }

  public setCategory(index: number) {
    this.selectedCategory = index;
    this.selectedPersonalData = undefined;
  }
  updateStatus(status: number) {
    this.personalData.status = status;
  }
  updateType(type: string) {
    this.personalData.employmentType = type;
  }
  public getIdOfGender(id) {
   this.getGender = this.housingTypeOptions.find(test => test.id === id).description;
  }
  getIdOfTitle(id) {
    this.getTitle = this.arrayService.titles.find(test => test.id === id).description;
  }
  getIdOfStatus(id) {
    this.getStatus = this.statusObject.find(t => t.id === id).description;
  }

  public removeClick() {
    this.selectedIndex = undefined;
    this.selectedPersonalData = undefined;
  }
  addData(data: PersonalizedDataDialogComponent) {
    this.dialog.open(PersonalDataSubDialogComponent, {
      data,
    }).afterClosed().subscribe(res => {
      console.log(res, "res")
    });
  }

  public setIndex(index) {
    this.selectedPersonalData = index;
    this.deselectCategory.emit(index);
  }
  editPersonalData(data: EditPersonalizedData) {
    this.dialog.open(PersonalizedDataDialogComponent, {
      data
    }).afterClosed().subscribe(personalData => {
      if (personalData) {
        this.personalData = personalData;
        this.getIdOfGender(this.user?.gender);
        this.getIdOfTitle(this.user.title);
        this.getIdOfStatus(this.user.status);
        this.stateAndCountry = `${this.personalData.state} ${this.personalData.country}`;
        this.postalCodeAndCity = `${this.personalData.postalCode} ${this.personalData.city}`;
        if (this.user.phone.data[0].internationalNumber) {
        this.phoneNumber = this.user.phone.data[0].internationalNumber;
        }
      } else {
        return null;
      }
    });
  }


  public deleteFirstName() {
    this.user.firstName = "";
  //  this.dataService.updatePage(this.model._id, this.model);
  }

  public deleteLastName() {
    this.user.lastName = "";
  //  this.dataService.updatePage(this.model._id, this.model);
  }

  public deleteAddress() {
    this.personalData.street = "";
    this.personalData.postalCode = null;
    this.personalData.city = "";
    this.personalData.country = "";
  }

  public deletePhone() {
    this.personalData.telephone = null;
  }

  public deleteBirthday() {
    this.user.birthday = null;
  }

  public deleteStatus() {
    this.personalData.status = null;
  }
  public get checkId() {
    return this.authService?.userID === this.user?._id;
    }
    getCountryID(id) {
      this.countryJsonObject = this.languages.find(t => t.type === id);
    }

  /*** Open dialog for Add new PersonalData**/
  public openDialogSubPersonalData() {
    this.dialog.open(PersonalDataDialogComponent, {
      data: this.personalData
    }).afterClosed().subscribe(result => {
      if (result === undefined) {
        return;
      } else {
        if (result.id === "firstName") {
          this.user.firstName = "Max";
        } else if (result.id === "lastName") {
          this.user.lastName = "Muster";
        } else if (result.id === "address") {
          this.personalData.street = "Muster";
          this.personalData.city = "Musterstadt";
          this.personalData.postalCode = 11111;
        }
        // return this.dataService.updatePage(this.model._id, this.model);
      }
    });
  }
}
