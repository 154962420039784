import { T } from '@angular/cdk/keycodes';
import { Component, Input, OnInit } from '@angular/core';
import { JComment } from '../shared/interfaces/comment';
import { JIssue } from '../shared/interfaces/jira.model';
import { Page } from '../shared/interfaces/model';
import { User } from '../shared/interfaces/user.model';
import { PagesService } from '../shared/services/pages.service';
import { UserService } from '../shared/services/user.service';

@Component({
  selector: 'app-user-name',
  templateUrl: './user-name.component.html',
  styleUrls: ['./user-name.component.scss']
})
export class UserNameComponent implements OnInit {

  @Input() comment: JComment
  @Input() issue: JIssue
  @Input() isPage: boolean = false;
  @Input() isUser: boolean = false;
  @Input() pageId: string = "false";
  @Input() userFollowers: boolean = false;
  @Input() isFromMessage: boolean = false;
  public user: User;
  public page: Page;
  constructor(public userService: UserService, private pageService: PagesService) { }

  ngOnInit(): void {
    if (this.comment?.pageId && this.comment?.userId && !this.isFromMessage) {
      this.pageService.getPage(this.comment?.pageId).subscribe(t => {
        this.page = t
      })
    } else if (this.comment?.userId && this.isFromMessage) {
      this.userService.getUserContactData(this.comment?.userId).subscribe(t => {
        this.user = t;
      })
    }
  }

}
