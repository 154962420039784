import { Component, Inject, OnInit } from "@angular/core";
import { Job, Model, PCKnowledge, Project, SkillOffer } from "../../../../shared/interfaces/model";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { AuthService } from "src/app/shared/services/auth.service";
import { WarningDialogComponent } from "src/app/sharedComponents/warning-dialog/warning-dialog.component";
import { UserService } from "src/app/shared/services/user.service";
import { User } from "src/app/shared/interfaces/user.model";

@Component({
  selector: "app-pc-knowledge-dialog",
  templateUrl: "./pc-knowledge-dialog.component.html",
  styleUrls: ["./pc-knowledge-dialog.component.scss"]
})
export class PcKnowledgeDialogComponent implements OnInit {
  public name;
  starCount = 5;
  public user: User;

  public confirmationDialog: MatDialogRef<WarningDialogComponent>

  constructor(@Inject(MAT_DIALOG_DATA) public data: EditPCKnowledge,
    private dialogRef: MatDialogRef<PcKnowledgeDialogComponent>,
    public authService: AuthService,
    public dialog: MatDialog,
    public userService: UserService
  ) { }

  ngOnInit(): void {
    if (!this.data.projectPCKnowledge && !this.data.jobProject) {


      if (!this.data.pcKnowledge) {
        this.data.pcKnowledge = {} as SkillOffer[];
      } else {
        this.userService.getUserContactData(this.authService.userID).subscribe((res: User) => {
          this.user = res;
          this.data.pcKnowledge = res.cv.data[this.data.indexOfLang].skills[0].subCategories[0].pcKnowledge.data;
        });
        ({ pcKnowledge: this.data.pcKnowledge } = this.data);
      }
    } else if (this.data.projectPCKnowledge) {
      this.data.project = new Project();
      this.data.project.pcKnowledge = [];
    } else if (this.data.jobProject) {
      this.data.job = new Job();
      this.data.job.pcKnowledge = [];
    }
  }
  addPCKnowledge() {
    this.data.pcKnowledge.unshift({ name: this.name, rate: 0 });
    this.name = "";
  }
  deletePcKnowledge(index) {
    this.data.pcKnowledge.splice(index, 1);
  }
  onRatingChanged(index, rating) {
    this.data.pcKnowledge[index].rate = rating * 20;
  }
  onRatingProjectChanged(index, rating) {
    this.data.project.pcKnowledge[index].rate = rating * 20;
  }
  onRatingJobPCKnowledge(index, rating) {
    this.data.job.pcKnowledge[index].rate = rating * 20;
  }

  saveData() {
    if (this.authService.isLoggedIn) {

      this.user.searchFilterSkills.searchPCKnowledge.data.unshift(...this.data.pcKnowledge);
      const data = this.user.searchFilterSkills.searchPCKnowledge.data.filter((el, i, a) => i === a.findIndex((findTest) => findTest.name === el.name))

      this.user.searchFilterSkills.searchPCKnowledge.data = data;


      this.userService.updateUser(this.user._id, this.user);
      this.dialogRef.close(this.user.cv.data[this.data.indexOfLang].skills[0].subCategories[0]);

      //  this.dataSer  vice.updatePage(this.data.model._id, this.data.model);
    } else {
      this.dialogRef.close();
      this.confirmationDialog = this.dialog.open(WarningDialogComponent, {
        disableClose: false
      });

      this.confirmationDialog.afterClosed().subscribe(result => {
        if (result) {
          // do confirmation actions
        }
        this.confirmationDialog = null;
      });
    }
  }

  addJobPCKnowledge() {
    if (this.data.job?.pcKnowledge?.length > 0) {
      this.data.job.pcKnowledge.unshift({ name: this.name, rate: 0 });
    } else {
      this.data.job.pcKnowledge = [];
      this.data.job.pcKnowledge.unshift({ name: this.name, rate: 0 });
    }
    this.name = "";
  }

  addProjectPCKnowledge() {
    if (this.data.project?.pcKnowledge?.length > 0) {
      this.data.project.pcKnowledge.unshift({ name: this.name, rate: 0 });
    } else {
      this.data.project.pcKnowledge = [];
      this.data.project.pcKnowledge.unshift({ name: this.name, rate: 0 });
    }
    this.name = "";
  }
  saveProjectPCKnowledge() {
    this.dialogRef.close(this.data.project);
  }

  saveJobPCKnowledge() {
    this.dialogRef.close(this.data.job);
  }
}
export interface EditPCKnowledge {
  pcKnowledge?: PCKnowledge[];
  edit?: boolean;
  model?: Model;
  projectPCKnowledge?: boolean;
  project?: Project;
  pcKnowledgeCV?: boolean;
  jobProject?: boolean;
  job?: Job;
  indexOfLang?: number
}

