<div class="container">
  <form class="form-grid" name="ngForm" #myForm="ngForm" (ngSubmit)="myForm.form.valid && saveData()">
    <h2>{{ "category.Education" | translate }}</h2>


    <li class="line form-row">
      <div class="col row col-md-12">
        <div class="col-md-6">
          <label> {{ "education.position" | translate }} </label>
          <input class="form-control col-md-12" [(ngModel)]="data.education.title" name="educationName"
          #educationName="ngModel"
          [ngClass]="{ 'is-invalid': myForm.submitted && educationName.invalid }"
          required />
          <div *ngIf="myForm.submitted && educationName.invalid" class="invalid-feedback">
            <div *ngIf="educationName.errors.required">
              {{ "education.position" | translate }}
              {{ "error.isRequired" | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label> {{ "education.graduation" | translate }} </label>
          <input class="form-control col-md-12" [ngModel]="data.education.graduation"
            (ngModelChange)="data.education.graduation = $event" name="educationGraduation"
            #educationGraduation="ngModel"
            [ngClass]="{ 'is-invalid': myForm.submitted && educationGraduation.invalid }"
            required />
            <div *ngIf="myForm.submitted && educationGraduation.invalid" class="invalid-feedback">
              <div *ngIf="educationGraduation.errors.required">
                {{ "education.graduation" | translate }}
                {{ "error.isRequired" | translate }}
              </div>
            </div>
        </div>
      </div>
    </li>

    <li class="line form-row">
      <div class="col row">
        <div class="col-md-12">
          <label> {{ "education.description" | translate }} </label>
          <quill-editor name="dialogDescription"
           [(ngModel)]="data.education.description"
           [modules]="modules">
          </quill-editor>

          <!--  <textarea  style="resize:vertical" (keyup)="autogrow()" (click)="autogrow()" type="text" id="educationDescription" [(ngModel)]="data.education.description" name="dialogDescription"
                     class="form-control col-md-12"></textarea>-->
        </div>
      </div>
    </li>
    <h6>{{'education.institution' | translate}}</h6>
    <li class="line form-row">
      <div class="col row col-md-12">
        <div class="col-md-12">
          <label>{{ "education.name" | translate }}</label>
       <!--   <input  name="school" autocomplete="off" [ngModel]="searchForPage"
            (ngModelChange)="searchForPage = $event" class="form-control col-md-12"
            name="educationUniversity" #educationUniversity="ngModel"
            [ngClass]="{ 'is-invalid': myForm.submitted && educationUniversity.invalid }"
            required /> -->
            <ng-container *ngIf="!data.edit">
              <div class="row no-gutters" *ngIf="pageSelected">
                <div class="col input-group">
                  <span class="input-group-prepend" *ngIf="pageActive">
                    <div class="input-group-text bg-transparent border-right-0">
                      <img *ngIf="hasPhoto" [src]="this.image" ngx-gravatar alt="photo" [email]="'example@mail.com'" fallback="mp"
                      size="24" />
                    <img *ngIf="!hasPhoto" alt="no-photo" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="24" />
                    </div>
                </span>
                    <input class="form-control" type="text" [value]="pageActive" [ngModel]="searchForPage"
                    (ngModelChange)="searchForPage = $event; searchForPageTrue = true" name="educationUniversity" #educationUniversity="ngModel" id="example-search-input4" [readonly]="pageActive" autocomplete="off"
                    [ngClass]="{ 'is-invalid': myForm.submitted && educationUniversity.invalid }"
                    required>
  
                <div class="col-auto px-0">
                    <button class="btn btn-outline-light" type="button" (click)="deletePage()">
                        <i class="fa fa-close"></i>
                    </button>
                </div>
                <div *ngIf="myForm.submitted && educationUniversity.invalid" class="invalid-feedback">
                  <div *ngIf="educationUniversity.errors.required">
                    {{ "education.startDate" | translate }}
                    {{ "error.isRequired" | translate }}
                  </div>
            </div>
  
              </div>
            </div>
              <ng-container *ngIf="searchForPageTrue">
                <div *ngFor="let page of (pages | searchPage:searchForPage)" class="card__list">
                  <div class="hstack gap-2 mb-3" (click)="addNewCompany(page)">
                    <!-- Avatar -->
                    <div class="avatar-xs">
                      <a ><img class="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""></a>
                    </div>
                    <!-- Title -->
                    <div class="overflow-hidden">
                      <a class="h6 mb-0" > {{ page.name }} </a>
                    </div>
                    <!-- Button -->
                  </div>
      
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!!data.edit">
              <div class="row no-gutters" *ngIf="pageSelected">
                <div class="col input-group">
                  <span class="input-group-prepend" *ngIf="pageActive">
                    <div class="input-group-text bg-transparent border-right-0">
                      <img *ngIf="hasPhoto" [src]="this.image" ngx-gravatar alt="photo" [email]="'example@mail.com'" fallback="mp"
                      size="24" />
                    <img *ngIf="!hasPhoto" alt="no-photo" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="24" />
                    </div>
                </span>
                    <input class="form-control border-secondary border-right-0 rounded-0" type="text" [value]="pageActive" [ngModel]="searchForPage"
                    (ngModelChange)="searchForPage = $event; searchForPageTrue = true" name="educationUniversity" #educationUniversity="ngModel" id="example-search-input4" [readonly]="pageActive" autocomplete="off"
                    [ngClass]="{ 'is-invalid': myForm.submitted && educationUniversity.invalid }"
                    required>
  
                <div class="col-auto px-0">
                    <button class="btn btn-outline-secondary border-left-0 rounded-0 rounded-right" type="button" (click)="deletePage()">
                        <i class="fa fa-close"></i>
                    </button>
                </div>
                <div *ngIf="myForm.submitted && educationUniversity.invalid" class="invalid-feedback">
                  <div *ngIf="educationUniversity.errors.required">
                    {{ "education.startDate" | translate }}
                    {{ "error.isRequired" | translate }}
                  </div>
            </div>
  
              </div>
            </div>
              <ng-container *ngIf="searchForPageTrue">
                <div *ngFor="let page of (pages | searchPage:searchForPage)" class="card__list">
                  <div class="hstack gap-2 mb-3" (click)="addCompany(page)">
                    <!-- Avatar -->
                    <div class="avatar-xs">
                      <a ><img class="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""></a>
                    </div>
                    <!-- Title -->
                    <div class="overflow-hidden">
                      <a class="h6 mb-0" > {{ page.name }} </a>
                    </div>
                    <!-- Button -->
                  </div>
      
                </div>
              </ng-container>
            </ng-container>
            
        </div>
      </div>
    </li>
    <li class="line form-row" *ngIf="pageSelected">
      <div class="col row col-md-12">
        <div class="col-md-6">
          <label>{{ "career.companyUrl" | translate }} </label>
          <span class="checkbox-inline margin5px">
            <input [(ngModel)]="data.education.showCompanyUrl" [checked]="data.education.showCompanyUrl" name="showWebsite"
              type="checkbox" value="" />
            {{ "data.show" | translate }}
          </span>
          <input *ngIf="selectedPageID"
            name="companyUrl"
            [(ngModel)]="website"
            class="form-control col-md-12"
            #companyUrl="ngModel"
            [readonly]="website"
          />
          <input *ngIf="!selectedPageID"
          name="companyUrl"
          [(ngModel)]="data.education.url"
          class="form-control col-md-12"
          #companyUrl="ngModel"
         
        />
        </div>
      </div>
    </li>
    <li class="line form-row">
      <div class="col row col-md-12">
        <div class="col-md-6">
          <label> {{ "education.startDate" | translate }} </label>
          <input [ngModel]="data.education.startDate | date: 'yyyy-MM-dd'"
            (ngModelChange)="data.education.startDate = $event" type="date"
             class="form-control col-md-12" name="educationStartDate"
             #educationStartDate="ngModel"
             [ngClass]="{ 'is-invalid': myForm.submitted && educationStartDate.invalid }"
             required  />
             <div *ngIf="myForm.submitted && educationStartDate.invalid" class="invalid-feedback">
              <div *ngIf="educationStartDate.errors.required">
                {{ "education.startDate" | translate }}
                {{ "error.isRequired" | translate }}
              </div>
            </div>
        </div>
        <div class="col-md-6" *ngIf="!data.education.today">
          <label> {{ "education.endDate" | translate }} </label>
          <input name="educationEndDate" type="date" [ngModel]="data.education.endDate | date: 'yyyy-MM-dd'"
            (ngModelChange)="data.education.endDate = $event" class="form-control col-md-12"
            #educationEndDate="ngModel"
            [ngClass]="{ 'is-invalid': myForm.submitted && educationEndDate.invalid }"
            required/>
            <div *ngIf="myForm.submitted && educationStartDate.invalid" class="invalid-feedback">
              <div *ngIf="educationEndDate.errors.required">
                {{ "education.endDate" | translate }}
                {{ "error.isRequired" | translate }}
              </div>
            </div>
        </div>
      </div>
    </li>
    <li class="line form-row">
      <div class="col row col-md-12">
        <div class="col-md-6">
          <label class="checkbox-inline">
            <input
              [(ngModel)]="data.education.today"
              name="tillToday"
              type="checkbox"
              value=""
              (ngModelChange)="todayChange($event)"
            />
            <span class="margin5px">{{ "education.today" | translate }}</span>
          </label>
        </div>
      </div>
    </li>
    <mat-dialog-actions align="end">
      <button type="button" class="btn btn-secondary" mat-button mat-dialog-close>
        {{ "cancel" | translate }}
      </button>
      <button type="submit" class="btn btn-success" mat-button>
        {{ "save" | translate }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
