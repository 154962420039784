<main>
  <!-- Container START -->
  <div class="container">
    <div class="row">

      <!-- Sidenav START -->
      <div class="col-lg-3">

        <!-- Advanced filter responsive toggler START -->
        <!-- Divider -->
        <div class="d-flex align-items-center mb-4 d-lg-none">
          <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <span class="btn btn-primary"><i class="bi bi-sliders"></i></span>
            <span class="h6 mb-0 fw-bold d-lg-none ms-2">Settings</span>
          </button>
        </div>
        <!-- Advanced filter responsive toggler END -->

        <nav class="navbar navbar-light navbar-expand-lg mx-0">
          <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
            <!-- Offcanvas header -->
            <div class="offcanvas-header">
              <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas"
                aria-label="Close"></button>
            </div>

            <!-- Offcanvas body -->
            <div class="offcanvas-body p-0">
              <!-- Card START -->
              <div class="card w-100">
                <!-- Card body START -->
                <div class="card-body">

                  <!-- Side Nav START -->
                  <ul class="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a class="nav-link d-flex mb-0 active" href="#nav-setting-tab-1" data-bs-toggle="tab"> <img
                          class="me-2 h-20px fa-fw" src="assets/images/icon/person-outline-filled.svg"
                          alt=""><span>Account </span></a>
                    </li>
                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a class="nav-link d-flex mb-0" href="#nav-setting-tab-2" data-bs-toggle="tab"> <img
                          class="me-2 h-20px fa-fw" src="assets/images/icon/notification-outlined-filled.svg"
                          alt=""><span>Notification </span></a>
                    </li>
                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a class="nav-link d-flex mb-0" href="#nav-setting-tab-3" data-bs-toggle="tab"> <img
                          class="me-2 h-20px fa-fw" src="assets/images/icon/shield-outline-filled.svg"
                          alt=""><span>Privacy and safety </span></a>
                    </li>
                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a class="nav-link d-flex mb-0" href="#nav-setting-tab-10" data-bs-toggle="tab"> <img
                          class="me-2 h-20px fa-fw" src="assets/images/icon/shield-outline-filled.svg"
                          alt=""><span>Blocking </span></a>
                    </li>

                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a class="nav-link d-flex mb-0" href="#nav-setting-tab-4" data-bs-toggle="tab"> <img
                          class="me-2 h-20px fa-fw" src="assets/images/icon/handshake-outline-filled.svg"
                          alt=""><span>Communications </span></a>
                    </li>
                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a class="nav-link d-flex mb-0" href="#nav-setting-tab-5" data-bs-toggle="tab"> <img
                          class="me-2 h-20px fa-fw" src="assets/images/icon/chat-alt-outline-filled.svg"
                          alt=""><span>Messaging </span></a>
                    </li>

                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a class="nav-link d-flex mb-0" href="#nav-setting-tab-7" data-bs-toggle="tab"> <i
                          class="bi bi-translate fa-fw me-2 h-20px fa-fw"></i><span>Language</span></a>
                    </li>
                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a class="nav-link d-flex mb-0" href="#nav-setting-tab-8" data-bs-toggle="tab"> <i class="bi bi-gem fa-fw me-2 h-20px fa-fw"></i><span>Premium</span></a>
                    </li>
                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a class="nav-link d-flex mb-0" href="#nav-setting-tab-9" data-bs-toggle="tab"> <i
                          class="bi bi-patch-check-fill text-success small fa-fw me-2 h-20px fa-fw"></i><span>Verified</span></a>
                    </li>
                  </ul>
                  <!-- Side Nav END -->
                </div>
                <!-- Card body END -->
                <!-- Card footer -->
                <div class="card-footer text-center py-2">
                  <a class="btn btn-link text-secondary btn-sm" href="#!">View Profile </a>
                </div>
              </div>
              <!-- Card END -->
            </div>
            <!-- Offcanvas body -->

            <!-- Helper link START -->

            <!-- Helper link END -->
            <!-- Copyright -->


          </div>
        </nav>
      </div>
      <!-- Sidenav END -->

      <!-- Main content START -->
      <div class="col-lg-6 vstack gap-4">
        <!-- Setting Tab content START -->
        <div class="tab-content py-0 mb-0">

          <!-- Account setting tab START -->
          <div class="tab-pane show active fade" id="nav-setting-tab-1">
            <!-- Account settings START -->
            <div class="card mb-4">

              <!-- Title START -->
              <div class="card-header border-0 pb-0">
                <h1 class="h5 card-title">Account Settings</h1>
                <p class="mb-0">He moonlights difficult engrossed it, sportsmen. Interested has all Devonshire
                  difficulty gay assistance joy. Unaffected at ye of compliment alteration to.</p>
              </div>
              <!-- Card header START -->
              <!-- Card body START -->
              <div class="card-body">
                <!-- Form settings START -->
                <form class="row g-3" name="ngForm" #myForm="ngForm"
                  (ngSubmit)="myForm.form.valid && saveUserSettings(myForm)">
                  <div class="col-sm-2">
                    <label class="form-label">Gender</label>
                    <select class="col-md-12 form-control" id="gender" [ngModel]="currentUser?.gender" name="gender">
                      <option *ngFor="let gender of genders" [ngValue]="gender.id" [selected]="gender">
                        {{ gender.description | translate }}
                      </option>
                    </select>
                  </div>
                  <div class="col-sm-2">
                    <label class="form-label">{{ "data.title" | translate }}</label>
                    <select class="col-md-12 form-control" id="selectedTitle" [ngModel]="currentUser?.title"
                      name="title">
                      <option *ngFor="let title of titleService.titles" [ngValue]="title.id" [selected]="title">
                        {{ title.description | translate }}
                      </option>
                    </select>
                  </div>

                  <!-- First name -->
                  <div class="col-sm-3 col-lg-4">
                    <label class="form-label">First name</label>
                    <input type="text" class="form-control" placeholder="" name="firstName"
                      [ngModel]="currentUser?.firstName">
                  </div>
                  <!-- Last name -->
                  <div class="col-sm-4 col-lg-4">
                    <label class="form-label">Last name</label>
                    <input type="text" class="form-control" placeholder="" name="lastName"
                      [ngModel]="currentUser?.lastName">
                  </div>
                  <!-- Additional name -->

                  <!-- User name -->
                  <div class="col-sm-5">
                    <label class="form-label">User name</label>
                    <input type="text" class="form-control" placeholder="" name="userName"
                      [ngModel]="currentUser?.userName">
                  </div>
                  <div class="col-sm-3">
                    <label class="form-label">{{ "data.status" | translate }}</label>
                    <select class="col-md-12 form-control" id="status" [ngModel]="currentUser?.status" (ngModelChange)="currentUser.status = $event" name="status">
                      <option *ngFor="let status of statusObject" [ngValue]="status.id" [selected]="status">
                        {{ status.description | translate }}
                      </option>
                    </select>
                  </div>
                  <div class="col-sm-4 col-lg-4">
                    <label class="form-label">User url</label>
                    <input type="text" class="form-control" placeholder="" name="userUrl"
                      [ngModel]="currentUser?.userUrl">
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label">Birthday </label>
                    <input type="date" class="form-control flatpickr" name="birthday"
                      [ngModel]="currentUser?.birthday.date | date: 'yyyy-MM-dd'"
                      (ngModelChange)="currentUser.birthday.date = $event">
                  </div>

                  <div class="col-lg-4">
                    <label class="form-label">{{"data.nationality" | translate }}</label>
                    <input type="text" name="nationality" class="form-control flatpickr"
                      [ngModel]="currentUser?.nationality.data">
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label">{{"religionName" | translate }}</label>
                    <select class="col-md-12 form-control" id="religion" [ngModel]="currentUser?.religion?.name"
                      name="religion">
                      <option *ngFor="let status of titleService.religions" [ngValue]="status.id" [selected]="status">
                        {{ status.description | translate }}
                      </option>
                    </select>
                  </div>
                  <!-- Birthday -->

                  <!-- Allow checkbox -->
                  <div class="col-12">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="allowChecked" checked>
                      <label class="form-check-label" for="allowChecked">
                        Allow anyone to add you to their team
                      </label>
                    </div>
                  </div>
                  <!-- Phone number -->
                  <div class="col-sm-6">
                    <label class="form-label">Phone number</label>
                    <br>
                    <ng-container *ngFor="let phone of currentUser?.phone?.data; let i = index">
                      <ng-container *ngIf="isEditingPhone && isSelectedPhoneIndex === i; else readOnlyPhoneTmpl">
                        <form name="ngForm" #editedNumber="ngForm"
                          (ngSubmit)="editedNumber.form.valid && saveEditedPhone(editedNumber, phone, i)">

                          <ngx-intl-tel-input [enablePlaceholder]="true"
                            [preferredCountries]="['de', 'us', 'gb', 'it', 'es', 'fr', 'ch', 'at']"
                            [separateDialCode]="true" #editedPhone="ngModel" name="editedPhone" id="phone" required
                            autoComplete="off" [phoneValidation]="true" [(ngModel)]="phone.number"
                            [selectedCountryISO]="phone.countryCode" [cssClass]="'form-control col-md-12'">
                          </ngx-intl-tel-input>
                          <div class="flex
                        items-center" style="
                        flex-direction: row;
                        display: flex;
                        border:none;
                    " class="specialty col-12 px-0 mt-1">
                            <button class="btn btn-light me-2" type="submit">
                              <i class="bi bi-check"></i>
                            </button>
                            <button class="btn btn-light" (click)="cancelPhoneEdit()">
                              <i class="bi bi-x"></i>
                            </button>
                          </div>
                        </form>
                      </ng-container>
                      <ng-template #readOnlyPhoneTmpl>
                        <div class="d-flex align-items-center rounded border px-3 py-1 mb-1">
                          <!-- Date -->
                          <p class="mb-0">
                            {{phone?.internationalNumber}}
                          </p>
                          <div class="dropdown ms-auto">
                            <!-- Card share action menu -->
                            <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction3"
                              data-bs-toggle="dropdown" aria-expanded="false">
                              <i class="bi bi-three-dots"></i>
                            </a>
                            <!-- Card share action dropdown menu -->
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction3">
                              <li><a class="dropdown-item" (click)="editPhoneNumber(i)"> <i
                                    class="bi bi-pencil-square fa-fw pe-2"></i>Edit</a></li>
                              <li *ngIf="currentUser?.phone.data.length > 1" (click)="removePhone(i)"><a
                                  class="dropdown-item"> <i class="bi bi-trash fa-fw pe-2"></i>Delete</a></li>
                            </ul>
                          </div>
                        </div>
                      </ng-template>

                    </ng-container>

                    <!-- Add new email -->
                    <ng-container *ngIf="anotherNumber">
                      <form name="ngForm" #newPhone="ngForm"
                        (ngSubmit)="newPhone.form.valid && saveNewPhoneNumber(newPhone)">
                        <ngx-intl-tel-input [enablePlaceholder]="true"
                          [preferredCountries]="['de', 'us', 'gb', 'it', 'es', 'fr', 'ch', 'at']"
                          [separateDialCode]="true" #addNewPhone="ngModel" name="addNewPhone" id="phone" required
                          autoComplete="off" [phoneValidation]="true" [(ngModel)]="newNumber.number"
                          [selectedCountryISO]="newNumber.countryCode" [cssClass]="'form-control col-md-12'">
                        </ngx-intl-tel-input>
                        <div class="flex
                          items-center" style="
                          flex-direction: row;
                          display: flex;
                          border:none;
                      " class="specialty col-12 px-0 mt-1">
                          <button class="btn btn-light me-2" type="submit">
                            <i class="bi bi-check"></i>
                          </button>
                          <button class="btn btn-light" (click)="cancel()">
                            <i class="bi bi-x"></i>
                          </button>
                        </div>
                      </form>
                    </ng-container>

                    <a class="btn btn-sm btn-dashed rounded mt-2" (click)="addNewPhoneNumber()"> <i
                        class="bi bi-plus-circle-dotted me-1"></i>Add new phone number</a>
                  </div>
                  <!-- Phone number -->
                  <div class="col-sm-6">
                    <label class="form-label">Email</label>
                    <ng-container *ngFor="let email of currentUser?.email.data; let i = index">
                      <ng-container *ngIf="isEditingMode && isSelectedIndex === i ; else readOnlyTmpl">
                        <!-- Date -->
                        <form name="ngForm" #emailForm="ngForm"
                          (ngSubmit)="emailForm.form.valid && save(myForm, email, i)">
                          <div class="col-lg-12">
                            <input type="email" class="form-control col-lg-8" name="changeEmail"
                              [(ngModel)]="email.name" placeholder="Try 'john@doe.com' and check" #changeEmail="ngModel"
                              [ngClass]="{ 'is-invalid': emailForm.submitted && changeEmail.invalid }"
                              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required>
                            <br />
                            <div *ngIf="emailForm.submitted && changeEmail.invalid" class="invalid-feedback">
                              <div *ngIf="changeEmail.errors.required">
                                {{ "error.isRequired" | translate }}
                              </div>
                            </div>
                            <div class="flex
                              items-center" style="
                              flex-direction: row;
                              display: flex;
                              border:none;
                          " class="specialty col-12 px-0 mt-1">
                              <button class="btn btn-light me-2" type="submit">
                                <i class="bi bi-check"></i>
                              </button>
                              <button class="btn btn-light" (click)="cancel()">
                                <i class="bi bi-x"></i>
                              </button>
                            </div>
                          </div>
                        </form>

                      </ng-container>
                      <ng-template #readOnlyTmpl>
                        <div class="d-flex align-items-center rounded border px-3 py-1 mb-1">
                          <!-- Date -->
                          <p class="mb-0">
                            {{email?.name}}
                          </p>
                          <div class="dropdown ms-auto">
                            <!-- Card share action menu -->
                            <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction3"
                              data-bs-toggle="dropdown" aria-expanded="false">
                              <i class="bi bi-three-dots"></i>
                            </a>
                            <!-- Card share action dropdown menu -->
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction3">
                              <li><a class="dropdown-item" (click)="editEmail(i)"> <i
                                    class="bi bi-pencil-square fa-fw pe-2"></i>Edit</a></li>
                              <li *ngIf="currentUser?.email.data.length > 1" (click)="removeEmail(i)"><a
                                  class="dropdown-item"> <i class="bi bi-trash fa-fw pe-2"></i>Delete</a></li>
                            </ul>
                          </div>
                        </div>
                      </ng-template>

                    </ng-container>

                    <!-- Add new email -->
                    <ng-container *ngIf="anotherEmail">
                      <form name="ngForm" #newEmailForm="ngForm"
                        (ngSubmit)="newEmailForm.form.valid && saveNewEmail(myForm)">
                        <input type="email" class="form-control col-lg-8" placeholder="" name="emailNew"
                          #emailNew="ngModel" [(ngModel)]="newEmail"
                          [ngClass]="{ 'is-invalid': newEmailForm.submitted && emailNew.invalid }"
                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required>
                        <div class="flex
                        items-center" style="
                        flex-direction: row;
                        display: flex;
                        border:none;
                    " class="specialty col-12 px-0 mt-1">
                          <button class="btn btn-light me-2" type="submit">
                            <i class="bi bi-check"></i>
                          </button>
                          <button class="btn btn-light" (click)="cancel()">
                            <i class="bi bi-x"></i>
                          </button>
                        </div>
                      </form>
                    </ng-container>

                    <a class="btn btn-sm btn-dashed rounded mt-2" (click)="addNewEmail()"> <i
                        class="bi bi-plus-circle-dotted me-1"></i>Add new email address</a>
                  </div>

                  <div class="col row col-md-12">
                    <span class="firstLabel">{{ "data.address" | translate }} </span>

                  </div>
                  <li class="line form-row">
                    <div class="col row col-md-12">
                      <div class="col-md-12">
                        <label>{{ "addressData.street" | translate }}</label>
                        <input class="form-control col-md-12" autocomplete="off"
                          [ngModel]="currentUser?.address.data[0]?.street" name="street"   #street="ngModel" (ngModelChange)="currentUser.address.data[0].street = $event" />
                      </div>
                    </div>
                  </li>
                  <li class="line form-row">
                    <div class="col row col-md-12">
                      <div class="col-md-4">
                        <label>{{ "data.zip" | translate }}</label>
                        <input class="form-control col-md-12" [ngModel]="currentUser?.address.data[0]?.postalCode"
                          #postalCode="ngModel" name="postalCode" autocomplete="postal-code" required />

                      </div>
                      <div class="col-md-8">
                        <label>{{ "data.city" | translate }}</label>
                        <input class="form-control col-md-12" [ngModel]="currentUser?.address.data[0]?.city" name="city"
                          #city="ngModel" required />

                      </div>
                    </div>
                  </li>
                  <li class="line form-row">
                    <div class="col row col-md-12">
                      <div class="col-md-6">
                        <label>{{ "data.country" | translate }}</label>
                        <div class="col-lg-12">

                          <input name="country" [ngModel]="address?.value" (ngModelChange)="onChange($event)" required
                            list="encodings" class="col-sm-6 form-control custom-select custom-select-sm"
                            autocomplete="off">
                          <datalist id="encodings">
                            <option *ngFor="let country of languages" [value]="country.value"
                              [selected]="selectedCountry" (change)="onChange(country)">
                              {{ country.value }}
                            </option>
                          </datalist>
                        </div>


                      </div>
                      <div class="col-md-6">
                        <label>{{ "data.state" | translate }}</label>
                        <input class="form-control col-md-12" [ngModel]="currentUser?.address.data[0]?.state" name="state"
                          #state="ngModel" />

                      </div>
                    </div>
                  </li>
                  <!-- Page information -->
                  <div class="col-12">
                    <label class="form-label">Overview</label>
                    <quill-editor name="description" (onContentChanged)="textChanged($event)"
                      [ngModel]="currentUser?.about" [modules]="config" [maxLength]="300"
                      #description="ngModel"></quill-editor>
                    <span [ngClass]="{'hints': counter > 299}">
                      {{counter}}/300</span>

                  </div>
                  <!-- Button  -->
                  <div class="col-12 text-end">
                    <button type="submit" [disabled]="myForm.pristine" class="btn btn-sm btn-primary mb-0">Save changes</button>
                  </div>
                </form>
                <!-- Settings END -->
              </div>
              <!-- Card body END -->
            </div>
            <!-- Account settings END -->

            <!-- Change your password START -->
            <div class="card">
              <!-- Title START -->
              <div class="card-header border-0 pb-0">
                <h5 class="card-title">Change your password</h5>
                <p class="mb-0">See resolved goodness felicity shy civility domestic had but.</p>
              </div>
              <!-- Title START -->
              <div class="card-body">
                <!-- Settings START -->
                <form class="row g-3">
                  <!-- Current password -->
                  <div class="col-12">
                    <label class="form-label">Current password</label>
                    <input type="text" class="form-control" placeholder="">
                  </div>
                  <!-- New password -->
                  <div class="col-12">
                    <label class="form-label">New password</label>
                    <!-- Input group -->
                    <div class="input-group">
                      <input class="form-control fakepassword" type="password" id="psw-input"
                        placeholder="Enter new password">
                      <span class="input-group-text p-0">
                        <i class="fakepasswordicon fa-solid fa-eye-slash cursor-pointer p-2 w-40px"></i>
                      </span>
                    </div>
                    <!-- Pswmeter -->
                    <div id="pswmeter" class="mt-2"></div>
                    <div id="pswmeter-message" class="rounded mt-1"></div>
                  </div>
                  <!-- Confirm password -->
                  <div class="col-12">
                    <label class="form-label">Confirm password</label>
                    <input type="text" class="form-control" placeholder="">
                  </div>
                  <!-- Button  -->
                  <div class="col-12 text-end">
                    <button type="submit" class="btn btn-primary mb-0">Update password</button>
                  </div>
                </form>
                <!-- Settings END -->
              </div>
            </div>
            <!-- Card END -->
          </div>
          <!-- Account setting tab END -->

          <!-- Notification tab START -->
          <div class="tab-pane fade" id="nav-setting-tab-2">
            <!-- Notification START -->
            <div class="card">
              <!-- Card header START -->
              <div class="card-header border-0 pb-0">
                <h5 class="card-title">Notification</h5>
                <p class="mb-0">Tried law yet style child. The bore of true of no be deal. Frequently sufficient to be
                  unaffected. The furnished she concluded depending procuring concealed. </p>
              </div>
              <!-- Card header START -->
              <!-- Card body START -->
              <div class="card-body pb-0">
                <!-- Notification START -->
                <ul class="list-group list-group-flush">
                  <!-- Notification list item -->
                  <li class="list-group-item d-flex justify-content-between align-items-center px-0 py-3">
                    <div class="me-2">
                      <h6 class="mb-0">Likes and Comments</h6>
                      <p class="small mb-0">Joy say painful removed reached end.</p>
                    </div>
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="NotiSwitchCheckChecked" checked>
                    </div>
                  </li>
                  <!-- Notification list item -->
                  <li class="list-group-item d-flex justify-content-between align-items-center px-0 py-3">
                    <div class="me-2">
                      <h6 class="mb-0">Reply to My comments</h6>
                      <p class="small mb-0">Ask a quick six seven offer see among.</p>
                    </div>
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="NotiSwitchCheckChecked2"
                        checked>
                    </div>
                  </li>
                  <!-- Notification list item -->
                  <li class="list-group-item d-flex justify-content-between align-items-center px-0 py-3">
                    <div class="me-2">
                      <h6 class="mb-0">Subscriptions</h6>
                      <p class="small mb-0">Preference any astonished unreserved Mrs.</p>
                    </div>
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="NotiSwitchCheckChecked3">
                    </div>
                  </li>
                  <!-- Notification list item -->
                  <li class="list-group-item d-flex justify-content-between align-items-center px-0 py-3">
                    <div class="me-2">
                      <h6 class="mb-0">Birthdays</h6>
                      <p class="small mb-0">Contented he gentleman agreeable do be</p>
                    </div>
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="NotiSwitchCheckChecked4">
                    </div>
                  </li>
                  <!-- Notification list item -->
                  <li class="list-group-item d-flex justify-content-between align-items-center px-0 py-3">
                    <div class="me-2">
                      <h6 class="mb-0">Events</h6>
                      <p class="small mb-0">Fulfilled direction use continually.</p>
                    </div>
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="NotiSwitchCheckChecked5"
                        checked>
                    </div>
                  </li>
                  <!-- Notification list item -->
                  <li class="list-group-item px-0 py-3">
                    <!-- Accordion START -->
                    <div class="accordion accordion-flush border-0" id="emailNotifications">
                      <!-- Accordion item -->
                      <div class="accordion-item bg-transparent">
                        <h2 class="accordion-header" id="flush-headingOne">
                          <a href="#!" class="accordion-button mb-0 p-0 collapsed bg-transparent shadow-none"
                            data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false"
                            aria-controls="flush-collapseOne">
                            <span>
                              <span class="mb-0 h6 d-block">Email notifications</span>
                              <small class="small mb-0 text-secondary">As hastened oh produced prospect. </small>
                            </span>
                          </a>
                        </h2>
                        <div id="flush-collapseOne" class="accordion-collapse collapse"
                          aria-labelledby="flush-headingOne" data-bs-parent="#emailNotifications">
                          <div class="accordion-body p-0 pt-3">
                            <!-- Notification list item -->
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="NotiSwitchCheckChecked6"
                                checked>
                              <label class="form-check-label" for="NotiSwitchCheckChecked6">
                                Product emails
                              </label>
                            </div>
                            <!-- Notification list item -->
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="NotiSwitchCheckChecke7">
                              <label class="form-check-label" for="NotiSwitchCheckChecke7">
                                Feedback emails
                              </label>
                            </div>
                            <hr>
                            <div class="mt-3">
                              <h6>Email frequency</h6>
                              <!-- Notification list item -->
                              <div class="form-check">
                                <input class="form-check-input" type="radio" name="NotiRadio" id="NotiRadio1">
                                <label class="form-check-label" for="NotiRadio1">
                                  Daily
                                </label>
                              </div>
                              <!-- Notification list item -->
                              <div class="form-check">
                                <input class="form-check-input" type="radio" name="NotiRadio" id="NotiRadio2" checked>
                                <label class="form-check-label" for="NotiRadio2">
                                  Weekly
                                </label>
                              </div>
                              <!-- Notification list item -->
                              <div class="form-check">
                                <input class="form-check-input" type="radio" name="NotiRadio" id="NotiRadio3">
                                <label class="form-check-label" for="NotiRadio3">
                                  Periodically
                                </label>
                              </div>
                              <!-- Notification list item -->
                              <div class="form-check">
                                <input class="form-check-input" type="radio" name="NotiRadio" id="NotiRadio4" checked>
                                <label class="form-check-label" for="NotiRadio4">
                                  Off
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Accordion END -->
                  </li>
                  <!-- Notification list item -->
                  <li class="list-group-item d-flex justify-content-between align-items-center px-0 py-3">
                    <div class="me-2">
                      <h6 class="mb-0">Push notifications</h6>
                      <p class="small mb-0">Rendered six say his striking confined. </p>
                    </div>
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="NotiSwitchCheckChecked8"
                        checked>
                    </div>
                  </li>
                  <!-- Notification list item -->
                  <li class="list-group-item d-flex justify-content-between align-items-center px-0 py-3">
                    <div class="me-2">
                      <h6 class="mb-0">Weekly account summary <span class="badge bg-primary smaller"> Pro only</span>
                      </h6>
                      <p class="small mb-0">Rendered six say his striking confined. </p>
                    </div>
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="NotiSwitchCheckChecked9"
                        disabled>
                    </div>
                  </li>
                </ul>
                <!-- Notification END -->

              </div>
              <!-- Card body END -->
              <!-- Button save -->
              <div class="card-footer pt-0 text-end border-0">
                <button type="submit" class="btn btn-sm btn-primary mb-0">Save changes</button>
              </div>
            </div>
            <!-- Notification END -->
          </div>
          <!-- Notification tab END -->

          <!-- Privacy and safety tab START -->
          <div class="tab-pane fade" id="nav-setting-tab-3">
            <!-- Privacy and safety START -->
            <div class="card">
              <!-- Card header START -->
              <div class="card-header border-0 pb-0">
                <h5 class="card-title">Privacy and safety</h5>
                <p class="mb-0">See information about your account, download an archive of your data, or learn about
                  your account deactivation options</p>
              </div>
              <!-- Card header START -->
              <!-- Card body START -->
              <div class="card-body">
                <!-- Privacy START -->
                <ul class="list-group">

                  <!-- Privacy item -->
                  <li class="list-group-item d-md-flex justify-content-between align-items-start">
                    <div class="me-md-3">
                      <h6 class="mb-0"> Use two-factor authentication</h6>
                      <p class="small mb-0">Unaffected occasional thoroughly. Adieus it no wonders spirit houses. </p>
                    </div>
                    <button class="btn btn-primary-soft btn-sm mt-1 mt-md-0"> <i class="bi bi-pencil-square"></i>
                      Change</button>
                  </li>

                  <!-- Privacy item -->
                  <li class="list-group-item d-md-flex justify-content-between align-items-start">
                    <div class="me-md-3">
                      <h6 class="mb-0">Login activity</h6>
                      <p class="small mb-0">Select the language you use on social</p>
                    </div>
                    <button class="btn btn-primary-soft btn-sm mt-1 mt-md-0" data-bs-toggle="modal"
                      data-bs-target="#modalLoginActivity"> <i class="bi bi-eye"></i> View</button>
                  </li>

                  <!-- Privacy item -->
                  <li class="list-group-item d-md-flex justify-content-between align-items-start">
                    <div class="me-md-3">
                      <h6 class="mb-0">Manage your data and activity</h6>
                      <p class="small mb-0">Select a language for translation</p>
                    </div>
                    <button class="btn btn-primary-soft btn-sm mt-1 mt-md-0"> <i class="bi bi-pencil-square"></i>
                      Change</button>
                  </li>

                  <!-- Privacy item -->
                  <li class="list-group-item d-md-flex justify-content-between align-items-start">
                    <div class="me-md-3">
                      <h6 class="mb-0">Search history</h6>
                      <p class="small mb-0">Choose to autoplay videos on social</p>
                    </div>
                    <button class="btn btn-primary-soft btn-sm mt-1 mt-md-0"> <i class="bi bi-pencil-square"></i>
                      Change</button>
                  </li>

                  <!-- Privacy item -->
                  <li class="list-group-item d-md-flex justify-content-between align-items-start">
                    <div class="me-md-3">
                      <h6 class="mb-0">Permitted services</h6>
                      <p class="small mb-0">Choose if this feature appears on your profile</p>
                    </div>
                    <button class="btn btn-primary-soft btn-sm mt-1 mt-md-0"> <i class="bi bi-pencil-square"></i>
                      Change</button>
                  </li>

                  <li class="list-group-item d-md-flex justify-content-between align-items-start">
                    <div class="me-md-3">
                      <h6 class="mb-0">Private account</h6>
                      <p class="small mb-0">With a private account, only users you approve can follow you and watch your profile. Your existing followers won't be affected.</p>
                    </div>
                    <div class="form-check form-switch">
                      <input class="form-check-input"   [ngModel]="currentUser?.pAccount" (ngModelChange)="changePrivacy($event)" type="checkbox" role="switch" id="comSwitchCheckChecked"
                        >
                    </div>
                  </li>
                  <li class="list-group-item d-md-flex justify-content-between align-items-start">
                    <div class="me-md-3">
                      <h6 class="mb-0">Deactivation and deletion</h6>
                      <p class="small mb-0">Temporarily deactivate or permanently delete your Account.</p>
                    </div>
                    <button (click)="userDeactivate()" class="btn btn-primary-soft btn-sm mt-1 mt-md-0"  data-bs-toggle="modal"
                      data-bs-target="#modalLoginActivity"> <i class="bi bi-eye"></i> View</button>
                  </li>
                </ul>

                <!-- Privacy END -->
              </div>
              <!-- Card body END -->
              <!-- Button save -->
              <div class="card-footer pt-0 text-end border-0">
                <button type="submit" (click)="saveChanges()" class="btn btn-sm btn-primary mb-0">Save changes</button>
              </div>
            </div>
            <!-- Privacy and safety END -->
          </div>
          <div class="tab-pane fade" id="nav-setting-tab-10">
            <!-- Privacy and safety START -->
            <div class="card">
              <!-- Card header START -->
              <div class="card-header border-0 pb-0">
                <h5 class="card-title">Blocking</h5>
                <p class="mb-0">See information about your account, download an archive of your data, or learn about
                  your account deactivation options</p>
              </div>
              <!-- Card header START -->
              <!-- Card body START -->
              <div class="card-body">
                <!-- Privacy START -->
                <ul class="list-group">

                  <!-- Privacy item -->
                  <li class="list-group-item d-md-flex justify-content-between align-items-start">
                    <div class="me-md-3">
                      <h6 class="mb-0"> Block users</h6>
                      <p class="small mb-0">Once you block someone, that person can no longer see things you post on your timeline, tag you, invite you to events or groups, start a conversation with you, or add you as a friend. Note: Does not include apps, games or groups you both participate in. </p>
                    </div>
                    <button (click)="edit()" class="btn btn-primary-soft btn-sm mt-1 mt-md-0"> <i class="bi bi-pencil-square"></i>
                      Edit</button>
                  </li>

                  <!-- Privacy item -->
                  <li class="list-group-item d-md-flex justify-content-between align-items-start">
                    <div class="me-md-3">
                      <h6 class="mb-0">Login activity</h6>
                      <p class="small mb-0">Select the language you use on social</p>
                    </div>
                    <button class="btn btn-primary-soft btn-sm mt-1 mt-md-0" data-bs-toggle="modal"
                      data-bs-target="#modalLoginActivity"> <i class="bi bi-eye"></i> View</button>
                  </li>

                  <!-- Privacy item -->
                  <li class="list-group-item d-md-flex justify-content-between align-items-start">
                    <div class="me-md-3">
                      <h6 class="mb-0">Manage your data and activity</h6>
                      <p class="small mb-0">Select a language for translation</p>
                    </div>
                    <button class="btn btn-primary-soft btn-sm mt-1 mt-md-0"> <i class="bi bi-pencil-square"></i>
                      Change</button>
                  </li>

                  <!-- Privacy item -->
                  <li class="list-group-item d-md-flex justify-content-between align-items-start">
                    <div class="me-md-3">
                      <h6 class="mb-0">Search history</h6>
                      <p class="small mb-0">Choose to autoplay videos on social</p>
                    </div>
                    <button class="btn btn-primary-soft btn-sm mt-1 mt-md-0"> <i class="bi bi-pencil-square"></i>
                      Change</button>
                  </li>

                  <!-- Privacy item -->
                  <li class="list-group-item d-md-flex justify-content-between align-items-start">
                    <div class="me-md-3">
                      <h6 class="mb-0">Permitted services</h6>
                      <p class="small mb-0">Choose if this feature appears on your profile</p>
                    </div>
                    <button class="btn btn-primary-soft btn-sm mt-1 mt-md-0"> <i class="bi bi-pencil-square"></i>
                      Change</button>
                  </li>

                  <li class="list-group-item d-md-flex justify-content-between align-items-start">
                    <div class="me-md-3">
                      <h6 class="mb-0">Private account</h6>
                      <p class="small mb-0">With a private account, only users you approve can follow you and watch your profile. Your existing followers won't be affected.</p>
                    </div>
                    <div class="form-check form-switch">
                      <input class="form-check-input"   [ngModel]="currentUser?.pAccount" (ngModelChange)="changePrivacy($event)" type="checkbox" role="switch" id="comSwitchCheckChecked"
                        >
                    </div>
                  </li>
                </ul>

                <!-- Privacy END -->
              </div>
              <!-- Card body END -->
              <!-- Button save -->
              <div class="card-footer pt-0 text-end border-0">
                <button type="submit" (click)="saveChanges()" class="btn btn-sm btn-primary mb-0">Save changes</button>
              </div>
            </div>
            <!-- Privacy and safety END -->
          </div>
          <!-- Privacy and safety tab END -->

          <!-- Communications tab START -->
          <div class="tab-pane fade" id="nav-setting-tab-4">
            <!-- Communications START -->
            <div class="card">
              <!-- Title START -->
              <div class="card-header border-0 pb-0">
                <h5 class="card-title">Who can connect with you?</h5>
                <p class="mb-0">He moonlights difficult engrossed it, sportsmen. Interested has all Devonshire
                  difficulty gay assistance joy. Unaffected at ye of compliment alteration to.</p>
              </div>
              <!-- Title START -->
              <!-- Card body START -->
              <div class="card-body">
                <!-- Accordion START -->
                <div class="accordion" id="communications">
                  <!-- Accordion item -->
                  <div class="accordion-item bg-transparent">
                    <h2 class="accordion-header" id="communicationOne">
                      <button class="accordion-button mb-0 h6" type="button" data-bs-toggle="collapse"
                        data-bs-target="#communicationcollapseOne" aria-expanded="true"
                        aria-controls="communicationcollapseOne">
                        Connection request
                      </button>
                    </h2>
                    <!-- Accordion info -->
                    <div id="communicationcollapseOne" class="accordion-collapse collapse show"
                      aria-labelledby="communicationOne" data-bs-parent="#communications">
                      <div class="accordion-body">
                        <!-- Notification list item -->
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="ComRadio" id="ComRadio5">
                          <label class="form-check-label" for="ComRadio5">
                            Everyone on social (recommended)
                          </label>
                        </div>
                        <!-- Notification list item -->
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="ComRadio" id="ComRadio2" checked>
                          <label class="form-check-label" for="ComRadio2">
                            Only people who know your email address
                          </label>
                        </div>
                        <!-- Notification list item -->
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="ComRadio" id="ComRadio3">
                          <label class="form-check-label" for="ComRadio3">
                            Only people who appear in your mutual connection list
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Accordion item -->
                  <div class="accordion-item bg-transparent">
                    <h2 class="accordion-header" id="communicationTwo">
                      <button class="accordion-button mb-0 h6 collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#communicationcollapseTwo" aria-expanded="false"
                        aria-controls="communicationcollapseTwo">
                        Who can message you
                      </button>
                    </h2>
                    <!-- Accordion info -->
                    <div id="communicationcollapseTwo" class="accordion-collapse collapse"
                      aria-labelledby="communicationTwo" data-bs-parent="#communications">
                      <div class="accordion-body">
                        <ul class="list-group list-group-flush">
                          <!-- Notification list item -->
                          <li
                            class="list-group-item d-sm-flex justify-content-between align-items-center px-0 py-1 border-0">
                            <div class="me-2">
                              <p class="mb-0">Enable message request notifications</p>
                            </div>
                            <div class="form-check form-switch">
                              <input class="form-check-input" type="checkbox" role="switch" id="comSwitchCheckChecked">
                            </div>
                          </li>
                          <!-- Notification list item -->
                          <li
                            class="list-group-item d-sm-flex justify-content-between align-items-center px-0 py-1 border-0">
                            <div class="me-2">
                              <p class="mb-0">Allow connections to add you on group </p>
                            </div>
                            <div class="form-check form-switch">
                              <input class="form-check-input" type="checkbox" role="switch" id="comSwitchCheckChecked2"
                                checked>
                            </div>
                          </li>
                          <!-- Notification list item -->
                          <li
                            class="list-group-item d-sm-flex justify-content-between align-items-center px-0 py-1 border-0">
                            <div class="me-2">
                              <p class="mb-0">Allow Sponsored Messages </p>
                              <p class="small">Your personal information is safe with our marketing partners unless you
                                respond to their Sponsored Messages </p>
                            </div>
                            <div class="form-check form-switch">
                              <input class="form-check-input" type="checkbox" role="switch" id="comSwitchCheckChecked3"
                                checked>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- Accordion item -->
                  <div class="accordion-item bg-transparent">
                    <h2 class="accordion-header" id="communicationThree">
                      <button class="accordion-button mb-0 h6 collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#communicationcollapseThree" aria-expanded="false"
                        aria-controls="communicationcollapseThree">
                        How people can find you
                      </button>
                    </h2>
                    <!-- Accordion info -->
                    <div id="communicationcollapseThree" class="accordion-collapse collapse"
                      aria-labelledby="communicationThree" data-bs-parent="#communications">
                      <div class="accordion-body">
                        <ul class="list-group list-group-flush">
                          <!-- Notification list item -->
                          <li
                            class="list-group-item d-sm-flex justify-content-between align-items-center px-0 py-1 border-0">
                            <div class="me-2">
                              <p class="mb-0">Allow search engines to show your profile?</p>
                            </div>
                            <div class="form-check form-switch">
                              <input class="form-check-input" type="checkbox" role="switch" id="comSwitchCheckChecked4"
                                checked>
                            </div>
                          </li>
                          <!-- Notification list item -->
                          <li
                            class="list-group-item d-sm-flex justify-content-between align-items-center px-0 py-1 border-0">
                            <div class="me-2">
                              <p class="mb-0">Allow people to search by your email address? </p>
                            </div>
                            <div class="form-check form-switch">
                              <input class="form-check-input" type="checkbox" role="switch" id="comSwitchCheckChecked5">
                            </div>
                          </li>
                          <!-- Notification list item -->
                          <li
                            class="list-group-item d-sm-flex justify-content-between align-items-center px-0 py-1 border-0">
                            <div class="me-2">
                              <p class="mb-0">Allow Sponsored Messages </p>
                              <p class="small">Your personal information is safe with our marketing partners unless you
                                respond to their Sponsored Messages </p>
                            </div>
                            <div class="form-check form-switch">
                              <input class="form-check-input" type="checkbox" role="switch" id="comSwitchCheckChecked6"
                                checked>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Accordion END -->
              </div>
              <!-- Card body END -->
              <!-- Button save -->
              <div class="card-footer pt-0 text-end border-0">
                <button type="submit" class="btn btn-sm btn-primary mb-0">Save changes</button>
              </div>
            </div>
            <!-- Communications  END -->
          </div>
          <!-- Communications tab END -->

          <!-- Messaging tab START -->
          <div class="tab-pane fade" id="nav-setting-tab-5">
            <!-- Messaging privacy START -->
            <div class="card mb-4">
              <!-- Title START -->
              <div class="card-header border-0 pb-0">
                <h5 class="card-title">Messaging privacy settings</h5>
                <p class="mb-0">As young ye hopes no he place means. Partiality diminution gay yet entreaties
                  admiration. In mention perhaps attempt pointed suppose. Unknown ye chamber of warrant of Norland
                  arrived. </p>
              </div>
              <!-- Title START -->
              <div class="card-body">
                <!-- Settings style START -->
                <ul class="list-group list-group-flush">
                  <!-- Message list item -->
                  <li class="list-group-item d-flex justify-content-between align-items-center px-0">
                    <div class="me-2">
                      <h6 class="mb-0">Enable message request notifications</h6>
                    </div>
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="msgSwitchCheckChecked" checked>
                    </div>
                  </li>
                  <!-- Message list item -->
                  <li class="list-group-item d-flex justify-content-between align-items-center px-0">
                    <div class="me-2">
                      <h6 class="mb-0">Invitations from your network</h6>
                    </div>
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="msgSwitchCheckChecked2" checked>
                    </div>
                  </li>
                  <!-- Message list item -->
                  <li class="list-group-item d-flex justify-content-between align-items-center px-0">
                    <div class="me-2">
                      <h6 class="mb-0">Allow connections to add you on group</h6>
                    </div>
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="msgSwitchCheckChecked3">
                    </div>
                  </li>
                  <!-- Message list item -->
                  <li class="list-group-item d-flex justify-content-between align-items-center px-0">
                    <div class="me-2">
                      <h6 class="mb-0">Reply to comments</h6>
                    </div>
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="msgSwitchCheckChecked4">
                    </div>
                  </li>
                  <!-- Message list item -->
                  <li class="list-group-item d-flex justify-content-between align-items-center px-0">
                    <div class="me-2">
                      <h6 class="mb-0">Messages from activity on my page or channel</h6>
                    </div>
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="msgSwitchCheckChecked5" checked>
                    </div>
                  </li>
                  <!-- Message list item -->
                  <li class="list-group-item d-flex justify-content-between align-items-center px-0">
                    <div class="me-2">
                      <h6 class="mb-0">Personalise tips for my page</h6>
                    </div>
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="msgSwitchCheckChecked6" checked>
                    </div>
                  </li>
                </ul>
                <!-- Message END -->
              </div>
              <!-- Button save -->
              <div class="card-footer pt-0 text-end border-0">
                <button type="submit" class="btn btn-sm btn-primary mb-0">Save changes</button>
              </div>
            </div>
            <!-- Messaging privacy END -->
            <!-- Messaging experience START -->
            <div class="card">
              <!-- Card header START -->
              <div class="card-header border-0 pb-0">
                <h5 class="card-title">Messaging experience</h5>
                <p class="mb-0">Arrived off she elderly beloved him affixed noisier yet. </p>
              </div>
              <!-- Card header START -->
              <!-- Card body START -->
              <div class="card-body">
                <!-- Message START -->
                <ul class="list-group list-group-flush">
                  <!-- Message list item -->
                  <li class="list-group-item d-sm-flex justify-content-between align-items-center px-0">
                    <div class="me-2">
                      <h6 class="mb-0">Read receipts and typing indicators</h6>
                    </div>
                    <button class="btn btn-primary-soft btn-sm mt-1 mt-md-0"> <i class="bi bi-pencil-square"></i>
                      Change</button>
                  </li>
                  <!-- Message list item -->
                  <li class="list-group-item d-sm-flex justify-content-between align-items-center px-0">
                    <div class="me-2">
                      <h6 class="mb-0">Message suggestions</h6>
                    </div>
                    <button class="btn btn-primary-soft btn-sm mt-1 mt-md-0"> <i class="bi bi-pencil-square"></i>
                      Change</button>
                  </li>
                  <!-- Message list item -->
                  <li class="list-group-item d-sm-flex justify-content-between align-items-center px-0">
                    <div class="me-2">
                      <h6 class="mb-0">Message nudges</h6>
                    </div>
                    <button class="btn btn-primary-soft btn-sm mt-1 mt-md-0"> <i class="bi bi-pencil-square"></i>
                      Change</button>
                  </li>
                </ul>
                <!-- Message END -->
              </div>
              <!-- Card body END -->
              <!-- Button save -->
              <div class="card-footer pt-0 text-end border-0">
                <button type="submit" class="btn btn-sm btn-primary mb-0">Save changes</button>
              </div>
            </div>
            <!-- Messaging experience END -->
          </div>
          <!-- Messaging tab END -->


          <div class="tab-pane fade" id="nav-setting-tab-7">
            <!-- Card START -->

            <ng-container *ngIf="!!currentUser">
              <app-language-page [data]="currentUser" [updatePrice]="Pricing.Language" (updateBuyForm)="updateBuy()" (saveObject)="saveLanguage($event)" ></app-language-page>

            </ng-container>


            <!-- Card END -->
          </div>
          <div class="tab-pane fade" id="nav-setting-tab-8">
            <div class="card">
            <span>Premium</span>
            </div>
            <!-- Card START -->
          <app-pricing [data]="titleService.pricingData"></app-pricing>
            <!-- Card END -->
          </div>
          <div class="tab-pane fade" id="nav-setting-tab-9">
            <div class="card">
            <span>Verified</span>
            </div>

            <app-pricing [data]="titleService.pricingDataVerified"></app-pricing>


            <!-- Card END -->
          </div>
          <!-- Close account tab END -->

        </div>
        <!-- Setting Tab content END -->
      </div>

    </div> <!-- Row END -->
  </div>
  <!-- Container END -->

</main>
