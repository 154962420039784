<main>
  <div class="container">
    <div class="row">
      <div class="col-lg-3" *ngIf="checkUserID">

        <!-- Advanced filter responsive toggler START -->
        <!-- Divider -->
        <div class="d-flex align-items-center mb-4 d-lg-none">
          <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <span class="btn btn-primary"><i class="bi bi-sliders"></i></span>
            <span class="h6 mb-0 fw-bold d-lg-none ms-2">{{'header.cv' | translate}}</span>
          </button>
        </div>
        <!-- Advanced filter responsive toggler END -->

        <nav class="navbar navbar-light navbar-expand-lg mx-0">
          <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
            <!-- Offcanvas header -->
            <div class="offcanvas-header">
              <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas"
                aria-label="Close"></button>
            </div>

            <!-- Offcanvas body -->
            <div class="offcanvas-body p-0">
              <!-- Card START -->
              <div class="card w-100">
                <!-- Card body START -->
                <div class="card-body">

                  <!-- Side Nav START -->
                  <ul class="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a  [routerLinkActiveOptions]="{exact: true}"   [routerLinkActive]="['active']" [routerLink]="'/' + currentUser?.userUrl + '/cv'" class="nav-link d-flex mb-0" href="#nav-setting-tab-1" data-bs-toggle="tab"> <img
                          class="me-2 h-20px fa-fw" src="assets/images/icon/person-outline-filled.svg"
                          alt=""><span>{{'header.cv' | translate}} </span></a>
                    </li>

                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a [routerLinkActiveOptions]="{exact: true}"   [routerLinkActive]="['active']"  routerLink="search_locations" class="nav-link d-flex mb-0"
                       data-bs-toggle="tab"> <i class="bi bi-search fa-fw me-2 h-20px fa-fw"></i><span>{{'search_filter' | translate}} </span></a>
                    </li>
                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a [routerLinkActiveOptions]="{exact: true}"   [routerLinkActive]="['active']"  routerLink="branch" class="nav-link d-flex mb-0"
                       data-bs-toggle="tab"> <i class="bi bi-buildings fa-fw me-2 h-20px"></i><span>{{'Branchen.name' | translate}} </span></a>
                    </li>
                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a  [routerLinkActiveOptions]="{exact: true}"   [routerLinkActive]="['active']"  routerLink="privacy" class="nav-link d-flex mb-0"
                       data-bs-toggle="tab">  <i class="bi bi-lock  fa-fw me-2 h-20px"></i><span>{{'settings.privacy' | translate}} </span></a>
                    </li>


                  </ul>
                  <!-- Side Nav END -->

                </div>
                <!-- Card body END -->
                <!-- Card footer -->
                <div (click)="navigateToUser()" class="card-footer text-center py-2">
                  <a [routerLink]="" class="btn btn-link text-secondary btn-sm">View Profile </a>
                </div>
              </div>
              <!-- Card END -->
            </div>
            <!-- Offcanvas body -->


          </div>
        </nav>
      </div>
      <div class="col-lg-6 vstack gap-4">
        <div class="tab-content py-0 mb-0">
          <ng-container *ngIf="router.url.endsWith('cv')">
            <div class="card">
              <div class="card-body">


             <!--  <share-buttons [theme]="'modern-light'" style="
                    display: flex;
                    position: absolute;
                    justify-content: center;
                    position: relative;"
                  [include]="['facebook','twitter','linkedin','reddit','telegram','whatsapp','xing','email','copy']"
                  [size]="-4" [autoSetMeta]="false"></share-buttons> -->

                <div class="Toolbar-toolbar">
                  <div class="Toolbar-toolbar-wrapper">
                    <ng-container>
                      <div  id="second-navbar">
                        <div class="col-lg-12 align-items-center text-center justify-content-center nav-justified d-flex" style="flex-wrap: wrap"
                        >
                          <button class="btn btn-light col-md-1" (click)="navigateToUser()"
                            *ngIf="!router.url.endsWith('/cvm')">
                            <i class="bi bi-house"></i>
                          </button>
                            <button class="ms-2 btn btn-success-soft btn-sm mb-2 mb-sm-0" (click)="printPDF()">
                              <i class="me-1 bi bi-file-earmark-pdf text-danger-m1 text-120 w-2"></i>Print
                            </button>
                            <button  class="ms-2 btn btn-primary-soft btn-sm mb-2 mb-sm-0"
                            (click)="openShare({data: currentUser, isPage: false, url: currentUser.userUrl, isJob: false })"> <i
                              class="bi me-1 bi-share text-warning pe-2"></i><span>{{'job.share' | translate}}</span></button>
                              <ng-container>
                                <div class="row col-md-12" style="display: flex;
                                    position: relative; align-items: flex-end;
    margin-top: 0.5rem;">
                                  <ng-container *ngFor="let cv of currentUser?.cv.data; let i = index">

                                    <div class="col-sm-3">
                                      <!-- Birthday START -->
                                      <div class="d-flex align-items-center rounded border bg-white px-3 py-2"
                                        [ngClass]="{active: selectedIndexLanguage === i }">

                                        <!-- Date -->
                                        <p class="mb-0" (click)="changeCVLanguage(cv, i)">
                                          <strong>{{isPrimaryFnc(i)}}</strong>
                                        </p>
                                        <div class="dropdown ms-auto" *ngIf="checkUserID">
                                          <!-- Card share action menu -->
                                          <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="bi bi-three-dots"></i>
                                          </a>
                                          <!-- Card share action dropdown menu -->
                                          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction">
                                            <li *ngIf="checkUserID"><a class="dropdown-item"
                                                (click)="editLanguage({cvLanguage: true, cvData: cv, editCVData: true}, index)"> <i
                                                  class="bi bi-pencil-square fa-fw pe-2"></i>Edit</a></li>
                                            <li *ngIf="currentUser?.cv.data.length > 1 "><a class="dropdown-item" (click)="deleteCV(i)"> <i
                                                  class="bi bi-trash fa-fw pe-2"></i>Delete</a></li>

                                          </ul>
                                        </div>
                                      </div>
                                      <!-- Birthday END -->
                                    </div>

                                  </ng-container>

                                  <div class="col-sm-2 position-relative">
                                    <!-- Workplace on START -->
                                    <a class="btn btn-dashed rounded w-100" *ngIf="checkUserID"
                                      (click)="addNewLanguage({cvLanguage: true, cvData: currentUser.cv[0], editCVData: false})"><i
                                        class="bi bi-plus-circle-dotted me-1"></i></a>
                                    <!-- Workplace on END -->
                                  </div>
                                  <div *ngIf="checkId" class="col-lg-7 col-md-7 d-flex align-items-center" style="

                                  flex-direction: row;

                              ">

                              <div class="col-md-4">
                                <label>{{'search' | translate}}

                                </label>
                                <select [ngModel]="currentUser?.searchLocation?.active?.status"
                                  (ngModelChange)="addSearchActive($event); selectForChange()"
                                  class="form-control-sm me-2 col-md-12" id="cv-searchActive" name="cv-searchActive">
                                  <option *ngFor="let privacy of searchActive" [selected]="privacy"
                                    [ngValue]="privacy.id">
                                    {{ privacy.description | translate}}
                                  </option>
                                </select>
                              </div>

                              <div class="col-md-4">
                                <ng-container *ngIf="currentUser?.searchLocation?.active?.status === 1">
                                  <label>From: </label>
                                  <input type="date" [ngModel]="currentUser?.searchLocation?.active?.from | date: 'yyyy-MM-dd'" (ngModelChange)="currentUser?.searchLocation.active.from = $event; selectForChange()" name="qualificationsDate"  class="form-control-sm col-md-12 ms-1">
                                </ng-container>
                              </div>
                              <div class="flex
                              items-center" style="
                              flex-direction: row;
                              display: flex;
                              border:none;
                          " class="specialty col-1 px-0 mt-4 ms-2">
                                <ng-container *ngIf="selectSearch">

                                  <button class="btn btn-light me-2" (click)="saveSearchActive(searchLocation, index)">
                                    <i class="bi bi-check"></i>
                                  </button>
                                </ng-container>
                              </div>


                              </div>

                                </div>



                              </ng-container>

                          <ng-container>
                            <div *ngIf="checkId"
                              class="Toolbar-toolbar-section Toolbar-toolbar-variable Toolbar-toolbar-hiddenHandheld Toolbar-toolbar-hiddenTablet  col-md-4">
                              <div class="ThemeSelection-theme-selection-wrapper">
                                <div class="ThemeSelection-theme-selection-inner"
                                  *ngFor="let theme of themes; let id = index" (mouseenter)="showIcons(id)"
                                  (mouseleave)="showIcons(id)">
                                  <div class="ThemeSelection-theme-selection-theme" role="button">
                                    <div class="ThemeSelection-theme-selection-image" (click)="changeTheme(theme)">
                                      <img [src]="theme.image">
                                    </div>
                                    <div *ngIf="selectedThemeVisibleColor === id">
                                      <div class="ThemeSelection-theme-selection-colorChoice">
                                        <div class="ThemeSelection-theme-selection-color">
                                          <input class="input-border-radius" readonly [style.background]="color9"
                                            [cpOKButton]="true" [cpSaveClickOutside]="false"
                                            [cpOKButtonClass]="'btn btn-primary btn-xl'"
                                            (colorPickerChange)="onChangeColor($event)" [cpCancelButton]="true"
                                            [cpPresetColors]="['#000', '#eb4a3b', '#21478e', '#9c1c38', '#44afa4', 'rgb(236,64,64)']"
                                            [(colorPicker)]="color9" />
                                          <input class="input-border-radius" readonly [style.background]="color8"
                                            [cpOKButton]="true" [cpSaveClickOutside]="false"
                                            [cpOKButtonClass]="'btn btn-primary btn-sm'"
                                            (colorPickerChange)="onChangeColor($event)" [cpCancelButton]="true"
                                            [cpPresetColors]="['#000', '#eb4a3b', '#21478e', '#9c1c38', '#44afa4', 'rgb(236,64,64)']"
                                            [(colorPicker)]="color8" />
                                          <input class="input-border-radius" readonly [style.background]="color7"
                                            [cpOKButton]="true" [cpSaveClickOutside]="false"
                                            [cpOKButtonClass]="'btn btn-primary btn-sm'"
                                            (colorPickerChange)="onChangeColor($event)" [cpCancelButton]="true"
                                            [cpPresetColors]="['#000', '#eb4a3b', '#21478e', '#9c1c38', '#44afa4', 'rgb(236,64,64)']"
                                            [(colorPicker)]="color7" />

                                        </div>
                                      </div>
                                      <div class="ThemeSelection-theme-selection-colorChoice" style="margin-top: 1.8rem;">
                                        <div class="ThemeSelection-theme-selection-color">
                                          <input class="input-border-radius xl" readonly [style.background]="color9"
                                            [cpOKButton]="true" [cpSaveClickOutside]="false"
                                            [cpOKButtonClass]="'btn btn-primary btn-xs'"
                                            (colorPickerChange)="onChangeColor($event)" [cpCancelButton]="true"
                                            [cpPresetColors]="['#000', '#eb4a3b', '#21478e', '#9c1c38', '#44afa4', 'rgb(236,64,64)']"
                                            [(colorPicker)]="color9" />
                                          <input class="input-border-radius xl" readonly [style.background]="color8"
                                            [cpOKButton]="true" [cpSaveClickOutside]="false"
                                            [cpOKButtonClass]="'btn btn-primary btn-xs'"
                                            (colorPickerChange)="onChangeColor($event)" [cpCancelButton]="true"
                                            [cpPresetColors]="['#000', '#eb4a3b', '#21478e', '#9c1c38', '#44afa4', 'rgb(236,64,64)']"
                                            [(colorPicker)]="color8" />
                                          <input class="input-border-radius xl" readonly [style.background]="color7"
                                            [cpOKButton]="true" [cpSaveClickOutside]="false"
                                            [cpOKButtonClass]="'btn btn-primary btn-xs'"
                                            (colorPickerChange)="onChangeColor($event)" [cpCancelButton]="true"
                                            [cpPresetColors]="['#000', '#eb4a3b', '#21478e', '#9c1c38', '#44afa4', 'rgb(236,64,64)']"
                                            [(colorPicker)]="color7" />
                                          <input class="input-border-radius xl" readonly [style.background]="color6"
                                            [cpOKButton]="true" [cpSaveClickOutside]="false"
                                            [cpOKButtonClass]="'btn btn-primary btn-xs'"
                                            (colorPickerChange)="onChangeColor($event)" [cpCancelButton]="true"
                                            [cpPresetColors]="['#000', '#eb4a3b', '#21478e', '#9c1c38', '#44afa4', 'rgb(236,64,64)']"
                                            [(colorPicker)]="color6" />
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-container>


                          <!--   <span [tooltip]="'header.more' | translate" class="font-weight-600 dropdown-toggle px-2 col-md-2" type="button"
                                id="menu3" data-toggle="dropdown" *ngIf="checkId">
                                <i class="bi bi-grid-3x3-gap-fill" *ngIf="checkId"></i>

                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown02">
                                  <div class="px-0 text-center">
                                    <a class="btn btn-light" *ngIf="!model?.showPageNumbers" (click)="showPageNumbers()">Show Page
                                      Numbers
                                    </a>
                                    <a class="btn btn-light" *ngIf="model?.showPageNumbers" (click)="hidePageNumbers()">Hide Page
                                      Numbers
                                    </a>
                                  </div>
                                </div>
                              </span> -->



                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>

              </div>
            </div>
            <div id="nav-setting-tab-1">
              <ng-container>
                <div clickOutsideInner (clickOutsideInner)="removeClick()" id="printPDFCV"
                  style="font-size:12px;width: 100%; margin-top: 3rem; transition: transform 0.25s ease 0s;transform: scale(1.3);transform-origin: 50% 0px 0px;backface-visibility: hidden;;display: block;-webkit-font-smoothing: antialiased;">
                  <app-paginated-view [pageSize]="'A4'"
                    *ngIf="model?.style.theme === 'firstTemplate'" [pageNumbers]="model?.showPageNumbers"
                    class="Grid-grid-column" id="content" [style.color]="model.style?.color">
                    <ng-container>
                      <div class="Grid-grid-row" pageContent (click)="setFirstCat()" (mouseenter)="setFirstCat()"
                        (mouseleave)="removeClick()" class="row" #content
                        [ngClass]="{ 'isCatActive': selectedFirstCat && checkId}">
                        <div class="Grid-grid-column Grid-grid-column-12">
                          <div class="Header-header-header Header-header-minHeight first-template-header">
                            <div class="Title-title-titleWrapper first-template-titleWrapper">
                              <h4 *ngIf="model?.hideName" [style.color]="model.style.color">{{currentUser?.firstName}}
                                {{currentUser?.lastName}}</h4>
                              <h5 [style.color]="model.style.color">{{model?.newJobTitle}}</h5>
                              <div [innerHTML]="model?.description | noSanitize"></div>
                            </div>
                            <div class="Photo-photo-photoWrapper" *ngIf="model?.showCVPhoto">
                              <div class="Photo-photo-photo first-template-photo">
                                <img *ngIf="this.image" [src]="this.image" height="100" style="cursor:  pointer"
                                  (error)="img.src = '../../../assets/img/CV.jpg'" #img>
                                <img *ngIf="!this.image" src="../../../assets/img/CV.jpg" height="100"
                                  style="cursor: pointer;">
                              </div>
                            </div>
                          </div>
                          <ng-container *ngIf="checkId">
                            <div *ngIf="selectedFirstCat">
                              <div clickOutside (clickOutside)="removeClick()">
                                <ul>
                                  <li class="bi bi-pencil addIconTop"
                                    (click)="editHeaderDialog({edit: true, model: model, index: selectedIndexLanguage})">
                                  </li>
                                  <li class="bi bi-plus addIconBottomCategory" (click)="openDialog({model: model})"></li>
                                </ul>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container class="Grid-grid-grid">
                      <ng-container>
                        <ng-container class="Unit-unit-unitGroup"
                          *ngFor="let personalData of model?.personalData; let id = index">
                          <ng-container pageContent *ngIf="personalData.visible">
                            <div pageContent class="Unit-unit-unitGroup"
                              [ngClass]="{ 'isCatActive': selectedCategory === category.PersonalData && checkId}">
                              <div *ngIf="selectedCategory === category.PersonalData && checkId">
                                <div clickOutside (clickOutside)="removeClick()">
                                  <ul>
                                    <li class="bi bi-plus addIconTop" (click)="openDialog({model: model})"></li>
                                    <li class="bi bi-plus addIconBottomCategory" (click)="openDialog({model: model})"></li>
                                    <li class="bi bi-arrow-down moveIconDown"></li>
                                    <li class="bi bi-arrow-up moveIconTop"></li>
                                  </ul>
                                </div>
                              </div>

                              <div pageContent class="col-md-12" (mouseenter)="setCategory(category.PersonalData)"
                                (mouseleave)="removeClick()">
                                <div class="row height">
                                  <div class="col-md-4 col-sm-6 text-right tLine" [style.background]="model.style.color">
                                  </div>
                                  <h3 class="first-template-paragraphTitle Paragraph-paragraph-title height"
                                    [style.color]="model.style.color">
                                    <div class="Text-text-wrapper">
                                      <div class="Text-Text-text">{{'category.PersonalData' | translate}}</div>
                                    </div>
                                  </h3>
                                </div>
                              </div>
                              <div pageContent class="container-fluid">
                                <app-personal-data [personalData]="personalData" [model]="model" [user]="currentUser"
                                  [id]="id" [index]="selectedIndexLanguage" (deselectCategory)="test($event)">
                                </app-personal-data>
                              </div>
                            </div>
                          </ng-container>
                        </ng-container>
                      </ng-container>

                      <!-- Skills Component-->
                      <ng-container *ngFor="let skills of model?.skills; let i = index" class="Unit-unit-unitGroup">
                        <div class="Unit-unit-unitGroup" pageContent
                          [ngClass]="{ 'isCatActive': selectedCategory === category.Skills && checkId}">
                          <div *ngIf="selectedCategory === category.Skills && checkId" (clickOutsideInner)="removeClick()">
                            <div clickOutside (clickOutside)="removeClick()">
                              <ul>
                                <li class="bi bi-plus addIconTop" (click)="openDialog({model: model})"></li>
                                <li class="bi bi-plus addIconBottomCategory" (click)="openDialog({model: model})"></li>
                                <li class="bi bi-trash deleteIconRight" (click)="deleteSkillsCategory(i)"></li>
                                <li class="bi bi-arrow-down moveIconDown"></li>
                                <li class="bi bi-arrow-up moveIconTop"></li>
                              </ul>
                            </div>
                          </div>
                          <div pageContent class="col-md-12" (mouseenter)="setCategory(category.Skills)"
                            (mouseleave)="removeClick()" (click)="setCategory(category.Skills)">
                            <div class="row height">
                              <div class="col-md-4 col-sm-6 text-right tLine" [style.background]="model.style.color"></div>
                              <h3 class="first-template-paragraphTitle Paragraph-paragraph-title height"
                                [style.color]="model.style.color">
                                <div class="Text-text-wrapper">
                                  <div class="Text-Text-text">{{'category.Skills' | translate}}</div>
                                </div>
                              </h3>
                            </div>
                          </div>
                          <div pageContent class="container-fluid">
                            <ng-container
                              *ngFor="let skillObj of skills.subCategories; let i = index; let isFirst = first; let isLast = last"
                              class="col-md-12">
                              <app-skills pageContent [skillObj]="skillObj" [id]="i" [first]="isFirst" [last]="isLast"
                                [indexOfSelected]="selectedIndexLanguage" [skills]="skills" [model]="model"
                                [user]="currentUser" [indexOfLang]="selectedIndexLanguage"></app-skills>
                            </ng-container>
                          </div>
                        </div>
                      </ng-container>

                      <!--Education Component-->
                      <ng-container *ngFor="let education of model?.education; let index = index"
                        class="Unit-unit-unitGroup">
                        <ng-container *ngIf="education.subCategories.length > 0">
                          <div *ngIf="selectedCategory === category.Education && checkId"
                            [ngClass]="{ 'isCatActive': selectedCategory === category.Education}">
                            <div clickOutside (clickOutside)="removeClick()">
                              <ul>
                                <li class="bi bi-plus addIconTop" (click)="openDialog({model: model})"></li>
                                <li class="bi bi-plus addIconBottom" (click)="openDialog({model: model})"></li>
                                <li class="bi bi-trash deleteIconRight" (click)="deleteEducationCategory(index)"></li>
                                <li class="bi bi-arrow-down moveIconDown"></li>
                                <li class="bi bi-arrow-up moveIconTop"></li>
                              </ul>
                            </div>
                          </div>
                          <div pageContent class="col-md-12" (click)="setCategory(category.Education)">
                            <div class="row height">
                              <div class="col-md-4 col-sm-6 text-right tLine" [style.background]="model.style.color"></div>
                              <h3 class="first-template-paragraphTitle Paragraph-paragraph-title height"
                                [style.color]="model.style.color">
                                <div class="Text-text-wrapper">
                                  <div class="Text-Text-text">{{'category.Education' | translate}}</div>
                                </div>
                              </h3>
                            </div>
                          </div>
                          <!-- <div pageContent class="container-fluid">
                                  <ng-container *ngFor="let educationObj of education.subCategories; let i = index; let isFirst = first; let isLast = last">
                                    <app-education pageContent [educationObj]="educationObj" [id]="i" [isFirst]="isFirst" [isLast]="isLast"></app-education>
                                      </ng-container>
                                </div> -->
                          <ng-container
                            *ngFor="let educationObj of education.subCategories; let i = index; let isFirst = first; let isLast = last">
                            <div pageContent class="container-fluid">
                              <div pageContent class="Unit-unit" (click)="setIndex(i)"
                                [ngClass]="{ 'isActive': selectedIndex === i && checkId}" (mouseenter)="setIndex(i)"
                                (mouseleave)="removeClick()">
                                <div pageContent class="Line-line-container">
                                  <div class="Line-line-line">
                                    <div class="Field-field-fieldBase first-template-fieldField">
                                      <div class="Text-Text-wrapper">
                                        <div pageContent class="Text-Text-text">
                                          {{educationObj?.startDate | date:'MM.yyyy'}}
                                          <div class="float-right" *ngIf="!educationObj?.today">{{educationObj?.endDate |
                                            date:'MM.yyyy'}}
                                          </div>
                                          <div class="float-right" *ngIf="educationObj?.today">{{'present' | translate}}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="Field-field-fieldBase first-template-fieldValue">
                                    <div class="Text-Text-wrapper">
                                      <div class="Text-Text-text-wrapper">
                                        <b>{{educationObj?.title}}</b>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="Field-field-fieldBase first-template-fieldValue">
                                    <div class="Text-Text-wrapper">
                                      <div class="Text-Text-text-wrapper">
                                        <app-education-name [educationObj]="educationObj" [educationObject]="true">
                                        </app-education-name>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="Field-field-fieldBase first-template-fieldValue">
                                    <div class="Text-Text-wrapper">
                                      <div class="Text-Text-text-wrapper" aria-multiline="true"
                                        [innerHTML]="educationObj?.description | noSanitize">
                                      </div>
                                    </div>
                                  </div>
                                  <div class="Field-field-fieldBase first-template-fieldValue"
                                    *ngIf="educationObj?.showCompanyUrl">
                                    <div class="Text-Text-wrapper">
                                      <div pageContent class="Text-Text-text-wrapper">
                                        <app-education-name [educationObj]="educationObj" [educationCompanyURL]="true">
                                        </app-education-name>
                                      </div>
                                    </div>
                                  </div>
                                  <ng-container pageContent *ngIf="selectedIndex === i && checkId">
                                    <div clickOutside (clickOutside)="removeClick()">
                                      <ul>
                                        <li class="bi bi-pencil addIconTop"
                                          (click)="editEducation({edit: true, education: educationObj, model: model, index: i,  languageIndex: selectedIndexLanguage, user: currentUser})">
                                        </li>
                                        <button class="btn"><i class="bi bi-plus addIconBottom"
                                            (click)="addEducation({edit: false, model: model, languageIndex: selectedIndexLanguage, user: currentUser})"></i></button>
                                        <button [disabled]="education.subCategories.length === 1"
                                          (click)="deleteEducationSubCategory(i)" class="btn"><i
                                            class="bi bi-trash deleteIconRight"></i></button>
                                        <button class="btn btn-light btn-light-hover-buttons" [disabled]="isLast"
                                          (click)="swapArray(education.subCategories, i, i)">
                                          <li class="bi bi-arrow-down moveIconDown"></li>
                                        </button>
                                        <button class="btn btn-light btn-light-hover-buttons" [disabled]="isFirst"
                                          (click)="swapArrayTop(education.subCategories, i, i)">
                                          <li class="bi bi-arrow-up moveIconTop"></li>
                                        </button>
                                      </ul>
                                    </div>
                                  </ng-container>
                                </div>
                              </div>
                            </div>
                            <!--- <app-education pageContent [educationObj]="educationObj" [id]="i" [education]="education" [model]="model">
                                    </app-education> -->
                          </ng-container>
                        </ng-container>
                      </ng-container>


                      <!-- Career Component -->
                      <ng-container *ngFor="let careers of model?.careers; let id = index; " class="Unit-unit-unitGroup">
                        <ng-container *ngIf="careers.subCategories.length > 0">

                          <div *ngIf="selectedCategory === category.Career && checkId">
                            <div clickOutside (clickOutside)="removeClick()">
                              <ul>
                                <li class="bi bi-plus addIconTop" (click)="openDialog({model: model})"></li>
                                <li class="bi bi-plus addIconBottom" (click)="openDialog({model: model})"></li>
                                <button (click)="deleteCareerCategory(id)" class="btn"><i
                                    class="bi bi-trash deleteIconRight"></i></button>
                                <li class="bi bi-arrow-down moveIconDown"></li>
                                <li class="bi bi-arrow-up moveIconTop"></li>
                              </ul>
                            </div>
                          </div>
                          <div pageContent class="col-md-12" (click)="setCategory(category.Career)">
                            <div class="row height">
                              <div class="col-md-4 col-sm-6 text-right tLine" [style.background]="model.style.color"></div>
                              <h3 class="first-template-paragraphTitle Paragraph-paragraph-title height"
                                [style.color]="model.style.color">
                                <div class="Text-text-wrapper">
                                  <div class="Text-Text-text">{{'category.Career' | translate}}</div>
                                </div>
                              </h3>
                            </div>
                          </div>
                          <ng-container
                            *ngFor="let careerObj of careers.subCategories; let i = index; let isFirst = first; let isLast = last">
                            <div pageContent class="container-fluid">
                              <div pageContent [ngClass]="{ 'isActive': selectedCareerIndex === i && checkId}"
                                class="Unit-unit" (click)="setCareerIndex(i)"
                                (mouseenter)="setCareerIndex(i)" (mouseleave)="removeClick()">
                                <div class="Line-line-container" >
                                  <div class="Line-line-line">
                                    <div class="Field-field-fieldBase first-template-fieldField">
                                      <div class="Text-Text-wrapper">
                                        <div pageContent class="Text-Text-text">
                                          {{careerObj.startDate | date:'MM.yyyy'}}
                                          <div class="float-right" *ngIf="!careerObj.today">{{careerObj.endDate |
                                            date:'MM.yyyy'}}
                                          </div>
                                          <div class="float-right" *ngIf="careerObj.today">{{'present' | translate}}</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="Field-field-fieldBase first-template-fieldValue">
                                    <div class="Text-Text-wrapper">
                                      <div pageContent class="Text-Text-text-wrapper">
                                        <b>{{careerObj.role}}</b>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="Field-field-fieldBase first-template-fieldValue">
                                    <div class="Text-Text-wrapper">
                                      <div pageContent class="Text-Text-text-wrapper">
                                        <app-education-name [careerObj]="careerObj"
                                          [careerObject]="true"></app-education-name>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="Field-field-fieldBase first-template-fieldValue">
                                    <div class="Text-Text-wrapper">
                                      <div pageContent class="Text-Text-text-wrapper" aria-multiline="true"
                                        [innerHTML]="careerObj.description | noSanitize">
                                      </div>
                                    </div>
                                  </div>
                                  <div class="Field-field-fieldBase first-template-fieldValue"
                                    *ngIf="careerObj.showCompanyUrl">
                                    <div class="Text-Text-wrapper">
                                      <div pageContent class="Text-Text-text-wrapper">
                                        <app-education-name [careerObj]="careerObj"
                                          [companyURL]="true"></app-education-name>
                                      </div>
                                    </div>
                                  </div>

                                  <ng-container pageContent *ngIf="selectedCareerIndex === i &&  checkId">
                                    <div clickOutside (clickOutside)="removeClick()">
                                      <ul>
                                        <li class="bi bi-pencil addIconTop"
                                          (click)="editCareer({edit: true, career: careerObj, model: model, index: i, indexOfLang: selectedIndexLanguage})">
                                        </li>
                                        <li class="bi bi-plus addIconBottom"
                                          (click)="addCareer({edit: false, model: model, career: false, indexOfLang: selectedIndexLanguage, user: currentUser})"></li>
                                        <button [disabled]="careers.subCategories.length < 2 && i === 0"
                                          (click)="deleteCareerSubCategory(i)" class="btn"><i
                                            class="bi bi-trash deleteIconRight"></i></button>
                                        <button class="btn btn-light btn-light-hover-buttons" [disabled]="isLast"
                                          (click)="swapArray(careers.subCategories, i, i)"><i
                                            class="bi bi-arrow-down moveIconDown"></i></button>
                                        <button class="btn btn-light btn-light-hover-buttons" [disabled]="isFirst"
                                          (click)="swapArrayTop(careers.subCategories, i, i)"><i
                                            class="bi bi-arrow-up moveIconTop"></i></button>
                                      </ul>
                                    </div>
                                  </ng-container>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                          <ng-container *ngFor="let emptyObj of careers.emptySubContents; let iEmpty = index">
                            <app-empty-object pageContent [emptyObj]="emptyObj" [iEmpty]="iEmpty" [model]="model"
                              [isFromCareer]="true">
                            </app-empty-object>
                          </ng-container>
                        </ng-container>
                      </ng-container>



                      <!-- Files Component -->
                      <ng-container>
                        <ng-container
                          *ngFor="let file of model?.files; let i = index; let isFirst = first; let isLast = last"
                          class="Unit-unit-unitGroup">

                          <div class="Unit-unit-unitGroup" *ngIf="selectedCategory === category.Files && checkId"
                            [ngClass]="{ 'isCatActive': selectedCategory === category.Files}">
                            <div clickOutside (clickOutside)="removeClick()">
                              <ul>
                                <li class="bi bi-plus addIconTop" (click)="openDialog({model:model})"></li>
                                <li class="bi bi-plus addIconBottom" (click)="openDialog({model: model})"></li>
                                <li class="bi bi-trash deleteIconRight" (click)="deleteEducationCategory(i)"></li>
                                <li class="bi bi-arrow-down moveIconDown"></li>
                                <li class="bi bi-arrow-up moveIconTop"></li>
                              </ul>
                            </div>
                          </div>
                          <div pageContent class="col-md-12" (click)="setCategory(category.Files)">
                            <div class="row height">
                              <div class="col-md-4 col-sm-6 text-right tLine" [style.background]="model.style.color"></div>
                              <h3 class="first-template-paragraphTitle Paragraph-paragraph-title height"
                                [style.color]="model.style.color">
                                <div class="Text-text-wrapper">
                                  <div class="Text-Text-text">{{'category.Files' | translate}}</div>
                                </div>
                              </h3>
                            </div>
                          </div>
                          <ng-container
                            *ngFor="let fileObj of file.subCategories; let i = index; let isFirst = first; let isLast = last"
                            class="col-md-12">
                            <div pageContent class="container-fluid">
                              <div pageContent [ngClass]="{ 'isActive': selectedFileIndex === i && checkId}">
                                <div pageContent class="Line-line-container" (mouseenter)="setFileIndex(i)"
                                  (mouseleave)="removeClick()" (click)="setFileIndex(i)">
                                  <div class="Line-line-line">
                                    <div class="Field-field-fieldBase first-template-fieldField">
                                      <div pageContent class="Text-Text-wrapper">
                                        <div class="Text-Text-text">
                                          {{fileObj.name}}</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="Field-field-fieldBase first-template-fieldValue" *ngIf="fileObj.link">
                                    <div class="Text-Text-wrapper">
                                      <div class="Text-Text-text-wrapper">
                                        <a target="_blank" [href]="fileObj.link">{{fileObj.link}}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="Field-field-fieldBase first-template-fieldValue">
                                    <div class="Text-Text-wrapper">
                                      <div class="Text-Text-text-wrapper" aria-multiline="true"
                                        [innerHTML]="fileObj.description | noSanitize">
                                      </div>
                                    </div>
                                  </div>
                                  <ng-container pageContent *ngIf="selectedFileIndex === i && checkId">
                                    <div clickOutside (clickOutside)="removeClick()">
                                      <ul>
                                        <li class="bi bi-pencil addIconTop"
                                          (click)="editFile({edit: true, file: fileObj, model: model, index: i})">
                                        </li>
                                        <button (click)="addFile({edit: false, model: model})" class="btn"><i
                                            class="bi bi-plus addIconBottom"></i></button>
                                        <button [disabled]="file.subCategories.length === 1" (click)="deleteSubFile(i)"
                                          class="btn"><i class="bi bi-trash deleteIconRight"></i></button>
                                        <button class="btn btn-light btn-light-hover-buttons" [disabled]="isLast"
                                          (click)="swapArray(file.subCategories, i, i)">
                                          <li class="bi bi-arrow-down moveIconDown"></li>
                                        </button>
                                        <button class="btn btn-light btn-light-hover-buttons" [disabled]="isFirst"
                                          (click)="swapArrayTop(file.subCategories, i, i)">
                                          <li class="bi bi-arrow-up moveIconTop"></li>
                                        </button>
                                      </ul>
                                    </div>
                                  </ng-container>
                                </div>
                              </div>
                            </div>
                          </ng-container>

                        </ng-container>
                      </ng-container>


                      <!-- Empty Category -->
                      <ng-container *ngFor="let emptyCat of model?.emptyCategory; let i = index"
                        class="Unit-unit-unitGroup">
                        <div *ngIf="selectedCategory === category.Another && checkId"
                          [ngClass]="{ 'isCatActive': selectedCategory === category.Another}">
                          <div clickOutside (clickOutside)="removeClick()">
                            <ul>
                              <li class="bi bi-pencil addIconTop"
                                (click)="editEmptyCategory({edit: true, model: model, emptyCategory: emptyCat, index: i, user: currentUser})">
                              </li>
                              <li class="bi bi-plus addIconBottom" (click)="openDialog({model: model})"></li>
                              <li class="bi bi-trash deleteIconRight" (click)="deleteEmptyCategory(i)"></li>
                              <li class="bi bi-arrow-down moveIconDown"></li>
                              <li class="bi bi-arrow-up moveIconTop"></li>
                            </ul>
                          </div>
                        </div>
                        <div pageContent class="col-md-12" (click)="setCategory(category.Another)">
                          <div class="row height">
                            <div class="col-md-4 col-sm-6 text-right tLine" [style.background]="model.style.color"></div>
                            <h3 class="first-template-paragraphTitle Paragraph-paragraph-title height"
                              [style.color]="model.style.color">
                              <div class="Text-text-wrapper">
                                <div class="Text-Text-text">{{emptyCat.name}}</div>
                              </div>
                            </h3>
                          </div>
                        </div>
                        <ng-container
                          *ngFor="let emptySubObj of emptyCat.emptySubContents; let i = index; let isFirst = first; let isLast = last"
                          class="col-md-12">
                          <div pageContent class="container-fluid">
                            <div pageContent [ngClass]="{ 'isActive': selectedEmptySubCat === i && checkId}">
                              <div pageContent class="Line-line-container" (mouseenter)="setEmptySubCatIndex(i)"
                                (mouseleave)="removeClick()" (click)="setEmptySubCatIndex(i)">
                                <div class="Line-line-line">
                                  <div class="Field-field-fieldBase first-template-fieldField">
                                    <div class="Text-Text-wrapper">
                                      <div pageContent class="Text-Text-text">
                                        {{emptySubObj.name}}</div>
                                    </div>
                                  </div>
                                </div>
                                <div class="Field-field-fieldBase first-template-fieldValue">
                                  <div class="Text-Text-wrapper">
                                    <div pageContent class="Text-Text-text-wrapper" aria-multiline="true"
                                      [innerHTML]="emptySubObj.description | noSanitize">
                                    </div>
                                  </div>
                                </div>
                                <ng-container pageContent *ngIf="selectedEmptySubCat === i && checkId">
                                  <div clickOutside (clickOutside)="removeClick()">
                                    <li class="bi bi-pencil addIconTop"
                                      (click)="editEmptySubCat({edit: true, empty: emptySubObj, model: model, emptySubCat: true, index: i, user: currentUser})">
                                    </li>
                                    <button (click)="addEmptySubCat({edit: false, model: model, emptySubCat: true})"
                                      class="btn"><i class="bi bi-plus addIconBottom"></i></button>
                                    <button [disabled]="emptyCat.emptySubContents.length === 1"
                                      (click)="deleteEmptyCatFile(i)" class="btn"><i
                                        class="bi bi-trash deleteIconRight"></i></button>
                                    <button class="btn btn-light btn-light-hover-buttons" [disabled]="isLast"
                                      (click)="swapArray(emptyCat.emptySubContents, i, i)">
                                      <li class="bi bi-arrow-down moveIconDown"></li>
                                    </button>
                                    <button class="btn btn-light btn-light-hover-buttons" [disabled]="isFirst"
                                      (click)="swapArrayTop(education.subCategories, i, i)">
                                      <li class="bi bi-arrow-up moveIconTop"></li>
                                    </button>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </ng-container>
                      <!--Here closes the container for the whole page-->
                    </ng-container>

                  </app-paginated-view>


                  <!--


                      /* Second Template


                    -->

                  <app-paginated-view [pageSize]="'A4'" *ngIf="model && model?.style?.theme === 'secondTemplate'"
                    [pageNumbers]="model?.showPageNumbers" class="Grid-grid-column">
                    <ng-container>
                      <div class="Grid-grid-row" pageContent (click)="setFirstCat()" class="row" #content
                        [ngClass]="{ 'isCatActive': selectedFirstCat}">
                        <div class="Grid-grid-column Grid-grid-column-12">
                          <div class="Header-header-header Header-header-minHeight first-template-header">
                            <div class="Title-title-titleWrapper first-template-titleWrapper">
                              <h4 [style.color]="model.style.color" *ngIf="model?.hideName">{{currentUser?.firstName}}
                                {{currentUser?.lastName}}</h4>
                              <h5 [style.color]="model.style.color">{{model?.newJobTitle}}</h5>
                              <div [style.color]="model.style.color" [innerHtml]="model?.description | noSanitize"></div>
                            </div>
                            <div class="Photo-photo-photoWrapper" *ngIf="model?.showCVPhoto">
                              <div class="Photo-photo-photo first-template-photo">
                                <img *ngIf="this.image" [src]="this.image" height="100" style="cursor:  pointer"
                                  (error)="img.src = '../../../assets/img/CV.jpg'" #img>
                                <img *ngIf="!this.image" src="../../../assets/img/CV.jpg" height="100"
                                  style="cursor: pointer;">
                              </div>
                            </div>
                          </div>
                          <div *ngIf="selectedFirstCat">
                            <div clickOutside (clickOutside)="removeClick()">
                              <ul>
                                <li class="bi bi-pencil addIconTop"
                                  (click)="editHeaderDialog({edit: true, model: model, index: selectedIndexLanguage})"></li>
                                <li class="bi bi-plus addIconBottom" (click)="openDialog({model: model})"></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container pageContent class="Grid-grid-column Grid-grid-column-6">
                      <!--PersonalData-->
                      <ng-container class="Unit-unit-unitGroup"
                        *ngFor="let personalData of model?.personalData; let id = index">
                        <div pageContent *ngIf="personalData.visible">
                          <div class="Unit-unit-unitGroup" pageContent
                            [ngClass]="{ 'isCatActive': selectedCategory === category.PersonalData}">
                            <div *ngIf="selectedCategory === category.PersonalData">
                              <div clickOutside (clickOutside)="removeClick()">
                                <ul>
                                  <li class="bi bi-plus addIconTop" (click)="openDialog({model: model})"></li>
                                  <li class="bi bi-plus addIconBottom" (click)="openDialog({model: model})"></li>
                                  <li class="bi bi-arrow-down moveIconDown"></li>
                                  <li class="bi bi-arrow-up moveIconTop"></li>
                                </ul>
                              </div>
                            </div>

                            <div pageContent class="Unit-unit-unitGroup" (click)="setCategory(category.PersonalData)">
                              <div class="row height">
                                <h3 class="Paragraph-paragraph-paragraphTitle Elegant-elegant-cv height"
                                  [style.color]="model.style.color">
                                  <div class="Text-text-wrapper">
                                    <div class="Text-Text-text">{{'category.PersonalData' | translate}}</div>
                                  </div>
                                </h3>
                              </div>
                            </div>
                            <app-personal-data-sec [personalData]="personalData" [model]="model" [id]="id"
                              [user]="currentUser" [index]="selectedIndexLanguage" (deselectCategory)="test($event)">
                            </app-personal-data-sec>
                          </div>
                        </div>
                      </ng-container>

                      <!-- Career Sec Template-->
                      <ng-container *ngFor="let careers of model?.careers; let id = index" class="Unit-unit-unitGroup">

                        <div *ngIf="selectedCategory === category.Career"
                          [ngClass]="{ 'isCatActive': selectedCategory === category.Career}">
                          <div clickOutside (clickOutside)="removeClick()">
                            <ul>
                              <li class="bi bi-plus addIconTop" (click)="openDialog({model: model})"></li>
                              <li class="bi bi-plus addIconBottom" (click)="openDialog({model: model})"></li>
                              <button (click)="deleteCareerCategory(id)" class="btn"><i
                                  class="bi bi-trash deleteIconRight"></i></button>
                              <li class="bi bi-arrow-down moveIconDown"></li>
                              <li class="bi bi-arrow-up moveIconTop"></li>
                            </ul>
                          </div>
                        </div>
                        <div pageContent class="Unit-unit-unitGroup" (click)="setCategory(category.Career)">
                          <div class="row height">
                            <h3 class="Paragraph-paragraph-paragraphTitle Elegant-elegant-cv height"
                              [style.color]="model.style.color">
                              <div class="Text-text-wrapper">
                                <div class="Text-Text-text">{{'category.Career' | translate}}</div>
                              </div>
                            </h3>
                          </div>
                        </div>
                        <ng-container *ngFor="let careerObj of careers.subCategories; let i = index">
                          <div pageContent>
                            <div pageContent [ngClass]="{ 'isActive': selectedCareerIndex === i}">
                              <div class="Line-line-container Elegant-elegant-cv" (click)="setCareerIndex(i)">
                                <div class="Line-line-line">
                                  <div class="Field-field-fieldBase" [style.color]="model.style.color">
                                    <div class="Text-Text-wrapper">
                                      <div pageContent class="Text-Text-text">
                                        <div class="float-left">
                                          {{careerObj.startDate | date:'MM.yyyy'}}</div>
                                        <div class="Elegant-float-revert" *ngIf="!careerObj.today">{{careerObj.endDate |
                                          date:'MM.yyyy'}}</div>
                                        <div class="Elegant-float-revert" *ngIf="careerObj.today">{{'present' | translate}}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="Field-field-fieldBase">
                                    <div class="Text-Text-wrapper">
                                      <div pageContent class="Text-Text-text-wrapper">
                                        <b>{{careerObj.role}}</b>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="Field-field-fieldBase">
                                    <div class="Text-Text-wrapper">
                                      <div pageContent class="Text-Text-text-wrapper">
                                        {{careerObj.name}}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="Field-field-fieldBase">
                                    <div class="Text-Text-wrapper">
                                      <div pageContent class="Text-Text-text-wrapper" aria-multiline="true"
                                        [innerHTML]="careerObj.description | noSanitize">
                                      </div>
                                    </div>
                                  </div>
                                  <div class="Field-field-fieldBase" *ngIf="careerObj.showCompanyUrl">
                                    <div class="Text-Text-wrapper">
                                      <div pageContent class="Text-Text-text-wrapper">
                                        <a target="_blank" [href]="careerObj.companyUrl">{{careerObj.companyUrl}}
                                        </a>
                                      </div>
                                    </div>
                                  </div>

                                  <ng-container pageContent *ngIf="selectedCareerIndex === i">
                                    <div clickOutside (clickOutside)="removeClick()">
                                      <ul>
                                        <li class="bi bi-pencil addIconTop"
                                          (click)="editCareer({edit: true, career: careerObj, model: model})">
                                        </li>
                                        <li class="bi bi-plus addIconBottom"
                                          (click)="addCareer({edit: false, model: model, career: false})"></li>
                                        <button [disabled]="careers.subCategories.length < 2"
                                          (click)="deleteSubCategory(id)" class="btn"><i
                                            class="bi bi-trash deleteIconRight"></i></button>
                                        <li class="bi bi-arrow-down moveIconDown"></li>
                                        <li class="bi bi-arrow-up moveIconTop"></li>
                                      </ul>
                                    </div>
                                  </ng-container>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngFor="let emptyObj of careers.emptySubContents; let iEmpty = index">
                          <app-empty-object pageContent [emptyObj]="emptyObj" [iEmpty]="iEmpty" [model]="model"
                            [isFromCareer]="true">
                          </app-empty-object>
                        </ng-container>
                      </ng-container>

                      <!--Education Component-->
                      <ng-container *ngFor="let education of model?.education; let index = index"
                        class="Unit-unit-unitGroup">
                        <ng-container *ngIf="education.subCategories.length > 0">
                          <div *ngIf="selectedCategory === category.Education"
                            [ngClass]="{ 'isCatActive': selectedCategory === category.Education}">
                            <div clickOutside (clickOutside)="removeClick()">
                              <ul>
                                <li class="bi bi-plus addIconTop" (click)="openDialog({model: model})"></li>
                                <li class="bi bi-plus addIconBottom" (click)="openDialog({model: model})"></li>
                                <li class="bi bi-trash deleteIconRight" (click)="deleteEducationCategory(index)"></li>
                                <li class="bi bi-arrow-down moveIconDown"></li>
                                <li class="bi bi-arrow-up moveIconTop"></li>
                              </ul>
                            </div>
                          </div>
                          <div pageContent class="Unit-unit-unitGroup" (click)="setCategory(category.Education)">
                            <div class="row height">
                              <h3 class="Paragraph-paragraph-paragraphTitle Elegant-elegant-cv height"
                                [style.color]="model.style.color">
                                <div class="Text-text-wrapper">
                                  <div class="Text-Text-text">{{'category.Education' | translate}}</div>
                                </div>
                              </h3>
                            </div>
                          </div>
                          <ng-container *ngFor="let educationObj of education.subCategories; let i = index">
                            <app-education pageContent [educationObj]="educationObj"></app-education>
                            <div pageContent>
                              <div pageContent [ngClass]="{ 'isActive': selectedIndex === i}">
                                <div pageContent class="Line-line-container Elegant-elegant-cv" (click)="setIndex(i)">
                                  <div class="Line-line-line">
                                    <div class="Field-field-fieldBase" [style.color]="model.style.color">
                                      <div class="Text-Text-wrapper">
                                        <div pageContent class="Text-Text-text">
                                          <div class="float-left">{{educationObj.startDate | date:'MM.yyyy'}}</div>
                                          <div class="Elegant-float-revert" *ngIf="!educationObj.today">
                                            {{educationObj.endDate | date:'MM.yyyy'}}</div>
                                          <div class="Elegant-float-revert" *ngIf="educationObj.today">{{'present' |
                                            translate}}</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="Field-field-fieldBase">
                                    <div class="Text-Text-wrapper">
                                      <div pageContent class="Text-Text-text-wrapper">
                                        <b>{{educationObj.title}}</b>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="Field-field-fieldBase">
                                    <div class="Text-Text-wrapper">
                                      <div class="Text-Text-text-wrapper">
                                        {{educationObj.name}}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="Field-field-fieldBase">
                                    <div class="Text-Text-wrapper">
                                      <div class="Text-Text-text-wrapper" aria-multiline="true"
                                        [innerHTML]="educationObj.description | noSanitize">
                                      </div>
                                    </div>
                                  </div>
                                  <ng-container pageContent *ngIf="selectedIndex === i">
                                    <div clickOutside (clickOutside)="removeClick()">
                                      <ul>
                                        <li class="bi bi-pencil addIconTop"
                                          (click)="editEducation({edit: true, education: educationObj, model: model, languageIndex: selectedIndexLanguage})"></li>
                                        <button class="btn"><i class="bi bi-plus addIconBottom"
                                            (click)="addEducation({edit: false, model: model, languageIndex: selectedIndexLanguage})"></i></button>
                                        <button [disabled]="education.subCategories.length === 1"
                                          (click)="deleteEducationSubCategory(i)" class="btn"><i
                                            class="bi bi-trash deleteIconRight"></i></button>
                                        <li class="bi bi-arrow-down moveIconDown"></li>
                                        <li class="bi bi-arrow-up moveIconTop"></li>
                                      </ul>
                                    </div>
                                  </ng-container>
                                </div>
                              </div>
                            </div>
                          </ng-container>


                        </ng-container>
                      </ng-container>

                      <!-- Skills Component-->
                      <ng-container *ngFor="let skills of model?.skills; let i = index" class="Unit-unit-unitGroup">

                        <div *ngIf="selectedCategory === category.Skills"
                          [ngClass]="{ 'isCatActive': selectedCategory === category.Skills}">
                          <div clickOutside (clickOutside)="removeClick()">
                            <ul>
                              <li class="bi bi-plus addIconTop" (click)="openDialog({model: model})"></li>
                              <li class="bi bi-plus addIconBottom" (click)="openDialog({model: model})"></li>
                              <button (click)="deleteCareerCategory(i)" class="btn"><i
                                  class="bi bi-trash deleteIconRight"></i></button>
                              <li class="bi bi-arrow-down moveIconDown"></li>
                              <li class="bi bi-arrow-up moveIconTop"></li>
                            </ul>
                          </div>
                        </div>
                        <div pageContent class="Unit-unit-unitGroup" (click)="setCategory(category.Career)">
                          <div class="row height">
                            <h3 class="Paragraph-paragraph-paragraphTitle Elegant-elegant-cv height"
                              [style.color]="model.style.color">
                              <div class="Text-text-wrapper">
                                <div class="Text-Text-text">{{'category.Skills' | translate}}</div>
                              </div>
                            </h3>
                          </div>
                        </div>
                        <ng-container *ngFor="let skillObj of skills.subCategories; let i = index">
                          <app-skills-sec pageContent [skillObj]="skillObj" [id]="i" [skills]="skills" [model]="model"
                          [user]="currentUser" [indexOfLang]="selectedIndexLanguage">
                          </app-skills-sec>
                        </ng-container>
                      </ng-container>

                      <!-- Files Component -->

                      <ng-container *ngFor="let file of model?.files; let index = index" class="Unit-unit-unitGroup">

                        <div *ngIf="selectedCategory === category.Skills"
                          [ngClass]="{ 'isCatActive': selectedCategory === category.Skills}">
                          <div clickOutside (clickOutside)="removeClick()">
                            <ul>
                              <li class="bi bi-plus addIconTop" (click)="openDialog({model: model})"></li>
                              <li class="bi bi-plus addIconBottom" (click)="openDialog({model: model})"></li>
                              <button (click)="deleteCareerCategory(index)" class="btn"><i
                                  class="bi bi-trash deleteIconRight"></i></button>
                              <li class="bi bi-arrow-down moveIconDown"></li>
                              <li class="bi bi-arrow-up moveIconTop"></li>
                            </ul>
                          </div>
                        </div>
                        <div pageContent class="Unit-unit-unitGroup" (click)="setCategory(category.Career)">
                          <div class="row height">
                            <h3 class="Paragraph-paragraph-paragraphTitle Elegant-elegant-cv height"
                              [style.color]="model.style.color">
                              <div class="Text-text-wrapper">
                                <div class="Text-Text-text">{{'category.Files' | translate}}</div>
                              </div>
                            </h3>
                          </div>
                        </div>
                        <ng-container *ngFor="let fileObj of file.subCategories; let i = index" class="col-md-12">
                          <div pageContent [ngClass]="{ 'isActive': selectedFileIndex === i}">
                            <div pageContent class="Line-line-container Elegant-elegant-cv" (mouseenter)="setFileIndex(i)"
                              (mouseleave)="removeClick()">
                              <div class="Line-line-line">
                                <div class="Field-field-fieldBase">
                                  <div pageContent class="Text-Text-wrapper">
                                    <div class="Text-Text-text">
                                      {{fileObj.name}}</div>
                                  </div>
                                </div>
                              </div>
                              <div class="Field-field-fieldBase" *ngIf="fileObj.link">
                                <div class="Text-Text-wrapper">
                                  <div class="Text-Text-text-wrapper">
                                    <a target="_blank" [href]="fileObj.link">{{fileObj.link}}
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div class="Field-field-fieldBase">
                                <div class="Text-Text-wrapper">
                                  <div class="Text-Text-text-wrapper" aria-multiline="true"
                                    [innerHTML]="fileObj.description | noSanitize">
                                  </div>
                                </div>
                              </div>
                              <ng-container pageContent *ngIf="selectedFileIndex === i">
                                <div clickOutside (clickOutside)="removeClick()">
                                  <ul>
                                    <li class="bi bi-pencil addIconTop"
                                      (click)="editFile({edit: true, file: fileObj, model: model})">
                                    </li>
                                    <button (click)="addFile({edit: false, model: model})" class="btn"><i
                                        class="bi bi-plus addIconBottom"></i></button>
                                    <button [disabled]="file.subCategories.length === 1" (click)="deleteSubFile(i)"
                                      class="btn"><i class="bi bi-trash deleteIconRight"></i></button>
                                    <li class="bi bi-arrow-down moveIconDown"></li>
                                    <li class="bi bi-arrow-up moveIconTop"></li>
                                  </ul>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </ng-container>
                      </ng-container>

                      <!-- Empty Category -->
                      <ng-container *ngFor="let emptyCat of model?.emptyCategory; let i = index"
                        class="Unit-unit-unitGroup">
                        <div *ngIf="selectedCategory === category.Another"
                          [ngClass]="{ 'isCatActive': selectedCategory === category.Another}">
                          <div clickOutside (clickOutside)="removeClick()">
                            <ul>
                              <li class="bi bi-pencil addIconTop"
                                (click)="editEmptyCategory({edit: true, model: model, emptyCategory: emptyCat})"></li>
                              <li class="bi bi-plus addIconBottom" (click)="openDialog({model: model})"></li>
                              <li class="bi bi-trash deleteIconRight" (click)="deleteEmptyCategory(i)"></li>
                              <li class="bi bi-arrow-down moveIconDown"></li>
                              <li class="bi bi-arrow-up moveIconTop"></li>
                            </ul>
                          </div>
                        </div>
                        <div pageContent class="Unit-unit-unitGroup" (click)="setCategory(category.Another)">
                          <div class="row height">
                            <h3 class="Paragraph-paragraph-paragraphTitle Elegant-elegant-cv height"
                              [style.color]="model.style.color">
                              <div class="Text-text-wrapper">
                                <div class="Text-Text-text">{{emptyCat.name}}</div>
                              </div>
                            </h3>
                          </div>
                        </div>
                        <ng-container *ngFor="let emptySubObj of emptyCat.emptySubContents; let i = index">
                          <div pageContent>
                            <div pageContent [ngClass]="{ 'isActive': selectedEmptySubCat === i}">
                              <div pageContent class="Line-line-container Elegant-elegant-cv"
                                (mouseenter)="setEmptySubCatIndex(i)" (mouseleave)="removeClick()">
                                <div class="Line-line-line">
                                  <div class="Field-field-fieldBase">
                                    <div class="Text-Text-wrapper">
                                      <div pageContent class="Text-Text-text">
                                        {{emptySubObj.name}}</div>
                                    </div>
                                  </div>
                                </div>
                                <div class="Field-field-fieldBase">
                                  <div class="Text-Text-wrapper">
                                    <div pageContent class="Text-Text-text-wrapper" aria-multiline="true"
                                      [innerHTML]="emptySubObj.description | noSanitize">
                                    </div>
                                  </div>
                                </div>
                                <ng-container pageContent *ngIf="selectedEmptySubCat === i">
                                  <div clickOutside (clickOutside)="removeClick()">
                                    <li class="bi bi-pencil addIconTop"
                                      (click)="editEmptySubCat({edit: true, empty: emptySubObj, model: model, emptySubCat: true})">
                                    </li>
                                    <button (click)="addEmptySubCat({edit: false, model: model, emptySubCat: true})"
                                      class="btn"><i class="bi bi-plus addIconBottom"></i></button>
                                    <button [disabled]="emptyCat.emptySubContents.length === 1"
                                      (click)="deleteEmptyCatFile(i)" class="btn btn-none"><i
                                        class="bi bi-trash deleteIconRight"></i></button>
                                    <li class="bi bi-arrow-down moveIconDown"></li>
                                    <li class="bi bi-arrow-up moveIconTop"></li>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </div>

                        </ng-container>
                      </ng-container>


                    </ng-container>
                  </app-paginated-view>

                </div>
              </ng-container>
             </div>
          </ng-container>


          <!-- <ng-container *ngIf="checkUserID">
                <div class="overview-flex col-md-4">
                  <a data-toggle="collapse" style="display: flex;
                align-items: baseline;
                justify-content: space-around;
                flex-direction: row;" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample"
                    class="btn btn-light more-filter flex"><i class="bi bi-funnel-fill"></i><label>{{'search_filter' |
                      translate}}</label></a>
                </div>

                  <div class="collapse" id="collapseExample">
                    <div class="card card-body">
                      <div class="row" fxLayout="row" fxLayoutAlign="space-around center" style="align-items: center;">
                        <div class="row col-md-4">
                          <label>{{ "project.firstPage.workplace" | translate }}:*</label>
                          <select id="remote" [(ngModel)]="currentUser.searchLocation.remote" (ngModelChange)="remoteCheck($event)"  class="col-md-6 form-control-sm">
                          <option *ngFor="let remote of remoteArray" [ngValue]="remote.id" (change)="remoteCheck(remote)" [selected]="remote">
                            {{ remote.description | translate }}
                          </option>
                        </select>
                        </div>
                      <ng-container *ngFor="let searchLocation of currentUser?.searchLocation?.data; let index = index; let isFirst = first">
                        <div class="flex row">
                          <nav class="nav nav-pills flex-column flex-sm-row pr-3 pb-3" (click)="selectSearchFilter(index)">
                            <button class="btn btn-light" style="min-width: 75px;" [ngClass]="{ isActive: selectedSearchFilter === index }">{{searchLocation?.country}} {{searchLocation?.region}}</button>
                          </nav>
                          <button *ngIf="!isFirst" class="bi bi-trash remove-icon" (click)="deleteSearchFilter(index)"></button>
                          </div>
                        </ng-container>
                      </div>
                      <ng-container *ngFor="let searchLocation of currentUser?.searchLocation?.data; let index = index;">
                      <div >
                        <div class="flex row">
                          <div class="flex row" *ngIf="selectedSearchFilter === index">

                            <label>
                              <strong>{{"data.country" | translate }}:*</strong>
                            </label>
                            <li class="line form-row" style="margin-bottom: 0.75rem; align-items: center;">
                              <div class="col-md-6">
                                <select class="col-md-12 form-control" name="selectedBranch" id="selectBranch"
                                  [(ngModel)]="searchLocation.country" (ngModelChange)="onChange($event, index)" required>
                                  <option hidden value="" disabled selected>{{selectedCountry?.ISO_3}}</option>
                                  <option *ngFor="let country of countryJson" [value]="country.ISO_3" [selected]="selectedCountry"
                                    (change)="onChange(country, index)">
                                    {{ country.EN }} - ({{country.LokalName}})
                                  </option>
                                </select>

                              </div>
                            </li>
                            <div class="row">
                              <mat-button-toggle-group style="margin-bottom: 0.75rem;" class="mat-group-toggle-buttons"
                                [(value)]="searchLocation.region" #group="matButtonToggleGroup" multiple=true>
                                <mat-button-toggle *ngFor="let item of toggleOptions;" (change)="selectionChanged($event, index)" [value]="item">
                                  {{item}}</mat-button-toggle>
                              </mat-button-toggle-group>
                              <label class="row">
                                <p style="margin-left: 0.75rem;">{{"data.zip" | translate }}</p>
                              </label>
                            </div>
                            <ng-container *ngIf="searchLocation.region.length < 2">
                              <label class="row">
                                <input type="text" class="form-control col-md-4" style="margin-bottom: 0.75rem;"
                                  [(ngModel)]="searchLocation.postalCode">
                                <p style="margin-left: 0.75rem;">{{"data.zip" | translate }}-{{"area" | translate}}</p>
                              </label>
                            </ng-container>
                          </div>
                          <div class="row justify-content-between" style="width: 100%;" *ngIf="selectedSearchFilter === index">
                            <button class="btn btn-success col-md-2" (click)="saveSearchFilter()">
                              {{ "save" | translate }}
                            </button>

                          </div>
                        </div>
                      </div>

                    </ng-container>
                    <div class="row justify-content-between pt-2" style="width: 100%;">
                      <button class="btn btn-light" (click)="addNewSearchFilter()">
                        <i class="bi bi-plus-circle"></i>
                      </button>
                    </div>
                    </div>

                  </div>
              </ng-container>
   -->


   <div class="col-md-12 col-lg-12 vstack gap-4" *ngIf="router.url.endsWith('cv/search_locations')">
    <div id="nav-setting-tab-4">
        <app-service-area [dataArray]="currentUser?.searchLocation?.data" [data]="currentUser?.searchLocation.remote" [activeSearch]="true"  [user]="currentUser" (buttonSaved)="saveData($event)"></app-service-area>
     </div>

   </div>
   <div class="col-md-12 col-lg-12 vstack gap-4" *ngIf="router.url.endsWith('cv/branch')">
    <div id="nav-setting-tab-4">
      <div class="card">
        <div class="card-body">
          <form  class="form-grid" name="ngForm" #branchForm="ngForm" (ngSubmit)="branchForm.form.valid && save()">
          <div class="col-sm-6 col-lg-6">
            <label class="form-label">Branch (required)</label>
            <select class="form-select js-choice" data-search-enabled="true" id="selectedBranch" name="selectedBranch" #selectedBranch="ngModel"
            [ngModel]="currentUser?.branches">
              <option *ngFor="let level of branchesService.branches" [ngValue]="level.id" [selected]="level">    {{ level.description | translate }}</option>
            </select>
          </div>
          <div class="col-sm-6 col-lg-6">
            <label class="form-label">{{ "pagesInfo.categories" | translate }}</label>
            <select class="form-select js-choice" name="selectedCategory" #selectedCategory="ngModel" [ngModel]="currentUser?.categories?.name" (ngModelChange)="showChildren($event)" data-search-enabled="true">
              <option *ngFor="let level of categoryService.categories" [ngValue]="level.id" [selected]="level">    {{ level.name | translate }}</option>
            </select>
          </div>
          <div class="col-sm-6 col-lg-6">

            <label class="form-label">{{ "categories.subCategories.name" | translate }}</label>
            <select class="form-select js-choice" name="selectedSubCategory" #selectedSubCategory="ngModel" [ngModel]="currentUser?.categories?.subCategory[0].name" (ngModelChange)="showKeywords($event)" data-search-enabled="true">
              <option *ngFor="let level of categoryService.categories[indexOfCategory]?.subCategory" [ngValue]="level.id"
              (change)="showKeywords($event)">    {{ level.name | translate }}</option>
            </select>
          </div>
          <button [disabled]="!branchForm.dirty" class="btn btn-primary mt-3" type="submit">{{'save' | translate}}</button>
        </form>
        </div>

      </div>
     </div>

   </div>
   <div class="col-md-12 col-lg-12 vstack gap-4" *ngIf="router.url.endsWith('cv/privacy')">
    <div class="card">
      <div class="card-header">
        <b>Who can see my CV</b>
      </div>
      <div class="card-body">
        <div class="col-md-3 d-flex align-items-center">
          <label class="me-2">{{'settings.privacy' | translate}}</label>
          <button  class="btn btn-outline-light" style="border: none">
            <i [class]="icon" (click)="openPrivacy(currentUser?.cv?.privacy)" [tooltip]="text"></i>

          </button>
        </div>
        <br>

      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <b>Who can offer me Project/Job</b>
      </div>
      <div class="card-body">
        <div class="col-md-3 d-flex align-items-center">
          <label class="me-2">{{'settings.privacy' | translate}}</label>
          <button  class="btn btn-outline-light" style="border: none">
            <i [class]="iconOffer" (click)="openPrivacyOffer({enum: currentUser?.offer?.status, offer: true})"></i>
          </button>
          <span class="me-2" style="text-wrap: nowrap"  [innerHTML]="textOffer"></span>
        </div>
        <br>

      </div>
    </div>
   </div>





        </div>

      </div>
    </div>
  </div>
</main>
