import { PrivacyButtonModule } from './../../components/privacy-button/privacy-button.module';
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { GravatarModule } from "ngx-gravatar";
import { UserContactsComponent } from "./user-friends/user-contacts.component";
import { UserProfileComponent } from "./user-profile/user-profile.component";
import { UserRoutingModule } from "./user-routing.module";
import { SettingsNotificationComponent } from "./user-settings/settings-notification/settings-notification.component";
import { SidebarUserComponent } from "./user-settings/sidebar-user/sidebar-user.component";
import { UserSettingsComponent } from "./user-settings/user-settings.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import { UserFriendChatComponent } from './user-friends/user-friend-chat/user-friend-chat.component';
import { QuillModule } from "ngx-quill";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MomentModule } from "ngx-moment";
import { UserJobsPublishedComponent } from './user-jobs-published/user-jobs-published.component';
import { PipeModule } from "src/app/shared/pipes/pipe.module";
import { MatChipsModule } from "@angular/material/chips";
import { SmartitsChipsModule } from "src/app/smartits-ui/chips/smartits-chips.module";
import { DirectivesModule } from "src/app/shared/directives/directives.module";
import { UserProjectsPublishedComponent } from './user-projects-published/user-projects-published.component';
import { UserComponent } from './user/user.component';
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { EducationComponent } from "src/app/components/education/education.component";
import { EducationNameComponent } from "src/app/sharedComponents/education-name/education-name.component";
import { EducationNameModule } from "src/app/sharedComponents/education-name/education-name.module";
import { SearchPagePipe } from "src/app/shared/pipes/searchPage.pipe";
import { MaterialModule } from "src/app/material.module";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { LanguagePageModule } from "src/app/components/language-page/language-page.module";
import { PricingModule } from "src/app/components/pricing/pricing.module";
import { UserFollowersComponent } from './user-profile/user-followers/user-followers.component';
@NgModule({
  declarations: [
    UserContactsComponent,
    UserProfileComponent,
    UserSettingsComponent,
    SidebarUserComponent,
    SettingsNotificationComponent,
    UserFriendChatComponent,
    UserJobsPublishedComponent,
    UserProjectsPublishedComponent,
    UserComponent,
    UserFollowersComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    GravatarModule,
    UserRoutingModule,
    TooltipModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MatTooltipModule,
    QuillModule,
    FlexLayoutModule,
    MomentModule,
    PipeModule,
    MatChipsModule,
    SmartitsChipsModule,
    DirectivesModule,
    NgxIntlTelInputModule,
    EducationNameModule,
    FontAwesomeModule,
    MaterialModule,
    LanguagePageModule,
    PricingModule,
    PrivacyButtonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader
      },
      isolate: true
    }),
  ],
  exports: [
    UserContactsComponent,
    UserProfileComponent,
    UserSettingsComponent,
    SidebarUserComponent,
    SettingsNotificationComponent,
    UserFollowersComponent
  ]
})

export class UserModule {

}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
