<div class="container">
    <form class="form-grid" name="ngForm" #myForm="ngForm" (ngSubmit)="myForm.form.valid && saveData()" novalidate>
      <h3>{{'skills.qualifications' | translate}}</h3>

    <li class="line row">
      <div class="col-md-12 px-0">
        <li class="line form-row">
          <div class="col row col-md-12">
            <div class="col-md-12 px-0">
              <label>{{'qualifications.name' | translate}}</label>
              <input [(ngModel)]="data.qualifications.name" name="qualificationsName" #qualificationsName="ngModel" class="form-control-sm col-md-12" [ngClass]="{'is-invalid': myForm.submitted && qualificationsName.invalid}" required>
              <div *ngIf="myForm.submitted && qualificationsName.invalid" class="invalid-feedback">
                <div *ngIf="qualificationsName.errors.required">
                  {{ "qualifications.name" | translate }}
                  {{ "error.isRequired" | translate }}
                </div>
              </div>
            </div>
          </div>
        </li>
        <br>
        <label>{{ "career.company" | translate }}</label>
        <li class="line form-row">
          <div class="col col-md-12">
              <div class="row no-gutters">
                <div class="col input-group">
                  <span class="input-group-prepend" *ngIf="pageActive">
                    <div class="input-group-text bg-transparent border-right-0">
                      <img *ngIf="hasPhoto" [src]="this.image" ngx-gravatar alt="photo" [email]="'example@mail.com'"
                        fallback="mp" size="24" />
                      <img *ngIf="!hasPhoto" alt="no-photo" ngx-gravatar [email]="'example@mail.com'" fallback="mp"
                        size="24" />
                    </div>
                  </span>
                  <input class="form-control border-secondary border-right-0 rounded-0" type="text" [value]="pageActive"
                    [ngModel]="searchForPage" (ngModelChange)="searchForPage = $event; searchForPageTrue = true;"
                    name="careerName" #careerName="ngModel" id="example-search-input5" [readonly]="pageActive"
                    autocomplete="off">
                </div>
                <div class="col-auto">
                  <button class="btn btn-outline-secondary border-left-0 rounded-0 rounded-right" type="button"
                    (click)="deletePage()">
                    <i class="fa fa-close"></i>
                  </button>
                </div>
              </div>
              <ng-container *ngIf="searchForPageTrue">
                <div *ngFor="let page of (pages | searchPage:searchForPage)" class="card__list">
                  <div class="d-flex row pr-2 pl-2 align-items-center" (click)="addCompany(page)">
                    <img *ngIf="hasPhoto" [src]="this.image" ngx-gravatar alt="photo" [email]="'example@mail.com'"
                      fallback="mp" size="35" />
                    <img *ngIf="!hasPhoto" alt="no-photo" ngx-gravatar [email]="'example@mail.com'" fallback="mp"
                      size="35" />
                    <span class="pr-2 pl-2">
                      {{ page.name }}
                      <span> </span>
                    </span>
                  </div>
                </div>
                <div *ngIf="myForm.submitted && companyName.invalid" class="invalid-feedback">
                  <div *ngIf="companyName.errors.required">
                    {{ "career.name" | translate }}
                    {{ "error.isRequired" | translate }}
                  </div>
                </div>
              </ng-container>

          </div>
        </li>
        <br>
        <li class="line form-row">
          <div class="col row col-md-12">
            <div class="col-md-12 px-0">
              <label>{{'qualifications.date' | translate}}</label>
              <input type="date" [ngModel]="data.qualifications.issueDate | date: 'yyyy-MM-dd'" (ngModelChange)="data.qualifications.issueDate = $event" name="qualificationsDate"  class="form-control-sm col-md-12">
            </div>
          </div>
        </li>
        <br>
        <li class="line form-row">
          <div class="col row col-md-12">
            <div class="col-md-12 px-0">
              <label>{{'qualifications.id' | translate}}</label>
              <input type="text" [(ngModel)]="data.qualifications.issueID" name="issueID" #issueID="ngModel" class="form-control-sm col-md-12">
            </div>
          </div>
        </li>
        <br>
        <li class="line form-row">
          <div class="col row col-md-12">
            <div class="col-md-12 px-0">
              <label>{{'qualifications.url' | translate}}</label>
              <input type="text" [(ngModel)]="data.qualifications.issueUrl" name="issueUrl" class="form-control-sm col-md-12">
            </div>
          </div>
        </li>

      </div>
    </li>
    <mat-dialog-actions align="end">
      <button type="button" class="btn btn-secondary" mat-button mat-dialog-close>{{'cancel' | translate}}</button>
      <button type="submit" class="btn btn-success" mat-button>{{'save' | translate}}</button>
    </mat-dialog-actions>
  </form>

</div>
