import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import * as QuillNamespace from "quill";
let Quill: any = QuillNamespace;
Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageHandler", ImageHandler);
Quill.register("modules/videoHandler", VideoHandler);
import { ImageHandler, VideoHandler } from "ngx-quill-upload";
import ImageResize from "quill-image-resize-module";

import {
  Duration,
  EmploymentType,
  Status,
  Incomes,
  Remote,
} from "src/app/shared/enums/enums-model";
import { IssuePriority, IssueStatus, IssueType, JIssue } from "src/app/shared/interfaces/jira.model";
import { Job, Page, Project } from "src/app/shared/interfaces/model";
import { User } from "src/app/shared/interfaces/user.model";
import { AuthService } from "src/app/shared/services/auth.service";
import { JobsService } from "src/app/shared/services/jobs.service";
import { SenderService } from "src/app/shared/services/sender.service";
import { UserService } from "src/app/shared/services/user.service";
import {
  EditDetailsData,
  EditJobsDialogComponent,
} from "../edit-jobs-dialog/edit-jobs-dialog.component";
import * as fromTodoStore from '../../../store/jobs-state/+state/jobs-state.actions';
import { InitialState } from "src/app/store/jobs-state/+state/jobs-state.reducer";
import { CountryDataService } from "src/app/shared/services/country-data.service";
import { ToastService } from "src/app/shared/services/toast.service";
import { ProjectService } from "src/app/shared/services/project.service";
import { PagesService } from "src/app/shared/services/pages.service";
import { tap } from "rxjs/operators";
import { Location } from "@angular/common";

@Component({
  selector: "app-job-details",
  templateUrl: "./job-details.component.html",
  styleUrls: ["./job-details.component.scss"],
})
export class JobDetailsComponent implements OnInit, OnDestroy {
  public job = new Job();
  public pages$: Observable<Page[]>;
  public subscription: Subscription;
  countryJsonObject;
  countryJsonArray;
  modal = false;
  public jobManage;
  isSelected;
  description;
  selectedPageID;

  public remoteArray = [
    {
      id: Remote.onSite,
      description: "project.firstPage.onSite",
      percent: "0%",
    },
    {
      id: Remote.hybrid,
      description: "project.firstPage.hybrid",
      percent: "50%",
    },
    {
      id: Remote.remote,
      description: "project.firstPage.remote",
      percent: "100%",
    },
  ];

  public employmentType = [
    {
      id: EmploymentType.EmployedFullTime,
      description: "employmentType.EmployedFullTime",
    },
    {
      id: EmploymentType.EmployedPartTime,
      description: "employmentType.EmployedPartTime",
    },
    { id: EmploymentType.Internship, description: "employmentType.Internship" },
    { id: EmploymentType.Owner, description: "employmentType.Owner" },
    {
      id: EmploymentType.BordMember,
      description: "employmentType.Boardmember",
    },
    { id: EmploymentType.Volunteer, description: "employmentType.Volunteer" },
    {
      id: EmploymentType.SelfEmployed,
      description: "employmentType.SelfEmployed",
    },
    {
      id: EmploymentType.Shareholder,
      description: "employmentType.Shareholder",
    },
    { id: EmploymentType.Official, description: "employmentType.Official" },
    { id: EmploymentType.Recruiter, description: "employmentType.Recruiter" },
    { id: EmploymentType.Freelancer, description: "employmentType.Freelancer" },
    { id: EmploymentType.Partner, description: "employmentType.Partner" },
  ];
  public salaryArray = [
    { id: Incomes.hour, description: "incomes.hour" },
    { id: Incomes.month, description: "incomes.month" },
    { id: Incomes.year, description: "incomes.year" },
    { id: Incomes.total, description: "incomes.total" },
  ];

  public jobDurationArray = [
    {
      id: Duration.unlimited,
      description: "job.fourthPage.duration.unlimited",
    },
    {
      id: Duration.temporary,
      description: "job.fourthPage.duration.temporary",
    },
  ];
  selectedSkills: boolean;
  selectedQualifications: boolean;
  selectedDriveLicense: boolean;
  selectedLanguage: boolean;
  selectedPCKnowledge: boolean;
  selectedTasks: boolean;
  selectedSecondGroup: boolean;
  selectedFirstGroup: boolean;
  public jIssue = new JIssue();
  public remote;
  public employmentTypeString;
  public jobDurationString;
  public currencyObject;
  public paymentID;
  items$: Observable<Job>;
  public loggedUser: User;
  contactedUser: User;
  cancelledUser: User;
  userObject; Job;
  public userJob: Job;
  editorOptions;
  public page: Page;
  public isPage: boolean = false;
  selectedUser;
  isApplied;

  arrayPages;

  public project: Project;
  user: User;
  constructor(
    public router: Router,
    public receiver: SenderService,
    public jobService: JobsService,
    private http: HttpClient,
    private dialog: MatDialog,
    private userAuth: AuthService,
    private senderService: SenderService,
    public userService: UserService,
    private countryDataService: CountryDataService,
    private toastService: ToastService,
    private projectService: ProjectService,
    private pageService: PagesService,
    private location: Location
  ) {}


  ngOnInit(): void {

    this.editorOptions = {
      "emoji-toolbar": true,
      "emoji-textarea": true,
      "emoji-shortname": true,
      imageResize: true,
      imageDrop: true,
    }
    this.countryDataService.countryData$.pipe().subscribe(res => this.countryJsonArray = res);
    this.userService.user().subscribe(user => {
      this.user = user;
    })
    this.userService.getUserContactData(this.userAuth.userID).subscribe(res => {
      this.user = res;
    })


if(this.router.url.endsWith('apply') && this.router.url.includes('projects')) {

  if (localStorage.getItem("projectID")) {
    this.projectService.getProject(localStorage.getItem("projectID")).subscribe(res => {
      this.project = res;

    })

    this.pageService.getMyPagesOfProject().subscribe(t => {
      this.arrayPages = t;
    })
  }

} else {

  if (localStorage.getItem("projectID") && localStorage.getItem("userPageID")) {
    this.projectService.getProject(localStorage.getItem("projectID")).subscribe(t => {
      this.project = t;
    })
    this.pageService.getPage(localStorage.getItem("userPageID")).subscribe(t => {
      this.page = t;
      this.isPage = true;
    })
  }
  if (localStorage.getItem("projectID") && !localStorage.getItem("userPageID")) {
    this.projectService.getProject(localStorage.getItem("projectID")).subscribe(t => {
      this.project = t;
      this.isPage = false;

    })
  }
  if (localStorage.getItem("userID")) {
    this.senderService.changeDataForApply.subscribe((t:any) => {
      this.userObject = t;
      this.userService.getUserContactData(t.userID).subscribe(res => {
        this.contactedUser = res;
        this.cancelledUser = res;
      })
    });
  }
    if (localStorage.getItem("searchJobId")) {
      this.jobService
        .getJob(localStorage.getItem("searchJobId"))
        .subscribe((job) => {
          this.userJob = job;
        });
      }
    this.userService.user().subscribe(response => {
      this.loggedUser = response;
    })

  if (localStorage.getItem("jobID")) {
    this.jobService.getJob(localStorage.getItem("jobID")).subscribe((job) => {
      this.job = job;
      this.getRemoteID(this.job?.remote);
      this.getEmploymentTypeID(this.job?.employmentType);
      this.getJoDurationID(this.job?.duration);
      this.getCountryID(this.job?.country);
      this.getCurrencyID(this.job?.currency);
      this.getPaymentFormID(this.job?.incomes.status);
    });
  } else {
    this.subscription = this.receiver.dataJobID$.subscribe((job) => {
      this.jobService.getJob(job).subscribe((res) => {
        this.job = res;
        this.getRemoteID(this.job.remote);
        this.getEmploymentTypeID(this.job.employmentType);
        this.getJoDurationID(this.job.duration);
        this.getCountryID(this.job.country);
        this.getCurrencyID(this.job.currency);
        this.getPaymentFormID(this.job.incomes.status);
      });
    });
  }
}




  }

  ngOnDestroy(): void {
    localStorage.removeItem("userID");
    localStorage.removeItem("userPageID");
  }

  getRemoteID(id) {
    this.remote = this.remoteArray.find((test) => test.id === id)?.description;
  }
  getEmploymentTypeID(id) {
    this.employmentTypeString = this.employmentType.find(
      (t) => t.id === id
    )?.description;
  }
  getJoDurationID(id) {
    this.jobDurationString = this.jobDurationArray.find(
      (t) => t.id === id
    )?.description;
  }

  getCountryID(id) {
    this.countryJsonObject = this.countryJsonArray.find((t) => t.ISO_3 === id);
  }
  getCurrencyID(id) {
    this.currencyObject = this.countryJsonArray.find(
      (t) => t.currency.Alphabeticcode === id
    );
    console.log(this.currencyObject);
  }
  manageJob(jobID: string) {
    this.senderService.dataManageJobID({ID: jobID});
    console.log(this.router.url)
    this.router.navigateByUrl(this.router.url  +"/manage");
  }

  getPaymentFormID(id) {
    this.paymentID = this.salaryArray.find((t) => t.id === id)?.description;
  }

  applyJob(job) {
    this.modal = true;
    this.router.navigateByUrl(this.router.url + '/apply')
  }


  apply() {
    let jIssue: JIssue = {
      status: IssueStatus.Applied,
      message: {
        userId: this.userAuth.userID,
        description: this.jIssue.message.description,
      },
      userId: this.userAuth.userID,
      createdAt: new Date(),
      id: Math.floor(Math.random() * 9).toString() + 1,
      cancel: {
        status: Status.Accepted,
        timestamp: new Date()
      },
    }

  this.job.manage_.unshift(jIssue);
  this.user.messages.unshift({jobId: this.job._id, timestamp: new Date()})
    const job = this.user.savedJobs.findIndex(t => t.jobId === this.job._id);
    this.user.savedJobs.splice(job, 1);
    this.user.appliedJobs.unshift({ jobId: this.job._id, timestamp: new Date() });
    this.userService.updateUser(this.user._id, this.user);
    this.jobService.updateJob(this.job._id, this.job);
    this.router.navigateByUrl(this.router.url.replace('/apply', ""));
  }

  applyProject() {
    let jIssue: JIssue = {
      status: IssueStatus.Applied,
      message: {
        userId: this.userAuth.userID,
        description: this.jIssue.message.description,
      },
      userId: this.userAuth.userID,
      createdAt: new Date(),
      id: Math.floor(Math.random() * 9).toString() + 1,
      cancel: {
        status: Status.Accepted,
        timestamp: new Date()
      },
    }

  this.project.manage_.unshift(jIssue);
  this.user.messages.unshift({projectId: this.project._id, timestamp: new Date()});

  this.user.appliedProjects.unshift({ projectId: this.project._id, timestamp: new Date() });

    this.userService.updateUser(this.user._id, this.user);
    this.projectService.updateProject(this.project._id, this.project);
    localStorage.removeItem("projectID");
    this.router.navigateByUrl(this.router.url.replace('/apply', ""));
  }

  applyProjectForPage() {
    let jIssue: JIssue = {
      status: IssueStatus.Applied,
      message: {
        userId: this.userAuth.userID,
        pageId: this.page?._id,
        description: this.jIssue.message.description,
      },
      userId: this.userAuth.userID,
      pageId: this.page?._id,
      createdAt: new Date(),
      id: Math.floor(Math.random() * 9).toString() + 1,
      cancel: {
        status: Status.Accepted,
        timestamp: new Date()
      },
    }

  this.project.manage_.unshift(jIssue);
  if (this.page.appliedProjects) {
    this.page.appliedProjects.unshift({projectId: this.project._id, timestamp: new Date()})
  } else if (!this.page.appliedProjects) {
    this.page.appliedProjects = [] as any;
    this.page.appliedProjects.unshift({projectId: this.project._id, timestamp: new Date()})
  }

  if (this.page.messages) {
    this.page.messages.unshift({projectId: this.project._id, timestamp: new Date()});
  } else {
      this.page.messages = [] as any;
      this.page.messages.unshift({projectId: this.project._id, timestamp: new Date()});

  }


  // this.pageService.updatePage(this.page._id, this.page);
 //  this.projectService.updateProject(this.project._id, this.project);
  // this.pageService.updatePage(this.page._id, this.page);
 // this.router.navigateByUrl(this.router.url.replace('/apply', ""));

 //  this.user.messages.unshift({projectId: this.project._id, timestamp: new Date()});

   //this.user.appliedProjects.unshift({ projectId: this.project._id, timestamp: new Date() });

   // this.userService.updateUser(this.user._id, this.user);
   // this.projectService.updateProject(this.project._id, this.project);
    //localStorage.removeItem("projectID");
   // this.router.navigateByUrl(this.router.url.replace('/apply', ""));
  }

  contact() {
    let jIssue: JIssue = {
      status: IssueStatus.Applied,
      message: {
        userId: this.userAuth.userID,
        description: this.jIssue.message.description,
      },
      userId: this.contactedUser._id,
      createdAt: new Date(),
      cancel: {
        status: Status.Pending,
        timestamp: new Date(),
      },
      id: Math.floor(Math.random() * 9).toString() + 1
    }
    this.userJob.manage_.unshift(jIssue);
    this.contactedUser.messages.unshift({jobId: this.job._id, timestamp: new Date()});

    this.toastService.openSnackBar("Succesfull", "", 'start');
    this.jobService.updateJob(this.userJob._id, this.userJob);
    this.userService.updateUser(this.contactedUser._id, this.contactedUser);
    this.senderService.dataManageJobID({ID:this.userJob._id});
    this.router.navigateByUrl(this.user.userUrl + '/jobs/' + this.userJob._id + '/manage/board');
  }

  removeClick() {
    this.selectedSkills = false;
    this.selectedQualifications = false;
    this.selectedDriveLicense = false;
    this.selectedLanguage = false;
    this.selectedPCKnowledge = false;
    this.selectedTasks = false;
    this.selectedSecondGroup = false;
    this.selectedFirstGroup = false;
  }
  selectSecondGroup() {
    this.selectedSecondGroup = true;
  }
  selectFirstGroup() {
    this.selectedFirstGroup = true;
  }
  selectSkills() {
    this.selectedSkills = true;
  }
  selectQualifications() {
    this.selectedQualifications = true;
  }
  selectDriveLicense() {
    this.selectedDriveLicense = true;
  }
  selectLanguage() {
    this.selectedLanguage = true;
  }
  selectPCKnowledge() {
    this.selectedPCKnowledge = true;
  }
  selectTasks() {
    this.selectedTasks =  true;
  }

  editQualifications(data: EditDetailsData) {
    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        this.job.qualifications = res.qualifications;
        this.jobService.updateJob(this.job._id, this.job);
      }
    });
  }
  editPCKnowledge(data: EditDetailsData) {
    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        this.job.pcKnowledge = res.pcKnowledge;
        this.jobService.updateJob(this.job._id, this.job);
      }
    });
  }

  goToCV() {
    const url = this.user.userUrl + '/cv' ;
  window.open(url, '_blank');
  }
  editJobDriveLicense(data: EditDetailsData) {
    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        this.job.driveLicense = res.driveLicense;
        this.jobService.updateJob(this.job._id, this.job);
      }
    });
  }

  editJobLanguage(data: EditDetailsData) {
    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        this.job.language = res.language;
        this.jobService.updateJob(this.job._id, this.job);
      }
    });
  }
  editJobTasks(data: EditDetailsData) {
    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        this.job.skillsOffer = res.skillsOffer;
        this.jobService.updateJob(this.job._id, this.job);
      }
    });
  }
  editSecondGroup(data: EditDetailsData) {
    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res: Job) => {
      if (res) {
        this.job.incomes.budget = res.incomes.budget;
        this.job.incomes.status = res.incomes.status;
        this.job.currency = res.currency;
        if (res.duration === Duration.unlimited) {
          this.job.duration = Duration.unlimited;
          this.job.startDate = res.startDate;
        } else if (res.duration === Duration.temporary) {
          this.job.duration = Duration.temporary;
          this.job.startDate = res.startDate;
        }
        this.job.currency = res.currency;
        this.getCurrencyID(this.job.currency);
        this.getJoDurationID(this.job.duration);
        this.getPaymentFormID(this.job.incomes.status);

       //this.jobService.updateJob(this.job._id, this.job);
      }
    });
  }
  editFirstGroup(data: EditDetailsData)
  {
    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        this.job.positionTitle = res.positionTitle;
        this.job.employmentType = res.employmentType;
        this.job.remote = res.remote;
        this.job.street = res.street;
        this.job.postalCode = res.postalCode;
        this.job.city = res.city;
        this.job.country = res.country;
        this.job.state = res.state;
        this.getCountryID(this.job.country);
        this.jobService.updateJob(this.job._id, this.job);
      }
    });
  }

  editJobDescription(data: EditDetailsData) {
    this.dialog
      .open(EditJobsDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.job.description = res.description;
          this.jobService.updateJob(this.job._id, this.job);
        }
      });
  }
  publicJob() {
    this.router.navigateByUrl(this.router.url + "/public");
  }

  searchJob() {
    localStorage.setItem("searchJobId", this.job._id);
    localStorage.setItem("jobUrl", this.router.url);
    this.router.navigateByUrl(this.router.url + "/search" );
  }

  public get isMyJob() {
    return this.job?.userID === this.userAuth.userID;
  }
  test(event) {
    if (event?.isUser) {
     this.selectedUser = true;
     this.isApplied = this.user.appliedProjects.find(t => t.projectId === this.project._id);
     console.log(this.isApplied, "applie")


    } else {
      this.selectedUser = false;
      this.selectedPageID = event;
      this.pageService.getPage(this.selectedPageID).subscribe(res => {
        this.page = res;
        this.isApplied = this.page.appliedProjects.find(t => t.projectId === this.project._id);
        console.log(this.isApplied, "applie")
      });
    }
  }

  goToMessages() {
    if (this.selectedUser) {
      this.router.navigateByUrl("messages")
    } else {
      this.router.navigateByUrl(this.page.pageUrl + "/messages")
    }
  }


  applyForProject() {
    let jIssue: JIssue;
    if (this.selectedUser) {
      jIssue = {
        status: IssueStatus.Applied,
        message: {
          userId: this.userAuth.userID,
          description: this.description,
        },
        userId: this.userAuth.userID,
        createdAt: new Date(),
        id: Math.floor(Math.random() * 9).toString() + 1,
        cancel: {
          status: Status.Accepted,
          timestamp: new Date()
        },
      }
      this.project.manage_.unshift(jIssue);
      this.user.messages.unshift({projectId: this.project._id, timestamp: new Date()});
      this.user.appliedProjects.unshift({ projectId: this.project._id, timestamp: new Date() });
       this.userService.updateUser(this.user._id, this.user);
     this.projectService.updateProject(this.project._id, this.project);

     this.router.navigateByUrl("/" + this.user.userUrl + "/projects")


    } else if (!this.selectedUser) {
      jIssue = {
        status: IssueStatus.Applied,
        message: {
          userId: this.userAuth.userID,
          pageId: this.selectedPageID,
          description: this.description,
        },
        userId: this.userAuth.userID,
        pageId: this.selectedPageID,
        createdAt: new Date(),
        id: Math.floor(Math.random() * 9).toString() + 1,
        cancel: {
          status: Status.Accepted,
          timestamp: new Date()
        },
      }
      this.project.manage_.unshift(jIssue);
      this.pageService.getPage(this.selectedPageID).subscribe(res => {
        this.page = res;
        if (this.page.appliedProjects) {
          this.page.appliedProjects.unshift({projectId: this.project._id, timestamp: new Date()})
        } else if (!this.page.appliedProjects) {
          this.page.appliedProjects = [] as any;
          this.page.appliedProjects.unshift({projectId: this.project._id, timestamp: new Date()})
        }

        if (this.page.messages) {
          this.page.messages.unshift({projectId: this.project._id, timestamp: new Date()});
        } else {
            this.page.messages = [] as any;
            this.page.messages.unshift({projectId: this.project._id, timestamp: new Date()});

        }
        this.pageService.updatePage(this.page._id, this.page);
       this.projectService.updateProject(this.project._id, this.project);
     this.router.navigateByUrl("/" + this.page.pageUrl + "/projects/ourprojects")


      })

    //   this.pageService.updatePage(this.page._id, this.page);
     // this.projectService.updateProject(this.project._id, this.project);

    }
  }

  sentBack() {
    this.userObject.manage_.find(t => t.userId === this.userAuth.userID).cancel.status = 2;
    this.userObject.manage_.find(t => t.userId === this.userAuth.userID).cancel.timestamp = new Date();
    this.userObject.manage_.find(t => t.userId === this.userAuth.userID).cancel.description = this.jIssue?.message?.description;
    this.userObject.manage_.find(t => t.userId === this.userAuth.userID).cancel.cancelID = this.userAuth.userID;
    this.jobService.updateJob(this.userObject._id, this.userObject);
    this.router.navigateByUrl("messages");
  }

  cancel() {
    this.location.back();
  }

}
