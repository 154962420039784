import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from "@angular/material/dialog";
import {Job, Model, Project, SkillOffer} from "../../../../shared/interfaces/model";
import { AuthService } from "src/app/shared/services/auth.service";
import { WarningDialogComponent } from "src/app/sharedComponents/warning-dialog/warning-dialog.component";
import { UserService } from "src/app/shared/services/user.service";
import { User } from "src/app/shared/interfaces/user.model";

@Component({
  selector: "app-offer-dialog",
  templateUrl: "./offer-dialog.component.html",
  styleUrls: ["./offer-dialog.component.scss"]
})
export class OfferDialogComponent implements OnInit {
  starCount = 5;
  name;
  public user: User;
  public existOnList: boolean = false;
  public confirmationDialog: MatDialogRef<WarningDialogComponent>

  constructor(@Inject(MAT_DIALOG_DATA) public data: EditOffer,
              private dialogRef: MatDialogRef<OfferDialogComponent>,
              public authService: AuthService,
              public dialog: MatDialog,
              public userService: UserService
           ) { }

  ngOnInit(): void {
    if(!this.data.createJob && !this.data.createProject && !this.data.createProjectSkills) {
      if (!this.data.offer) {
        this.data.offer = {} as SkillOffer[];
      } else {
        this.userService.getUserContactData(this.authService.userID).subscribe((res: User) => {
          this.user = res;
          this.data.offer = res.cv.data[this.data.indexOfLang].skills[0].subCategories[0].skillsOffer.data;
        });
      }
    } else if (this.data.createJob) {
      this.data.job = new Job();
      this.data.job.skillsOffer = [];
    } else if (this.data.createProject) {
      this.data.project = new Project();
      this.data.project.tasks = [];
    } else if (this.data.createProjectSkills) {
      this.data.project = new Project();
      this.data.project.skillsOffer = [];
    }
  }

  checkForSkill(event) {

    const title = new RegExp(`^(?:${this.name})+`, "g")

    console.log(title);


    const checkForName = this.data.offer.some(t => t.name.match(title))
    if (!!checkForName) {
      this.existOnList = true;
    } else {
      this.existOnList = false;
    }

  }

  addSkill() {
      this.data.offer.unshift({name: this.name, rate: 0});
      this.name = "";
  }

  addJobSkillOffer() {
    if(this.data.job.skillsOffer.length > 0) {
      this.data.job.skillsOffer.unshift({name: this.name, rate: 0});
    } else {
      this.data.job.skillsOffer = [];
      this.data.job.skillsOffer.unshift({name: this.name, rate: 0});
    }
    this.name = "";
  }

  addProjectTasks() {
    if(this.data.project?.tasks?.length > 0) {
      this.data.project.tasks.unshift({name: this.name});
    } else {
      this.data.project.tasks = [];
      this.data.project.tasks.unshift({name: this.name});
    }
    this.name = "";
  }
  addProjectSkillOffer() {
    if(this.data.project?.skillsOffer?.length > 0) {
      this.data.project.skillsOffer.unshift({name: this.name, rate: 0});
    } else {
      this.data.project.skillsOffer = [];
      this.data.project.skillsOffer.unshift({name: this.name, rate: 0});
    }
    this.name = "";
  }

  removeProjectTask(index) {
    this.data.project.tasks.splice(index, 1);
  }

  ratingJobSkill(index, rating) {
    this.data.job.skillsOffer[index].rate = rating * 20;
  }
  deleteOffer(index) {
    console.log(index)
    this.data.offer.splice(index, 1);
  }
  onRatingChanged(index, rating) {
    this.data.offer[index].rate = rating * 20;
  }
  saveJobSkills() {
    this.dialogRef.close(this.data.job);
  }

  saveProjectTasks() {
    this.dialogRef.close(this.data.project);
  }

  saveProjectSkillsOffer() {
    this.dialogRef.close(this.data.project);
  }
  saveData() {
    if(this.authService.isLoggedIn) {



      this.user.searchFilterSkills.searchSkillsOffer.data.unshift(...this.data.offer);
      const data = this.user.searchFilterSkills.searchSkillsOffer.data.filter((el, i, a) => i === a.findIndex((findTest) => findTest.name === el.name))

      this.user.searchFilterSkills.searchSkillsOffer.data = data;



   //   this.user.searchFilterSkills.searchSkillsOffer = this.user.cv[0].skills[0].subCategories[0].skillsOffer;
      this.userService.updateUser(this.user._id, this.user);
      this.dialogRef.close(this.user.cv.data[this.data.indexOfLang].skills[0].subCategories[0]);
      //this.dataService.updatePage(this.data.model._id, this.data.model);
    } else {
      this.dialogRef.close();
      this.confirmationDialog = this.dialog.open(WarningDialogComponent, {
        disableClose: false
      });

      this.confirmationDialog.afterClosed().subscribe(result => {
        if(result) {
          // do confirmation actions
        }
        this.confirmationDialog = null;
      });
    }
  }
}
export interface EditOffer {
  offer?: SkillOffer[];
  edit?: boolean;
  model?: Model;
  createJob?: boolean;
  createProject?: boolean;
  job?: Job;
  project?: Project;
  createProjectSkills?: boolean;
  indexOfLang?: number;
}

