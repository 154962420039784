import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CountryDataService } from 'src/app/shared/services/country-data.service';
import { PagesService } from 'src/app/shared/services/pages.service';
import { EditCategoryDialogComponent } from '../../dialogs/edit-category-dialog/edit-category-dialog.component';
import { Location } from 'src/app/shared/interfaces/model';
import _ from "lodash"

@Component({
  selector: 'app-location-dialog',
  templateUrl: './location-dialog.component.html',
  styleUrls: ['./location-dialog.component.scss']
})
export class LocationDialogComponent implements OnInit {
  public countryJsonArray;
  empty;
  toggleOptions: Array<String> = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

  isStreetActive: boolean = false;


  public digit;
  constructor(@Inject(MAT_DIALOG_DATA) public data: EditLocation,
  public dialog: MatDialog,
  private countryDataService: CountryDataService,
  private pageService: PagesService, private dialogRef: MatDialogRef<EditCategoryDialogComponent>,
) { }

  ngOnInit(): void {
    this.countryDataService.countryData$.pipe().subscribe(res => this.countryJsonArray = res);
    console.log(this.data.location.country);

    this.data.location = _.cloneDeep(this.data.location);

    if (this.data.isAddNewLocation) {
       this.data.location.region = [this.data.location.postalCode.toString()[0]];
       this.data.location.postalCode = this.data.location.postalCode.slice(0, 3);
    }
  }


  onChange(event) {

  }

  saveData() {
    this.dialogRef.close(this.data);
  }

  selectionChanged(item) {
    if (this.data.location.region.length > 1) {
      this.data.location.postalCode = null;
      this.data.location.postalCode = "";
    } else if (this.data.location.region.length === 0) {
      this.data.location.postalCode = null;
      this.data.location.postalCode = "";
    } else {
      this.data.location.region.forEach(
        (i) => (this.data.location.postalCode = i)
      );
    }
  }
}

export class EditLocation {
location: Location;
isEditLocation: false;
isAddNewLocation: false;
}
