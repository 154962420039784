<div class="card">
    <div class="card-header">
        <h5 class="card-title">

        </h5>
    </div>
    <div class="card-body">
        <div class="content-box is-disabled search-form" id="box_skills">
            <h6>{{'search_filter' | translate}}</h6>
            <div class="col-lg-12">
                <label class="px-0">{{ "project.firstPage.workplace" | translate }}:</label>
                <select id="remote" [(ngModel)]="data" (ngModelChange)="remoteCheck($event); selectWorkplace()" class="form-select col-md-8 form-control">
                    <option *ngFor="let remote of titleService.remoteArray" [ngValue]="remote.id" (change)="remoteCheck($event)" [selected]="remote">
                        {{ remote.description | translate }}
                    </option>
                </select>
                <div class="d-flex align-items-center specialty col-md-12 px-0 mt-1"  style="
                flex-direction: row;
                display: flex;
                border:none;
            ">
                    <ng-container *ngIf="selectedWorkplace">
                        <button  class="btn btn-light me-2" (click)="saveSearchLocation(searchLocation, index)">
                            <i class="bi bi-check"></i>
                             </button>
                          <button (click)="cancelNodeEdit()" class="btn btn-light">
                            <i class="bi bi-x"></i>
                          </button>
                    </ng-container>
                </div>
                <div class="search-widget">
                    <ul class="ui-selection-list px-0">
                        <li class="line form-row d-flex" style="flex-wrap: wrap;">
                            <ng-container *ngFor="let searchLocation of dataArray; let index = index">
                                <div class="col-12 col-lg-5 col-sm-5 card pb 2 pt 2 specialty me-2 mt 2 mb-2">
                                    <span *ngIf="dataArray.length > 1" class="bi bi-trash3-fill remove-icon col-2" (click)="deleteSearchFilter(index)"></span>
                                    <div class="col-12">
                                        <select name="selectedCountry"  id="selectedCountry" class="col-12 form-control-sm" [(ngModel)]="searchLocation.country" (ngModelChange)="onChange($event, index); selectIndexForButtonsPage(index)">
                                            <option  *ngFor="let country of countryJson" [value]="country.ISO_2" [selected]="selectedCountry"
                                            (change)="onChangeCountry(country)">
                                            {{ country.EN }} - ({{country.LokalName}})
                                            </option>


                                        </select>
                                    </div>
                                    <mat-button-toggle-group style="margin-bottom: 0.75rem;" class="mat-group-toggle-buttons"
                                    [(value)]="searchLocation.region" #group="matButtonToggleGroup" multiple=true>
                                    <mat-button-toggle *ngFor="let item of toggleOptions; let i = index"
                                      (change)="selectionChanged($event, index); selectIndexForButtonsPage(index)"
                                      [value]="item">{{item}}</mat-button-toggle>
                                  </mat-button-toggle-group>
                                  <ng-container *ngIf="selectedIndexPage === index; else readOnlyNote">
                                    <label class="column" *ngIf="searchLocation?.region.length < 2">
                                      <p>{{"data.zip" | translate }}-{{"area" | translate}}</p>
                                      <input type="text" class="form-control-sm col-8" style="margin-bottom: 0.75rem;"
                                        [(ngModel)]="searchLocation.postalCode">
                                    </label>
                                    <div class="flex
                                    items-center" style="
                                    flex-direction: row;
                                    display: flex;
                                    border:none;
                                " class="specialty col-md-12 px-0 mt-1">
                                      <ng-container *ngIf="selectedIndexPage === index">

                                        <button class="btn btn-light me-2" (click)="saveSearchLocation(searchLocation, index)">
                                          <i class="bi bi-check"></i>
                                        </button>
                                        <button (click)="cancelNodeEdit()" class="btn btn-light">
                                          <i class="bi bi-x"></i>
                                        </button>
                                      </ng-container>
                                    </div>

                                  </ng-container>
                                  <ng-template #readOnlyNote>
                                    <label class="column">
                                      <p *ngIf="searchLocation?.region.length < 2">{{"data.zip" | translate }}-{{"area" | translate}}</p>
                                      <input type="text" class="form-control-sm col-12" style="margin-bottom: 0.75rem;"
                                        *ngIf="searchLocation?.region.length < 2" [(ngModel)]="searchLocation.postalCode"
                                        (click)="selectIndexForButtonsPage(index)">
                                    </label>
                                  </ng-template>

                                </div>
                            </ng-container>
                            <div class="specialty" style="border: none;">
                                <button stopPropagation class="btn btn-light ms-1" (click)="addSearchLocation()">
                                  <i class="bi bi-plus-circle"></i></button>
                                </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
