import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/shared/interfaces/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StatusFollow } from 'src/app/shared/enums/enums-model';
import { ConfirmationDialogBlockedComponent } from '../confirmation-dialog-blocked/confirmation-dialog-blocked.component';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { url } from 'inspector';
import { debounceTime, shareReplay, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-blocked-user-dialog',
  templateUrl: './blocked-user-dialog.component.html',
  styleUrls: ['./blocked-user-dialog.component.scss']
})
export class BlockedUserDialogComponent implements OnInit {
  confirmationDialog: MatDialogRef<ConfirmationDialogBlockedComponent>;

  public searchUser = "";
  selectUser;
  allUsers: User[];
  authUser: User;
  userSelected: User;
  isBlocked;

  searchForm: UntypedFormGroup = new UntypedFormGroup({
    userSearch: new UntypedFormControl()
  });

  users: User[];
  constructor(private userService: UserService,
    private userAuth: AuthService,
    private dialog: MatDialog) { }

  ngOnInit(): void {

    this.userService.showBlocked(this.userAuth.userID).subscribe((users: User[]) => {
      this.users = users;
    })

  }

  addNewUser(user) {
    this.searchUser = `${user.firstName} ${user.lastName}`;
    this.selectUser = true;
    this.userSelected = user;

  }

  blockUser(userProperty: User) {

    const findUser = userProperty.following.data.find(t => t.userId === this.userAuth.userID && t.status && StatusFollow.Block);

    if (findUser) {
      return;
    } else {
      this.confirmationDialog = this.dialog.open(ConfirmationDialogBlockedComponent);
      this.confirmationDialog.componentInstance.isBlock = true;

      this.confirmationDialog.componentInstance.firstName = `${ userProperty?.firstName}`;
      this.confirmationDialog.componentInstance.lastName = `${ userProperty?.lastName}`;

      this.confirmationDialog.componentInstance.header =  `${'Block'} ${userProperty?.firstName} ${userProperty?.lastName}?` ;
      this.confirmationDialog.componentInstance.confirmButtonText = "Confirm";
      this.confirmationDialog.afterClosed().subscribe(result => {
        if (result) {
             this.userService.getUserContactData(this.userAuth.userID).subscribe((user: User) => {
              this.authUser = user;

              const blockedUser = this.authUser.following?.data?.findIndex(t => t.userId === user._id);

              if (blockedUser === -1) {
              } else {
               this.authUser.following.data.splice(blockedUser, 1);
              }

              const outBlockedUser = userProperty.following?.data?.find(t => t.userId === user._id);

              if (outBlockedUser) {
                outBlockedUser.status = StatusFollow.Block;
              } else {
                const blockUser = {
                  userId: this.userAuth.userID,
                  timestamp: new Date(),
                  status: StatusFollow.Block
                }

                userProperty.following.data.unshift(blockUser);
              }

            this.userService.updateUser(userProperty._id, userProperty);
             this.userService.updateUser(this.authUser._id, this.authUser);

             })
             this.userService.showBlocked(this.userAuth.userID).subscribe((users: User[]) => {
              this.users = users;
            })


        }
        });
    }

  }

  unblockUser(userProp: User) {
    this.confirmationDialog = this.dialog.open(ConfirmationDialogBlockedComponent);
    this.confirmationDialog.componentInstance.isUnblock = true;
    this.confirmationDialog.componentInstance.firstName = `${ userProp?.firstName}`;
    this.confirmationDialog.componentInstance.lastName = `${ userProp?.lastName}`;

    this.confirmationDialog.componentInstance.header =  `${'Unblock'} ${userProp?.firstName} ${userProp?.lastName}?` ;
    this.confirmationDialog.componentInstance.confirmButtonText = "Confirm";
    this.confirmationDialog.afterClosed().subscribe(result => {
      if (result) {
        this.userService.getUserContactData(this.userAuth.userID).subscribe((user: User) => {
          this.authUser = user;
          const outBlockedUser = userProp.following?.data?.findIndex(t => t.userId === this.authUser._id);
          userProp.following.data.splice(outBlockedUser, 1);

          this.userService.updateUser(userProp._id, userProp);

        });

      }
    });



  }

  searchForUser(name: string) {
    this.searchForm.valueChanges
      .pipe(debounceTime(500))
      .subscribe(data => {
        if (data.userSearch) {
          this.userService.searchUser(data.userSearch).pipe((take(1))).subscribe(res => {
            this.allUsers = res;
          })
        }
      });

  }

  set userBlocked(user: User) {
    this.isBlocked = user.following.data.find(t => t.userId === this.userAuth.userID && t.status === StatusFollow.Block);
  }

  get userBlocked() {
     return this.isBlocked;
  }

  getUser(userProperty) {
    return userProperty.following.data.find(t => t.userId === this.userAuth.userID && t.status && StatusFollow.Block);
  }




}
